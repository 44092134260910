import { API_ENDPOINT } from '../constants';
import { HTTP } from '../services/http.service';

export const UsersService = {
  getCompnieUsers: (data: { profile: string; company: string }) => {
    const { profile, company } = data;
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}users/${company}/`, {
      params: { profile }
    });
  },
  saveUser: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}users`, data);
  },
  editUser: (data: any) => {
    return HTTP.put<any, any>(`${API_ENDPOINT.CORE}users`, data);
  }
};
