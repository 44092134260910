import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './footer.module.scss';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <div className={`${styles.footer} footer d-flex flex-lg-column py-3`}>
        <div className="container-fluid d-flex flex-column flex-md-row flex-stack">
          <div className="order-2 order-md-1 d-flex align-items-center">
            <Link to="/sendCampaigns">
              <img src="/assets/media/logos/logo-c.svg" title="logo-footer" height={'40px'}></img>
              <span className="text-muted fw-semibold me-2 text-nowrap">2024 © ChatBridge </span>
            </Link>
          </div>
          <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
            <li className="menu-item">
              <div className="dropdown">
                <a
                  type="button"
                  className=" menu-link px-5"
                  id="navbarNotificationsDropdown"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  data-bs-dropdown-animation
                >
                  <i className="bi-life-preserver me-1"></i> {t('footer.text-btn')}
                </a>
                <div
                  className={`p-0 dropdown-menu dropdown-menu-end dropdown-card navbar-dropdown-menu navbar-dropdown-menu-borderless ${styles.widthDorpdown}`}
                  aria-labelledby="navbarNotificationsDropdown"
                >
                  <div className="card">
                    <div className="card-header card-header-content-between">
                      <h4 className="card-title mb-0">
                        <i className="bi-life-preserver me-2"></i> {t('footer.text-help')}{' '}
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="menu-item px-3 my-0">
                        <a
                          className="menu-link px-3 py-2"
                          onClick={() =>
                            window.open(
                              'https://api.whatsapp.com/send?phone=5524156393&text=Hola,%20buen%20d%C3%ADa,%20necesito%20de%20su%20apoyo',
                              '_blank'
                            )
                          }
                        >
                          {t('footer.talk-consultant-btn')}{' '}
                          <i className="bi-box-arrow-up-right ms-2"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
