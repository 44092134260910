import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

declare global {
  interface Window {
    ClipboardJS: any;
  }
}

function useClipboard(ids: string[]) {
  const { t } = useTranslation();

  useEffect(() => {
    const clipboardInstances: any[] = [];
    const originalButtonContent: { [key: string]: string } = {};

    const checkElementsExist = () => {
      let allElementsFound = true;

      ids.forEach((id) => {
        const element = document.getElementById(id);
        const button = element?.nextElementSibling as HTMLElement | null;

        if (!element || !button) {
          allElementsFound = false;
          return;
        }

        if (!originalButtonContent[id]) {
          originalButtonContent[id] = button.innerHTML;
        }

        const clipboard = new window.ClipboardJS(button, {
          target: () => element,
          text: () => element.innerHTML
        });

        clipboard.on('success', () => {
          button.innerHTML = `
            <i class="ki-duotone ki-check-circle fs-1">
              <span class="path1"></span>
              <span class="path2"></span>
            </i> ¡${t('general.copied')}!
          `;
          element.classList.add('text-primary');
          setTimeout(() => {
            element.classList.remove('text-primary');
            button.innerHTML = `<i class="ki-duotone ki-copy fs-1"></i>${t('general.copy')}`;
          }, 1000);
        });

        clipboardInstances.push(clipboard);
      });

      return allElementsFound;
    };

    const interval = setInterval(() => {
      if (checkElementsExist()) {
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
      clipboardInstances.forEach((clipboard) => clipboard.destroy());
    };
  }, [ids, t]);
  return {};
}

export default useClipboard;
