import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function SelectLanguage({ menuPlacement = 'auto' }: any) {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  const languages: any = [
    {
      value: 'es',
      label: (
        <div className="d-flex align-items-center">
          <img
            src="/assets/media/flags/mexico.svg"
            className="rounded-circle me-2"
            height={'16px'}
            title="languages-es"
          />{' '}
          {t('languages.es')}
        </div>
      )
    },
    {
      value: 'en',
      label: (
        <div className="d-flex align-items-center">
          <img
            src="/assets/media/flags/united-states.svg"
            className="rounded-circle me-2"
            height={'16px'}
            title="languages-en"
          />{' '}
          {t('languages.en')}
        </div>
      )
    },
    {
      value: 'zh',
      label: (
        <div className="d-flex align-items-center">
          <img
            src="/assets/media/flags/china.svg"
            className="rounded-circle me-2"
            height={'16px'}
            title="languages-zh"
          />{' '}
          {t('languages.zh')}
        </div>
      )
    }
  ];

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    setCurrentLang(i18n.resolvedLanguage || 'es');
  }, [i18n.language]);

  return (
    <>
      <Select
        options={languages}
        className={'ms-auto form-select-language-dark'}
        classNamePrefix={'form-select-language-dark'}
        placeholder="Idioma"
        onChange={(option) => changeLanguage(option.value)}
        value={languages.find((l: any) => l.value === currentLang) || ''}
        menuPlacement={menuPlacement}
      />
    </>
  );
}
export default SelectLanguage;
