import { useTranslation } from 'react-i18next';
import useBgPages from '../../hooks/bg-pages.hook';
import useRequest from '../../hooks/use-request.hook';
import { useEffect, useState } from 'react';
import Aside from '../../components/layout/asaid.component';
import Header from '../../components/layout/header.component';
import Footer from '../../components/layout/footer.component';
import AddBlackList from './add-blacklist.modal';
import Select from 'react-select';
import { CompaniesService } from '../../services/companies.service';
import { Areas } from '../../services/area.service';
import { BlackListService } from '../../services/blacklist.service';
import DataTable from '../../components/datatable/datatable.component';
import EditBlackList from './edit-blacklist.modal';

function BlackListPage() {
  useBgPages();
  const { t } = useTranslation();
  const { call, isRequesting } = useRequest();
  const [rows, setRows] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<any>();
  const [areaId, setAreaId] = useState<string>();
  const [areas, setAreas] = useState<Array<any>>([]);
  const [blacklists, setBlackLists] = useState<number>(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const columns = [
    {
      name: t('blacklist-page.title-column-1'),
      sortable: true,
      cell: (rows: any) => {
        const areaName = areas.find((area) => area.value === rows.area)?.label;
        return (
          <div className="text-nowrap">
            <p>
              <i className="ki-duotone ki-book-open fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </i>
              <strong>{t('blacklist-page.name')}</strong>
              <span className="badge badge-dark">{rows.name}</span>
            </p>
            <p>
              <i className="ki-duotone ki-data fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
                <span className="path6"></span>
              </i>
              <strong>{t('blacklist-page.area')}</strong>
              <span className="badge badge-light-primary">{areaName}</span>
            </p>
          </div>
        );
      }
    },
    {
      name: t('blacklist-page.title-column-2'),
      cell: (rows: any) => {
        const fechaHora = new Date(rows.created_at);
        const fecha = fechaHora.toISOString().split('T')[0];
        const hora = fechaHora.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
        return (
          <div className="text-nowrap">
            <h5>
              {t('general.date')}: <span className="text-muted">{fecha}</span>
            </h5>
            <h5>
              {t('general.hour')}: <span className="text-muted">{hora}</span>
            </h5>
          </div>
        );
      }
    },
    {
      name: t('general.acctions'),
      sortable: false,
      center: true,
      exportable: false,
      cell: (rows: any) => {
        return (
          <>
            <div className="text-nowrap">
              <button
                className="btn btn-light-primary btn-sm btn-flex btn-center"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                type="button"
              >
                {t('general.acctions')}
                <i className="ki-duotone ki-down fs-5 ms-1" />
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    href="#"
                    onClick={() => setShowModalEdit({ show: true, data: rows })}
                  >
                    <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">{t('channels.wa.btn-update')}</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  ];
  const getBlackList = async () => {
    const data = await call(() => BlackListService.getBlackList(areaId));
    setRows(data);
    setBlackLists(data?.length);
  };

  const getCompanies = async () => {
    const data = await CompaniesService.getCompanies();
    if (data) {
      const mappedCompanies = data.map((item: any) => ({
        value: item._id,
        label: item.name
      }));
      setCompanies(mappedCompanies);
      if (mappedCompanies.length > 0) {
        setSelectedCompanyId(mappedCompanies[0].value);
      }
    } else {
      setCompanies([]);
    }
  };

  const handleAreaChange = (selectedOption: { value: string; label: string } | null) => {
    if (selectedOption) {
      setAreaId(selectedOption.value);
    }
  };

  const getAreas = async () => {
    if (selectedCompanyId) {
      const data = await Areas.getAreas({ company: selectedCompanyId });
      const mappedAreas = data.map((item: any) => ({
        value: item._id,
        label: item.name
      }));
      setAreas(mappedAreas);
      if (isFirstLoad && mappedAreas.length > 0) {
        setAreaId(mappedAreas[0].value);
        setIsFirstLoad(false);
      }
    } else {
      setAreas([]);
    }
  };

  const [showModal, setShowModal] = useState({
    show: false
  });

  const handleShowModal = async (refresh: boolean) => {
    if (refresh) {
      getBlackList();
    }
    setShowModal({ show: false });
  };

  const [showModalEdit, setShowModalEdit] = useState({
    show: false,
    data: null
  });

  const handleShowModalEdit = async (refresh: boolean) => {
    if (refresh) {
      (selectedCompanyId && areaId) !== undefined && getBlackList();
    }
    setShowModalEdit({ show: false, data: null });
  };

  useEffect(() => {
    (selectedCompanyId && areaId) !== undefined && getBlackList();
    selectedCompanyId && getAreas();
  }, [selectedCompanyId, areaId]);

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Aside />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">{t('templates.wa.tools')}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-shield fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <span className="ms-3">{t('asaid.link-blacklist')}</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-4 mb-xl-10">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            {t('blacklist-page.title-statistics')}
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('asaid.link-blacklist')}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-category fs-1 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <div className="d-flex flex-stack mb-2 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-shield fs-3x text-info w-35px me-6 hover-scale">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-info fw-bolder"
                                href="#"
                              >
                                {t('blacklist-page.blacklists')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('blacklist-page.my-blacklist')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder ">
                            {blacklists}
                          </span>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                          <a
                            className="btn btn-primary hover-scale"
                            onClick={() => setShowModal({ show: true })}
                          >
                            <i className="ki-duotone ki-abstract-10 fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            {t('blacklist-page.add-blacklist')}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">{t('general.filters')}</span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('blacklist-page.filter-text')}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-setting-4 fs-1 text-primary"></i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <label className="form-label">{t('blacklist-page.company-label')}</label>
                        <Select
                          options={companies}
                          noOptionsMessage={() => (
                            <div className="empty-select">{t('general.no-options')}</div>
                          )}
                          className="form-select-custom"
                          classNamePrefix="form-select-custom"
                          placeholder={t('blacklist-page.company-placeholder')}
                          onChange={(selectedOption: { value: string; label: string } | null) => {
                            if (selectedOption) {
                              setSelectedCompanyId(selectedOption.value);
                              setAreaId(undefined);
                            }
                          }}
                          value={companies.find(
                            (company: any) => company.value === selectedCompanyId
                          )}
                        />
                      </div>
                      <div className="card-body pt-1">
                        <label className="form-label">{t('blacklist-page.area-label')}</label>
                        <Select
                          options={areas}
                          noOptionsMessage={() => (
                            <div className="empty-select">{t('general.no-options')}</div>
                          )}
                          className="form-select-custom"
                          classNamePrefix="form-select-custom"
                          placeholder={t('blacklist-page.area-placeholder')}
                          onChange={handleAreaChange}
                          value={
                            areas.find((profileOption: any) => profileOption.value === areaId) ||
                            null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 mb-5 mb-xl-10">
                    <div className="row g-5 g-xl-10 h-xxl-50 mb-0 mb-xl-10">
                      <div className="card mb-5 mb-xxl-8">
                        <div className="card-header align-items-center border-0 mt-5">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="fw-bolder fw-bold fs-2">
                              {t('asaid.link-blacklist')}
                            </span>
                            <span className="text-gray-400 mt-2 fw-semibold fs-6">
                              {t('roles.registered')}
                            </span>
                          </h3>
                        </div>
                        <div className="card-body pt-1">
                          <DataTable columns={columns} data={rows} isLoading={isRequesting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <AddBlackList
        show={showModal.show}
        onRequestClose={(refresh: any) => handleShowModal(refresh)}
      />
      <EditBlackList
        show={showModalEdit.show}
        onRequestClose={(refresh: any) => handleShowModalEdit(refresh)}
        payload={showModalEdit.data}
      />
    </>
  );
}

export default BlackListPage;
