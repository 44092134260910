import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { ChannelsService } from '../../services/channels.service';
import { Areas } from '../../services/area.service';
import { UsersService } from '../../services/users.service';
import Select from 'react-select';
import Input from '../../components/forms/input.component';
import Button from '../../components/forms/button.component';
import useStepper from '../../hooks/stepper.hook';
import InvalidFeedback from '../../components/forms/invalid-feedback.component';
import useInputValidation from '../../hooks/input-validation.hook';
import { AlertService } from '../../services/alert.service';
import { useTranslation } from 'react-i18next';
import useClipboard from '../../hooks/clipboard.hook';
import ReactDOMServer from 'react-dom/server';
import useModal from '../../hooks/close-modal.hook';

interface AddAreaProps {
  updateArea: (companyId: string) => void;
  companies: { value: string; label: string }[];
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
}

function AddArea({ updateArea, companies, show, onRequestClose }: AddAreaProps) {
  const {
    emailRegex,
    priceRegex,
    noSpaceRegex,
    validateEmail,
    handlePrice,
    validatePrice,
    handleNoSpaces
  } = useInputValidation();
  useClipboard(['copy-password']);
  const { currentStep, setCurrentStep, resteStepper } = useStepper('add_new_area_stepper');
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const [listData, setlistaData] = useState({
    usersRegistred: [],
    channelWhatsApp: [],
    channelRcsConversacional: [],
    channelRcsSingle: [],
    channelRcsBasic: [],
    channelSMS: []
  });

  const [channelConfig, setchannelConfig] = useState({
    registerUser: true,
    whatsapp: false,
    rcsConversational: false,
    rcsSingle: false,
    rcsBasic: false,
    sms: false
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      nameArea: '',
      companie: '',
      userName: '',
      fullName: '',
      email: '',
      usersRegistred: '',
      channelwhatsapp: '',
      priceWhatsApp: '',
      channelRcsConversacional: '',
      pricesRcsConversational: '',
      channelRcsSingle: '',
      pricesRcsSingle: '',
      channelRcsBasic: '',
      priceRcsBasic: '',
      channelSMS: '',
      priceSMS: ''
    }
  });

  const getData = async (type?: string) => {
    const types = type ? [type] : ['channelWhatsApp', 'channelRcsConversacional'];
    try {
      for (const currentType of types) {
        let data: any;
        if (currentType === 'channelWhatsApp') {
          data = await ChannelsService.getAccountsWhatsApp();
          setlistaData((prevData) => ({
            ...prevData,
            channelWhatsApp: data.map((item: any) => ({
              value: item._id,
              label: item.name
            }))
          }));
        } else if (currentType === 'channelRcsConversacional') {
          data = await [];
          setlistaData((prevData) => ({
            ...prevData,
            channelRcsConversacional: data.map((item: any) => ({
              value: item._id,
              label: item.name
            }))
          }));
        }
      }
    } catch (error: any) {
      AlertService.toastError(t('general.atention'), error);
    }
  };

  const getUsers = async (profile: string, company: string) => {
    try {
      const data = await UsersService.getCompnieUsers({ profile, company });
      setlistaData((prevData) => ({
        ...prevData,
        usersRegistred: data.map((item: any) => ({
          value: item._id,
          label: item.username
        }))
      }));
    } catch (error: any) {
      AlertService.toastError(t('general.atention'), error);
    }
  };

  const isStepValid = (step: number, watch: any) => {
    const values = watch();
    switch (step) {
      case 1:
        return !!values.nameArea && !!values.companie;
      case 2:
        return channelConfig.registerUser
          ? !!values.userName && !!values.fullName && (!values.email || validateEmail(values.email))
          : !!values.usersRegistred;
      case 3:
        return (
          (channelConfig.whatsapp
            ? !!values.channelwhatsapp &&
              !!values.priceWhatsApp &&
              validatePrice(values.priceWhatsApp)
            : true) &&
          (channelConfig.rcsConversational
            ? !!values.channelRcsConversacional &&
              !!values.pricesRcsConversational &&
              validatePrice(values.pricesRcsConversational)
            : true) &&
          (channelConfig.rcsSingle
            ? !!values.channelRcsSingle &&
              !!values.pricesRcsSingle &&
              validatePrice(values.pricesRcsSingle)
            : true) &&
          (channelConfig.rcsBasic
            ? !!values.channelRcsBasic &&
              !!values.priceRcsBasic &&
              validatePrice(values.priceRcsBasic)
            : true) &&
          (channelConfig.sms
            ? !!values.channelSMS && !!values.priceSMS && validatePrice(values.priceSMS)
            : true)
        );
      default:
        return false;
    }
  };
  const isCurrentStepValid = isStepValid(currentStep, watch);

  const addArea = async (data: any) => {
    const requestBody: any = {
      area: {
        company: data.companie,
        name: data.nameArea
      },
      area_user: {},
      channels: {}
    };
    if (channelConfig.registerUser) {
      requestBody.area_user = {
        username: data.userName,
        full_name: data.fullName,
        email: data.email || ''
      };
    } else if (data.usersRegistred) {
      requestBody.area_user = {
        _id: data.usersRegistred
      };
    }
    const addChannel = (
      channelKey: keyof typeof channelConfig,
      channelId?: string,
      price?: string
    ) => {
      if (channelConfig[channelKey] && channelId) {
        requestBody.channels[channelKey] = {
          _id: channelId,
          price: parseFloat(price || '0.0')
        };
      }
    };
    addChannel('whatsapp', data.channelwhatsapp, data.priceWhatsApp);
    addChannel('rcsConversational', data.channelRcsConversacional, data.pricesRcsConversational);
    addChannel('rcsSingle', data.channelRcsSingle, data.pricesRcsSingle);
    addChannel('rcsBasic', data.channelRcsBasic, data.pricesRcsBasic);
    addChannel('sms', data.channelSMS, data.priceSMS);
    try {
      const response = await Areas.saveAreas(requestBody);
      resetStepper();
      if (channelConfig.registerUser) {
        const password = response.area_user.password;
        const contentAlert = ReactDOMServer.renderToString(
          <>
            <div className="text-muted fw-semibold fs-6 mb-10">
              {t('areas-page.modal-add-area.success-message')}
            </div>
            <div className="d-flex flex-column align-items-center">
              <h4 className="mb-3 text-center bold" id="copy-password">
                {password}
              </h4>
              <button
                type="button"
                title="copy"
                className="btn btn-light-primary mb-3 btn-sm"
                data-clipboard-target="#copy-password"
              >
                <i className="ki-duotone ki-copy fs-1"></i> {t('general.copy')}
              </button>
            </div>
          </>
        );
        AlertService.success(t('areas-page.modal-add-area.success-message-title'), contentAlert);
      } else {
        AlertService.toastSuccess(
          t('areas-page.modal-add-area.success-message-title') +
            ', Se asigno el área al usuario seleccionado.'
        );
      }
      updateArea(data.companie);
    } catch (error: any) {
      AlertService.toastError(t('general.atention') + error);
    }
  };

  const resetStepper = () => {
    onRequestClose(false);
    reset();
    resteStepper.goFirst();
    setCurrentStep(1);
    setchannelConfig({
      registerUser: true,
      whatsapp: false,
      rcsConversational: false,
      rcsSingle: false,
      rcsBasic: false,
      sms: false
    });
  };

  const handleRadioChange = (event: any) => {
    setchannelConfig((prevConfig) => ({
      ...prevConfig,
      registerUser: event.target.value === '1'
    }));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div aria-hidden="true" className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div className="btn btn-sm btn-icon btn-active-light-primary" onClick={resetStepper}>
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <div className="mt-1 text-center">
            <h2>{t('areas-page.modal-add-area.title-modal')}</h2>
            <div className="text-muted fw-semibold fs-5">
              <span dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}></span>
            </div>
            <div className="separator border-2 my-5"></div>
          </div>
          <div className="modal-body mt-10 px-5 px-xl-20">
            <div
              className="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row"
              id="add_new_area_stepper"
            >
              <div className="d-flex flex-row-auto w-100 w-lg-300px">
                <div className="stepper-nav">
                  <div className="stepper-item me-5 current" data-kt-stepper-element="nav">
                    <div className="stepper-wrapper d-flex align-items-center">
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check" />
                        <span className="stepper-number">1</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">
                          {t('areas-page.modal-add-area.stepper-menu-t1')}
                        </h3>
                        <div className="stepper-desc">
                          {t('areas-page.modal-add-area.stepper-menu-subt1')}
                        </div>
                      </div>
                    </div>
                    <div className="stepper-line h-40px" />
                  </div>
                  <div className="stepper-item me-5" data-kt-stepper-element="nav">
                    <div className="stepper-wrapper d-flex align-items-center">
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check" />
                        <span className="stepper-number">2</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">
                          {t('areas-page.modal-add-area.stepper-menu-t2')}
                        </h3>
                        <div className="stepper-desc">
                          {t('areas-page.modal-add-area.stepper-menu-subt2')}
                        </div>
                      </div>
                    </div>
                    <div className="stepper-line h-40px" />
                  </div>
                  <div className="stepper-item me-5" data-kt-stepper-element="nav">
                    <div className="stepper-wrapper d-flex align-items-center">
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check" />
                        <span className="stepper-number">3</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">
                          {t('areas-page.modal-add-area.stepper-menu-t3')}
                        </h3>
                        <div className="stepper-desc">
                          {t('areas-page.modal-add-area.stepper-menu-subt3')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-row-fluid">
                <form className="form" onSubmit={handleSubmit(addArea)}>
                  <div className="mb-5">
                    <div className="flex-column current" data-kt-stepper-element="content">
                      <div className="pb-10 pb-lg-12">
                        <label className="d-flex flex-stack mb-7">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-data fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <h2 className="fw-bold">
                                {t('areas-page.modal-add-area.stepper-1-title1')}
                              </h2>
                              <div className="text-muted fw-semibold fs-6">
                                {t('areas-page.modal-add-area.stepper-1-subtitle1')}
                              </div>
                            </span>
                          </span>
                        </label>
                        <div className="fv-row">
                          <Input
                            label={t('areas-page.modal-add-area.stepper-1-input1')}
                            requiredIndicator="required"
                            placeholder={t('areas-page.modal-add-area.stepper-1-placeholder1')}
                            errors={errors.nameArea}
                            {...register('nameArea', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="fv-row mb-10">
                        <label className="d-flex flex-stack mb-7">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-info">
                                <i className="ki-duotone ki-briefcase fs-2x text-info">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <h2 className="fw-bold">
                                {t('areas-page.modal-add-area.stepper-1-title2')}
                              </h2>
                              <div className="text-muted fw-semibold fs-6">
                                {t('areas-page.modal-add-area.stepper-1-subtitle2')}
                              </div>
                            </span>
                          </span>
                        </label>
                        <label className="form-label required">
                          {t('areas-page.modal-add-area.stepper-1-input2')}
                        </label>
                        <Controller
                          control={control}
                          name="companie"
                          rules={{ required: true }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                              <Select
                                options={companies}
                                className={
                                  error ? 'form-select-custom is-invalid' : 'form-select-custom'
                                }
                                noOptionsMessage={() => (
                                  <div className="empty-select">{t('general.no-options')}</div>
                                )}
                                classNamePrefix="form-select-custom"
                                placeholder={t('areas-page.modal-add-area.stepper-1-placeholder2')}
                                onChange={(selectedOption) => {
                                  const selectedValue =
                                    (selectedOption as { value: any; label: string } | null)
                                      ?.value ?? null;
                                  onChange(selectedValue);
                                  getUsers('standard_user', selectedValue);
                                }}
                                value={
                                  companies.find(
                                    (option: { value: string; label: string }) =>
                                      option.value === value
                                  ) || null
                                }
                              />
                              <InvalidFeedback error={error} />
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex-column" data-kt-stepper-element="content">
                      <div className="w-100 ">
                        <div className="fv-row mb-10">
                          <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
                            <span className="d-flex flex-column mb-3">
                              <span className="fw-bold fs-2">
                                {t('areas-page.modal-add-area.stepper-2-title1')}
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-2-subtitle1')}
                              </span>
                            </span>
                          </label>
                          <label className="d-flex flex-stack cursor-pointer mb-5">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-circle symbol-50px me-6">
                                <span className="symbol-label bg-light-primary">
                                  <i className="ki-duotone ki-faceid fs-2x text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                    <span className="path4" />
                                    <span className="path5" />
                                    <span className="path6" />
                                  </i>
                                </span>
                              </span>
                              <span className="d-flex flex-column">
                                <span className="fw-bold fs-6">
                                  {t('areas-page.modal-add-area.stepper-2-switch1')}
                                </span>
                                <span className="fs-7 text-muted">
                                  {t('areas-page.modal-add-area.stepper-2-switch1-subtitle')}
                                </span>
                              </span>
                            </span>
                            <span className="form-check form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                checked={channelConfig.registerUser}
                                value="1"
                                name="framework"
                                type="radio"
                                onChange={handleRadioChange}
                              />
                            </span>
                          </label>
                          <label className="d-flex flex-stack cursor-pointer mb-5">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-circle symbol-50px me-6">
                                <span className="symbol-label bg-light-info">
                                  <i className="ki-duotone ki-user-tick fs-2x text-info">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                    <span className="path4" />
                                    <span className="path5" />
                                    <span className="path6" />
                                  </i>
                                </span>
                              </span>
                              <span className="d-flex flex-column">
                                <span className="fw-bold fs-6">
                                  {t('areas-page.modal-add-area.stepper-2-switch2')}
                                </span>
                                <span className="fs-7 text-muted">
                                  {t('areas-page.modal-add-area.stepper-2-switch2-subtitle')}
                                </span>
                              </span>
                            </span>
                            <span className="form-check form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                checked={!channelConfig.registerUser}
                                value="2"
                                name="framework"
                                type="radio"
                                onChange={handleRadioChange}
                              />
                            </span>
                          </label>
                        </div>
                      </div>

                      {channelConfig.registerUser ? (
                        <>
                          <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
                            <span className="d-flex flex-column mb-0">
                              <span className="fw-bold fs-2">
                                {t('areas-page.modal-add-area.stepper-2-sw1-form-title')}
                              </span>
                            </span>
                          </label>
                          <div className="fv-row mb-3">
                            <Input
                              label={t('areas-page.modal-add-area.stepper-2-sw1-form-input1')}
                              requiredIndicator="required"
                              placeholder={t(
                                'areas-page.modal-add-area.stepper-2-sw1-form-input1-placeholder'
                              )}
                              errors={errors.fullName}
                              {...register('fullName', { required: true })}
                            />
                          </div>
                          <div className="fv-row mb-5">
                            <Input
                              label={t('areas-page.modal-add-area.stepper-2-sw1-form-input2')}
                              requiredIndicator="required"
                              placeholder={t(
                                'areas-page.modal-add-area.stepper-2-sw1-form-input2-placeholder'
                              )}
                              onKeyDown={handleNoSpaces}
                              errors={errors.userName}
                              {...register('userName', {
                                required: true,
                                pattern: {
                                  value: noSpaceRegex,
                                  message: t('forms.invalid-FeedBack.no-spaces')
                                }
                              })}
                            />
                          </div>
                          <div className="fv-row mb-10">
                            <Input
                              label={t('areas-page.modal-add-area.stepper-2-sw1-form-input3')}
                              optional={true}
                              placeholder={t(
                                'areas-page.modal-add-area.stepper-2-sw1-form-input3-placeholder'
                              )}
                              errors={errors.email}
                              {...register('email', {
                                required: false,
                                pattern: {
                                  value: emailRegex,
                                  message: t('forms.invalid-FeedBack.valid-email')
                                }
                              })}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
                            <span className="d-flex flex-column mb-0">
                              <span className="fw-bold fs-2">
                                {t('areas-page.modal-add-area.stepper-2-sw2-form-title')}
                              </span>
                            </span>
                          </label>
                          <div className="fv-row mb-10">
                            <label className="form-label required">
                              {t('areas-page.modal-add-area.stepper-2-sw2-form-input1')}
                            </label>
                            <Controller
                              control={control}
                              name="usersRegistred"
                              rules={{ required: true }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                  <Select
                                    options={listData.usersRegistred}
                                    noOptionsMessage={() => (
                                      <div className="empty-select">{t('general.no-options')}</div>
                                    )}
                                    className={
                                      error ? 'form-select-custom is-invalid' : 'form-select-custom'
                                    }
                                    classNamePrefix="form-select-custom"
                                    placeholder={t(
                                      'areas-page.modal-add-area.stepper-2-sw2-form-input1-placeholder'
                                    )}
                                    onChange={(
                                      selectedOption: { value: string; label: string } | null
                                    ) => {
                                      onChange(selectedOption ? selectedOption.value : null);
                                    }}
                                    value={
                                      listData.usersRegistred.find(
                                        (option: { value: string; label: string }) =>
                                          option.value === value
                                      ) || null
                                    }
                                  />
                                  <InvalidFeedback error={error} />
                                </>
                              )}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex-column" data-kt-stepper-element="content">
                      <span className="d-flex flex-column mb-10">
                        <span className="fw-bold fs-2">
                          {t('areas-page.modal-add-area.stepper-3-title1')}
                        </span>
                        <span className="fs-7 text-muted">
                          {t('areas-page.modal-add-area.stepper-3-subtitle1')}
                        </span>
                      </span>
                      <div className="scroll mh-500px">
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px  me-6">
                              <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-whatsapp fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                {t('areas-page.modal-add-area.stepper-3-ch1')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-3-ch1-subtitle')}
                              </span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              value="1"
                              name="framework"
                              checked={channelConfig.whatsapp}
                              type="checkbox"
                              onChange={() =>
                                setchannelConfig((prevConfig) => ({
                                  ...prevConfig,
                                  whatsapp: !prevConfig.whatsapp
                                }))
                              }
                            />
                          </span>
                        </label>
                        {channelConfig.whatsapp && (
                          <>
                            <div className="fv-row mb-5">
                              <label className="form-label required">
                                {' '}
                                {t('areas-page.modal-add-area.stepper-3-input-accounts')}
                              </label>
                              <Controller
                                control={control}
                                name="channelwhatsapp"
                                rules={{ required: true }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      options={listData.channelWhatsApp}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {t('general.no-options')}
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? 'form-select-custom is-invalid'
                                          : 'form-select-custom'
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={t(
                                        'areas-page.modal-add-area.stepper-3-input-accounts-placeholder'
                                      )}
                                      onChange={(
                                        selectedOption: { value: string; label: string } | null
                                      ) => {
                                        onChange(selectedOption ? selectedOption.value : null);
                                      }}
                                      value={
                                        listData.channelWhatsApp.find(
                                          (option: { value: string; label: string }) =>
                                            option.value === value
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row mb-10">
                              <Input
                                label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                requiredIndicator="required"
                                placeholder={t(
                                  'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                )}
                                onKeyDown={handlePrice}
                                errors={errors.priceWhatsApp}
                                {...register('priceWhatsApp', {
                                  required: true,
                                  pattern: {
                                    value: priceRegex,
                                    message: t('forms.invalid-FeedBack.valid-price')
                                  }
                                })}
                              />
                            </div>
                          </>
                        )}
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px  me-6">
                              <span className="symbol-label bg-light-info">
                                <i className="ki-duotone ki-messages fs-2x text-info">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                {t('areas-page.modal-add-area.stepper-3-ch2')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-3-rcs-subtitle')}
                              </span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              value="1"
                              name="framework"
                              checked={channelConfig.rcsConversational}
                              type="checkbox"
                              onChange={() =>
                                setchannelConfig((prevConfig) => ({
                                  ...prevConfig,
                                  rcsConversational: !prevConfig.rcsConversational
                                }))
                              }
                            />
                          </span>
                        </label>
                        {channelConfig.rcsConversational && (
                          <>
                            <div className="fv-row mb-5">
                              <label className="form-label required">
                                {t('areas-page.modal-add-area.stepper-3-input-agents')}
                              </label>
                              <Controller
                                control={control}
                                name="channelRcsConversacional"
                                rules={{ required: true }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      options={listData.channelRcsConversacional}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {t('general.no-options')}
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? 'form-select-custom is-invalid'
                                          : 'form-select-custom'
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={t(
                                        'areas-page.modal-add-area.stepper-3-input-agents-placeholder'
                                      )}
                                      onChange={(
                                        selectedOption: { value: string; label: string } | null
                                      ) => {
                                        onChange(selectedOption ? selectedOption.value : null);
                                      }}
                                      value={
                                        listData.channelRcsConversacional.find(
                                          (option: { value: string; label: string }) =>
                                            option.value === value
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row mb-10">
                              <Input
                                label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                requiredIndicator="required"
                                placeholder={t(
                                  'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                )}
                                onKeyDown={handlePrice}
                                errors={errors.pricesRcsConversational}
                                {...register('pricesRcsConversational', {
                                  required: true,
                                  pattern: {
                                    value: priceRegex,
                                    message: t('forms.invalid-FeedBack.valid-price')
                                  }
                                })}
                              />
                            </div>
                          </>
                        )}
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-info">
                                <i className="ki-duotone ki-messages fs-2x text-info">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                {t('areas-page.modal-add-area.stepper-3-ch3')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-3-rcs-subtitle')}
                              </span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              value="1"
                              name="framework"
                              checked={channelConfig.rcsSingle}
                              type="checkbox"
                              onChange={() =>
                                setchannelConfig((prevConfig) => ({
                                  ...prevConfig,
                                  rcsSingle: !prevConfig.rcsSingle
                                }))
                              }
                            />
                          </span>
                        </label>
                        {channelConfig.rcsSingle && (
                          <>
                            <div className="fv-row mb-5">
                              <label className="form-label required">
                                {t('areas-page.modal-add-area.stepper-3-input-agents')}
                              </label>
                              <Controller
                                control={control}
                                name="channelRcsSingle"
                                rules={{ required: true }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      options={listData.channelRcsSingle}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          No hay opciones disponibles.
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? 'form-select-custom is-invalid'
                                          : 'form-select-custom'
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={t(
                                        'areas-page.modal-add-area.stepper-3-input-agents-placeholder'
                                      )}
                                      onChange={(
                                        selectedOption: { value: string; label: string } | null
                                      ) => {
                                        onChange(selectedOption ? selectedOption.value : null);
                                      }}
                                      value={
                                        listData.channelRcsSingle.find(
                                          (option: { value: string; label: string }) =>
                                            option.value === value
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row mb-10">
                              <Input
                                label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                requiredIndicator="required"
                                placeholder={t(
                                  'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                )}
                                onKeyDown={handlePrice}
                                errors={errors.pricesRcsSingle}
                                {...register('pricesRcsSingle', {
                                  required: true,
                                  pattern: {
                                    value: priceRegex,
                                    message: t('forms.invalid-FeedBack.valid-price')
                                  }
                                })}
                              />
                            </div>
                          </>
                        )}
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-info">
                                <i className="ki-duotone ki-messages fs-2x text-info">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                {t('areas-page.modal-add-area.stepper-3-ch4')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-3-rcs-subtitle')}
                              </span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              value="1"
                              name="framework"
                              checked={channelConfig.rcsBasic}
                              type="checkbox"
                              onChange={() =>
                                setchannelConfig((prevConfig) => ({
                                  ...prevConfig,
                                  rcsBasic: !prevConfig.rcsBasic
                                }))
                              }
                            />
                          </span>
                        </label>
                        {channelConfig.rcsBasic && (
                          <>
                            <div className="fv-row mb-5">
                              <label className="form-label required">
                                {t('areas-page.modal-add-area.stepper-3-input-agents')}
                              </label>
                              <Controller
                                control={control}
                                name="channelRcsBasic"
                                rules={{ required: true }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      options={listData.channelRcsBasic}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {t('general.no-options')}
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? 'form-select-custom is-invalid'
                                          : 'form-select-custom'
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={t(
                                        'areas-page.modal-add-area.stepper-3-input-agents-placeholder'
                                      )}
                                      onChange={(
                                        selectedOption: { value: string; label: string } | null
                                      ) => {
                                        onChange(selectedOption ? selectedOption.value : null);
                                      }}
                                      value={
                                        listData.channelRcsBasic.find(
                                          (option: { value: string; label: string }) =>
                                            option.value === value
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row mb-10">
                              <Input
                                label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                requiredIndicator="required"
                                placeholder={t(
                                  'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                )}
                                onKeyDown={handlePrice}
                                errors={errors.priceRcsBasic}
                                {...register('priceRcsBasic', {
                                  required: true,
                                  pattern: {
                                    value: priceRegex,
                                    message: t('forms.invalid-FeedBack.valid-price')
                                  }
                                })}
                              />
                            </div>
                          </>
                        )}
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-warning">
                                <i className="ki-duotone ki-message-notif fs-2x text-warning">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                {t('areas-page.modal-add-area.stepper-3-ch5')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-3-ch5-subtitle')}
                              </span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              value="1"
                              name="framework"
                              checked={channelConfig.sms}
                              type="checkbox"
                              onChange={() =>
                                setchannelConfig((prevConfig) => ({
                                  ...prevConfig,
                                  sms: !prevConfig.sms
                                }))
                              }
                            />
                          </span>
                        </label>
                        {channelConfig.sms && (
                          <>
                            <div className="fv-row mb-5">
                              <label className="form-label required">
                                {t('areas-page.modal-add-area.stepper-3-input-mask')}
                              </label>
                              <Controller
                                control={control}
                                name="channelSMS"
                                rules={{ required: true }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      options={listData.channelSMS}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {t('general.no-options')}
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? 'form-select-custom is-invalid'
                                          : 'form-select-custom'
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={t(
                                        'areas-page.modal-add-area.stepper-3-input-mask-placeholder'
                                      )}
                                      onChange={(
                                        selectedOption: { value: string; label: string } | null
                                      ) => {
                                        onChange(selectedOption ? selectedOption.value : null);
                                      }}
                                      value={
                                        listData.channelSMS.find(
                                          (option: { value: string; label: string }) =>
                                            option.value === value
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row mb-10">
                              <Input
                                label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                requiredIndicator="required"
                                placeholder={t(
                                  'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                )}
                                onKeyDown={handlePrice}
                                errors={errors.priceSMS}
                                {...register('priceSMS', {
                                  required: true,
                                  pattern: {
                                    value: priceRegex,
                                    message: t('forms.invalid-FeedBack.valid-price')
                                  }
                                })}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-stack mb-10">
                    <div className="me-2">
                      <Button
                        type="button"
                        typeStepperBtn="previous"
                        id="previous"
                        className="btn btn-lg btn-secondary w-100 mb-5 hover-scale"
                      >
                        <i className="ki-duotone ki-double-left ms-2 fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        {t('areas-page.modal-add-area.stepper-btn-previous')}
                      </Button>
                    </div>
                    <div>
                      <div className="me-2">
                        <Button
                          type="submit"
                          typeStepperBtn="submit"
                          id="sendData"
                          className="btn btn-lg btn-primary w-100 mb-5 hover-scale"
                          disabled={!isValid && !isCurrentStepValid}
                          isLoading={isSubmitting}
                        >
                          {t('areas-page.modal-add-area.stepper-btn-submit')}
                          <i className="ki-duotone ki-send ms-2 fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-lg btn-primary w-100 mb-5 hover-scale"
                          id="next"
                          typeStepperBtn="next"
                          disabled={!isCurrentStepValid}
                        >
                          {t('areas-page.modal-add-area.stepper-btn-next')}
                          <i className="ki-duotone ki-double-right ms-2 fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddArea;
