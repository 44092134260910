import { useMemo, useState } from 'react';
import { STORAGE } from '../constants';
import { LocalStorageService } from '../services/local-storage.service';

export const useSession = () => {
  const [session] = useState(LocalStorageService.get(STORAGE.SESSION));

  const role = useMemo(() => {
    return session?.profile ? session?.profile.toLowerCase() : '';
  }, []);

  return {
    session,
    role
  };
};
