import React from 'react';
import Select from 'react-select';
import InvalidFeedback from './invalid-feedback.component';
import { useTranslation } from 'react-i18next';

interface TimeOption {
  value: string;
  label: string;
}

interface TimePickerProps {
  time: string;
  disable?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
  optional?: boolean;
  label?: string;
  requiredIndicator?: string;
  errors?: any;
  sizeClass?: string;
}

const TimePicker = React.forwardRef<HTMLSelectElement, TimePickerProps>(
  (
    {
      time,
      disable = false,
      onChange,
      placeholder = 'Selecciona una hora',
      optional = false,
      label,
      requiredIndicator = '',
      errors,
      sizeClass = 'form-control-lg',
      ...props
    },
    ref
  ) => {
    const [times, setTimes] = React.useState<TimeOption[]>([]);
    const { t } = useTranslation();

    React.useEffect(() => {
      const times: TimeOption[] = [];
      for (let index = 0; index <= 23; index++) {
        const h = `${index}`.padStart(2, '0');
        times.push({ value: `${h}:00`, label: `${h}:00` });
        times.push({ value: `${h}:30`, label: `${h}:30` });
      }
      times.push({ value: '23:59', label: '23:59' });

      setTimes(times);
    }, []);

    const handleChange = (data: any) => {
      onChange(data ? data.value : '');
    };

    return (
      <>
        <label className={'form-label ' + requiredIndicator}>
          {label}
          {optional && <span className="fs-8 text-muted ms-1">({t('general.optional')})</span>}
        </label>
        <Select
          {...props}
          options={times}
          isMulti={false}
          className={'form-select-custom ' + (errors ? ' is-invalid' : '')}
          classNamePrefix="form-select-custom"
          placeholder={placeholder}
          onChange={handleChange}
          value={times.find((option) => option.value === time) || null}
          isDisabled={disable}
        />
        <InvalidFeedback error={errors} />
      </>
    );
  }
);

export default TimePicker;
