import { useEffect } from 'react';

declare const KTComponents: any;
declare const KTThemeMode: any;

const useBgPages = () => {
  useEffect(() => {
    const bodyClasses = [
      'header-fixed',
      'header-tablet-and-mobile-fixed',
      'toolbar-enabled',
      'aside-fixed',
      'aside-default-enabled'
    ];
    const rootClasses = ['d-flex', 'flex-column', 'flex-root'];

    document.body.classList.add(...bodyClasses);

    const root = document.getElementById('root');
    if (root) {
      root.classList.add(...rootClasses);
    }

    KTComponents.init();
    KTThemeMode.init();

    return () => {
      document.body.classList.remove(...bodyClasses);
      if (root) {
        root.classList.remove(...rootClasses);
      }
    };
  }, []);
};

export default useBgPages;
