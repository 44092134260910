import React from 'react';
import { AlertService } from '../../services/alert.service';
import { useNavigate } from 'react-router-dom';

interface NodeData {
  id: string;
  data: {
    label: string;
    description?: string;
    externalId?: string;
    config?: {
      key1?: string;
      key2?: string;
    };
    user?: {
      id: string;
      name: string;
    };
    createdAt?: string;
    actions?: string[];
    icon?: string;
    totalMessage: number;
    status: string;
  };
  position: {
    x: number;
    y: number;
  };
  sourcePosition?: string;
  targetPosition?: string;
  type?: string;
  style?: React.CSSProperties;
}

interface CustomNodeActionsProps {
  node: NodeData;
  updateNodeStatus: (nodeId: string, status: string) => void;
}

const CustomNodeActions: React.FC<CustomNodeActionsProps> = ({ node, updateNodeStatus }) => {
  const navigate = useNavigate();
  const handleResume = async () => {
    const confirmed = await AlertService.question(
      '¿Quieres reanudar el envío de tu campaña?',
      'Los canales configurados reanudarán el envío.',
      () => Promise.resolve(),
      'Reanudar campaña'
    );

    if (!confirmed) return;
    try {
      AlertService.toastSuccess('¡ Éxito !, Seguiremos con el envío de tu campaña.');
      updateNodeStatus(node.id, 'process');
    } catch (error) {
      console.error('Error al reanudar tu campaña:', error);
      AlertService.toastError(
        '¡ Atención !, No se pudo reanudar tu campaña, Contacta a tu administrador.'
      );
    }
  };

  const handlePause = async () => {
    const confirmed = await AlertService.question(
      '¿Quieres pausar el envío de tu campaña?',
      'Los canales configurados pausarán el envío.',
      () => Promise.resolve(),
      'Pausar camapaña'
    );

    if (!confirmed) return;
    try {
      AlertService.toastSuccess('¡ Éxito !, Pausaremos el envío de tu campaña.');
      updateNodeStatus(node.id, 'paused');
    } catch (error) {
      console.error('Error al pausar tu campaña:', error);
      AlertService.toastError(
        '¡ Atención !, No se pudo pausar tu campaña, Contacta a tu administrador.'
      );
    }
  };

  const handleCancel = async () => {
    const confirmed = await AlertService.question(
      '¿Quieres cancelar el envío de tu campaña?',
      'Esta acción no se podrá revertir.',
      () => Promise.resolve(),
      'Cancelar camapaña'
    );

    if (!confirmed) return;
    try {
      await AlertService.toastSuccess('¡ Éxito !, Cancelamos tu campaña.');
      updateNodeStatus(node.id, 'canceled');
      navigate('/campaigns/monitoring');
    } catch (error) {
      console.error('Error al cancelar tu campaña:', error);
      AlertService.toastError(
        '¡ Atención !, No se pudo cancelar tu campaña, Contacta a tu administrador.'
      );
    }
  };

  return (
    <>
      {node.data.status === 'process' ? (
        <>
          <h4 className="mb-5 text-primary">{node.data.label}</h4>
          <div className="d-flex flex-stack">
            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
              <div className="flex-grow-1">
                <a className="text-gray-800 text-hover-primary fs-6 fw-bold">Pausar campaña</a>
                <span className="text-muted fw-semibold d-block fs-7">Podrás reanudar después</span>
              </div>
              <a className="btn btn-icon btn-light-warning fs-8 fw-bold" onClick={handlePause}>
                <i className="bi bi-pause-circle fs-2"></i>
              </a>
            </div>
          </div>
          <div className="separator separator-dashed my-4" />
        </>
      ) : (
        <>
          <div className="d-flex flex-stack">
            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
              <div className="flex-grow-1">
                <a className="text-gray-800 text-hover-primary fs-6 fw-bold">Reanudar campaña</a>
                <span className="text-muted fw-semibold d-block fs-7">Reanudaremos el envío.</span>
              </div>
              <a className="btn btn-icon btn-light-primary fs-8 fw-bold" onClick={handleResume}>
                <i className="bi bi-play-circle-fill fs-2"></i>
              </a>
            </div>
          </div>
          <div className="separator separator-dashed my-4" />
        </>
      )}
      <div className="d-flex flex-stack">
        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
          <div className="flex-grow-1">
            <a className="text-gray-800 text-hover-primary fs-6 fw-bold">Cancelar campaña</a>
            <span className="text-muted fw-semibold d-block fs-7">No se puede revertir.</span>
          </div>
          <a className="btn btn-icon btn-light-danger fs-8 fw-bold" onClick={handleCancel}>
            <i className="bi bi-stop-circle fs-2"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default CustomNodeActions;
