import axios from 'axios';
import { STORAGE } from '../constants';
import { LocalStorageService } from './local-storage.service';
import i18n from 'i18next';

const _HTTP_ = axios.create();

_HTTP_.interceptors.request.use((config) => {
  const token = LocalStorageService.get(STORAGE.TOKEN);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

_HTTP_.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const { statusCode } = error.response.data;
    const { message } = error.response.data;
    return handleResponseCode(statusCode, message);
  }
);

const handleResponseCode = (code: number, errorMessage: any) => {
  const t = i18n.t.bind(i18n);

  switch (code) {
    case 400:
      if (errorMessage === 'Usuario y/o contraseña incorrectos') {
        errorMessage = t('errors.alerts-erros-01');
      }
      return Promise.reject(errorMessage);
    case 500:
      if (errorMessage.toLowerCase() === 'sesión caducada') {
        window.location.replace('/auth/login');
        LocalStorageService.clear();
        return;
      }
      if (errorMessage === 'Internal server error') {
        errorMessage = t('errors.error-500');
      }
      return Promise.reject(errorMessage || { message: t('errors.unknown') });
    case 401:
      window.location.replace('/auth/login');
      LocalStorageService.clear();
      break;
  }
};

export const HTTP = _HTTP_;
