import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Login } from '../../services/login.service';
import { STORAGE } from '../../constants';
import { LocalStorageService } from '../../services/local-storage.service';
import { useTranslation } from 'react-i18next';
import BgBody from '../../components/layout/bg-body.component';
import Input from '../../components/forms/input.component';
import Button from '../../components/forms/button.component';
import style from './style.module.scss';
import SelectLanguage from '../../components/layout/select-language.component';
import BgPages from '../../hooks/bg-pages.hook';

function AuthPage() {
  BgPages();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: ''
    }
  });

  const [errorMessage, seterrorMessage] = useState('');
  const animateDotlottie = [
    'https://lottie.host/e5e7c882-5023-4410-8830-4bf8d0b7fc53/ND6eODntns.lottie',
    'https://lottie.host/1c61ec3e-f971-4f19-9860-dbf0d83c22fe/ggBwyqBDla.lottie',
    'https://lottie.host/6f47772e-1cb2-4018-bb50-8c031f5e65d5/MpM19JyE2t.lottie',
    'https://lottie.host/ce5ccc76-8f4a-4d21-8dde-dc7bea9b4882/wOZfAfZnwJ.json'
  ];
  const phrases = [
    { text: t('phrases-Text.phrase-One'), author: t('phrases-Author.author-One') },
    { text: t('phrases-Text.phrase-Two'), author: t('phrases-Author.author-Two') },
    { text: t('phrases-Text.phrase-Three'), author: t('phrases-Author.author-Three') },
    { text: t('phrases-Text.phrase-Four'), author: t('phrases-Author.author-Four') },
    { text: t('phrases-Text.phrase-Five'), author: t('phrases-Author.author-Five') }
  ];

  const getRandomItem = (array: { text: string; author: string }[]) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };

  const randomDotlottie = animateDotlottie[Math.floor(Math.random() * animateDotlottie.length)];
  const randomPhrase = getRandomItem(phrases);

  const login = async (data: any) => {
    try {
      const response = await Login.login(data);
      LocalStorageService.save(STORAGE.TOKEN, response.access_token);
      LocalStorageService.save(STORAGE.SESSION, response.user);
      navigate('/sendCampaigns');
    } catch (error: any) {
      console.log('LoginPage.login.error::', error);
      seterrorMessage(error);
    }
  };

  const handleAlertClose = () => {
    seterrorMessage('');
  };

  useEffect(() => {
    const session = LocalStorageService.get(STORAGE.SESSION);
    if (session) {
      navigate('/sendCampaigns');
    }
  }, [navigate]);

  return (
    <>
      <BgBody />
      <div className="d-flex flex-column flex-lg-row flex-column-fluid" id="module-auth-login">
        <div className="d-flex flex-column flex-lg-row-auto w-xl-650px position-xl-relative">
          <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-650px scroll-y bg-dark">
            <div className="d-flex justify-content-between align-items-start w-100 p-2">
              <img
                alt="Logo"
                src="/assets/media/logos/logo-c.svg"
                className="h-45px h-lg-45px logo-default theme-light-show mb-5 d-none d-lg-block"
              />
              <img
                alt="Logo"
                src="/assets/media/logos/logo-c.svg"
                className="h-45px h-lg-45px logo-default theme-dark-show mb-5 d-none d-lg-block"
              />
              <SelectLanguage />
            </div>
            <div className="d-flex flex-grow-1 align-items-center justify-content-center position-relative">
              <div
                className={`d-none d-lg-block d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-450px h-lg-450px ${style.dotlottie}`}
              >
                <dotlottie-player
                  src={randomDotlottie}
                  background="transparent"
                  speed="1"
                  direction="1"
                  mode="normal"
                  loop
                  autoplay
                  data-hs-theme-appearance="default"
                ></dotlottie-player>
              </div>
            </div>
            <div className={` ms-3 ${style.phraseWidth}`}>
              <figure>
                <blockquote className="blockquote">
                  <p className="mb-8 text-white opacity-50">{randomPhrase.text}</p>
                  <figcaption className="blockquote-footer text-white opacity-50 ">
                    <span className="text-white opacity-50 fs-14">{randomPhrase.author}</span>
                  </figcaption>
                </blockquote>
              </figure>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-500px p-10 p-lg-15 mx-auto">
              <div className="form w-100" id="kt_sign_in_form" data-kt-redirect-url="/auth/login">
                <div className="text-center mb-10">
                  <img
                    alt="Logo"
                    // src="/assets/media/logos/logo.png"
                    src="/assets/media/logos/ch-l.svg"
                    className="h-100px h-lg-150px logo-default theme-light-show mb-5"
                  />
                  <img
                    alt="Logo"
                    src="/assets/media/logos/ch-l-dark.svg"
                    className="h-100px h-lg-150px logo-default theme-dark-show mb-5"
                  />
                  <h1 className="text-gray-900 mb-3 fs-2qx">{t('auth-Login.title')}</h1>
                  <div className="text-gray-500 fw-semibold fs-4">{t('auth-Login.subtitle')}</div>
                </div>
                <form
                  className="form"
                  onSubmit={handleSubmit((data) => {
                    login(data);
                  })}
                >
                  {errorMessage && (
                    <div className="alert alert-danger d-flex align-items-center p-2">
                      <i className="ki-duotone ki-shield-cross fs-2hx text-danger me-4">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      <div className="d-flex flex-column">
                        <h4 className="mb-1">{t('errors.title-text-alert-atencion')}</h4>
                        <span>{errorMessage}</span>
                      </div>
                      <a
                        className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
                        onClick={handleAlertClose}
                      >
                        <i className="ki-duotone ki-cross fs-1 text-danger">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                      </a>
                    </div>
                  )}
                  <div className="fv-row mb-10">
                    <Input
                      requiredIndicator="required"
                      label={t('forms.inputs.title-input-username')}
                      placeholder={t('forms.inputs.placeholder-input-username')}
                      errors={errors.username}
                      {...register('username', { required: true })}
                    />
                  </div>
                  <div className="fv-row mb-10">
                    <Input
                      requiredIndicator="required"
                      label={t('forms.inputs.title-input-password')}
                      placeholder={t('forms.inputs.placeholder-input-password')}
                      type="password"
                      errors={errors.password}
                      {...register('password', { required: true })}
                    />
                  </div>
                  <div className="text-center">
                    <Button
                      type="submit"
                      className="btn btn-lg btn-primary w-100 mb-5"
                      disabled={!isValid}
                      isLoading={isSubmitting}
                      onClick={handleSubmit(login)}
                    >
                      {t('forms.inputs.title-button-submit')}
                    </Button>
                  </div>
                </form>
                <div className="d-flex flex-stack">
                  <div className="me-5">
                    {/* <a href="authentication/sign-in/password-reset.html" className="link-primary fs-6 fw-bold">{t("auth-Login.recover-password")}</a> */}
                  </div>
                  <a
                    className="btn btn-active-light-primary"
                    data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                    data-kt-menu-placement="bottom-end"
                    title="theme"
                  >
                    <i className="ki-duotone ki-night-day theme-light-show fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                      <span className="path6"></span>
                      <span className="path7"></span>
                      <span className="path8"></span>
                      <span className="path9"></span>
                      <span className="path10"></span>
                    </i>
                    <i className="ki-duotone ki-moon theme-dark-show fs-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="menu-title opacity-50 theme-light-show">
                      {t('theme.brightness')}
                    </span>
                    <span className="menu-title opacity-50 theme-dark-show">{t('theme.dark')}</span>
                  </a>
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                    data-kt-menu="true"
                    data-kt-element="theme-mode-menu"
                  >
                    <div className="menu-item px-3 my-0">
                      <a
                        href="#"
                        className="menu-link px-3 py-2 active"
                        data-kt-element="mode"
                        data-kt-value="light"
                      >
                        <span className="menu-icon" data-kt-element="icon">
                          <i className="ki-duotone ki-night-day fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                            <span className="path4"></span>
                            <span className="path5"></span>
                            <span className="path6"></span>
                            <span className="path7"></span>
                            <span className="path8"></span>
                            <span className="path9"></span>
                            <span className="path10"></span>
                          </i>
                        </span>
                        <span className="menu-title">{t('theme.brightness')}</span>
                      </a>
                    </div>
                    <div className="menu-item px-3 my-0">
                      <a
                        href="#"
                        className="menu-link px-3 py-2"
                        data-kt-element="mode"
                        data-kt-value="dark"
                      >
                        <span className="menu-icon" data-kt-element="icon">
                          <i className="ki-duotone ki-moon fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                        <span className="menu-title">{t('theme.dark')}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AuthPage;
