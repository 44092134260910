import DropLanguage from './drop-language.component';
import { useNavigate, Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { STORAGE } from '../../constants';
import { LocalStorageService } from '../../services/local-storage.service';

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useMemo(() => LocalStorageService.get(STORAGE.SESSION), []);

  const logout = () => {
    LocalStorageService.clear();
    navigate('/auth/login');
  };
  return (
    <>
      <div
        id="kt_header"
        className="header"
        data-kt-sticky="true"
        data-kt-sticky-name="header"
        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      >
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <div className="d-flex align-items-center d-lg-none">
              <div
                className="btn btn-icon btn-active-color-primary ms-n2 me-1"
                id="kt_aside_toggle"
              >
                <i className="ki-duotone ki-abstract-14 fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
            </div>
            <Link to="/dashboard" className="d-lg-none">
              <img alt="Logo" src="/assets/media/logos/logo-c.svg" className="mh-40px" />
            </Link>
            <div
              className="btn btn-icon w-auto ps-0 btn-active-color-primary d-none d-lg-inline-flex me-2 me-lg-5 active"
              data-kt-toggle="true"
              data-kt-toggle-state="active"
              data-kt-toggle-target="body"
              data-kt-toggle-name="aside-minimize"
            >
              <i className="ki-duotone ki-black-left-line fs-2x fw-bolder rotate-180">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          </div>
          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            <div className="d-flex align-items-stretch me-1"></div>
            <div className="d-flex align-items-stretch flex-shrink-0">
              <div className="d-flex align-items-center ms-1 ms-lg-2">
                <div
                  className="d-flex align-items-center ms-2 ms-lg-3"
                  id="kt_header_user_menu_toggle"
                >
                  <div
                    className="cursor-pointer symbol symbol-50px symbol-circle symbol-lg-50px"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <img alt="profile-picture" src="/assets/media/avatars/av1.png" />
                    <div className="bg-primary position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                  </div>
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                    data-kt-menu="true"
                  >
                    <div className="menu-item px-3">
                      <div className="menu-content d-flex align-items-center px-3">
                        <div className="symbol symbol-50px symbol-circle symbol-lg-50px me-5">
                          <img alt="profile-picture" src="/assets/media/avatars/av1.png" />
                          <div className="bg-primary position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="fw-bold d-flex align-items-center fs-5">
                            {session.full_name}{' '}
                            <span className="badge badge-light-primary fw-bold fs-8 px-2 py-1 ms-2 text-uppercase">
                              {session.profile}
                            </span>
                          </div>
                          <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">
                            {session.username}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="separator my-2"></div>
                    <div
                      className="menu-item px-5"
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement="left-start"
                      data-kt-menu-offset="-15px, 0"
                    >
                      <a className="menu-link px-5">
                        <span className="menu-title position-relative d-flex align-items-center">
                          {t('header.title-1')}
                          <span className="fs-8 rounded px-3 py-2 position-absolute translate-middle-y top-50 end-0 d-flex align-items-center">
                            <i className="ki-duotone ki-night-day fs-1 ms-2 theme-light-show">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                              <span className="path5"></span>
                              <span className="path6"></span>
                              <span className="path7"></span>
                              <span className="path8"></span>
                              <span className="path9"></span>
                              <span className="path10"></span>
                            </i>
                            <i className="ki-duotone ki-moon fs-2 ms-1 theme-dark-show">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </span>
                        </span>
                      </a>
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                        data-kt-menu="true"
                        data-kt-element="theme-mode-menu"
                      >
                        <div className="menu-item px-3 my-0">
                          <a
                            href="#"
                            className="menu-link px-3 py-2"
                            data-kt-element="mode"
                            data-kt-value="light"
                          >
                            <span className="menu-icon" data-kt-element="icon">
                              <i className="ki-duotone ki-night-day fs-2">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                                <span className="path6"></span>
                                <span className="path7"></span>
                                <span className="path8"></span>
                                <span className="path9"></span>
                                <span className="path10"></span>
                              </i>
                            </span>
                            <span className="menu-title">{t('theme.brightness')}</span>
                          </a>
                        </div>
                        <div className="menu-item px-3 my-0">
                          <a
                            href="#"
                            className="menu-link px-3 py-2 active"
                            data-kt-element="mode"
                            data-kt-value="dark"
                          >
                            <span className="menu-icon" data-kt-element="icon">
                              <i className="ki-duotone ki-moon fs-2">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </span>
                            <span className="menu-title">{t('theme.dark')}</span>
                          </a>
                        </div>
                        <div className="menu-item px-3 my-0">
                          <a
                            href="#"
                            className="menu-link px-3 py-2"
                            data-kt-element="mode"
                            data-kt-value="system"
                          >
                            <span className="menu-icon" data-kt-element="icon">
                              <i className="ki-duotone ki-screen fs-2">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                              </i>
                            </span>
                            <span className="menu-title">{t('theme.system')}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <DropLanguage />
                    <div className="menu-item px-5">
                      <a className="menu-link px-5 btn-active-light-danger" onClick={logout}>
                        <span className="menu-title position-relative text-danger">
                          {t('header.title-3')}
                          <span className="fs-8 rounded px-3 py-2 position-absolute translate-middle-y top-50 end-0 d-flex align-items-center">
                            <i className="bi bi-power fs-2 text-danger"></i>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
