import { API_ENDPOINT } from '../constants';
import { HTTP } from '../services/http.service';
export const RolesService = {
  getRoles: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}roles`);
  },
  saveRoles: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}roles/`, data);
  }
};
