import { STORAGE_PREFIX } from '../constants';

export const LocalStorageService = {
  clear: () => {
    for (const key in localStorage) {
      if (key.startsWith(STORAGE_PREFIX)) {
        localStorage.removeItem(key);
      }
    }
  },
  save: (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get: (key: string) => {
    const value = localStorage.getItem(key);

    if (!value) return null;

    try {
      return JSON.parse(value);
    } catch (error) {
      return null;
    }
  }
};
