import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '../../hooks/close-modal.hook';
interface AddAccountProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
}
function Logs({ show, onRequestClose, payload }: AddAccountProps) {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const resetForm = () => {
    onRequestClose(false);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const currentDateTime = getCurrentDateTime();

  return (
    <div aria-hidden="true" className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-xl">
        <div className="modal-content rounded">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div className="btn btn-icon btn-active-light-primary" onClick={() => resetForm()}>
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
            <div className="mb-1 text-center">
              <h1 className="mb-3">Logs</h1>
              <div className="fw-bolder fs-5">
                <span
                  className={`fw-bolder ${payload?.data.status === 'error' ? 'text-danger' : 'text-primary'}`}
                >
                  Consumidor
                </span>{' '}
                {payload?.data.label} del canal {payload?.labelChannel}
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="row mt-10">
                <div className="col-lg-12">
                  <div
                    className={`tab-content rounded h-100  ${payload?.data.status === 'error' ? 'bg-light-danger' : 'bg-light-primary'} p-10`}
                  >
                    <div className="tab-pane fade show active" id="kt_upgrade_plan_startup">
                      <div className="pb-5 mb-10">
                        <h2 className="fw-bold text-gray-900">
                          {payload?.data.status === 'error' ? (
                            <span className="text-danger">
                              Errores encontrados
                              <i className="ki-duotone ki-abstract-22 ms-1 fs-2x text-danger">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </span>
                          ) : (
                            <span className="text-primary">
                              Funcionando con normalidad
                              <i className="ki-duotone ki-abstract-20 ms-1 fs-2x text-primary refresh-animate">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </span>
                          )}
                        </h2>
                        {payload?.data.status === 'error' ? (
                          <div className="text-muted fw-semibold">
                            Te recomendamos reiniciar el consumidor o habilitar un nuevo consumidor
                          </div>
                        ) : (
                          <div className="text-muted fw-semibold">
                            El consumidor{' '}
                            <strong>
                              {payload?.data.label} del canal {payload?.labelChannel}
                            </strong>{' '}
                            sigue procesando y enviando los mensajes.
                          </div>
                        )}
                      </div>
                      {payload?.data.status === 'process' ? (
                        <div className="pt-1">
                          <div className="d-flex align-items-center mb-7">
                            <i className="ki-duotone ki-check-circle fs-2x text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <span className="fw-semibold fs-5 text-gray-700">
                              <span className="text-primary fw-bolder">
                                {currentDateTime} [INFO] [ConsumerService]
                              </span>{' '}
                              Consumer "{payload?.data.label}" El sistema está activo y conectado.
                              Verificación de estado superada.
                            </span>
                          </div>

                          <div className="d-flex align-items-center mb-7">
                            <i className="ki-duotone ki-check-circle fs-2x text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <span className="fw-semibold fs-5 text-gray-700">
                              <span className="text-primary fw-bolder">
                                {currentDateTime} [INFO] [ConsumerService]
                              </span>{' '}
                              Consumer "{payload?.data.label}" Listo para procesar mensajes.
                            </span>
                          </div>
                          <div className="d-flex align-items-center mb-7">
                            <i className="ki-duotone ki-check-circle fs-2x text-primary me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            <span className="fw-semibold fs-5 text-gray-700">
                              <span className="text-primary fw-bolder">
                                {currentDateTime} [INFO] [ConsumerService]
                              </span>{' '}
                              Consumer "{payload?.data.label}" está procesando mensajes desde
                              "ProviderID456". Estado: OK.
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="pt-1">
                          <div className="d-flex align-items-center mb-7">
                            <i className="ki-duotone ki-shield-cross fs-2x text-danger me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                            </i>
                            <span className="fw-semibold fs-5 text-gray-700">
                              <span className="text-danger fw-bolder">
                                {currentDateTime} [ERROR] [ConsumerService]
                              </span>{' '}
                              Consumer "{payload?.data.label}" El consumidor "ConsumerID123" ha
                              encontrado un error inesperado. Razón: "Excepción inesperada en el
                              procesamiento de mensajes".
                            </span>
                          </div>

                          <div className="d-flex align-items-center mb-7">
                            <i className="ki-duotone ki-shield-cross fs-2x text-danger me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                            </i>
                            <span className="fw-semibold fs-5 text-gray-700">
                              <span className="text-danger fw-bolder">
                                {currentDateTime} [ERROR] [ConsumerService]
                              </span>{' '}
                              Consumer "{payload?.data.label}" El consumidor "ConsumerID123" está
                              fuera de servicio. Reiniciando el servicio e investigando la causa
                              raíz.
                            </span>
                          </div>

                          <div className="d-flex align-items-center mb-7">
                            <i className="ki-duotone ki-shield-cross fs-2x text-danger me-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                            </i>
                            <span className="fw-semibold fs-5 text-gray-700">
                              <span className="text-danger fw-bolder">
                                {currentDateTime} [ERROR] [ConsumerService]
                              </span>{' '}
                              Consumer "{payload?.data.label}" El consumidor "ConsumerID123" se
                              detuvo inesperadamente. Error: "Memoria insuficiente".
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-center flex-row-fluid pt-12">
              <button className="btn btn-light-info btn-bg me-3" onClick={() => resetForm()}>
                Cerrar logs
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logs;
