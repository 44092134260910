import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { AlertService } from '../../../services/alert.service';
import { ChannelsService } from '../../../services/channels.service';
import Select from 'react-select';
import Input from '../../../components/forms/input.component';
import Switch from '../../../components/forms/switch.component';
import Button from '../../../components/forms/button.component';
import useModal from '../../../hooks/close-modal.hook';

interface OperatorField {
  sms_agent: string;
  priority: string;
}

interface ChannelModalProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
  agents: any;
}

interface AgentField {
  id: number;
  sms_agent: string;
  priority: string;
}

interface FormValues {
  _id: string;
  name: string;
  is_international: boolean;
  [key: string]: any;
}

function ChannelModal({ payload, agents, show, onRequestClose }: ChannelModalProps) {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const [operatorFieldsTelcel, setOperatorFieldsTelcel] = useState<AgentField[]>([
    { id: 0, sms_agent: '', priority: '' }
  ]);
  const [operatorFieldsMovistar, setOperatorFieldsMovistar] = useState<AgentField[]>([
    { id: 0, sms_agent: '', priority: '' }
  ]);
  const [operatorFieldsAtt, setOperatorFieldsAtt] = useState<AgentField[]>([
    { id: 0, sms_agent: '', priority: '' }
  ]);
  const [operatorFieldsOther, setOperatorFieldsOther] = useState<AgentField[]>([
    { id: 0, sms_agent: '', priority: '' }
  ]);
  const [nextIdTelcel, setNextIdTelcel] = useState(1);
  const [nextIdMovistar, setNextIdMovistar] = useState(1);
  const [nextIdAtt, setNextIdAtt] = useState(1);
  const [nextIdOther, setNextIdOther] = useState(1);
  const [atLeastOneFilled, setAtLeastOneFilled] = useState(false);

  const defaultValues: FormValues = {
    _id: '',
    name: '',
    is_international: false
  };

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues
  });

  const international = watch('is_international');

  const checkIfAtLeastOneFilled = () => {
    if (international) {
      const isFilled = operatorFieldsOther.some(
        (field) => field.sms_agent !== '' && field.priority !== ''
      );
      setAtLeastOneFilled(isFilled);
    } else {
      const operators: OperatorField[][] = [
        operatorFieldsTelcel,
        operatorFieldsMovistar,
        operatorFieldsAtt,
        operatorFieldsOther
      ];
      const isAtLeastOneFilled = operators.some((fields) =>
        fields.some((field) => field.sms_agent !== '' && field.priority !== '')
      );
      setAtLeastOneFilled(isAtLeastOneFilled);
    }
  };

  const areRelevantFieldsFilled = () => {
    const operators: { fields: OperatorField[]; relevant: boolean }[] = [
      { fields: operatorFieldsTelcel, relevant: false },
      { fields: operatorFieldsMovistar, relevant: false },
      { fields: operatorFieldsAtt, relevant: false },
      { fields: operatorFieldsOther, relevant: true }
    ];

    const allFieldsFilled = (fields: OperatorField[]) =>
      fields.every((field) => field.sms_agent !== '' && field.priority !== '');
    const isRelevant = (fields: OperatorField[]) =>
      fields.some((field) => field.sms_agent !== '' || field.priority !== '');

    if (international) {
      return !isRelevant(operatorFieldsOther) || allFieldsFilled(operatorFieldsOther);
    } else {
      return operators.every(({ fields }) => !isRelevant(fields) || allFieldsFilled(fields));
    }
  };

  const handleAgentsChange = (operatorType: string, id: number, value: string) => {
    const operators: Record<
      string,
      { fields: AgentField[]; setFields: React.Dispatch<React.SetStateAction<AgentField[]>> }
    > = {
      telcel: { fields: operatorFieldsTelcel, setFields: setOperatorFieldsTelcel },
      movistar: { fields: operatorFieldsMovistar, setFields: setOperatorFieldsMovistar },
      att: { fields: operatorFieldsAtt, setFields: setOperatorFieldsAtt },
      other: { fields: operatorFieldsOther, setFields: setOperatorFieldsOther }
    };

    const { fields: agentFields, setFields } = operators[operatorType];
    setFields(
      agentFields.map((field) => (field.id === id ? { ...field, sms_agent: value } : field))
    );
  };

  const handlePriorityChange = (operatorType: string, id: number, value: string) => {
    const operators: Record<
      string,
      { fields: AgentField[]; setFields: React.Dispatch<React.SetStateAction<AgentField[]>> }
    > = {
      telcel: { fields: operatorFieldsTelcel, setFields: setOperatorFieldsTelcel },
      movistar: { fields: operatorFieldsMovistar, setFields: setOperatorFieldsMovistar },
      att: { fields: operatorFieldsAtt, setFields: setOperatorFieldsAtt },
      other: { fields: operatorFieldsOther, setFields: setOperatorFieldsOther }
    };

    const operator = operators[operatorType];
    if (!operator) {
      console.error(`Operator type ${operatorType} not found.`);
      return;
    }

    const { fields: agentFields, setFields } = operator;
    setFields(
      agentFields.map((field) => (field.id === id ? { ...field, priority: value } : field))
    );
  };

  const addAgentField = (operatorType: string, agentOptionsCount: number) => {
    const operators: Record<
      string,
      {
        fields: AgentField[];
        setFields: React.Dispatch<React.SetStateAction<AgentField[]>>;
        nextId: number;
        setNextId: React.Dispatch<React.SetStateAction<number>>;
      }
    > = {
      telcel: {
        fields: operatorFieldsTelcel,
        setFields: setOperatorFieldsTelcel,
        nextId: nextIdTelcel,
        setNextId: setNextIdTelcel
      },
      movistar: {
        fields: operatorFieldsMovistar,
        setFields: setOperatorFieldsMovistar,
        nextId: nextIdMovistar,
        setNextId: setNextIdMovistar
      },
      att: {
        fields: operatorFieldsAtt,
        setFields: setOperatorFieldsAtt,
        nextId: nextIdAtt,
        setNextId: setNextIdAtt
      },
      other: {
        fields: operatorFieldsOther,
        setFields: setOperatorFieldsOther,
        nextId: nextIdOther,
        setNextId: setNextIdOther
      }
    };

    const { fields: agentFields, setFields, nextId, setNextId } = operators[operatorType];
    const maxRows = Math.min(agentOptionsCount, 10);
    const firstRowFilled =
      agentFields.length > 0 && agentFields[0].sms_agent !== '' && agentFields[0].priority !== '';
    const canAddMoreRows = agentFields.length < maxRows;

    if (firstRowFilled && canAddMoreRows) {
      setFields([...agentFields, { id: nextId, sms_agent: '', priority: '' }]);
      setNextId(nextId + 1);
    }
  };

  const removeAgentField = (operatorType: string, id: number) => {
    const operators: Record<
      string,
      { fields: AgentField[]; setFields: React.Dispatch<React.SetStateAction<AgentField[]>> }
    > = {
      telcel: { fields: operatorFieldsTelcel, setFields: setOperatorFieldsTelcel },
      movistar: { fields: operatorFieldsMovistar, setFields: setOperatorFieldsMovistar },
      att: { fields: operatorFieldsAtt, setFields: setOperatorFieldsAtt },
      other: { fields: operatorFieldsOther, setFields: setOperatorFieldsOther }
    };
    const { fields: agentFields, setFields } = operators[operatorType];
    if (agentFields.length > 1) {
      setFields(agentFields.filter((field) => field.id !== id));
    }
    checkIfAtLeastOneFilled();
  };

  const renderAgentFields = (operatorType: string, agentFields: AgentField[]) => {
    if (!agents[operatorType]) {
      return null;
    }
    const firstRowFilled =
      agentFields.length > 0 && agentFields[0].sms_agent !== '' && agentFields[0].priority !== '';
    const agentOptionsCount = agents[operatorType].length;
    let maxRows = 10;
    if (agentOptionsCount <= 10) {
      maxRows = agentOptionsCount;
    }

    return (
      <>
        {agentFields.map((field) => {
          const selectedAgents = agentFields
            .map((f) => f.sms_agent)
            .filter((agent) => agent !== field.sms_agent);
          const agentOptions = agents[operatorType]
            .filter((agent: any) => !selectedAgents.includes(agent._id))
            .map((agent: any) => ({
              value: agent._id,
              label: agent.name
            }));
          const selectedPriorities = agentFields
            .map((f) => f.priority)
            .filter((priority) => priority !== field.priority);
          const priorityOptions = Array.from({ length: 10 }, (_, i) => ({
            value: `${i + 1}`,
            label: `${i + 1}`
          })).filter((option) => !selectedPriorities.includes(option.value));

          return (
            <div className="form-group row" key={field.id}>
              <div className="col-md-7">
                <label className="form-label">{t('channels.sms.modal-C-seccion2-agente')}</label>
                <Select
                  value={agentOptions.find((agent: any) => agent.value === field.sms_agent)}
                  onChange={(selectedOption) =>
                    handleAgentsChange(
                      operatorType,
                      field.id,
                      selectedOption ? selectedOption.value : ''
                    )
                  }
                  options={agentOptions}
                  noOptionsMessage={() => (
                    <div className="empty-select">{t('general.no-options')}</div>
                  )}
                  placeholder={t('channels.sms.modal-C-seccion2-agente-placeholder')}
                  className="form-select-custom"
                  classNamePrefix="form-select-custom"
                  isClearable
                />
              </div>
              <div className="col-md-4 mb-3">
                <label className="form-label">{t('channels.sms.modal-C-seccion2-priority')}</label>
                <Select
                  value={priorityOptions.find((option) => option.value === field.priority)}
                  onChange={(selectedOption) =>
                    handlePriorityChange(
                      operatorType,
                      field.id,
                      selectedOption ? selectedOption.value : ''
                    )
                  }
                  options={priorityOptions}
                  noOptionsMessage={() => (
                    <div className="empty-select">{t('general.no-options')}</div>
                  )}
                  className="form-select-custom"
                  classNamePrefix="form-select-custom"
                  placeholder={t('channels.sms.modal-C-seccion2-priority')}
                  isClearable
                />
              </div>
              <div className="col-md-1 d-flex align-items-center mb-4">
                {agentFields.length > 1 && (
                  <div className="col-md-1 mt-0">
                    <a
                      className="btn btn-light-danger mt-3 mt-md-8"
                      onClick={() => removeAgentField(operatorType, field.id)}
                    >
                      <i className="ki-duotone ki-trash fs-2">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                        <span className="path5" />
                      </i>
                    </a>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <button
          type="button"
          className={`btn btn-light-info ${agentFields.length >= maxRows || !firstRowFilled ? 'd-none' : ''}`}
          onClick={() => addAgentField(operatorType, agentOptionsCount)}
          disabled={!firstRowFilled}
        >
          <i className="ki-duotone ki-plus fs-3" />
          {t('channels.sms.modal-C-seccion2-btnAdd')}
        </button>
      </>
    );
  };

  const registerChannel = async (data: any) => {
    if (!atLeastOneFilled) {
      AlertService.toastError(t('channels.sms.modal-C-errorValid'));
      return;
    }
    const filterAndMapOperators = (operatorFields: any[]) => {
      if (!operatorFields) return [];
      return operatorFields
        .filter((field: OperatorField) => field.sms_agent !== '' && field.priority !== '')
        .map(({ sms_agent, priority }) => ({ sms_agent, priority }));
    };
    const { _id, ...filteredData } = data;
    const filteredTelcel = international ? [] : filterAndMapOperators(operatorFieldsTelcel);
    const filteredMovistar = international ? [] : filterAndMapOperators(operatorFieldsMovistar);
    const filteredAtt = international ? [] : filterAndMapOperators(operatorFieldsAtt);
    const filteredOther = filterAndMapOperators(operatorFieldsOther);
    const formData = {
      ...filteredData,
      telcel: filteredTelcel,
      movistar: filteredMovistar,
      att: filteredAtt,
      other: filteredOther,
      status: true,
      ...(payload != null && { _id })
    };
    try {
      if (payload != null) {
        await ChannelsService.updateChannelsMS(formData);
        AlertService.toastSuccess(t('channels.sms.modal-C-succesEdit'));
      } else {
        await ChannelsService.addChannelsMS(formData);
        AlertService.toastSuccess(t('channels.sms.modal-C-succes'));
      }
      await onRequestClose(true);
      resetForm();
    } catch (error) {
      console.log(error);
      AlertService.toastError(`${t('general.atencion')} ${error}`);
    }
  };

  const resetFields = () => {
    const initialFields = [{ id: 0, sms_agent: '', priority: '' }];
    setOperatorFieldsTelcel(initialFields);
    setOperatorFieldsMovistar(initialFields);
    setOperatorFieldsAtt(initialFields);
    setOperatorFieldsOther(initialFields);
    setNextIdTelcel(1);
    setNextIdMovistar(1);
    setNextIdAtt(1);
    setNextIdOther(1);
  };

  const resetForm = () => {
    onRequestClose(false);
    reset(defaultValues);
    setOperatorFieldsTelcel([]);
    setOperatorFieldsMovistar([]);
    setOperatorFieldsAtt([]);
    setOperatorFieldsOther([]);
    setNextIdTelcel(1);
    setNextIdMovistar(1);
    setNextIdAtt(1);
    setNextIdOther(1);
  };

  useEffect(() => {
    if (show) {
      resetFields();
    }
  }, [show]);

  useEffect(() => {
    checkIfAtLeastOneFilled();
  }, [
    operatorFieldsTelcel,
    operatorFieldsMovistar,
    operatorFieldsAtt,
    operatorFieldsOther,
    international
  ]);

  useEffect(() => {
    const updateOperatorFields = (field: string, data: any[]) => {
      if (data.length > 0) {
        return data.map((item: any, index: number) => ({
          id: index,
          sms_agent: item.sms_agent,
          priority: item.priority.toString()
        }));
      } else {
        return [{ id: 0, sms_agent: '', priority: '' }];
      }
    };

    if (payload) {
      setOperatorFieldsTelcel(updateOperatorFields('telcel', payload.telcel));
      setOperatorFieldsMovistar(updateOperatorFields('movistar', payload.movistar));
      setOperatorFieldsAtt(updateOperatorFields('att', payload.att));
      setOperatorFieldsOther(updateOperatorFields('other', payload.other));

      reset({
        _id: payload?._id || defaultValues._id,
        name: payload.name || defaultValues.name,
        is_international: payload.is_international || defaultValues.is_international
      });
    }
  }, [payload]);

  return (
    <div className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => resetForm()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(registerChannel)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h1 className="mb-3">
                  {watch('_id') ? (
                    <>
                      {t('channels.sms.modal-C-titleEdit')}
                      <a className="link-primary fw-bold ms-2" href="#">
                        {payload?.name}
                      </a>
                    </>
                  ) : (
                    <> {t('channels.sms.modal-C-title')}</>
                  )}
                </h1>
                <div className="text-muted fw-semibold fs-5">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}
                  ></span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div className="col-lg-6 mb-10 mb-lg-0 h-100 p-10">
                  <label className="d-flex flex-stack mb-13">
                    <span className="d-flex align-items-center me-2">
                      <span className="symbol symbol-circle symbol-50px me-6">
                        <span className="symbol-label bg-light-info">
                          <i className="ki-duotone ki-questionnaire-tablet fs-2x text-info">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <h2 className="fw-bold">{t('channels.sms.modal-C-seccion1-title')}</h2>
                        <div className="text-muted fw-semibold fs-6">
                          {t('channels.sms.modal-C-seccion1-Subtitle')}
                        </div>
                      </span>
                    </span>
                  </label>
                  <div className="fv-row mb-13">
                    <Input
                      label={t('channels.sms.tc-name')}
                      requiredIndicator="required"
                      placeholder={t('channels.sms.modal-C-seccion1-placeholder')}
                      errors={errors.name}
                      {...register('name', { required: true })}
                    />
                  </div>
                  <div className="row mb-20">
                    <div className="d-flex flex-stack">
                      <div className="d-flex">
                        <span className="d-flex align-items-center">
                          <i className="bi bi-globe-americas fs-3x hover-scale me-2 me-6"></i>
                        </span>
                        <div className="d-flex flex-column">
                          <a className="fs-5 text-dark text-hover-primary fw-bold" href="#">
                            {t('channels.sms.international')}
                          </a>
                          <div className="fs-6 fw-semibold text-gray-400 mw-300px">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t('channels.sms.modal-C-seccion1-subtitle-inter')
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="form-check form-check-solid form-check-custom form-switch">
                          <Switch {...register('is_international')} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 rounded h-100 p-10">
                  <label className="d-flex flex-stack mb-7">
                    <span className="d-flex align-items-center me-2">
                      <span className="symbol symbol-circle symbol-50px me-6">
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-satellite fs-3x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <h2 className="fw-bold">
                          {t('channels.sms.modal-C-seccion2-title')}{' '}
                          <span className="required"></span>
                        </h2>
                        <div className="text-muted fw-semibold fs-6">
                          {t('channels.sms.modal-C-seccion2-Subtitle')}
                        </div>
                      </span>
                    </span>
                  </label>
                  {!international && (
                    <>
                      <div className="fw-bold fs-6 theme-light-show mb-2">
                        <img src="/assets/media/operators/telcel.png" alt="" width="70px" />
                      </div>
                      <div className="fw-bold fs-6 theme-dark-show">
                        <img src="/assets/media/operators/telcel-dark.png" alt="" width="70px" />
                      </div>
                      <div className="fv-row mb-10">
                        {renderAgentFields('telcel', operatorFieldsTelcel)}
                      </div>
                      <div className="fw-bold fs-6 theme-light-show mb-2">
                        <img src="/assets/media/operators/movistar.png" alt="" width="80px" />
                      </div>
                      <div className="fw-bold fs-6 theme-dark-show">
                        <img src="/assets/media/operators/movistar-dark.png" alt="" width="80px" />
                      </div>
                      <div className="fv-row mb-10">
                        {renderAgentFields('movistar', operatorFieldsMovistar)}
                      </div>
                      <div className="fw-bold fs-6 theme-light-show mb-2">
                        <img src="/assets/media/operators/att.png" alt="" width="70px" />
                      </div>
                      <div className="fw-bold fs-6 theme-dark-show">
                        <img src="/assets/media/operators/att-dark.png" alt="" width="70px" />
                      </div>
                      <div className="fv-row mb-10">
                        {renderAgentFields('att', operatorFieldsAtt)}
                      </div>
                      <div className="fw-bold fs-6 theme-light-show mb-2">
                        <img src="/assets/media/operators/altan.png" alt="" width="60px" />
                      </div>
                      <div className="fw-bold fs-6 theme-dark-show">
                        <img src="/assets/media/operators/altan.png" alt="" width="60px" />
                      </div>
                      <div className="fv-row mb-10">
                        {renderAgentFields('other', operatorFieldsOther)}
                      </div>
                    </>
                  )}
                  {international && (
                    <>
                      <div className="fw-bold fs-6 theme-light-show">
                        <img
                          src="/assets/media/operators/altan.png"
                          alt=""
                          width="60px"
                          height="30px"
                        />
                      </div>
                      <div className="fw-bold fs-6 theme-dark-show">
                        <img
                          src="/assets/media/operators/altan.png"
                          alt=""
                          width="60px"
                          height="30px"
                        />
                      </div>
                      <div className="fv-row mb-10">
                        {renderAgentFields('other', operatorFieldsOther)}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => resetForm()}
                >
                  {t('general.close')}
                </button>
                <Button
                  type="submit"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={
                    !atLeastOneFilled || !isValid || isSubmitting || !areRelevantFieldsFilled()
                  }
                  isLoading={isSubmitting}
                >
                  {watch('_id') ? t('general.edit') : t('channels.sms.modal-C-seccion2-btnsubmit')}
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChannelModal;
