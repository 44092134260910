import { API_ENDPOINT } from '../constants';
import { HTTP } from './http.service';

export const Areas = {
  getAreas: (data: { company: string }) => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}areas/${data.company}`);
  },
  saveAreas: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}wizard/area/`, data);
  }
};
