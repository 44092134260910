import { useEffect, useMemo, useRef, useState } from 'react';
import useModal from '../../hooks/close-modal.hook';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../components/forms/button.component';
import Input from '../../components/forms/input.component';
import { BlackListService } from '../../services/blacklist.service';
import { AlertService } from '../../services/alert.service';
import useRequest from '../../hooks/use-request.hook';
import LoaderModalTable from '../../components/loader/loader-table-modal.component';
import style from './blacklist.module.scss';
interface EditBlackListProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
}

function EditBlackList({ show, onRequestClose, payload }: EditBlackListProps) {
  const { t } = useTranslation();
  const numberRegex = /^\d{10}$/;
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const [term, setTerm] = useState<string>('');
  const { call, isRequesting } = useRequest();
  const [rows, setRows] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 20;
  const [totalRows, setTotalRows] = useState(0);

  const defaultValues = {
    blacklist: '',
    phone_number: ''
  };

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const columns = [
    {
      name: t('blacklist-page.phone-number-label'),
      sortable: false,
      cell: (row: any) => {
        return <h5>{row.phone_number}</h5>;
      }
    },
    {
      name: t('general.acctions'),
      sortable: false,
      center: true,
      exportable: false,
      cell: (row: any) => {
        return (
          <div className={`d-flex text-nowrap ${style.btnContainer}`}>
            <button
              type="button"
              className="btn btn-light-danger"
              onClick={() => deleteNumber(row)}
            >
              <i className="ki-duotone ki-trash fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
              </i>
              {t('general.delete')}
            </button>
          </div>
        );
      }
    }
  ];

  const closeModal = () => {
    reset(defaultValues);
    onRequestClose(false);
  };

  const getFile = async (page: number) => {
    const data = await call(() => BlackListService.getCSVs(payload?._id, page + 1, rowsPerPage));
    if (data) {
      setRows(data);
      setTotalRows(payload?.total_phone_numbers || 0);
    } else {
      setRows([]);
    }
  };

  const submitNumber = async (data: any) => {
    try {
      const newData = { blacklist: payload._id, phone_number: data.phone_number };
      await BlackListService.saveNumber(newData);
      resetField('phone_number');
      AlertService.toastSuccess(t('blacklist-page.success-update-phone'));
      getFile(currentPage);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteNumber = async (data: any) => {
    const confirmed = await AlertService.warning(
      t('general.confirmation'),
      t('blacklist-page.confirm-delete', { number: data?.phone_number }),
      () => Promise.resolve(),
      t('general.delete')
    );

    if (!confirmed) return;
    try {
      await BlackListService.deleteNumber(data._id);
      AlertService.toastSuccess(t('blacklist-page.success-delete'));
      getFile(currentPage);
    } catch (error) {
      console.error('Error eliminando número:', error);
      AlertService.toastError(t('blacklist-page.error-delete'));
    }
  };
  const filteredRows = useMemo(() => {
    return rows.filter((row: any) =>
      Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(term.toLowerCase())
      )
    );
  }, [rows, term]);

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const page = Number(e.target.value) - 1;
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const visiblePages = useMemo(() => {
    const visiblePageCount = 3;
    const start = Math.max(0, Math.min(currentPage - 1, totalPages - visiblePageCount));
    const end = Math.min(totalPages, start + visiblePageCount);
    return Array.from({ length: end - start }, (_, i) => start + i);
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (payload) {
      getFile(currentPage);
    }
  }, [payload, currentPage]);

  return (
    <div aria-hidden="true" className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => closeModal()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(submitNumber)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                <h2>
                  {t('blacklist-page.edit-blacklist-title')}
                  <a className="link-primarry fw-bold ms-2" href="#">
                    {payload?.name}
                  </a>
                </h2>
                <div className="text-muted fw-semibold fs-5">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}
                  ></span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="row mt-10">
                <div className="col-lg-5 mb-10 mb-lg-0  h-100 p-10">
                  <label className="d-flex flex-stack mb-7">
                    <span className="d-flex align-items-center me-2">
                      <span className="symbol symbol-circle symbol-50px me-6">
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <h4 className="fw-bold">{t('blacklist-page.add-number-title')}</h4>
                        <div className="text-muted fw-semibold fs-7">
                          {t('blacklist-page.add-number-text')}
                        </div>
                      </span>
                    </span>
                  </label>
                  <div className="d-flex flex-column flex-md-row align-items-center">
                    <div className="flex-grow-1 me-md-3 mb-3 mb-md-0">
                      <Input
                        label={t('blacklist-page.phone-number-label')}
                        requiredIndicator="required"
                        className={
                          'form-control form-control-solid' +
                          (errors.phone_number ? ' is-invalid' : '')
                        }
                        placeholder={t('blacklist-page.phone-number-placeholder')}
                        {...register('phone_number', {
                          required: true,
                          pattern: {
                            value: numberRegex,
                            message: t('blacklist-page.phone-number-error')
                          }
                        })}
                      />
                    </div>
                    <Button
                      type="submit"
                      id="sendData"
                      className="btn btn-primary hover-scale mt-10 ms-2"
                      disabled={!isValid}
                      isLoading={isSubmitting}
                    >
                      {t('general.btn-add-modal')}
                    </Button>
                  </div>
                  {errors.phone_number && (
                    <div className="text-danger">{t('blacklist-page.phone-number-error')}</div>
                  )}
                </div>
                {/* TABLA BLACKLIST */}
                <div className="col-lg-7 rounded h-100 p-10">
                  <>
                    <div className="d-flex justify-content-end">
                      <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        <input
                          className="form-control form-control-solid w-250px ps-12"
                          placeholder={t('datatable.input-search')}
                          type="text"
                          disabled={isRequesting}
                          value={term}
                          onChange={({ target: { value } }) => setTerm(value)}
                        />
                      </div>
                    </div>
                    {isRequesting ? (
                      <div className="text-center relative">
                        <LoaderModalTable />
                      </div>
                    ) : filteredRows.length === 0 ? (
                      <div className="text-center pt-20 h-400px">
                        <img
                          title="empty-table"
                          className="mb-3 theme-light-show"
                          src="/assets/media/illustrations/front/oc-thinking.svg"
                          width="50%"
                        />
                        <img
                          title="empty-table"
                          className="mb-3 theme-dark-show"
                          src="/assets/media/illustrations/front/oc-thinking-dark.svg"
                          width="50%"
                        />
                        <p className="mb-0 fs-3">{t('datatable.empty-records')}</p>
                      </div>
                    ) : (
                      <div className="table-responsive mh-md-400px">
                        <table className="table table-row-bordered gy-5 gs-7">
                          <thead className="thead-light">
                            <tr className="text-start text-gray-500 fw-bold fs-5 gs-0">
                              {columns.map((column, index) => (
                                <th
                                  key={index}
                                  className={
                                    column.name === t('general.acctions')
                                      ? `${style.btnContainer}`
                                      : ''
                                  }
                                >
                                  {column.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredRows.map((row: any, index: any) => (
                              <tr key={index}>
                                {columns.map((column, colIndex) => (
                                  <td key={colIndex}>{column.cell(row)}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    {filteredRows.length > 0 && (
                      <nav className="p-4 d-flex justify-content-between align-items-center">
                        <div className="pagination-container d-flex align-items-center w-100">
                          <button
                            title="btn-pagination"
                            type="button"
                            className="btn btn-sm btn-icon btn-active-light-primary mx-1"
                            onClick={handlePrevPage}
                            disabled={currentPage === 0}
                          >
                            <i className="bi bi-chevron-left fs-3"></i>
                          </button>
                          {visiblePages.map((page) => (
                            <button
                              key={page}
                              type="button"
                              className={`btn btn-sm btn-icon mx-1 ${page === currentPage ? 'btn-primary' : 'btn-secondary'}`}
                              onClick={() => handlePageClick(page)}
                            >
                              {page + 1}
                            </button>
                          ))}
                          <button
                            title="btn-pagination"
                            type="button"
                            className="btn btn-sm btn-icon btn-active-light-primary mx-1"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages - 1}
                          >
                            <i className="bi bi-chevron-right fs-3"></i>
                          </button>
                          <div className="d-flex align-items-center ms-auto">
                            <input
                              type="number"
                              className="form-control form-control-solid w-75px text-center"
                              onChange={handlePageInputChange}
                              min={1}
                              max={totalPages}
                            />
                            <span className="mx-2">/ {totalPages}</span>
                          </div>
                        </div>
                      </nav>
                    )}
                  </>
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => closeModal()}
                >
                  {t('general.close')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditBlackList;
