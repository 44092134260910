import { useTranslation } from 'react-i18next';
import useBgPages from '../../hooks/bg-pages.hook';
import useRequest from '../../hooks/use-request.hook';
import { useEffect, useState } from 'react';
import Aside from '../../components/layout/asaid.component';
import Header from '../../components/layout/header.component';
import Footer from '../../components/layout/footer.component';
import Select from 'react-select';
import AddUser from './add-user.modal';
import Datatable from '../../components/datatable/datatable.component';
import { UsersService } from '../../services/users.service';
import { CompaniesService } from '../../services/companies.service';
import { PROFILES } from '../../constants';

function UsersPage() {
  useBgPages();
  const { t } = useTranslation();
  const { call, isRequesting } = useRequest();
  const [rows, setRows] = useState([]);
  const [companies, setCompanies] = useState<any>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [profile, setProfile] = useState<any>('');
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const columns = [
    {
      name: t('users-page.title-column-1'),
      sortable: true,
      cell: (rows: any) => {
        return (
          <div className="text-nowrap">
            <p className="mb-0">
              <strong>{t('users-page.title-name-2')}: </strong>
              <span className="badge badge-light-secondary">{rows.full_name}</span>
            </p>
            <div className="d-flex align-items-center">
              <p className="mb-0">
                <strong>{t('users-page.title-username-1')}: </strong>
                <span className="badge badge-light-info">{rows.username}</span>
              </p>
            </div>
            <div className="text-nowrap">
              {(() => {
                switch (rows.profile) {
                  case PROFILES.ROOT:
                    return (
                      <div className="d-flex align-items-center">
                        <strong>{t('users-page.title-profile')}: </strong>
                        <span className="badge badge-light-info">
                          {t('users-page.root-profile')}
                        </span>
                      </div>
                    );
                  case PROFILES.ADMIN:
                    return (
                      <div className="d-flex align-items-center">
                        <strong>{t('users-page.title-profile')}: </strong>
                        <span className="badge badge-light-warning">
                          {t('users-page.admin-profile')}
                        </span>
                      </div>
                    );
                  case PROFILES.STANDARD_USER:
                    return (
                      <div className="d-flex align-items-center">
                        <strong>{t('users-page.title-profile')}: </strong>
                        <span className="badge badge-light-success">
                          {t('users-page.standard-user-profile')}
                        </span>
                      </div>
                    );
                  case PROFILES.AGENTE:
                    return (
                      <div className="d-flex align-items-center">
                        <strong>{t('users-page.title-profile')}: </strong>
                        <span className="badge badge-light-success">
                          {t('users-page.agent-profile')}
                        </span>
                      </div>
                    );
                  default:
                    return <span className="badge badge-light-danger">Error</span>;
                }
              })()}
            </div>
          </div>
        );
      }
    },
    {
      name: t('users-page.title-column-4'),
      cell: (areas: any) => {
        const fechaHora = new Date(areas.created_at);
        const fecha = fechaHora.toISOString().split('T')[0];
        const hora = fechaHora.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
        return (
          <div className="text-nowrap">
            <h5>
              {t('general.date')}: <span className="text-muted">{fecha}</span>
            </h5>
            <h5>
              {t('general.hour')}: <span className="text-muted">{hora}</span>
            </h5>
          </div>
        );
      }
    },
    {
      name: t('users-page.title-column-5'),
      cell: (rows: any) =>
        rows.status ? (
          <span className="badge badge-light-primary fs-6">
            <i className="ki-duotone ki-check-circle text-primary fs-3">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {t('general.active')}
          </span>
        ) : (
          <span className="badge badge-light-danger fs-6">
            <i className="ki-duotone ki-minus-circle text-danger fs-3">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {t('general.inactive')}
          </span>
        )
    },
    {
      name: t('general.acctions'),
      sortable: false,
      center: true,
      exportable: false,
      cell: (rows: any) => {
        return (
          <>
            <div className="text-nowrap">
              <button
                className="btn btn-light-primary btn-sm btn-flex btn-center"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                type="button"
              >
                {t('general.acctions')}
                <i className="ki-duotone ki-down fs-5 ms-1" />
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    href="#"
                    onClick={() => setShowModal({ show: true, data: rows })}
                  >
                    <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">{t('channels.wa.btn-update')}</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  ];

  const getUsers = async () => {
    if (selectedCompanyId) {
      const data = await call(() =>
        UsersService.getCompnieUsers({ profile, company: selectedCompanyId })
      );
      setRows(data);
      setTotalUsers(data.length);
    } else {
      setRows([]);
    }
  };

  const options = [
    { value: '', label: t('users-page.show-all-profiles') },
    { value: PROFILES.ROOT, label: t('users-page.root-profile') },
    { value: PROFILES.ADMIN, label: t('users-page.admin-profile') },
    { value: PROFILES.STANDARD_USER, label: t('users-page.standard-user-profile') },
    { value: PROFILES.AGENTE, label: t('users-page.agent-profile') }
  ];

  const getCompanies = async () => {
    const data = await CompaniesService.getCompanies();
    const mappedCompanies = data.map((item: any) => ({
      value: item._id,
      label: item.name
    }));
    setCompanies(mappedCompanies);
    if (mappedCompanies.length > 0) {
      setSelectedCompanyId(mappedCompanies[0].value);
    }
  };

  const [showModal, setShowModal] = useState({
    show: false,
    data: null
  });

  const handleShowModal = async (refresh: boolean) => {
    if (refresh) {
      await getUsers();
    }
    setShowModal({ show: false, data: null });
  };
  useEffect(() => {
    getUsers();
  }, [selectedCompanyId, profile]);

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Aside />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">{t('asaid.link-users')}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-profile-user fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                      <span className="ms-3">{t('asaid.link-users')}</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            {/*Tarjeta Estadísticas y Filtros*/}
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-4 mb-xl-10">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            {t('users-page.title-statistics')}
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('users-page.title-page')}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-category fs-1 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <div className="d-flex flex-stack mb-2 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-profile-user fs-3x text-info w-35px me-6 hover-scale">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-info fw-bolder"
                                href="#"
                              >
                                {t('users-page.my-users')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('users-page.total-users')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder ">
                            {totalUsers}
                          </span>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                          <a
                            href="#"
                            className="btn btn-primary hover-scale"
                            onClick={() => setShowModal({ show: true, data: null })}
                          >
                            <i className="ki-duotone ki-abstract-10 fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            {t('users-page.add-users-btn')}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">{t('general.filters')}</span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('users-page.filter-text')}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-setting-4 fs-1 text-primary"></i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <label className="form-label">{t('users-page.companies-label')}</label>
                        <Select
                          options={companies}
                          noOptionsMessage={() => (
                            <div className="empty-select">{t('general.no-options')}</div>
                          )}
                          className="form-select-custom"
                          classNamePrefix="form-select-custom"
                          placeholder="Selecciona una empresa"
                          onChange={(selectedOption: { value: string; label: string } | null) => {
                            if (selectedOption) {
                              setSelectedCompanyId(selectedOption.value);
                            }
                          }}
                          value={companies.find(
                            (company: any) => company.value === selectedCompanyId
                          )}
                        />
                      </div>
                      <div className="card-body pt-1">
                        <label className="form-label">{t('users-page.profiles-label')}</label>
                        <Select
                          options={options}
                          noOptionsMessage={() => (
                            <div className="empty-select">{t('general.no-options')}</div>
                          )}
                          className="form-select-custom"
                          classNamePrefix="form-select-custom"
                          placeholder="Selecciona un perfil"
                          onChange={(selectedOption: { value: string; label: string } | null) => {
                            setProfile(selectedOption?.value);
                          }}
                          value={
                            options.find((profileOption: any) => profileOption.value === profile) ||
                            null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/*TABLA*/}
                  <div className="col-xl-8 mb-5 mb-xl-10">
                    <div className="row g-5 g-xl-10 h-xxl-50 mb-0 mb-xl-10">
                      <div className="card mb-5 mb-xxl-8">
                        <div className="card-header align-items-center border-0 mt-5">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="fw-bolder fw-bold fs-2">
                              {t('users-page.title-page')}
                            </span>
                            <span className="text-gray-400 mt-2 fw-semibold fs-6">
                              {t('users-page.registered-text')}
                            </span>
                          </h3>
                        </div>
                        <div className="card-body pt-1">
                          <Datatable columns={columns} data={rows} isLoading={isRequesting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <AddUser
        show={showModal.show}
        onRequestClose={(refresh: any) => handleShowModal(refresh)}
        payload={showModal.data}
      />
    </>
  );
}
export default UsersPage;
