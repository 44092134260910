import React from 'react';
import { Handle, Position } from 'reactflow';

const CustomNodeChannel = ({ data }: any) => {
  const getStatusClass = (status: string) => {
    switch (status) {
      case 'paused':
        return 'bg-light-warning border-warning';
      case 'error':
        return 'alert-danger-fow border-danger';
      case 'pending':
        return 'bg-light';
      default:
        return 'bg-light';
    }
  };

  const getIcon = (label: string, status: string) => {
    let iconClass = 'text-muted';
    if (status === 'process' || status === 'pending') {
      iconClass = 'text-primary';
    } else if (status === 'error') {
      iconClass = 'text-danger';
    } else if (status === 'paused') {
      iconClass = 'text-warning';
    }

    if (label.includes('WhatsApp')) {
      return (
        <i className={`ki-duotone ki-whatsapp fs-2x ${iconClass}`}>
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      );
    } else if (label.includes('SMS')) {
      return (
        <i className={`ki-duotone ki-message-notif fs-2x ${iconClass}`}>
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
          <span className="path4"></span>
          <span className="path5"></span>
        </i>
      );
    } else if (label.includes('RCS')) {
      return (
        <i className={`ki-duotone ki-messages fs-2x ${iconClass}`}>
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      );
    }

    return null;
  };

  return (
    <div
      className={`card p-0 h-md-100 mb-6 mb-md-0 border border-2 ${getStatusClass(data.status)}`}
    >
      <div className="card-header border-0">
        <div className="card-title">
          {getIcon(data.label, data.status)}
          <h1 className="fw-bold ms-1">{data.label}</h1>
        </div>
      </div>
      <Handle type="target" position={Position.Left} />
      <div className="card-body pt-0">
        <div className="fw-bold fs-2">
          <div className="d-flex mb-5">
            <div className="ms-0">
              <span className="fs-1">{data.totalMessage.toLocaleString()}</span>
              <span className="text-muted fs-4 fw-bolder ms-2">Mensajes encolados</span>
            </div>
          </div>
          <div className="fs-3 fw-bolder">
            {data.status === 'error'
              ? 'Verifica el problema dando click aquí.'
              : data.status === 'paused'
                ? 'Se ha pausado el envío.'
                : 'En proceso de envío.'}
          </div>
        </div>
      </div>
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

export default CustomNodeChannel;
