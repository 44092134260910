import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Header from '../../components/layout/header.component';
import Asaid from '../../components/layout/asaid.component';
import Footer from '../../components/layout/footer.component';
import useBgPages from '../../hooks/bg-pages.hook';

function NotFoundPage() {
  useBgPages();
  const { t } = useTranslation();

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column flex-center p-10 text-center">
                  <img
                    src="/assets/media/illustrations/sigma-1/22.png"
                    alt=""
                    className="img-fluid mw-100 mh-175px mb-3"
                  />
                  <h1 className="fs-4qx text-danger mb-5">{t('errors.title-404')}</h1>
                  <h1 className="fs-1qx mb-5">{t('errors.subtitle-404')}</h1>
                  <div className="mb-10 ms-1 fs-6 text-break w-400x">
                    <span dangerouslySetInnerHTML={{ __html: t('errors.text-404') }}></span>
                  </div>
                  <Link
                    className="btn btn-lg btn-primary w-150px w-lg-175px mb-5"
                    to="/sendCampaigns"
                  >
                    <i className="ki-duotone ki-double-left fs-2x">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                      <span className="path6" />
                    </i>
                    {t('errors.btn-404')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;
