import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Asaid from '../../components/layout/asaid.component';
import Header from '../../components/layout/header.component';
import Footer from '../../components/layout/footer.component';
import useBgPages from '../../hooks/bg-pages.hook';
import ReactFlow, {
  Background,
  Controls,
  useEdgesState,
  useNodesState,
  addEdge,
  Node,
  Edge,
  Position,
  ReactFlowProvider
} from 'reactflow';
import 'reactflow/dist/style.css';
import CustomNode from '../../components/monitoring/custom-node.component';
import CustomNodeChannel from '../../components/monitoring/custom-node-channel.component';
import CustomNodeConsumer from '../../components/monitoring/custom-node-consumer.component';
import CustomNodeProvider from '../../components/monitoring/custom-node-provider.component';
import CustomNodeActions from '../../components/monitoring/custom-node-actions.component';
import CustomNodeActionsChannel from '../../components/monitoring/custom-node-actions-channel.component';
import CustomNodeActionsConsumer from '../../components/monitoring/custom-node-actions-consumer.component';
import CustomNodeActionsProvider from '../../components/monitoring/custom-node-actions-providers.component';

interface ChannelData {
  status: string;
  totalmsg: number;
  tasa: number;
  consumers?: { [key: string]: { status: string; tasa: number } };
  providers?: { [key: number]: { status: string; _id: string; name: string } };
}

interface Channel {
  [key: string]: ChannelData;
}

interface NodeData extends Node {
  data: {
    enqueuedMessages: number;
    label: string;
    description?: string;
    externalId?: string;
    config?: {
      key1?: string;
      key2?: string;
    };
    user?: {
      id: string;
      name: string;
    };
    createdAt?: string;
    actions?: string[];
    icon?: string;
    totalMessage: number;
    status: string;
  };
  position: {
    x: number;
    y: number;
  };
  sourcePosition?: Position;
  targetPosition?: Position;
  type?: string;
  style?: React.CSSProperties;
  labelChannel?: string;
  idChannel?: string;
}

const nodeTypes = {
  custom: CustomNode,
  customChannel: CustomNodeChannel,
  customConsumer: CustomNodeConsumer,
  customProvider: CustomNodeProvider
};

const channelLabels: { [key: string]: string } = {
  wa: 'WhatsApp',
  sms: 'SMS',
  rcs_basic: 'RCS Basic',
  rcs_single: 'RCS Single',
  rcs_conversational: 'RCS Conversational'
};

function LiveMonitoringPage() {
  useBgPages();
  const location = useLocation();
  const data = location.state?.data;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedNode, setSelectedNode] = useState<NodeData | null>(null);

  const nodeDefaults = {
    sourcePosition: Position.Right,
    targetPosition: Position.Left
  };

  const initialNodes: NodeData[] = [
    {
      id: '1',
      data: {
        label: data?.name || 'Default Name',
        description: 'Descripción del nodo',
        status: data?.status || 'pending',
        totalMessage: data?.totalmsg || 0,
        enqueuedMessages: 0
      },
      position: { x: 40, y: 570 },
      ...nodeDefaults,
      type: 'custom'
    }
  ];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const onNodeClick = useCallback((event: React.MouseEvent, node: Node) => {
    setSelectedNode(node as NodeData);
  }, []);

  const updateNodeStatus = useCallback(
    (nodeId: string, status: string) => {
      setNodes((nds) => {
        const updatedNodes = nds.map((node) =>
          node.id === nodeId ? { ...node, data: { ...node.data, status: status } } : node
        );

        const updatedNode = updatedNodes.find((node) => node.id === nodeId);
        if (updatedNode) {
          setSelectedNode(updatedNode as NodeData);

          const connectedChannelNodes = updatedNodes.filter((node) =>
            edges.some(
              (edge) =>
                edge.source === nodeId && edge.target === node.id && node.type === 'customChannel'
            )
          );
          const updatedChannelNodes = updatedNodes.map((node) =>
            connectedChannelNodes.some((channelNode) => channelNode.id === node.id)
              ? { ...node, data: { ...node.data, status: status } }
              : node
          );

          return updatedChannelNodes;
        }

        return updatedNodes;
      });
    },
    [setNodes, setSelectedNode, edges]
  );

  const deleteNode = (nodeId: string) => {
    const nodesToDelete = new Set<string>();
    edges.forEach((edge) => {
      if (edge.source === nodeId) {
        nodesToDelete.add(edge.target);
      }
    });
    nodesToDelete.add(nodeId);
    nodesToDelete.forEach((id) => {
      edges.forEach((edge) => {
        if (nodesToDelete.has(edge.source)) {
          nodesToDelete.add(edge.target);
        }
      });
    });
    setNodes((prevNodes) => prevNodes.filter((node) => !nodesToDelete.has(node.id)));
    setEdges((prevEdges) =>
      prevEdges.filter((edge) => !nodesToDelete.has(edge.source) && !nodesToDelete.has(edge.target))
    );
    setSelectedNode(null);
  };

  const addNode = (nodeId: string, enqueuedMessages: number, labelChannel: string) => {
    const providerNames = ['CM sms', 'CM rcs', 'zorya', 'voices', 'spark'];
    const newConsumerNodeId = `C-${Date.now()}`;
    const newConsumerNode = {
      id: newConsumerNodeId,
      type: 'customConsumer',
      data: {
        label: `${newConsumerNodeId}`,
        status: 'process',
        totalMessage: 5300,
        enqueuedMessages: enqueuedMessages,
        channel: labelChannel
      },
      position: { x: 2200, y: 0 },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      labelChannel: labelChannel,
      idChannel: nodeId,
      style: {}
    };
    setNodes((prevNodes) => [...prevNodes, newConsumerNode]);
    const newEdgeConsumer: Edge = {
      id: `e-${nodeId}-${newConsumerNodeId}`,
      source: nodeId,
      target: newConsumerNodeId,
      animated: true
    };
    setEdges((prevEdges) => [...prevEdges, newEdgeConsumer]);

    if (labelChannel === 'WhatsApp') {
      return;
    }

    const getRandomProviderName = () => {
      const randomIndex = Math.floor(Math.random() * providerNames.length);
      return providerNames[randomIndex];
    };

    const newProviderNodeId = `P${Date.now()}`;
    const newProviderNode = {
      id: newProviderNodeId,
      type: 'customProvider',
      data: {
        label: getRandomProviderName(),
        status: 'process',
        totalMessage: 0,
        enqueuedMessages: 0,
        labelChannel: labelChannel,
        labelConsumer: newConsumerNodeId
      },
      position: { x: 2700, y: 0 },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      style: {}
    };
    setNodes((prevNodes) => [...prevNodes, newProviderNode]);
    const newEdgeProvider: Edge = {
      id: `e-${newConsumerNodeId}-${newProviderNodeId}`,
      source: newConsumerNodeId,
      target: newProviderNodeId,
      animated: true
    };
    setEdges((prevEdges) => [...prevEdges, newEdgeProvider]);
  };

  const getCustomNodeStatus = useCallback(
    (nodeId: string) => {
      const customNode = nodes.find(
        (node) =>
          node.type === 'custom' &&
          edges.some((edge) => edge.source === node.id && edge.target === nodeId)
      );
      return customNode ? customNode.data.status : null;
    },
    [nodes, edges]
  );

  useEffect(() => {
    if (data?.channels && Array.isArray(data.channels)) {
      const channelData: NodeData[] = [];
      const consumerData: NodeData[] = [];
      const providerData: NodeData[] = [];
      const newEdges: Edge[] = [];
      const consumerEdges: Edge[] = [];
      const providerEdges: Edge[] = [];

      data.channels.forEach((channel: Channel, channelIndex: number) => {
        Object.entries(channel).forEach(
          ([key, { status, totalmsg, consumers, providers }], index) => {
            const channelNodeId = `${index + 2}-${key}`;
            const channelNode = {
              id: channelNodeId,
              type: 'customChannel',
              data: {
                label: channelLabels[key] || key,
                totalMessage: totalmsg,
                status: status,
                enqueuedMessages: 0
              },
              position: { x: 650, y: 50 + index * 260 },
              ...nodeDefaults
            };
            channelData.push(channelNode);

            newEdges.push({
              id: `e2-${channelNodeId}`,
              source: '1',
              target: channelNodeId,
              animated: true
            });

            if (consumers) {
              Object.entries(consumers).forEach(
                ([consumerKey, { status, tasa }], consumerIndex) => {
                  const consumerNodeId = `${channelNodeId}-${consumerKey}`;
                  const consumerNode = {
                    id: consumerNodeId,
                    type: 'customConsumer',
                    data: {
                      label: `C-${Date.now()}`,
                      status,
                      totalMessage: tasa,
                      enqueuedMessages: totalmsg,
                      channel: channelLabels[key] || key
                    },
                    labelChannel: channelLabels[key] || key,
                    idChannel: channelNodeId,
                    position: {
                      x: 1200,
                      y: 40 + index * 255 + 200 * channelIndex + 220 * consumerIndex
                    },
                    ...nodeDefaults
                  };

                  consumerData.push(consumerNode);
                  consumerEdges.push({
                    id: `e-${channelNodeId}-${consumerKey}`,
                    source: channelNodeId,
                    target: consumerNodeId,
                    animated: true
                  });

                  if (providers) {
                    Object.entries(providers).forEach(
                      ([providerKey, { _id, status, name }], providerIndex) => {
                        const providerNodeId = `${consumerNodeId}-${providerKey}`;
                        const providerNode = {
                          id: providerNodeId,
                          type: 'customProvider',
                          data: {
                            label: name,
                            status: status,
                            providerId: _id,
                            enqueuedMessages: 0,
                            totalMessage: 0,
                            labelChannel: channelLabels[key] || key,
                            labelConsumer: consumerKey
                          },
                          position: {
                            x: 1850,
                            y: 80 + index * 255 + 200 * consumerIndex + 200 * providerIndex
                          },
                          ...nodeDefaults
                        };

                        providerData.push(providerNode);
                        providerEdges.push({
                          id: `e-${consumerNodeId}-${providerKey}`,
                          source: consumerNodeId,
                          target: providerNodeId,
                          animated: true
                        });
                      }
                    );
                  }
                }
              );
            }
          }
        );
      });

      setNodes((nodes) => [...nodes, ...channelData, ...consumerData, ...providerData]);
      setEdges((edges) => [...edges, ...newEdges, ...consumerEdges, ...providerEdges]);
    }
  }, [data, setNodes, setEdges]);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-7 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">Campañas</li>
                    <li className="breadcrumb-item text-muted"> Monitoreo</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <a
                        className="symbol symbol-circle symbol-50px hover-scale cursor-pointer"
                        onClick={() => navigate('/campaigns/monitoring')}
                      >
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-double-left fs-2x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </a>
                      <span className="ms-3">Monitreo en vivo</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-7 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="card mb-10">
                      <div className="card-header align-items-center border-0 mt-3">
                        <h4 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-3">
                            <span className="fw-bolder fw-bold fs-3">
                              {(() => {
                                switch (selectedNode?.type) {
                                  case 'custom':
                                    return <span>Acciones generales</span>;
                                  case 'customChannel':
                                    return <span>Acciones canal</span>;
                                  case 'customConsumer':
                                    return <span>Acciones consumidor</span>;
                                  case 'customProvider':
                                    return <span>Acciones proveedor</span>;
                                  default:
                                    return <span>Acciones</span>;
                                }
                              })()}
                            </span>
                          </span>
                        </h4>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-category fs-2 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-0 mt-0">
                        {selectedNode ? (
                          <>
                            {selectedNode.type === 'custom' && (
                              <CustomNodeActions
                                node={selectedNode}
                                updateNodeStatus={updateNodeStatus}
                              />
                            )}
                            {selectedNode.type === 'customChannel' && (
                              <CustomNodeActionsChannel
                                node={selectedNode}
                                updateNodeStatus={updateNodeStatus}
                                deleteNode={deleteNode}
                                getCustomNodeStatus={getCustomNodeStatus}
                              />
                            )}
                            {selectedNode.type === 'customConsumer' && (
                              <CustomNodeActionsConsumer
                                node={selectedNode}
                                updateNodeStatus={updateNodeStatus}
                                addNode={addNode}
                                deleteNode={deleteNode}
                                getCustomNodeStatus={getCustomNodeStatus}
                              />
                            )}
                            {selectedNode.type === 'customProvider' && (
                              <CustomNodeActionsProvider
                                node={selectedNode}
                                updateNodeStatus={updateNodeStatus}
                                deleteNode={deleteNode}
                                getCustomNodeStatus={getCustomNodeStatus}
                              />
                            )}
                          </>
                        ) : (
                          <div
                            key="1"
                            className="alert alert-dismissible bg-light d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10"
                          >
                            <i className="ki-duotone ki-click fs-3x text-primary mb-5">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                              <span className="path5" />
                            </i>
                            <div className="text-center">
                              <h3 className="fw-bold mb-5">¡ Atención !</h3>
                              <div className="separator separator-dashed border-primary fw-bolder opacity-25 mb-5" />
                              <div className="mb-9 text-gray-900 w-200px">
                                Haz clic en un nodo para explorar las opciones disponibles.
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-9">
                    <div className="card ">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-circle bg-light-primary symbol-50px me-2">
                              <span className="symbol-label">
                                <i className="ki-duotone ki-send fs-2x text-primary">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </span>
                            </div>
                            <div className="ps-3">
                              <a
                                className="text-gray-800 fw-bolder fs-5 text-hover-primary mb-0"
                                href="#"
                              >
                                Campaña
                              </a>
                              <span className="text-gray-500 fw-semibold d-block">{data.name}</span>
                            </div>
                          </div>
                        </h3>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body pt-9 pb-0 d-flex flex-column mh-700px h-700px">
                        <ReactFlowProvider>
                          <ReactFlow
                            className="flex-grow-1"
                            nodes={nodes.map((node) => ({
                              ...node,
                              style:
                                selectedNode?.id === node.id
                                  ? {
                                      ...node.style,
                                      boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.2)',
                                      borderRadius: '7px'
                                    }
                                  : node.style
                            }))}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onPaneClick={() => setSelectedNode(null)}
                            onConnect={onConnect}
                            nodeTypes={nodeTypes}
                            onNodeClick={onNodeClick}
                          >
                            <Background />
                            <Controls />
                          </ReactFlow>
                        </ReactFlowProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default LiveMonitoringPage;
