import React from 'react';
import { Handle, Position } from 'reactflow';

const CustomNode = ({ data }: any) => {
  const getStatusClass = (status: string) => {
    switch (status) {
      case 'process':
        return 'bg-light-primary border-primary';
      case 'paused':
        return 'bg-light-warning border-warning';
      case 'error':
        return 'alert-danger-fow border-danger';
      case 'pending':
        return 'bg-light';
      default:
        return 'bg-light';
    }
  };
  return (
    <div
      className={`card p-0 h-md-100 mb-6 mb-md-0 border border-2 ${getStatusClass(data.status)}`}
    >
      <div className="card-header border-0">
        <div className="card-title">
          <i
            className={`ki-duotone ki-medal-star fs-2x ${data.status === 'process' ? 'text-primary' : 'text-warning'}`}
          >
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </i>
          <h1 className="fw-bold ms-2">{data.label}</h1>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="fw-bold fs-2">
          <div className="d-flex mb-5">
            <div className="ms-0">
              <span className="fs-1">{data.totalMessage.toLocaleString()}</span>
              <span className="text-muted fs-4 fw-bolder ms-1">Mensajes en total</span>
            </div>
          </div>
          <div className="fs-3 fw-border">
            {data.status === 'error'
              ? 'Verifica el problema dando click aquí.'
              : data.status === 'paused'
                ? 'Se ha pausado el envío.'
                : 'En proceso de envío.'}
          </div>
        </div>
      </div>
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

export default CustomNode;
