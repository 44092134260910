import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface SemiDonutProps {
  datasetName: string;
  dataset: {
    labels: string[];
    data: number[];
    backgroundColor: string[];
  };
}

const SemiDonut: React.FC<SemiDonutProps> = ({ dataset }) => {
  const { labels, data, backgroundColor } = dataset;
  const formatNumber = (num: number) => {
    return new Intl.NumberFormat().format(num);
  };

  const options: ApexOptions = {
    chart: {
      type: 'donut'
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10
      }
    },
    grid: {
      padding: {
        bottom: -80
      }
    },
    labels: labels,
    colors: backgroundColor,
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff']
      }
    },
    tooltip: {
      y: {
        formatter: (val: number) => formatNumber(val)
      }
    },
    legend: {
      position: 'right'
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };

  return (
    <div>
      <Chart options={options} series={data} type="donut" width="370" />
    </div>
  );
};

export default SemiDonut;
