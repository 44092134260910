import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Dropzone } from '@dropzone-ui/react';
import Header from '../../../components/layout/header.component';
import useRCSStructures from '../../../hooks/structure-templates.hook';
import Asaid from '../../../components/layout/asaid.component';
import Footer from '../../../components/layout/footer.component';
import useBgPages from '../../../hooks/bg-pages.hook';
import Select from 'react-select';
import InvalidFeedback from '../../../components/forms/invalid-feedback.component';
import Input from '../../../components/forms/input.component';
import Switch from '../../../components/forms/switch.component';
import TextArea from '../../../components/forms/text-area.component';
import Button from '../../../components/forms/button.component';
import { AlertService } from '../../../services/alert.service';

interface Cards {
  URLImagen: string;
  URLImagenPoster: string;
  URLVideo: string;
  title: string;
  msg: string;
  labelURL: string;
  linkURL: string;
}

interface FormValues {
  _id: string;
  name: string;
  typeRCS: string;
  msgBasic: string;
  btnBasic1: string;
  btnBasic2: string;
  onlyCard: boolean;
  carrousel: boolean;
  URLImage: string;
  URLImagePoster: string;
  URLVideo: string;
  title: string;
  msg: string;
  labelURL: string;
  linkURL: string;
  totalCarrouselCards: string;
  carrouselCards: Cards[];
}

function CreteTemplates() {
  useBgPages();
  const { generateStructures } = useRCSStructures();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [files, setFiles] = useState<any[]>([]);
  const [filesPoster, setFilesPoster] = useState<any[]>([]);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [rcsBasic, setRcsBasic] = useState(false);
  const [rcsSingle, setRcsSingle] = useState(false);
  const [onlyCardImage, setOnlyCardImage] = useState(false);
  const [onlyCardVideo, setOnlyCardVideo] = useState(false);
  const [carrouselCards, setCarrouselCards] = useState<any[]>([]);
  const [rcsConversational, setRcsConversational] = useState(false);

  const channel = useMemo(() => location?.state?.channel || 'basic', [location]);

  const filtersTemplates = [
    { value: 'rcs_basic', label: t('templates.rcs.rcs-title') + ' ' + t('templates.rcs.basic') },
    { value: 'rcs_single', label: t('templates.rcs.rcs-title') + ' ' + t('templates.rcs.single') },
    {
      value: 'rcs_conversacional',
      label: t('templates.rcs.rcs-title') + ' ' + t('templates.rcs.conversational')
    }
  ];
  const optionsCards = [
    { value: '2', label: t('templates.rcs.create.total-cards-option-2') },
    { value: '3', label: t('templates.rcs.create.total-cards-option-3') },
    { value: '4', label: t('templates.rcs.create.total-cards-option-4') },
    { value: '5', label: t('templates.rcs.create.total-cards-option-5') },
    { value: '6', label: t('templates.rcs.create.total-cards-option-6') },
    { value: '7', label: t('templates.rcs.create.total-cards-option-7') },
    { value: '8', label: t('templates.rcs.create.total-cards-option-8') }
  ];

  const removeFileOC = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
    setFiles([]);
  };

  const removeFileOCPoster = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
    setFilesPoster([]);
  };

  const defaultValues: FormValues = {
    _id: '',
    name: '',
    typeRCS: '',
    // RCS Basic
    msgBasic: '',
    btnBasic1: '',
    btnBasic2: '',
    // RCS onlyCard
    onlyCard: false,
    carrousel: false,
    URLImage: '',
    URLImagePoster: '',
    URLVideo: '',
    title: '',
    msg: '',
    labelURL: '',
    linkURL: '',
    // RCS carrousel
    totalCarrouselCards: '',
    carrouselCards: []
  };

  const {
    register,
    watch,
    reset,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const showOnlyCard = watch('onlyCard');
  const showCarrousel = watch('carrousel');
  const showtypeRCS = watch('typeRCS');
  const labelURL = watch('labelURL');
  const selectedCard = showOnlyCard || showCarrousel;
  //RCS Basic
  const msgBasicValue = watch('msgBasic');
  const btn1BasicValue = watch('btnBasic1');
  const btn2BasicValue = watch('btnBasic2');
  const URLImagePoster = watch('URLImagePoster');
  const isButtonDisabled = () => {
    if (showtypeRCS === 'rcs_single' || showtypeRCS === 'rcs_conversational') {
      // Aqui se validará almenos un bloque tenga un campo lleno
      const showURLImage = watch('URLImage');
      const showURLImagePoster = watch('URLImagePoster');
      const showURLVideo = watch('URLVideo');
      const showtitle = watch('title');
      const showmsg = watch('msg');
      const showlabelURL = watch('labelURL');
      const showlinkURL = watch('linkURL');

      const validOnlyCard =
        showURLImage ||
        showURLImagePoster ||
        showURLImagePoster ||
        showURLVideo ||
        showtitle ||
        showmsg ||
        showlabelURL ||
        showlinkURL;
      const validaCarrousel = true;

      return !isValid || !selectedCard || (!validOnlyCard && !validaCarrousel);
    }
    return !isValid;
  };

  const handleKeyDown = (e: any, maxLength: number) => {
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  };

  const registerTemplate = async (data: any) => {
    console.log(data);
    const { cmData, googleData } = generateStructures(data);
    AlertService.toastSuccess(t('templates.rcs.create.success-alert'));
    // Forma correcta de regresar en la navegación
    navigate(-1);
    // navigate('/templates/rcs');
    console.log('CM Data:', cmData);
    console.log('Google Data:', googleData);
  };

  const handleCardChange = (value: any) => {
    const numberOfCards = value ? parseInt(value) : 0;

    setCarrouselCards((prevCards) => {
      const newBlocks: Cards[] = [...prevCards];

      if (numberOfCards > newBlocks.length) {
        for (let i = newBlocks.length; i < numberOfCards; i++) {
          newBlocks.push({
            URLImagen: '',
            URLImagenPoster: '',
            URLVideo: '',
            title: '',
            msg: '',
            labelURL: '',
            linkURL: ''
          });
        }
      } else if (numberOfCards < newBlocks.length) {
        newBlocks.splice(numberOfCards);
      }

      const updatedValues: FormValues = {
        ...defaultValues,
        carrouselCards: newBlocks,
        typeRCS: watch('typeRCS'),
        _id: watch('_id'),
        name: watch('name'),
        carrousel: watch('carrousel'),
        onlyCard: watch('onlyCard'),
        totalCarrouselCards: watch('totalCarrouselCards'),
        URLImage: watch('URLImage'),
        URLImagePoster: watch('URLImagePoster'),
        URLVideo: watch('URLVideo'),
        title: watch('title'),
        msg: watch('msg'),
        labelURL: watch('labelURL'),
        linkURL: watch('linkURL')
      };

      reset(updatedValues);

      return newBlocks;
    });
  };

  const updateCards = (index: number, newData: Partial<any>) => {
    setCarrouselCards((prevCards) => {
      const updateCard = [...prevCards];
      updateCard[index] = { ...updateCard[index], ...newData };
      return updateCard;
    });
  };

  const updateFiles = (files: any[], index: number) => {
    console.log(files, index);
    updateCards(index, { files });

    if (index === -99) {
      setFiles([files[0]]);
      setValue(`URLImage`, files[0]?.name || '');
    } else if (index === -98) {
      setFilesPoster([files[0]]);
      setValue(`URLImagePoster`, files[0]?.name || '');
    } else if (
      files[0].type === 'image/jpeg' ||
      files[0].type === 'image/jpg' ||
      files[0].type === 'image/png' ||
      files[0].type === 'image/gif'
    ) {
      setValue(`carrouselCards.${index}.URLImagen`, files[0]?.name || '');
    } else if (files[0].type === 'video/mp4') {
      setValue(`carrouselCards.${index}.URLVideo`, files[0]?.name || '');
    }
  };

  const updateFilesPoster = (files: any[], index: number) => {
    updateCards(index, { filesPoster: files });
    setValue(`carrouselCards.${index}.URLImagenPoster`, files[0]?.name || '');
  };

  const removeFile = (index: number) => {
    updateCards(index, { files: [] });
  };

  const removeFilePoster = (index: number) => {
    updateCards(index, { filesPoster: [] });
  };

  useEffect(() => {
    const checkTheme = () => {
      const isDark = document.documentElement.getAttribute('data-bs-theme') === 'dark';
      setIsDarkTheme(isDark);
    };
    checkTheme();
    const observer = new MutationObserver(() => checkTheme());
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-bs-theme']
    });
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (channel.toLowerCase() === 'basic') {
      setValue('typeRCS', 'rcs_basic');
      // Actualizar estados
      setRcsBasic(true);
      setRcsSingle(false);
      setRcsConversational(false);
    }
    if (channel.toLowerCase() === 'single') {
      setValue('typeRCS', 'rcs_single');
      // Actualizar estados
      setRcsBasic(false);
      setRcsSingle(true);
      setRcsConversational(false);
    }
    if (channel.toLowerCase() === 'conversacional') {
      setValue('typeRCS', 'rcs_conversacional');
      // Actualizar estados
      setRcsBasic(false);
      setRcsSingle(false);
      setRcsConversational(true);
    }
  }, [channel]);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">{t('templates.rcs.tools')}</li>
                    <li className="breadcrumb-item text-muted">
                      {' '}
                      {t('templates.rcs.templates-title')}
                    </li>
                    <li className="breadcrumb-item text-muted"> {t('templates.rcs.rcs-title')}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <a
                        className="symbol symbol-circle symbol-50px hover-scale cursor-pointer"
                        onClick={() => navigate(-1)}
                      >
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-double-left  fs-2x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </a>
                      <span className="ms-3">{t('templates.rcs.add-btn')}</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <form className="form" onSubmit={handleSubmit(registerTemplate)}>
                  <div className="row g-xl-10">
                    <div className="col-xl-8 mb-5">
                      <div className="card mb-5 mb-xxl-8">
                        <div className="card-header align-items-center border-0 mt-5">
                          <label className="d-flex flex-stack mb-10">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-circle symbol-50px me-6">
                                <span className="symbol-label bg-light-primary">
                                  <i className="ki-duotone ki-note-2  fs-3x text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                    <span className="path4" />
                                  </i>
                                </span>
                              </span>
                              <span className="d-flex flex-column">
                                <h2 className="fw-bold">
                                  {t('templates.rcs.create.general-data-title')}
                                  <span className="required"></span>
                                </h2>
                                <div className="text-muted fw-semibold fs-6">
                                  {t('templates.rcs.create.general-data-description')}
                                </div>
                              </span>
                            </span>
                          </label>
                          <div className="card-toolbar"></div>
                        </div>
                        <div className="card-body pt-1">
                          <div className="row mb-7">
                            <div className="col-md-6">
                              <div className="fv-row">
                                <Input
                                  label={t('templates.rcs.create.template-name-label')}
                                  requiredIndicator="required"
                                  placeholder={t('templates.rcs.create.template-name-placeholder')}
                                  errors={errors.name}
                                  {...register('name', { required: true })}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="fv-row">
                                <label className="form-label required">
                                  {t('templates.rcs.create.type-template-label')}
                                </label>
                                <Controller
                                  control={control}
                                  name="typeRCS"
                                  rules={{ required: true }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error }
                                  }) => (
                                    <>
                                      <Select
                                        options={filtersTemplates}
                                        noOptionsMessage={() => (
                                          <div className="empty-select">
                                            {t('general.no-options')}
                                          </div>
                                        )}
                                        isDisabled
                                        className={
                                          error
                                            ? 'form-select-custom is-invalid'
                                            : 'form-select-custom'
                                        }
                                        classNamePrefix="form-select-custom"
                                        placeholder={t(
                                          'templates.rcs.create.type-template-placeholder'
                                        )}
                                        onChange={(selectedOption) => {
                                          const selectedValue = selectedOption
                                            ? selectedOption.value
                                            : null;
                                          onChange(selectedValue);
                                          reset({
                                            ...defaultValues,
                                            typeRCS: watch('typeRCS'),
                                            _id: watch('_id'),
                                            name: watch('name')
                                          });
                                          // Actualizar estados
                                          setRcsBasic(selectedValue === 'rcs_basic');
                                          setRcsSingle(selectedValue === 'rcs_single');
                                          setRcsConversational(
                                            selectedValue === 'rcs_conversacional'
                                          );
                                          setFiles([]);
                                        }}
                                        value={
                                          filtersTemplates.find(
                                            (option: { value: string; label: string }) =>
                                              option.value === value
                                          ) || null
                                        }
                                      />
                                      <InvalidFeedback error={error} />
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {rcsBasic && (
                        <div className="card mb-5 mb-xxl-8">
                          <div className="card-header align-items-center border-0 mt-5">
                            <label className="d-flex flex-stack mb-10">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-circle symbol-50px me-6">
                                  <span className="symbol-label">
                                    <i className="ki-duotone ki-messages fs-3x">
                                      <span className="path1" />
                                      <span className="path2" />
                                    </i>
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <h2 className="fw-bold">
                                    {t('templates.rcs.rcs-title') + ' ' + t('templates.rcs.basic')}
                                    <span className="required"></span>
                                  </h2>
                                  <div className="text-muted fw-semibold fs-6">
                                    {t('templates.rcs.create.rcs-basic-description')}
                                  </div>
                                </span>
                              </span>
                            </label>
                            <div className="card-toolbar"></div>
                          </div>
                          <div className="card-body pt-1">
                            <div className="fv-row mb-7">
                              <TextArea
                                label={t('templates.rcs.create.message-label')}
                                requiredIndicator="required"
                                placeholder={t('templates.rcs.create.message-placeholder')}
                                errors={errors.msgBasic}
                                value={watch('msgBasic').length}
                                counter={160}
                                onKeyDown={(e: any) => handleKeyDown(e, 160)}
                                {...register('msgBasic', {
                                  required: true,
                                  maxLength: { value: 160, message: '160' }
                                })}
                              />
                            </div>
                            <div className="row mb-7">
                              <div className="col-md-6">
                                <div className="fv-row">
                                  <Input
                                    label={t('templates.rcs.create.btn-reply-1')}
                                    optional
                                    placeholder={t('templates.rcs.create.btn-text-placeholder')}
                                    currentLength={watch('btnBasic1').length}
                                    errors={errors.btnBasic1}
                                    onKeyDown={(e: any) => handleKeyDown(e, 15)}
                                    {...register('btnBasic1', {
                                      required: false,
                                      maxLength: { value: 15, message: '15' }
                                    })}
                                    maxLength={15}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="fv-row">
                                  <Input
                                    label={t('templates.rcs.create.btn-reply-2')}
                                    optional
                                    placeholder={t('templates.rcs.create.btn-text-placeholder')}
                                    currentLength={watch('btnBasic2').length}
                                    errors={errors.btnBasic2}
                                    onKeyDown={(e: any) => handleKeyDown(e, 15)}
                                    {...register('btnBasic2', {
                                      required: false,
                                      maxLength: { value: 15, message: '15' }
                                    })}
                                    maxLength={15}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(rcsSingle || rcsConversational) && (
                        <div className="card mb-5 mb-xxl-8">
                          <div className="card-header align-items-center border-0 mt-5">
                            <label className="d-flex flex-stack mb-10">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-circle symbol-50px me-6">
                                  <span
                                    className={`symbol-label ${rcsSingle ? 'bg-light-info' : 'bg-light-primary'}`}
                                  >
                                    <i
                                      className={`ki-duotone ki-messages fs-3x ${rcsSingle ? 'text-info ' : 'text-primary '}`}
                                    >
                                      <span className="path1" />
                                      <span className="path2" />
                                    </i>
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <h2 className="fw-bold">
                                    {rcsSingle
                                      ? t('templates.rcs.rcs-title') +
                                        ' ' +
                                        t('templates.rcs.single')
                                      : t('templates.rcs.rcs-title') +
                                        ' ' +
                                        t('templates.rcs.conversational')}
                                    <span className="required"></span>
                                  </h2>
                                  <div className="text-muted fw-semibold fs-6">
                                    {t('templates.rcs.create.rcs-single-description')}
                                  </div>
                                </span>
                              </span>
                            </label>
                            <div className="card-toolbar"></div>
                          </div>
                          <div className="card-body pt-1">
                            <div className="fv-row mb-10">
                              <label className="d-flex flex-stack cursor-pointer mb-8">
                                <span className="d-flex align-items-center me-2">
                                  <span className="d-flex flex-column">
                                    <span className="fw-bolder fs-6">
                                      {t('templates.rcs.create.enriched-card')}
                                    </span>
                                    <span className="fs-7 text-muted">
                                      {t('templates.rcs.create.enriched-card-description')}
                                    </span>
                                  </span>
                                </span>
                                <span className="form-check form-check-custom form-check-solid">
                                  <Switch
                                    {...register('onlyCard')}
                                    onChange={(e: any) => {
                                      const checked = e.target.checked;
                                      setValue('onlyCard', checked);
                                      reset({
                                        ...defaultValues,
                                        typeRCS: watch('typeRCS'),
                                        _id: watch('_id'),
                                        name: watch('name'),
                                        onlyCard: checked,
                                        carrousel: watch('carrousel'),
                                        totalCarrouselCards: watch('totalCarrouselCards'),
                                        carrouselCards: watch('carrouselCards')
                                      });
                                      setFiles([]);
                                      setFilesPoster([]);
                                      setOnlyCardVideo(false);
                                      setOnlyCardImage(false);
                                    }}
                                  />
                                </span>
                              </label>
                              {showOnlyCard && (
                                <>
                                  <div
                                    key="1"
                                    className="accordion accordion-icon-toggle"
                                    id="acordion_onlyCard"
                                  >
                                    <div className="mb-10">
                                      <div
                                        className="accordion-header py-3 d-flex"
                                        data-bs-target="#acordion_onlyCard_item"
                                        data-bs-toggle="collapse"
                                      >
                                        <span className="accordion-icon">
                                          <i className="ki-duotone ki-arrow-right fs-1">
                                            <span className="path1" />
                                            <span className="path2" />
                                          </i>
                                        </span>
                                        <h4 className="fs-6 fw-semibold mb-0 ms-4 text-primary fw-bolder mt-1">
                                          {t('templates.rcs.create.swipe-card-title')}
                                        </h4>
                                      </div>
                                      <div
                                        className="fs-6 collapse show ps-10"
                                        data-bs-parent="#acordion_onlyCard"
                                        id="acordion_onlyCard_item"
                                      >
                                        <div className="mb-10 mt-0 border p-10 rounded">
                                          <div className="row mb-10">
                                            <label className="form-label">
                                              {t('templates.rcs.create.media-title')}
                                              <span className="fs-8 text-muted ms-1">
                                                ({t('general.optional')})
                                              </span>
                                            </label>
                                            <div className="col-6">
                                              <div key="1" data-kt-buttons="true">
                                                <label
                                                  className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-0 ${onlyCardImage ? 'active' : ''}`}
                                                >
                                                  <div className="d-flex align-items-center me-2">
                                                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                      <input
                                                        className="form-check-input"
                                                        value="startup"
                                                        name="plan"
                                                        type="radio"
                                                        checked={onlyCardImage}
                                                        onChange={() => {
                                                          setOnlyCardImage(true);
                                                          setOnlyCardVideo(false);
                                                          setFiles([]);
                                                          setFilesPoster([]);
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                                        {t('templates.rcs.create.image-title')}
                                                      </h2>
                                                    </div>
                                                  </div>
                                                  <div className="ms-5">
                                                    <i className="ki-duotone ki-picture fs-2x">
                                                      <span className="path1" />
                                                      <span className="path2" />
                                                    </i>
                                                  </div>
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-6">
                                              <div key="2" data-kt-buttons="true">
                                                <label
                                                  className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-0 ${onlyCardVideo ? 'active' : ''}`}
                                                >
                                                  <div className="d-flex align-items-center me-2">
                                                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                      <input
                                                        className="form-check-input"
                                                        value="enterprise"
                                                        name="plan"
                                                        type="radio"
                                                        checked={onlyCardVideo}
                                                        onChange={() => {
                                                          setOnlyCardImage(false);
                                                          setOnlyCardVideo(true);
                                                          setFiles([]);
                                                          setFilesPoster([]);
                                                        }}
                                                      />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                      <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                                        {t('templates.rcs.create.video-title')}
                                                      </h2>
                                                    </div>
                                                  </div>
                                                  <div className="ms-5">
                                                    <i className="ki-duotone ki-google-play fs-2x">
                                                      <span className="path1" />
                                                      <span className="path2" />
                                                    </i>
                                                  </div>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          {(onlyCardVideo || onlyCardImage) && (
                                            <>
                                              <div className="row mb-7">
                                                <div className="col-md-8 mt-4">
                                                  {files.length === 0 ? (
                                                    <Dropzone
                                                      // onChange={updateFilesOC}
                                                      onChange={(files) => updateFiles(files, -99)}
                                                      value={files}
                                                      className="dropzone cursor-default bg-light"
                                                      accept={
                                                        onlyCardVideo
                                                          ? '.mp4'
                                                          : '.png, .jpg, .jpeg, .gif'
                                                      }
                                                      maxFiles={1}
                                                    >
                                                      <div className="dz-message needsclick">
                                                        <i
                                                          className={`ki-duotone ${onlyCardVideo ? 'ki-google-play' : 'ki-picture'} fs-3x text-primary`}
                                                        >
                                                          <span className="path1" />
                                                          <span className="path2" />
                                                        </i>
                                                        <div className="ms-4">
                                                          <h3 className="fs-7 fw-bold text-gray-900 mb-0">
                                                            {t('templates.rcs.create.drag-file')}
                                                          </h3>
                                                          <span className="fs-8 fw-semibold text-info">
                                                            {onlyCardVideo
                                                              ? t(
                                                                  'templates.rcs.create.format-video'
                                                                )
                                                              : t(
                                                                  'templates.rcs.create.format-images'
                                                                )}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </Dropzone>
                                                  ) : (
                                                    <div className="dropzone cursor-default">
                                                      <div className="dz-message">
                                                        <i
                                                          className={`ki-duotone ${onlyCardVideo ? 'ki-google-play' : 'ki-picture'} fs-3x text-primary`}
                                                        >
                                                          <span className="path1" />
                                                          <span className="path2" />
                                                        </i>
                                                        <div className="ms-4">
                                                          <h3 className="fs-7 fw-bold text-gray-900 mb-1">
                                                            {onlyCardVideo
                                                              ? t('templates.rcs.create.video-set')
                                                              : t('templates.rcs.create.image-set')}
                                                          </h3>
                                                          <span className="fs-8 fw-semibold text-info">
                                                            {files[0]?.name}
                                                          </span>
                                                          <a
                                                            className="ms-2 cursor-pointer"
                                                            onClick={removeFileOC}
                                                          >
                                                            <i className="ki-duotone ki-abstract-11 text-danger fs-6">
                                                              <span className="path1"></span>
                                                              <span className="path2"></span>
                                                            </i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          )}
                                          {onlyCardImage && (
                                            <div className="fv-row mb-10">
                                              <Input
                                                label={t('templates.rcs.create.url-image-label')}
                                                optional
                                                placeholder="http://www.dominio.com/imagen.jpg"
                                                errors={errors.URLImage}
                                                {...register('URLImage', {
                                                  required: false
                                                })}
                                              />
                                            </div>
                                          )}
                                          {onlyCardVideo && (
                                            <>
                                              <div className="fv-row mb-7">
                                                <Input
                                                  label={t('templates.rcs.create.url-video-label')}
                                                  optional
                                                  placeholder="http://www.dominio.com/video.mp4"
                                                  errors={errors.URLVideo}
                                                  {...register('URLVideo', {
                                                    required: false
                                                  })}
                                                />
                                              </div>
                                              <>
                                                <div className="row mb-7">
                                                  <div className="col-md-8">
                                                    {filesPoster.length === 0 ? (
                                                      <Dropzone
                                                        // onChange={updateFilesOCPoster}
                                                        onChange={(files) =>
                                                          updateFiles(files, -98)
                                                        }
                                                        value={filesPoster}
                                                        className="dropzone cursor-default bg-light"
                                                        accept=".png, .jpg, .jpeg, .gif'"
                                                        maxFiles={1}
                                                      >
                                                        <div className="dz-message needsclick">
                                                          <i className="ki-duotone ki-picture fs-3x text-primary">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                          </i>
                                                          <div className="ms-4">
                                                            <h3 className="fs-7 fw-bold text-gray-900 mb-0">
                                                              {t('templates.rcs.create.drag-file')}
                                                            </h3>
                                                            <span className="fs-8 fw-semibold text-info">
                                                              {t(
                                                                'templates.rcs.create.format-poster'
                                                              )}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </Dropzone>
                                                    ) : (
                                                      <div className="dropzone cursor-default">
                                                        <div className="dz-message">
                                                          <i className="ki-duotone ki-picture fs-3x text-primary">
                                                            <span className="path1" />
                                                            <span className="path2" />
                                                          </i>
                                                          <div className="ms-4">
                                                            <h3 className="fs-7 fw-bold text-gray-900 mb-1">
                                                              {t('templates.rcs.create.image-set')}
                                                            </h3>
                                                            <span className="fs-8 fw-semibold text-info">
                                                              {filesPoster[0]?.name}
                                                            </span>
                                                            <a
                                                              className="ms-2 cursor-pointer"
                                                              onClick={removeFileOCPoster}
                                                            >
                                                              <i className="ki-duotone ki-abstract-11 text-danger fs-6">
                                                                <span className="path1"></span>
                                                                <span className="path2"></span>
                                                              </i>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                              <div className="fv-row mb-10">
                                                <Input
                                                  label={t(
                                                    'templates.rcs.create.url-poster-video-label'
                                                  )}
                                                  optional
                                                  placeholder="http://www.dominio.com/posterVideo.jpg"
                                                  errors={errors.URLImagePoster}
                                                  {...register('URLImagePoster', {
                                                    required: false
                                                  })}
                                                />
                                              </div>
                                            </>
                                          )}
                                          <div className="fv-row mb-0">
                                            <Input
                                              label={t('templates.rcs.create.title-label')}
                                              optional
                                              placeholder={t(
                                                'templates.rcs.create.title-placeholder'
                                              )}
                                              currentLength={watch('title').length}
                                              errors={errors.title}
                                              onKeyDown={(e: any) => handleKeyDown(e, 200)}
                                              {...register('title', {
                                                required: false,
                                                maxLength: { value: 200, message: '200' }
                                              })}
                                              maxLength={200}
                                            />
                                          </div>
                                          <div className="fv-row mb-0">
                                            <TextArea
                                              label={t('templates.rcs.create.message-label')}
                                              optional
                                              placeholder={t(
                                                'templates.rcs.create.message-placeholder'
                                              )}
                                              errors={errors.msg}
                                              value={watch('msg').length}
                                              counter={2000}
                                              onKeyDown={(e: any) => handleKeyDown(e, 2000)}
                                              {...register('msg', {
                                                required: false,
                                                maxLength: { value: 2000, message: '2000' }
                                              })}
                                            />
                                          </div>
                                          <div className="row mb-5">
                                            <div className="col-md-6">
                                              <Input
                                                label={t('templates.rcs.create.url-tag')}
                                                optional
                                                placeholder={t(
                                                  'templates.rcs.create.url-tag-placeholder'
                                                )}
                                                currentLength={watch('labelURL').length}
                                                errors={errors.labelURL}
                                                onKeyDown={(e: any) => handleKeyDown(e, 25)}
                                                {...register('labelURL', {
                                                  required: false,
                                                  maxLength: { value: 25, message: '25' }
                                                })}
                                                maxLength={25}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              <Input
                                                label={t('templates.rcs.create.url-label')}
                                                optional
                                                placeholder={t(
                                                  'templates.rcs.create.url-placeholder'
                                                )}
                                                errors={errors.linkURL}
                                                {...register('linkURL', {
                                                  required: false
                                                })}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <label className="d-flex flex-stack cursor-pointer mb-10">
                                <span className="d-flex align-items-center me-2">
                                  <span className="d-flex flex-column">
                                    <span className="fw-bolder fs-6">
                                      {t('templates.rcs.create.carrousel-title')}
                                    </span>
                                    <span className="fs-7 text-muted">
                                      {t('templates.rcs.create.carrousel-description')}
                                    </span>
                                  </span>
                                </span>
                                <span className="form-check form-check-custom form-check-solid">
                                  <Switch
                                    {...register('carrousel')}
                                    onChange={(e: any) => {
                                      const checked = e.target.checked;
                                      setValue('carrousel', checked);
                                      reset({
                                        ...defaultValues,
                                        typeRCS: watch('typeRCS'),
                                        _id: watch('_id'),
                                        name: watch('name'),
                                        carrousel: checked,
                                        onlyCard: watch('onlyCard'),
                                        URLImage: watch('URLImage'),
                                        URLImagePoster: watch('URLImagePoster'),
                                        URLVideo: watch('URLVideo'),
                                        title: watch('title'),
                                        msg: watch('msg'),
                                        labelURL: watch('labelURL'),
                                        linkURL: watch('linkURL')
                                      });
                                      setCarrouselCards([]);
                                      // setFiles([]);
                                      // setFilesPoster([]);
                                      // setOnlyCardVideo(false);
                                      // setOnlyCardImage(false);
                                    }}
                                  />
                                </span>
                              </label>
                              {showCarrousel && (
                                <>
                                  <label className="form-label required">
                                    {t('templates.rcs.create.total-cards-title')}
                                  </label>
                                  <Controller
                                    control={control}
                                    name="totalCarrouselCards"
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error }
                                    }) => (
                                      <>
                                        <Select
                                          options={optionsCards}
                                          noOptionsMessage={() => (
                                            <div className="empty-select">
                                              {t('general.no-options')}
                                            </div>
                                          )}
                                          className={
                                            error
                                              ? 'form-select-custom is-invalid'
                                              : 'form-select-custom'
                                          }
                                          classNamePrefix="form-select-custom"
                                          placeholder={t(
                                            'templates.rcs.create.total-cards-placeholder'
                                          )}
                                          onChange={(selectedOption) => {
                                            const selectedValue = selectedOption
                                              ? selectedOption.value
                                              : null;
                                            onChange(selectedValue);
                                            handleCardChange(selectedValue);
                                          }}
                                          value={
                                            optionsCards.find(
                                              (optionsCards) => optionsCards.value === value
                                            ) || null
                                          }
                                        />
                                        {error && (
                                          <div className="invalid-feedback">{error.message}</div>
                                        )}
                                      </>
                                    )}
                                  />
                                  {carrouselCards.map((carrouselCards, index) => (
                                    <div key={index}>
                                      <div
                                        className="accordion accordion-icon-toggle mt-7"
                                        id={`acordion_${index}`}
                                      >
                                        <div className="mb-10">
                                          <div
                                            className="accordion-header py-3 d-flex collapsed"
                                            data-bs-target={`#acordion_${index}_item`}
                                            data-bs-toggle="collapse"
                                          >
                                            <span className="accordion-icon">
                                              <i className="ki-duotone ki-arrow-right fs-1">
                                                <span className="path1" />
                                                <span className="path2" />
                                              </i>
                                            </span>
                                            <h4 className="fs-6 fw-semibold mb-0 ms-4 text-primary fw-bolder mt-1">
                                              {t('templates.rcs.create.swipe-card-title') +
                                                ' ' +
                                                (index + 1)}
                                            </h4>
                                          </div>
                                          <div
                                            className="fs-6 collapse ps-10"
                                            data-bs-parent={`#acordion_${index}`}
                                            id={`acordion_${index}_item`}
                                          >
                                            <div className="mb-10 mt-0 border p-10 rounded">
                                              <div className="row mb-10">
                                                <label className="form-label">
                                                  {t('templates.rcs.create.media-title')}
                                                  <span className="fs-8 text-muted ms-1">
                                                    ({t('general.optional')})
                                                  </span>
                                                </label>
                                                <div className="col-6">
                                                  <div data-kt-buttons="true">
                                                    <label
                                                      className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-0 ${carrouselCards.onlyCardImage ? 'active' : ''}`}
                                                    >
                                                      <div className="d-flex align-items-center me-2">
                                                        <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                          <input
                                                            className="form-check-input"
                                                            value="startup"
                                                            name={`plan_${index}`}
                                                            type="radio"
                                                            checked={carrouselCards.onlyCardImage}
                                                            onChange={() => {
                                                              updateCards(index, {
                                                                onlyCardImage: true,
                                                                onlyCardVideo: false,
                                                                files: [],
                                                                filesPoster: []
                                                              });
                                                            }}
                                                          />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                          <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                                            {t('templates.rcs.create.image-title')}
                                                          </h2>
                                                        </div>
                                                      </div>
                                                      <div className="ms-5">
                                                        <i className="ki-duotone ki-picture fs-2x">
                                                          <span className="path1" />
                                                          <span className="path2" />
                                                        </i>
                                                      </div>
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-6">
                                                  <div data-kt-buttons="true">
                                                    <label
                                                      className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-0 ${carrouselCards.onlyCardVideo ? 'active' : ''}`}
                                                    >
                                                      <div className="d-flex align-items-center me-2">
                                                        <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                          <input
                                                            className="form-check-input"
                                                            value="enterprise"
                                                            name={`plan_${index}`}
                                                            type="radio"
                                                            checked={carrouselCards.onlyCardVideo}
                                                            onChange={() => {
                                                              updateCards(index, {
                                                                onlyCardImage: false,
                                                                onlyCardVideo: true,
                                                                files: [],
                                                                filesPoster: []
                                                              });
                                                            }}
                                                          />
                                                        </div>
                                                        <div className="flex-grow-1">
                                                          <h2 className="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                                            {t('templates.rcs.create.video-title')}
                                                          </h2>
                                                        </div>
                                                      </div>
                                                      <div className="ms-5">
                                                        <i className="ki-duotone ki-google-play fs-2x">
                                                          <span className="path1" />
                                                          <span className="path2" />
                                                        </i>
                                                      </div>
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                              {(carrouselCards.onlyCardVideo ||
                                                carrouselCards.onlyCardImage) && (
                                                <>
                                                  <div className="row mb-7">
                                                    <div className="col-md-8 mt-4">
                                                      {carrouselCards.files.length === 0 ? (
                                                        <Dropzone
                                                          onChange={(files) =>
                                                            updateFiles(files, index)
                                                          }
                                                          value={carrouselCards.files}
                                                          className="dropzone cursor-default bg-light"
                                                          accept={
                                                            carrouselCards.onlyCardVideo
                                                              ? '.mp4'
                                                              : '.png, .jpg, .jpeg, .gif'
                                                          }
                                                          maxFiles={1}
                                                        >
                                                          <div className="dz-message needsclick">
                                                            <i
                                                              className={`ki-duotone ${carrouselCards.onlyCardVideo ? 'ki-google-play' : 'ki-picture'} fs-3x text-primary`}
                                                            >
                                                              <span className="path1" />
                                                              <span className="path2" />
                                                            </i>
                                                            <div className="ms-4">
                                                              <h3 className="fs-7 fw-bold text-gray-900 mb-0">
                                                                {t(
                                                                  'templates.rcs.create.drag-file'
                                                                )}
                                                              </h3>
                                                              <span className="fs-8 fw-semibold text-info">
                                                                {carrouselCards.onlyCardVideo
                                                                  ? t(
                                                                      'templates.rcs.create.format-video'
                                                                    )
                                                                  : t(
                                                                      'templates.rcs.create.format-images'
                                                                    )}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </Dropzone>
                                                      ) : (
                                                        <div className="dropzone cursor-default">
                                                          <div className="dz-message">
                                                            <i
                                                              className={`ki-duotone ${carrouselCards.onlyCardVideo ? 'ki-google-play' : 'ki-picture'} fs-3x text-primary`}
                                                            >
                                                              <span className="path1" />
                                                              <span className="path2" />
                                                            </i>
                                                            <div className="ms-4">
                                                              <h3 className="fs-7 fw-bold text-gray-900 mb-1">
                                                                {carrouselCards.onlyCardVideo
                                                                  ? t(
                                                                      'templates.rcs.create.video-set'
                                                                    )
                                                                  : t(
                                                                      'templates.rcs.create.image-set'
                                                                    )}
                                                              </h3>
                                                              <span className="fs-8 fw-semibold text-info">
                                                                {carrouselCards.files[0]?.name}
                                                              </span>
                                                              <a
                                                                className="ms-2 cursor-pointer"
                                                                onClick={() => removeFile(index)}
                                                              >
                                                                <i className="ki-duotone ki-abstract-11 text-danger fs-6">
                                                                  <span className="path1" />
                                                                  <span className="path2" />
                                                                </i>
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                  {carrouselCards.onlyCardImage && (
                                                    <div className="fv-row mb-10">
                                                      <Controller
                                                        name={
                                                          `carrouselCards.${index}.URLImagen` as const
                                                        }
                                                        control={control}
                                                        render={({ field }) => (
                                                          <Input
                                                            label={t(
                                                              'templates.rcs.create.url-image-label'
                                                            )}
                                                            optional
                                                            placeholder="http://www.dominio.com/imagen.jpg"
                                                            errors={
                                                              errors.carrouselCards?.[index]
                                                                ?.URLImagen
                                                            }
                                                            {...register(
                                                              `carrouselCards.${index}.URLImagen`,
                                                              {
                                                                required: false
                                                              }
                                                            )}
                                                          />
                                                        )}
                                                      />
                                                    </div>
                                                  )}
                                                  {carrouselCards.onlyCardVideo && (
                                                    <>
                                                      <div className="fv-row mb-7">
                                                        <Controller
                                                          name={
                                                            `carrouselCards.${index}.URLVideo` as const
                                                          }
                                                          control={control}
                                                          render={({ field }) => (
                                                            <Input
                                                              label={t(
                                                                'templates.rcs.create.url-video-label'
                                                              )}
                                                              optional
                                                              placeholder="http://www.dominio.com/video.mp4"
                                                              errors={
                                                                errors.carrouselCards?.[index]
                                                                  ?.URLVideo
                                                              }
                                                              {...register(
                                                                `carrouselCards.${index}.URLVideo`,
                                                                {
                                                                  required: false
                                                                }
                                                              )}
                                                            />
                                                          )}
                                                        />
                                                      </div>
                                                      <div className="row mb-7">
                                                        <div className="col-md-8 mt-4">
                                                          {carrouselCards.filesPoster.length ===
                                                          0 ? (
                                                            <Dropzone
                                                              onChange={(files) =>
                                                                updateFilesPoster(files, index)
                                                              }
                                                              value={carrouselCards.filesPoster}
                                                              className="dropzone cursor-default bg-light"
                                                              accept=".png, .jpg, .jpeg, .gif"
                                                              maxFiles={1}
                                                            >
                                                              <div className="dz-message needsclick">
                                                                <i className="ki-duotone ki-picture fs-3x text-primary">
                                                                  <span className="path1" />
                                                                  <span className="path2" />
                                                                </i>
                                                                <div className="ms-4">
                                                                  <h3 className="fs-7 fw-bold text-gray-900 mb-0">
                                                                    {t(
                                                                      'templates.rcs.create.drag-file'
                                                                    )}
                                                                  </h3>
                                                                  <span className="fs-8 fw-semibold text-info">
                                                                    {t(
                                                                      'templates.rcs.create.format-poster'
                                                                    )}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </Dropzone>
                                                          ) : (
                                                            <div className="dropzone cursor-default">
                                                              <div className="dz-message">
                                                                <i className="ki-duotone ki-picture fs-3x text-primary">
                                                                  <span className="path1" />
                                                                  <span className="path2" />
                                                                </i>
                                                                <div className="ms-4">
                                                                  <h3 className="fs-7 fw-bold text-gray-900 mb-1">
                                                                    {t(
                                                                      'templates.rcs.create.image-set'
                                                                    )}
                                                                  </h3>
                                                                  <span className="fs-8 fw-semibold text-info">
                                                                    {
                                                                      carrouselCards.filesPoster[0]
                                                                        ?.name
                                                                    }
                                                                  </span>
                                                                  <a
                                                                    className="ms-2 cursor-pointer"
                                                                    onClick={() =>
                                                                      removeFilePoster(index)
                                                                    }
                                                                  >
                                                                    <i className="ki-duotone ki-abstract-11 text-danger fs-6">
                                                                      <span className="path1" />
                                                                      <span className="path2" />
                                                                    </i>
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className="fv-row mb-10">
                                                        <Controller
                                                          name={
                                                            `carrouselCards.${index}.URLImagenPoster` as const
                                                          }
                                                          control={control}
                                                          render={({ field }) => (
                                                            <Input
                                                              label={t(
                                                                'templates.rcs.create.url-poster-video-label'
                                                              )}
                                                              optional
                                                              placeholder="http://www.dominio.com/posterVideo.jpg"
                                                              errors={
                                                                errors.carrouselCards?.[index]
                                                                  ?.URLImagenPoster
                                                              }
                                                              {...register(
                                                                `carrouselCards.${index}.URLImagenPoster`,
                                                                {
                                                                  required: false
                                                                }
                                                              )}
                                                            />
                                                          )}
                                                        />
                                                      </div>
                                                    </>
                                                  )}
                                                </>
                                              )}

                                              <div className="mb-0">
                                                <Controller
                                                  name={`carrouselCards.${index}.title` as const}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <Input
                                                      label={t('templates.rcs.create.title-label')}
                                                      optional
                                                      placeholder={t(
                                                        'templates.rcs.create.title-placeholder'
                                                      )}
                                                      errors={errors.carrouselCards?.[index]?.title}
                                                      currentLength={
                                                        watch(`carrouselCards.${index}.title`)
                                                          .length
                                                      }
                                                      onKeyDown={(e: any) => handleKeyDown(e, 200)}
                                                      {...register(
                                                        `carrouselCards.${index}.title`,
                                                        {
                                                          required: false,
                                                          maxLength: { value: 200, message: '200' }
                                                        }
                                                      )}
                                                      maxLength={200}
                                                    />
                                                  )}
                                                />
                                              </div>
                                              <div className="mb-0">
                                                <Controller
                                                  name={`carrouselCards.${index}.msg` as const}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <TextArea
                                                      label={t(
                                                        'templates.rcs.create.message-label'
                                                      )}
                                                      optional
                                                      placeholder={t(
                                                        'templates.rcs.create.message-placeholder'
                                                      )}
                                                      errors={errors.carrouselCards?.[index]?.msg}
                                                      value={
                                                        watch(`carrouselCards.${index}.msg`).length
                                                      }
                                                      onKeyDown={(e: any) => handleKeyDown(e, 2000)}
                                                      counter={2000}
                                                      {...register(`carrouselCards.${index}.msg`, {
                                                        required: false,
                                                        maxLength: { value: 2000, message: '2000' }
                                                      })}
                                                      maxLength={2000}
                                                    />
                                                  )}
                                                />
                                              </div>
                                              <div className="row mb-5">
                                                <div className="col-md-6">
                                                  <Controller
                                                    name={
                                                      `carrouselCards.${index}.labelURL` as const
                                                    }
                                                    control={control}
                                                    render={({ field }) => (
                                                      <Input
                                                        label={t('templates.rcs.create.url-tag')}
                                                        optional
                                                        placeholder={t(
                                                          'templates.rcs.create.url-tag-placeholder'
                                                        )}
                                                        errors={
                                                          errors.carrouselCards?.[index]?.labelURL
                                                        }
                                                        currentLength={
                                                          watch(`carrouselCards.${index}.labelURL`)
                                                            .length
                                                        }
                                                        onKeyDown={(e: any) => handleKeyDown(e, 25)}
                                                        {...register(
                                                          `carrouselCards.${index}.labelURL`,
                                                          {
                                                            required: false,
                                                            maxLength: { value: 25, message: '25' }
                                                          }
                                                        )}
                                                        maxLength={25}
                                                      />
                                                    )}
                                                  />
                                                </div>
                                                <div className="col-md-6">
                                                  <Controller
                                                    name={
                                                      `carrouselCards.${index}.linkURL` as const
                                                    }
                                                    control={control}
                                                    render={({ field }) => (
                                                      <Input
                                                        label={t('templates.rcs.create.url-label')}
                                                        optional
                                                        placeholder={t(
                                                          'templates.rcs.create.url-placeholder'
                                                        )}
                                                        errors={
                                                          errors.carrouselCards?.[index]?.linkURL
                                                        }
                                                        {...register(
                                                          `carrouselCards.${index}.linkURL`,
                                                          {
                                                            required: false
                                                          }
                                                        )}
                                                      />
                                                    )}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="text-left pt-9">
                        <Button
                          type="submit"
                          typeStepperBtn="submit"
                          id="sendData"
                          className="btn btn-lg btn-primary w-325px mb-5 hover-scale"
                          disabled={isButtonDisabled()}
                          isLoading={isSubmitting}
                        >
                          {t('templates.rcs.create.save-btn')}
                          <i className="ki-duotone ki-send ms-2 fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </Button>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div
                        className="mb-5"
                        data-kt-sticky="true"
                        data-kt-sticky-name="docs-sticky-summary"
                        data-kt-sticky-offset="{default: true, xl: '330px'}"
                        data-kt-sticky-top="100px"
                        data-kt-sticky-animation="true"
                        data-kt-sticky-zindex="95"
                      >
                        <div className={isDarkTheme ? 'wrap-android-dark' : 'wrap-android'}>
                          <div className={`preview-container-RCS  scroll`}>
                            {rcsBasic ? (
                              <>
                                {msgBasicValue && (
                                  <div
                                    className={
                                      isDarkTheme
                                        ? 'preview-template-RCS-dark'
                                        : 'preview-template-RCS-Basic'
                                    }
                                  >
                                    <div className="preview-template-header fw-bold">
                                      {msgBasicValue}
                                    </div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="text-right btn-container-Basic">
                                    {btn1BasicValue && (
                                      <div
                                        className={
                                          isDarkTheme ? 'btn-answer-basic-dark' : 'btn-answer-basic'
                                        }
                                      >
                                        {btn1BasicValue}
                                      </div>
                                    )}
                                    {btn2BasicValue && (
                                      <div
                                        className={
                                          isDarkTheme
                                            ? 'btn-answer-basic-dark ms-2'
                                            : 'btn-answer-basic ms-2'
                                        }
                                      >
                                        {btn2BasicValue}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : rcsSingle || rcsConversational ? (
                              <>
                                {watch('onlyCard') && (
                                  <div
                                    className={
                                      isDarkTheme
                                        ? 'preview-template-RCS-dark'
                                        : 'preview-template-RCS'
                                    }
                                  >
                                    <div>
                                      <div>
                                        {onlyCardVideo && (
                                          <div className="preview-template-video-container mb-5"></div>
                                        )}
                                        {onlyCardImage &&
                                          files.length > 0 &&
                                          files[0].type.startsWith('image/') && (
                                            <div className="preview-template-img-container-url mb-5">
                                              <img
                                                src={URL.createObjectURL(files[0].file)}
                                                alt="Uploaded"
                                                className="preview-image mw-100 mb-3"
                                              />
                                            </div>
                                          )}
                                      </div>
                                      <div className="preview-template-header fw-bolder">
                                        {watch('title')}
                                      </div>
                                      <p className="mb-10">{watch('msg')}</p>
                                      <div className="text-center">
                                        <p className="text-info">
                                          {labelURL && (
                                            <>
                                              <i className="bi bi-globe-americas text-info fw-bolder"></i>
                                              <span className="ms-2 fw-bolder">
                                                {watch('labelURL')}
                                              </span>
                                            </>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {showCarrousel && carrouselCards.length > 0 && (
                                  <div className="carrousel-container mt-5">
                                    <div className="preview-cards-carrousel">
                                      {carrouselCards.map((card, index) => (
                                        <div
                                          key={index}
                                          className={
                                            isDarkTheme
                                              ? 'preview-template-RCS-dark me-5'
                                              : 'preview-template-RCS me-5'
                                          }
                                        >
                                          {card.files &&
                                            card.files.length > 0 &&
                                            card.files[0].type.startsWith('image/') && (
                                              <div>
                                                <div className="preview-template-img-container-url mb-5">
                                                  <img
                                                    src={URL.createObjectURL(card.files[0].file)}
                                                    alt="Uploaded"
                                                    className="preview-image mw-100 mb-3"
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          {card.onlyCardVideo && (
                                            <div className="preview-template-video-container mb-5"></div>
                                          )}
                                          <div className="preview-template-header fw-bold">
                                            {watch(`carrouselCards.${index}.title`)}
                                          </div>
                                          <p className="mb-10">
                                            {watch(`carrouselCards.${index}.msg`)}
                                          </p>
                                          <div className="text-center">
                                            <p className="text-info">
                                              {watch(`carrouselCards.${index}.labelURL`) && (
                                                <>
                                                  <i className="bi bi-globe-americas text-info fw-bolder"></i>
                                                  <span className="ms-2 fw-bolder">
                                                    {watch(`carrouselCards.${index}.labelURL`)}
                                                  </span>
                                                </>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default CreteTemplates;
