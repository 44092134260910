import { useForm, Controller } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelsService } from '../../../services/channels.service';
import { AlertService } from '../../../services/alert.service';
import useInputValidation from '../../../hooks/input-validation.hook';
import Select from 'react-select';
import Input from '../../../components/forms/input.component';
import Button from '../../../components/forms/button.component';
import InvalidFeedback from '../../../components/forms/invalid-feedback.component';
import { ProvidersService } from '../../../services/providers.service';
import useModal from '../../../hooks/close-modal.hook';
interface AddAccountProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
}
function AddAccount({ show, onRequestClose, payload }: AddAccountProps) {
  const { t } = useTranslation();
  const [providers, setProviders] = useState([]);
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const { tenDigitsRegex, numericRegex, alphanumericRegex, handleAlphanumericInput } =
    useInputValidation();
  const whatsappProviders = providers.filter((provider: any) => provider.type === 'whatsapp');

  const defaultValues = {
    _id: '',
    provider: '',
    name: '',
    settings: {
      phone_number: '',
      phone_number_id: '',
      waba_id: '',
      app_id: '',
      token: ''
    }
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const registerAccount = async (data: any) => {
    try {
      if (payload != null) {
        data.settings.phone_number = '52' + data.settings.phone_number;
        await ChannelsService.updateAccountWhatsApp(data);
        AlertService.toastSuccess(t('channels.wa.success-add'));
      } else {
        delete data._id;
        data.settings.phone_number = '52' + data.settings.phone_number;
        await ChannelsService.saveAccountWhatsApp(data);
        AlertService.toastSuccess(t('channels.wa.success-edit'));
      }
      await onRequestClose(true);
      reset(defaultValues);
    } catch (error) {
      AlertService.toastError(`${t('general.atention')} ${error}`);
    }
  };

  const resetForm = () => {
    onRequestClose(false);
    reset(defaultValues);
  };

  const getProviders = async () => {
    try {
      const data = await ProvidersService.getProviders();
      setProviders(
        data.map((item: any) => ({
          value: item._id,
          label: item.name,
          type: item.service_type
        }))
      );
    } catch (error: any) {
      AlertService.toastError(t('general.atention'), error);
    }
  };
  useEffect(() => {
    getProviders();
  }, []);
  useEffect(() => {
    reset({
      _id: payload?._id ? payload._id : defaultValues._id,
      provider: payload?.provider ? payload.provider : defaultValues.provider,
      name: payload?.name ? payload.name : defaultValues.name,
      settings: {
        phone_number: payload?.settings?.phone_number
          ? payload.settings.phone_number.replace('52', '')
          : defaultValues.settings.phone_number,
        phone_number_id: payload?.settings?.phone_number_id
          ? payload.settings.phone_number_id
          : defaultValues.settings.phone_number_id,
        waba_id: payload?.settings?.waba_id
          ? payload.settings.waba_id
          : defaultValues.settings.waba_id,
        app_id: payload?.settings?.app_id ? payload.settings.app_id : defaultValues.settings.app_id,
        token: payload?.settings?.token ? payload.settings.token : defaultValues.settings.token
      }
    });
  }, [payload]);

  return (
    <div aria-hidden="true" className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => resetForm()}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <form onSubmit={handleSubmit(registerAccount)}>
            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mt-1 text-center">
                {watch('_id') ? (
                  <>
                    <h2>
                      {t('channels.wa.modal-title-edit')}{' '}
                      <small className="text-muted fw-semibold fs-6 ms-2">{payload?.name}</small>
                    </h2>
                  </>
                ) : (
                  <h2>{t('channels.wa.modal-title')}</h2>
                )}
                <div className="text-muted fw-semibold fs-5">
                  <span
                    dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}
                  ></span>
                </div>
                <div className="separator border-2 my-5"></div>
              </div>
              <div className="fv-row mt-10">
                <label className="d-flex flex-stack mb-7">
                  <span className="d-flex align-items-center me-2">
                    <span className="symbol symbol-circle symbol-50px me-6">
                      <span className="symbol-label bg-light-primary">
                        <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </span>
                    </span>
                    <span className="d-flex flex-column">
                      <h4 className="fw-bold">{t('channels.wa.modal-data')}</h4>
                      <div className="text-muted fw-semibold fs-7">
                        {watch('_id')
                          ? t('channels.wa.modal-data-sub-edit')
                          : t('channels.wa.modal-data-sub')}
                      </div>
                    </span>
                  </span>
                </label>
                <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                  <div className="col">
                    <div className="fv-row mb-7">
                      <Input
                        label={t('channels.wa.name')}
                        requiredIndicator="required"
                        placeholder={t('channels.wa.placecholder-name')}
                        errors={errors.name}
                        {...register('name', {
                          required: true,
                          setValueAs: (value) => value.trim()
                        })}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="fv-row mb-7">
                      <label className="form-label required">{t('channels.wa.provider')}</label>
                      <Controller
                        control={control}
                        name="provider"
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            <Select
                              options={whatsappProviders}
                              noOptionsMessage={() => (
                                <div className="empty-select">{t('general.no-options')}</div>
                              )}
                              className={
                                error ? 'form-select-custom is-invalid' : 'form-select-custom'
                              }
                              classNamePrefix="form-select-custom"
                              placeholder={t('channels.wa.placecholder-provider')}
                              onChange={(
                                selectedOption: { value: string; label: string } | null
                              ) => {
                                onChange(selectedOption ? selectedOption.value : null);
                              }}
                              value={
                                whatsappProviders.find(
                                  (option: { value: string; label: string }) =>
                                    option.value === value
                                ) || null
                              }
                            />
                            <InvalidFeedback error={error} />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <label className="d-flex flex-stack mb-7">
                  <span className="d-flex align-items-center me-2">
                    <span className="symbol symbol-circle symbol-50px me-6">
                      <span className="symbol-label bg-light-info">
                        <i className="ki-duotone ki-setting-2 fs-2x text-info">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </span>
                    </span>
                    <span className="d-flex flex-column">
                      <h4 className="fw-bold">{t('channels.wa.modal-settings')}</h4>
                      <div className="text-muted fw-semibold fs-7">
                        {watch('_id')
                          ? t('channels.wa.modal-settings-sub-edit')
                          : t('channels.wa.modal-settings-sub')}
                      </div>
                    </span>
                  </span>
                </label>
                <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                  <div className="col">
                    <div className="fv-row mb-7">
                      <Input
                        label={t('channels.wa.number')}
                        requiredIndicator="required"
                        placeholder={t('channels.wa.placecholder-number')}
                        errors={errors?.settings?.phone_number}
                        {...register('settings.phone_number', {
                          required: true,
                          setValueAs: (value) => value.trim(),
                          pattern: {
                            value: tenDigitsRegex,
                            message: t('channels.wa.validate-1')
                          }
                        })}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="fv-row mb-7">
                      <Input
                        label={t('channels.wa.id-number')}
                        requiredIndicator="required"
                        placeholder={t('channels.wa.placecholder-IDnumber')}
                        errors={errors?.settings?.phone_number_id}
                        {...register('settings.phone_number_id', {
                          required: true,
                          setValueAs: (value) => value.trim(),
                          pattern: {
                            value: numericRegex,
                            message: t('channels.wa.validate-2')
                          }
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                  <div className="col">
                    <div className="fv-row mb-7">
                      <Input
                        label={t('channels.wa.id-waba')}
                        requiredIndicator="required"
                        placeholder={t('channels.wa.placecholder-IDwaba')}
                        errors={errors?.settings?.waba_id}
                        {...register('settings.waba_id', {
                          required: true,
                          setValueAs: (value) => value.trim(),
                          pattern: {
                            value: numericRegex,
                            message: t('channels.wa.validate-2')
                          }
                        })}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="fv-row mb-7">
                      <Input
                        label={t('channels.wa.id-app')}
                        requiredIndicator="required"
                        placeholder={t('channels.wa.placecholder-IDapp')}
                        errors={errors?.settings?.app_id}
                        {...register('settings.app_id', {
                          required: true,
                          setValueAs: (value) => value.trim(),
                          pattern: {
                            value: numericRegex,
                            message: t('channels.wa.validate-2')
                          }
                        })}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="fv-row">
                      <Input
                        label={t('channels.wa.token')}
                        requiredIndicator="required"
                        placeholder={t('channels.wa.placecholder-token')}
                        onKeyDown={handleAlphanumericInput}
                        errors={errors?.settings?.token}
                        {...register('settings.token', {
                          required: true,
                          setValueAs: (value) => value.trim(),
                          pattern: {
                            value: alphanumericRegex,
                            message: t('channels.wa.validate-3')
                          }
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => resetForm()}
                >
                  {t('general.close')}
                </button>
                <Button
                  type="submit"
                  id="sendData"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                >
                  {watch('_id') ? t('channels.wa.btn-update') : t('channels.wa.btn-add-modal')}
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddAccount;
