import { useEffect, useMemo, useRef, useState } from 'react';
import 'moment/locale/es';
import { useTranslation } from 'react-i18next';
import Loader from '../loader/loader.component';

declare const KTComponents: any;

interface ColumnType {
  name: any;
  sortable?: boolean;
  center?: boolean;
  exportable?: boolean;
  selector?: (row: any) => any;
  formatToExport?: (row: any) => any;
  cell?: (row: any) => React.ReactNode;
  width?: number;
}

interface CustomDataTableProps {
  columns: Array<ColumnType>;
  data: Array<any>;
  filters?: Array<React.ReactNode>;
  showDates?: boolean;
  showExport?: boolean;
  onExport?: (format: 'csv' | 'excel') => void;
  extraExportActions?: React.ReactNode;
  singleAccounts?: boolean;
  filter?: (row: any, term: string) => any;
  hideAccountsFilter?: boolean;
  isLoading?: boolean;
}

function CustomDataTable(props: CustomDataTableProps) {
  const { t } = useTranslation();

  const [hideColumns, setHideColumns] = useState<boolean[]>([]);
  const [columns, setColumns] = useState<Array<ColumnType>>(props.columns);
  const [rows, setRows] = useState<Array<any>>(props.data);
  const [term, setTerm] = useState<string>('');
  const [paginationConfig, setPaginationConfig] = useState<{
    totalPages: number;
    currentPage: number;
    rowsPerPage: number;
    isFirst: boolean;
    isLast: boolean;
    rows: React.ReactNode[];
  }>({
    totalPages: 1,
    currentPage: 0,
    rowsPerPage: 10,
    isFirst: true,
    isLast: false,
    rows: []
  });

  useEffect(() => {
    setColumns(props.columns);
    setRows(props.data);
  }, [props.columns, props.data]);

  const columnsFiltered = useMemo(() => {
    return columns.filter((_, index) => !hideColumns[index]);
  }, [columns, hideColumns]);

  const getHeaders = useMemo(() => {
    return columnsFiltered.map((column, index) => (
      <th
        scope="col"
        key={index}
        className={column.center ? 'text-center' : ''}
        style={{ width: column.width ? `${column.width}%` : 'auto' }}
      >
        {column.name}
      </th>
    ));
  }, [columnsFiltered]);

  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      if (props.filter) {
        return props.filter(row, term);
      } else {
        const searchKeys = Object.values(row).join(' ').toLowerCase();
        return searchKeys.includes(term.toLowerCase());
      }
    });
  }, [rows, term, props.filter]);

  const rowsOfTable = useMemo(() => {
    const start = paginationConfig.currentPage * paginationConfig.rowsPerPage;
    return filteredRows.slice(start, start + paginationConfig.rowsPerPage).map((row, index) => (
      <tr key={index}>
        {columnsFiltered.map((column, indexCol) => (
          <td key={`row-${index}-col-${indexCol}`} className={column.center ? 'text-center' : ''}>
            {column.cell ? column.cell(row) : (column.selector && column.selector(row)) || ''}
          </td>
        ))}
      </tr>
    ));
  }, [filteredRows, columnsFiltered, paginationConfig]);

  useEffect(() => {
    const totalPages = Math.ceil(filteredRows.length / paginationConfig.rowsPerPage);
    setPaginationConfig((prevConfig) => ({
      ...prevConfig,
      totalPages: totalPages,
      currentPage: 0,
      isFirst: true,
      isLast: totalPages <= 1,
      rows: rowsOfTable
    }));
  }, [filteredRows, paginationConfig.rowsPerPage]);

  const handleNextPrevPage = (action: 'prev' | 'next' | 'first' | 'last') => {
    let currentPage = paginationConfig.currentPage;
    switch (action) {
      case 'prev':
        currentPage--;
        break;
      case 'next':
        currentPage++;
        break;
      case 'first':
        currentPage = 0;
        break;
      case 'last':
        currentPage = paginationConfig.totalPages - 1;
        break;
      default:
        break;
    }
    const isFirst = currentPage === 0;
    const isLast = currentPage === paginationConfig.totalPages - 1;

    const start = currentPage * paginationConfig.rowsPerPage;
    const rows = filteredRows
      .slice(start, start + paginationConfig.rowsPerPage)
      .map((row, index) => (
        <tr key={index}>
          {columnsFiltered.map((column, indexCol) => (
            <td key={`row-${index}-col-${indexCol}`} className={column.center ? 'text-center' : ''}>
              {column.cell ? column.cell(row) : (column.selector && column.selector(row)) || ''}
            </td>
          ))}
        </tr>
      ));

    setPaginationConfig({
      ...paginationConfig,
      currentPage: currentPage,
      isFirst: isFirst,
      isLast: isLast,
      rows: rows
    });
  };

  useEffect(() => {
    KTComponents.init();
  });

  useEffect(() => {
    const handleLanguageChange = () => {
      setColumns([...props.columns]);
      setRows([...props.data]);
      KTComponents.init();
    };
    window.addEventListener('languageChanged', handleLanguageChange);
    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, [props.columns, props.data]);

  if (props.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="d-flex align-items-center position-relative my-1">
        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
          <span className="path1" />
          <span className="path2" />
        </i>
        <input
          className="form-control form-control-solid w-250px ps-12"
          placeholder={t('datatable.input-search')}
          type="text"
          value={term}
          onChange={({ target: { value } }) => setTerm(value)}
        />
      </div>
      <div className="relative">
        {rows.length === 0 && (
          <div className="text-center p-4">
            <img
              title="empty-table"
              className="mb-3 theme-light-show"
              src="/assets/media/illustrations/front/oc-thinking.svg"
              width="50%"
            />
            <img
              title="empty-table"
              className="mb-3 theme-dark-show"
              src="/assets/media/illustrations/front/oc-thinking-dark.svg"
              width="50%"
            />
            <p className="mb-0 fs-3">{t('datatable.empty-records')}</p>
          </div>
        )}
        {props.isLoading && <Loader />}
      </div>
      {rows.length > 0 && (
        <div className="table-responsive">
          <table className="table table-row-bordered gy-5 gs-7">
            <thead className="thead-light">
              <tr className="text-start text-gray-500 fw-bold fs-5 gs-0">{getHeaders}</tr>
            </thead>
            <tbody>{paginationConfig.rows}</tbody>
          </table>
        </div>
      )}
      {filteredRows.length === 0 && rows.length > 0 && (
        <div className="text-center p-4">
          <img
            title="empty-table"
            className="mb-3 theme-light-show"
            src="/assets/media/illustrations/front/oc-looking-for-answers.svg"
            width="50%"
          />
          <img
            title="empty-table"
            className="mb-3 theme-dark-show"
            src="/assets/media/illustrations/front/oc-looking-for-answers-dark.svg"
            width="50%"
          />
          <p className="mb-0 fs-3">{t('datatable.zero-records')}</p>
        </div>
      )}
      {rows.length > 0 && (
        <nav className="p-4 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <select
              title="select"
              className="form-select form-select-sm w-auto ms-2 form-select-solid"
              onChange={({ target: { value } }) =>
                setPaginationConfig({ ...paginationConfig, rowsPerPage: +value })
              }
              value={paginationConfig.rowsPerPage}
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="30">30</option>
            </select>
            <small className="text-muted ms-2">{t('datatable.lenght-menu')}</small>
          </div>
          <div className="d-flex align-items-center">
            <small className="text-muted mx-4">
              {paginationConfig.currentPage * paginationConfig.rowsPerPage + 1} -{' '}
              {Math.min(
                (paginationConfig.currentPage + 1) * paginationConfig.rowsPerPage,
                filteredRows.length
              )}{' '}
              {t('general.of')} {filteredRows.length}
            </small>
            <button
              title="btn-pagination"
              type="button"
              className="btn btn-sm btn-icon btn-active-light-primary mx-1"
              disabled={paginationConfig.isFirst}
              onClick={() => handleNextPrevPage('first')}
            >
              <i className="bi bi-chevron-bar-left fs-3"></i>
            </button>
            <button
              title="btn-pagination"
              type="button"
              className="btn btn-sm btn-icon btn-active-light-primary mx-1"
              disabled={paginationConfig.isFirst}
              onClick={() => handleNextPrevPage('prev')}
            >
              <i className="bi bi-chevron-left fs-3"></i>
            </button>
            <button
              title="btn-pagination"
              type="button"
              className="btn btn-sm btn-icon btn-active-light-primary mx-1"
              disabled={paginationConfig.isLast}
              onClick={() => handleNextPrevPage('next')}
            >
              <i className="bi bi-chevron-right fs-3"></i>
            </button>
            <button
              title="btn-pagination"
              type="button"
              className="btn btn-sm btn-icon btn-active-light-primary mx-1"
              disabled={paginationConfig.isLast}
              onClick={() => handleNextPrevPage('last')}
            >
              <i className="bi bi-chevron-bar-right fs-3"></i>
            </button>
          </div>
        </nav>
      )}
    </>
  );
}

export default CustomDataTable;
