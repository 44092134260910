import { useEffect, useRef } from 'react';
import Input from '../../components/forms/input.component';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../components/forms/button.component';
import { AlertService } from '../../services/alert.service';
import { CompaniesService } from '../../services/companies.service';
import useModal from '../../hooks/close-modal.hook';
interface EditCompanyProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
  payload: any;
}
function EditCompany({ show, onRequestClose, payload }: EditCompanyProps) {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);
  const defaultValues = {
    _id: '',
    name: ''
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });
  const editCompany = async (data: any) => {
    const requestBody = {
      _id: data._id,
      name: data.name
    };
    try {
      await CompaniesService.editCompany(requestBody);
      AlertService.toastSuccess(t('companies-page.alert-success-edit'));
      onRequestClose(true);
      reset(defaultValues);
    } catch (error) {
      console.log(error);
      AlertService.toastError(t('companies-page.alert-error-edit'));
    }
  };
  useEffect(() => {
    reset({
      _id: payload?._id ? payload?._id : defaultValues?._id,
      name: payload?.name ? payload?.name : defaultValues?.name
    });
  }, [payload]);
  return (
    <>
      <div aria-hidden="true" className="modal fade" ref={modalRef} data-bs-backdrop="static">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h2>{t('companies-page.modal-edit-company.title-edit-company')}</h2>
              <div
                className="btn btn-sm btn-icon btn-active-light-primary"
                onClick={() => onRequestClose(false)}
              >
                <i className="ki-duotone ki-cross fs-1">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </div>
            </div>
            <form onSubmit={handleSubmit(editCompany)}>
              <div className="modal-body py-lg-10 px-lg-10">
                <div className="fv-row mb-5">
                  <label className="d-flex flex-stack mb-7">
                    <span className="d-flex align-items-center me-2">
                      <span className="symbol symbol-circle symbol-50px me-6">
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <h4 className="fw-bold">
                          {t('general.edit')} {payload?.name}
                        </h4>
                        <div className="text-muted fw-semibold fs-7">
                          {t('companies-page.modal-edit-company.subtitle-edit-company')}
                          {' ' + payload?.name}
                        </div>
                      </span>
                    </span>
                  </label>
                  <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-1">
                    <div className="col">
                      <div className="fv-row mb-7">
                        <Input
                          label={t('companies-page.modal-edit-company.label-name')}
                          requiredIndicator="required"
                          placeholder={t('companies-page.modal-edit-company.name-placeholder')}
                          errors={errors.name}
                          {...register('name', {
                            required: true
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light hover-scale"
                  onClick={() => onRequestClose(false)}
                >
                  {t('general.close')}
                </button>
                <Button
                  type="submit"
                  id="sendData"
                  className="btn btn-primary ms-3 hover-scale"
                  disabled={!isValid}
                  isLoading={isSubmitting}
                >
                  {t('general.edit')}
                  <i className="ki-duotone ki-send ms-2 fs-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCompany;
