import { useTranslation } from 'react-i18next';
import Header from '../../components/layout/header.component';
import Asaid from '../../components/layout/asaid.component';
import Footer from '../../components/layout/footer.component';
import useBgPages from '../../hooks/bg-pages.hook';
function DashboardPage() {
  useBgPages();
  const { t } = useTranslation();

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <h1 className="text-gray-900 fw-bold my-1 fs-2">
                    {t('dashboard-page.title-page')}
                    <small className="text-muted fs-6 fw-normal ms-1"></small>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl"></div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default DashboardPage;
