import { useEffect, useState } from 'react';

declare global {
  interface Window {
    KTStepper: any;
  }
}

function useStepper(id: string) {
  const [currentStep, setCurrentStep] = useState(1);
  const [resteStepper, setresteStepper] = useState<any>(null);

  useEffect(() => {
    const element = document.querySelector(`#${id}`);
    if (!element) return;

    const stepperInstance = new window.KTStepper(element);
    setresteStepper(stepperInstance);

    const handleNext = () => {
      stepperInstance.goNext();
      setCurrentStep((prev) => prev + 1);
    };

    const handlePrevious = () => {
      stepperInstance.goPrevious();
      setCurrentStep((prev) => prev - 1);
    };

    stepperInstance.on('kt.stepper.next', handleNext);
    stepperInstance.on('kt.stepper.previous', handlePrevious);

    return () => {
      stepperInstance.off('kt.stepper.next', handleNext);
      stepperInstance.off('kt.stepper.previous', handlePrevious);
    };
  }, [id]);

  return { currentStep, setCurrentStep, resteStepper };
}

export default useStepper;
