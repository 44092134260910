import { API_ENDPOINT } from '../constants';
import { HTTP } from './http.service';

export const BlackListService = {
  getBlackList: (area: any) => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}blacklist/${area}`);
  },
  saveBlackList: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}blacklist`, data);
  },
  saveNumber: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}blacklist/phone-number`, data);
  },
  deleteNumber: (_id: any) => {
    return HTTP.delete<any, any>(`${API_ENDPOINT.CORE}blacklist/phone-number`, {
      data: { _id }
    });
  },
  getCSVs: (blacklist: string, page: number, size: number) => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}blacklist/phone_numbers/${blacklist}`, {
      params: {
        page: page,
        size: size
      }
    });
  }
};
