import React from 'react';
import { Handle, Position } from 'reactflow';

const CustomNodeConsumer = ({ data }: any) => {
  const getStatusClass = (status: string) => {
    switch (status) {
      case 'process':
        return 'bg-light-primary border-primary';
      case 'restart':
        return 'alert-info-fow border-info';
      case 'paused':
        return 'bg-light-warning border-warning';
      case 'error':
        return 'alert-danger-fow border-danger';
      case 'pending':
        return 'bg-light';
      default:
        return 'bg-light';
    }
  };

  const getIcon = (status: string) => {
    let iconClass = 'text-muted';
    if (status === 'process' || status === 'pending') {
      iconClass = 'text-primary';
    } else if (status === 'error') {
      iconClass = 'text-danger';
    } else if (status === 'paused') {
      iconClass = 'text-warning';
    }

    return <i className={`bi bi-hdd-network fs-1 ${iconClass}`}></i>;
  };

  const convertToTimeFormat = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    const seconds = Math.floor((totalMinutes * 60) % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const estimatedTime =
    data.enqueuedMessages > 0
      ? convertToTimeFormat(data.enqueuedMessages / data.totalMessage)
      : 'N/A';

  return (
    <div
      className={`card p-0 h-md-100 mb-6 mb-md-0 border border-2 ${getStatusClass(data.status)}`}
    >
      <div className="card-header border-0">
        <div className="card-title">
          {getIcon(data.status)}
          <h1 className="fw-bold ms-2">{data.label}</h1>
        </div>
      </div>
      <Handle type="target" position={Position.Left} />
      <div className="card-body pt-0">
        <div className="fw-bold fs-2">
          <div className="d-flex">
            <div className="ms-0">
              <span className="fs-1">{data.totalMessage.toLocaleString()}</span>
              <span className="text-muted fs-4 fw-bolder ms-2">Envíos por minuto</span>
            </div>
          </div>
          <div className="d-flex mb-5">
            <div className="ms-0">
              <span className="fs-1">{estimatedTime}</span>
              <span className="text-muted fs-4 fw-bolder ms-2">
                Tiempo estimado de procesamiento.
              </span>
            </div>
          </div>
          <div className="fs-3  fw-bolder">
            {data.status === 'error'
              ? 'Verifica el problema dando click aquí.'
              : data.status === 'paused'
                ? 'Se ha pausado el envío.'
                : 'Funcionando correctamente.'}
          </div>
        </div>
      </div>
      {data.channel !== 'WhatsApp' && <Handle type="source" position={Position.Right} />}
    </div>
  );
};

export default CustomNodeConsumer;
