import React from 'react';
import useHasAccess from '../hooks/use-has-access.hook';

interface HasAccessViewProps {
  children: any;
  permissions: string[];
}

const HasAccessView = ({ children, permissions }: HasAccessViewProps) => {
  const { isAllowed } = useHasAccess({ permissions });

  return isAllowed ? children : null;
};

export default HasAccessView;
