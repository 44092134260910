import { useTranslation } from 'react-i18next';
import Input from '../../../components/forms/input.component';
import { useState } from 'react';

function QuickReplyBtn({ errors, register, watch, index, onDelete, type }: any) {
  const { t } = useTranslation();

  const handleKeyDown = (e: any, maxLength: number) => {
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div className="border p-7 mt-4">
      <div className="mb-2">
        {type === 'QUICK_REPLY' && (
          <>
            <i className="ki-duotone ki-send fs-1 me-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {t('templates.wa.create.quick-reply')}
          </>
        )}
        {type === 'QUICK_REPLY_DISABLE_MKT' && (
          <>
            <i className="ki-duotone ki-send fs-1 me-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {t('templates.wa.create.quick-reply-disable')}
          </>
        )}
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 me-2">
            <Input
              label={t('templates.wa.create.text-btn-label')}
              currentLength={watch(`buttons.buttons.${index}.text`)?.length}
              maxLength={25}
              placeholder={t('templates.wa.create.body-placeholder')}
              errors={errors}
              onKeyDown={(e: any) => handleKeyDown(e, 25)}
              {...register(`buttons.buttons.${index}.text`, {
                required: false,
                maxLength: { value: 25, message: '25' },
                setValueAs: (value: any) => value.trim()
              })}
            />
          </div>
          <a
            className="btn btn-light-danger btn-icon"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onDelete}
          >
            <i className="ki-duotone ki-trash fs-2x">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
            </i>
          </a>
        </div>
        {type === 'QUICK_REPLY_DISABLE_MKT' && (
          <div className="alert alert-warning mt-2">
            <p className="m-0">{t('templates.wa.create.quick-reply-disable-warning')}</p>
          </div>
        )}
      </div>
    </div>
  );
}
export default QuickReplyBtn;
