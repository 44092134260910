import useBgPages from '../../../hooks/bg-pages.hook';
import Header from '../../../components/layout/header.component';
import Asaid from '../../../components/layout/asaid.component';
import Footer from '../../../components/layout/footer.component';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import FormModal from './form.modal';
import Datatable from '../../../components/datatable/datatable.component';
import { useNavigate } from 'react-router-dom';

function ChannelsRCSSinglePage() {
  useBgPages();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [totalAccounts, settotalAccounts] = useState<number>(2);
  const [totalActives, settotalActives] = useState<number>(0);
  const [showModal, setShowModal] = useState({
    show: false,
    data: null
  });

  const columns = [
    {
      name: 'Datos de cuenta',
      sortable: true,
      cell: (row: any) => {
        return (
          <li className="list-group-item">
            <div className="d-flex flex-column">
              <div className="w-100 mb-1 d-flex align-items-top">
                <strong className="fs-6">Proveedor:</strong>
                <span className="ms-1">{row.provider}</span>
              </div>
            </div>
            <div className="w-100">
              <strong className="fs-6">Estatus:</strong>
              <span className="badge badge-light-primary fs-9">
                <i className="ki-duotone ki-check-circle text-primary fs-5">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                Activo
              </span>
            </div>
          </li>
        );
      }
    },
    {
      name: 'Configuración',
      sortable: true,
      cell: (row: any) => {
        return (
          <li className="list-group-item">
            <div className="d-flex flex-column">
              {row.provider === 'Google' ? (
                <div className="w-100">
                  <strong className="fs-6">Token:</strong>
                  <span className="ms-1 d-inline-block text-break">{row.private_key}</span>
                </div>
              ) : (
                <>
                  <div className="w-100">
                    <strong className="fs-6">Api Key:</strong>
                    <span className="ms-1">{row.api_key}</span>
                  </div>
                  <div className="w-100">
                    <strong className="fs-6">Token:</strong>
                    <span className="ms-1">{row.token}</span>
                  </div>
                  <div className="w-100">
                    <strong className="fs-6">Agent ID:</strong>
                    <span className="ms-1">{row.agent_id}</span>
                  </div>
                </>
              )}
            </div>
          </li>
        );
      }
    },
    {
      name: 'Fecha alta',
      sortable: true,
      width: 20,
      cell: (row: any) => {
        const fechaHora = new Date(row.created_at);
        const fecha = fechaHora.toISOString().split('T')[0];
        const hora = fechaHora.toISOString().split('T')[1].split('.')[0];

        return (
          <li className="list-group-item">
            <div className="d-flex flex-column">
              <div className="w-100 mb-1">
                <strong className="fs-6">Día:</strong>
                <span className="ms-1">{fecha}</span>
              </div>
              <div className="w-100">
                <strong className="fs-6">Hora:</strong>
                <span className="ms-1">{hora}</span>
              </div>
            </div>
          </li>
        );
      }
    },
    {
      name: 'Acciones',
      sortable: false,
      center: true,
      exportable: false,
      cell: (account: any) => {
        return (
          <>
            <div>
              <button
                className="btn btn-light-primary btn-sm btn-flex btn-center"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                type="button"
              >
                {t('general.acctions')}
                <i className="ki-duotone ki-down fs-5 ms-1" />
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    onClick={() => setShowModal({ show: true, data: account })}
                  >
                    <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">Editar</span>
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    onClick={() => navigate('/templates/rcs', { state: { channel: 'single' } })}
                  >
                    <span className="menu-icon">
                      <i className="ki-duotone ki-picture fs-2x text-primary">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </span>
                    <span className="ms-2">Plantillas</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  ];

  const handleShowModal = async (refresh: boolean) => {
    setShowModal({ show: false, data: null });
  };

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">
                      {t('asaid.link-title-operations')}
                    </li>
                    <li className="breadcrumb-item text-muted"> {t('asaid.link-menu-channels')}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-messages fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                      </i>
                      <span className="ms-2">RCS Single</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-4">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">{t('general.estatistics')}</span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('channels.wa.accounts')}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-category fs-2 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>
                      </div>

                      <div className="card-body pt-1">
                        <div className="d-flex flex-stack mb-2 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-book-open fs-3x text-info w-35px me-6 hover-scale">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-info fw-bolder"
                                href="#"
                              >
                                {t('channels.wa.our-account')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('channels.wa.our-account-total')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder ">
                            {totalAccounts}
                          </span>
                        </div>
                        <div className="d-flex flex-stack mb-10 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-check-circle fs-3x text-primary w-35px me-6 hover-scale">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-primary fw-bolder"
                                href="#"
                              >
                                {t('channels.wa.actives')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('channels.wa.total-actives')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder">
                            {totalActives}
                          </span>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                          <a
                            href="#"
                            className="btn btn-primary hover-scale"
                            onClick={() => setShowModal({ show: true, data: null })}
                          >
                            <i className="ki-duotone ki-abstract-10 fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            {t('channels.wa.btn-add')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            {t('channels.wa.accounts')}
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('channels.wa.registred')}
                          </span>
                        </h3>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body pt-1">
                        <Datatable
                          columns={columns}
                          data={[
                            {
                              provider: 'Concepto Móvil',
                              api_key: 'Mp4vwWdYzn9bgR',
                              token: '26xCExJRXCXc3StTczQkJxGE9wREZhw',
                              agent_id: '63650689',
                              private_key: '',
                              created_at: '2024-06-03T20:01:41.870Z'
                            },
                            {
                              provider: 'Google',
                              api_key: '',
                              token: '',
                              agent_id: '',
                              private_key:
                                'v7SY7xAzIcCxrjGWnVYxis9sDOJLM6a7um79gwfmCdnGxCZOp85oJy1nImM8qRYU8BXPw',
                              created_at: '2024-06-03T20:01:41.870Z'
                            }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <FormModal
            show={showModal.show}
            onRequestClose={(refresh: any) => handleShowModal(refresh)}
            payload={showModal.data}
          />
        </div>
      </div>
    </>
  );
}

export default ChannelsRCSSinglePage;
