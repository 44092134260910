import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ChannelsService } from '../../../services/channels.service';
import { ProvidersService } from '../../../services/providers.service';
import { AlertService } from '../../../services/alert.service';
import Header from '../../../components/layout/header.component';
import Asaid from '../../../components/layout/asaid.component';
import Footer from '../../../components/layout/footer.component';
import useBgPages from '../../../hooks/bg-pages.hook';
import AgentModal from './agent.modal';
import Datatable from '../../../components/datatable/datatable.component';
import useRequest from '../../../hooks/use-request.hook';
import ChannelModal from './channel.modal';

interface Data {
  _id: string;
  created_at: string;
  is_international: boolean;
  updated_at: string;
  status: boolean;
  provider: string;
  telcel: Array<{ sms_agent: string; priority: number }>;
  movistar: Array<{ sms_agent: string; priority: number }>;
  att: Array<{ sms_agent: string; priority: number }>;
  other: Array<{ sms_agent: string; priority: number }>;
  name: string;
  carriers: Array<string>;
  settings: Array<string>;
}

interface AgentsData {
  [provider: string]: {
    _id: string;
    provider: string;
    name: string;
    settings: {
      sender: string;
    };
    carriers: string[];
    status: boolean;
    created_at: string;
    updated_at: string;
  }[];
}

function ChannelSMSPage() {
  useBgPages();
  const { t } = useTranslation();
  const { call, isRequesting } = useRequest();
  const [totalRegistersAgents, setTotalRegistersAgents] = useState<number>(0);
  const [totalAgents, setTotalActivesAgents] = useState<number>(0);
  const [totalInternational, setTotalInternational] = useState<number>(0);
  const [totalRegistersChannels, setTotalRegistersChannels] = useState<number>(0);
  const [totalChannels, setTotalActivesChannels] = useState<number>(0);
  const [providers, setProviders] = useState<any[]>([]);
  const [agents, setAgents] = useState<Data[]>([]);
  const [agentsChannel, setAgentsChannel] = useState<{ [key: string]: Array<any> }>({});
  const [channels, setChannels] = useState<Data[]>([]);
  const [activeTab, setActiveTab] = useState('tabOne');
  const [showModalAgent, setShowModalAgent] = useState({
    show: false,
    data: null
  });
  const [showModalChannel, setShowModalChannel] = useState({
    show: false,
    data: null
  });

  const columnsAgents = [
    {
      name: t('channels.sms.agent-tc-1'),
      sortable: true,
      width: 23,
      cell: (agents: any) => {
        const statusEmpty = !agents.status && agents.status !== false;
        return (
          <div className="d-flex flex-column">
            <div className="mb-1">
              <div className="d-flex flex-column">
                <div className="mb-1">
                  <span className="d-flex align-items-center ms-1 fs-7">
                    <strong className="fs-4">{t('channels.sms.tc-name')}:</strong>
                    <br />
                    <span className="ms-1 fs-4">{agents.name || t('general.no-info')}</span>
                  </span>
                </div>
                <div className="mb-1">
                  {statusEmpty ? (
                    <span className="d-flex align-items-center ms-1 fs-7">
                      <strong className="fs-4">{t('channels.wa.status')}:</strong>
                      <i className="ki-duotone ki-information-2 fw-bold text-info fs-4 ms-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      <span className="text-info fw-bold fs-4">{t('general.no-info')}</span>
                    </span>
                  ) : (
                    <span className="d-flex align-items-center ms-1 fs-7">
                      <strong className="fs-4">{t('channels.wa.status')}:</strong>
                      <i
                        className={`ki-duotone ki-${agents.status ? 'check-circle text-primary' : 'minus-circle text-danger'} fw-bold fs-4 ms-1`}
                      >
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <span className={`text-${agents.status ? 'primary' : 'danger'} fw-bold fs-4`}>
                        {agents.status ? t('general.active') : t('general.inactive')}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
    },
    {
      name: t('channels.sms.agent-tc-2'),
      sortable: true,
      width: 15,
      cell: (agents: any) => {
        const filteredProviders = providers.filter(
          (p) => p.value === agents.provider && p.type === 'sms'
        );
        const providerImages: any = {
          cm: '/assets/media/logos/cm.png',
          zorya: '/assets/media/logos/zorya.png',
          meta: '/assets/media/logos/meta.png',
          spark: '/assets/media/logos/spark.png',
          voices: '/assets/media/logos/voices.png'
        };

        if (filteredProviders.length === 0) {
          return null;
        }
        return (
          <div className="d-flex flex-column">
            {filteredProviders.map((provider, index) => {
              const imageUrl = Object.keys(providerImages)
                .map((key) => ({ key, url: providerImages[key] }))
                .find(({ key }) => provider.label.toLowerCase().includes(key))?.url;

              return (
                <div key={index} className="mb-1">
                  <span className="d-flex align-items-center ms-1 fs-7">
                    {imageUrl && <img src={imageUrl} alt={provider.label} width="35" />}
                    <span className="ms-1 fs-4">
                      <strong className="fs-4">{provider.label}</strong>
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
        );
      }
    },
    {
      name: t('channels.sms.agent-tc-3'),
      sortable: true,
      width: 30,
      cell: (agents: any) => {
        const operators = [
          {
            name: 'telcel',
            img: '/assets/media/operators/telcel.png',
            imgDark: '/assets/media/operators/telcel-dark.png',
            size: '45em'
          },
          {
            name: 'movistar',
            img: '/assets/media/operators/movistar.png',
            imgDark: '/assets/media/operators/movistar-dark.png',
            size: '45em'
          },
          {
            name: 'att',
            img: '/assets/media/operators/att.png',
            imgDark: '/assets/media/operators/att-dark.png',
            size: '35em'
          },
          {
            name: 'altan',
            img: '/assets/media/operators/altan.png',
            imgDark: '/assets/media/operators/altan-dark.png',
            size: '33em'
          }
        ];

        return (
          <div className="d-flex flex-column justify-content-center alaign-items-center pb-5 px-0">
            <ul className="nav nav-pills nav-pills-custom mb-3">
              {operators.map((operator) => {
                const isActive =
                  agents.carriers.includes(operator.name) ||
                  (operator.name === 'altan' && agents.carriers.includes('other'));
                return (
                  <li className="nav-item mb-3 me-3 me-lg-3" key={operator.name}>
                    <a
                      className="nav-link btn btn-outline btn-flex btn-active-color-primary flex-column overflow-hidden w-50px h-50px pt-3 pb-2 active"
                      data-bs-toggle="pill"
                      href="#"
                      id={`kt_charts_widget_10_tab_${operator.name}`}
                    >
                      <div className="mb-3">
                        <span className="theme-light-show">
                          <img title="img" src={operator.img} width={operator.size} />
                        </span>
                        <span className="theme-dark-show">
                          <img title="img" src={operator.imgDark} width={operator.size} />
                        </span>
                      </div>
                      <span
                        className={`bullet-custom position-absolute bottom-0 w-100 h-4px ${isActive ? 'bg-primary' : 'bg-danger'}`}
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      }
    },
    {
      name: t('channels.sms.agent-tc-4'),
      sortable: true,
      width: 20,
      cell: (agents: any) => {
        const settings = agents.settings;
        const isEmpty = !settings || Object.keys(settings).length === 0;

        return (
          <div className="d-flex flex-column">
            {isEmpty ? (
              <span className="text-muted">{t('general.no-info')}</span>
            ) : (
              Object.entries(settings).map(([key, value]) => (
                <div key={key} className="mb-1">
                  <span className="d-flex align-items-center ms-1 fs-7">
                    <strong className="fs-4">{key}:</strong>
                    <br />
                    <span className="ms-1 fs-4">{String(value)}</span>
                  </span>
                </div>
              ))
            )}
          </div>
        );
      }
    },
    {
      name: t('general.acctions'),
      sortable: false,
      center: true,
      exportable: false,
      width: 20,
      cell: (agents: any) => (
        <div>
          <button
            title="acctions"
            className="btn btn-light-primary btn-flex btn-center btn-sm fw-bold"
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            type="button"
          >
            {t('general.acctions')}
            <i className="ki-duotone ki-down fs-4 ms-1" />
          </button>
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold fs-7 w-175px py-3"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#"
                onClick={() => setShowModalAgent({ show: true, data: agents })}
              >
                <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="ms-2">{t('channels.wa.btn-update')}</span>
              </a>
            </div>
          </div>
        </div>
      )
    }
  ];

  const columnsChannels = [
    {
      name: t('channels.sms.channel-tc-1'),
      sortable: true,
      width: 15,
      cell: (channels: any) => {
        const statusEmpty = !channels.status && channels.status !== false;
        const internationalEmpty =
          !channels.is_international && channels.is_international !== false;

        return (
          <div className="d-flex flex-column">
            <div className="mb-1">
              <span className="d-flex align-items-center ms-1 fs-7">
                <strong className="fs-4">{t('channels.sms.tc-name')}:</strong>
                <span className="ms-1 fs-4">{channels.name || t('general.no-info')}</span>
              </span>
            </div>
            <div className="mb-1">
              {statusEmpty ? (
                <span className="d-flex align-items-center ms-1 fs-7">
                  <strong className="fs-4">{t('channels.wa.status')}:</strong>
                  <i className="ki-duotone ki-information-2 fw-bold text-info fs-4 ms-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                  <span className="text-info fw-bold fs-4">{t('general.no-info')}</span>
                </span>
              ) : (
                <span className="d-flex align-items-center ms-1 fs-7">
                  <strong className="fs-4">{t('channels.wa.status')}:</strong>
                  <i
                    className={`ki-duotone ki-${channels.status ? 'check-circle text-primary' : 'minus-circle text-danger'} fw-bold fs-4 ms-1`}
                  >
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <span className={`text-${channels.status ? 'primary' : 'danger'} fw-bold fs-4`}>
                    {channels.status ? t('general.active') : t('general.inactive')}
                  </span>
                </span>
              )}
            </div>
            <div className="mb-1">
              {internationalEmpty ? (
                <span className="d-flex align-items-center ms-1 fs-7">
                  <strong className="fs-4">{t('channels.sms.international')}:</strong>
                  <i className="ki-duotone ki-information-2 fw-bold text-info fs-4 ms-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>
                  <span className="text-info fw-bold fs-4">{t('general.no-info')}</span>
                </span>
              ) : (
                <span className="d-flex align-items-center ms-1 fs-7">
                  <strong className="fs-4">{t('channels.sms.international')}:</strong>
                  <i
                    className={`ki-duotone ki-${channels.is_international ? 'check-circle text-primary' : 'minus-circle text-danger'} fw-bold fs-4 ms-1`}
                  >
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <span
                    className={`text-${channels.is_international ? 'primary' : 'danger'} fw-bold fs-4`}
                  >
                    {channels.is_international ? t('general.active') : t('general.inactive')}
                  </span>
                </span>
              )}
            </div>
          </div>
        );
      }
    },
    {
      name: (
        <>
          <span className="theme-light-show">
            <img title="img" src="/assets/media/operators/telcel.png" width={'67em'} />
          </span>
          <span className="theme-dark-show">
            <img title="img" src="/assets/media/operators/telcel-dark.png" width={'67em'} />
          </span>
        </>
      ),
      sortable: true,
      center: true,
      width: 15,
      cell: (channels: any) => (
        <div className="d-flex justify-content-center align-items-center">
          <i
            className={`ki-duotone ki-${channels.telcel.length > 0 ? 'check-circle text-primary' : 'cross-circle text-danger'} fs-2x fw-bold`}
          >
            <span className="path1"></span>
            <span className="path2"></span>
            {channels.telcel.length === 0 && <span className="path3"></span>}
          </i>
        </div>
      )
    },
    {
      name: (
        <>
          <span className="theme-light-show">
            <img title="img" src="/assets/media/operators/movistar.png" width={'77em'} />
          </span>
          <span className="theme-dark-show">
            <img title="img" src="/assets/media/operators/movistar-dark.png" width={'77em'} />
          </span>
        </>
      ),
      sortable: true,
      center: true,
      width: 15,
      cell: (channels: any) => (
        <div className="d-flex justify-content-center align-items-center">
          <i
            className={`ki-duotone ki-${channels.movistar.length > 0 ? 'check-circle text-primary' : 'cross-circle text-danger'} fs-2x fw-bold`}
          >
            <span className="path1"></span>
            <span className="path2"></span>
            {channels.movistar.length === 0 && <span className="path3"></span>}
          </i>
        </div>
      )
    },
    {
      name: (
        <>
          <span className="theme-light-show">
            <img title="img" src="/assets/media/operators/att.png" width={'52em'} />
          </span>
          <span className="theme-dark-show">
            <img title="img" src="/assets/media/operators/att-dark.png" width={'52em'} />
          </span>
        </>
      ),
      sortable: true,
      center: true,
      width: 15,
      cell: (channels: any) => (
        <div className="d-flex justify-content-center align-items-center">
          <i
            className={`ki-duotone ki-${channels.att.length > 0 ? 'check-circle text-primary' : 'cross-circle text-danger'} fs-2x fw-bold`}
          >
            <span className="path1"></span>
            <span className="path2"></span>
            {channels.att.length === 0 && <span className="path3"></span>}
          </i>
        </div>
      )
    },
    {
      name: (
        <>
          <span className="theme-light-show">
            <img title="img" src="/assets/media/operators/altan.png" width={'47em'} />
          </span>
          <span className="theme-dark-show">
            <img title="img" src="/assets/media/operators/altan-dark.png" width={'47em'} />
          </span>
        </>
      ),
      sortable: true,
      center: true,
      width: 15,
      cell: (channels: any) => (
        <div className="d-flex justify-content-center align-items-center">
          <i
            className={`ki-duotone ki-${channels.other.length > 0 ? 'check-circle text-primary' : 'cross-circle text-danger'} fs-2x fw-bold`}
          >
            <span className="path1"></span>
            <span className="path2"></span>
            {channels.other.length === 0 && <span className="path3"></span>}
          </i>
        </div>
      )
    },
    {
      name: t('general.acctions'),
      sortable: false,
      center: true,
      exportable: false,
      width: 10,
      cell: (channels: any) => (
        <div>
          <button
            title="img"
            className="btn btn-light-primary btn-flex btn-center btn-sm fw-bold"
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            type="button"
          >
            {t('general.acctions')}
            <i className="ki-duotone ki-down fs-4 ms-1" />
          </button>
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold fs-7 w-175px py-3"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#"
                onClick={() => setShowModalChannel({ show: true, data: channels })}
              >
                <i className="ki-duotone ki-notepad-edit fs-1 text-primary">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="ms-2">{t('channels.wa.btn-update')}</span>
              </a>
            </div>
          </div>
        </div>
      )
    }
  ];

  const getChannels = async () => {
    try {
      const response = await call(() => ChannelsService.getChannelsSMS());
      const channelsData: Data[] = response;
      setTotalActivesChannels(channelsData.filter((channel) => channel.status === true).length);
      setTotalInternational(
        channelsData.filter((channel) => channel.is_international === true).length
      );
      setTotalRegistersChannels(channelsData.length);
      setChannels(channelsData);
    } catch (error: any) {
      AlertService.toastError(t('general.atention'), error);
    }
  };

  const getagentsCatalogue = async () => {
    try {
      const data: AgentsData = await call(() => ChannelsService.getAgentsCataogue());
      const catalogue: { [key: string]: Array<any> } = {};
      Object.keys(data).forEach((provider) => {
        if (Array.isArray(data[provider])) {
          catalogue[provider] = data[provider].map((item) => ({
            _id: item._id,
            name: item.name
          }));
        }
      });
      setAgentsChannel(catalogue);
    } catch (error: any) {
      AlertService.toastError(t('general.atention'), error);
    }
  };

  const getAgents = async () => {
    try {
      const response = await call(() => ChannelsService.getAgentsSMS());
      const agentsData: Data[] = response;
      setTotalActivesAgents(agentsData.filter((channel) => channel.status === true).length);
      setTotalRegistersAgents(agentsData.length);
      setAgents(agentsData);
    } catch (error: any) {
      AlertService.toastError(t('general.atention'), error);
    }
  };

  const getProviders = async () => {
    try {
      const data = await ProvidersService.getProviders();
      setProviders(
        data.map((item: any) => ({
          value: item._id,
          label: item.name,
          type: item.service_type,
          settings: item.param_settings
        }))
      );
    } catch (error: any) {
      AlertService.toastError(t('general.atention'), error);
    }
  };

  const handleShowModalAgent = async (refresh: boolean) => {
    if (refresh) {
      await getAgents();
    }
    setShowModalAgent({ show: false, data: null });
  };

  const handleShowModalChannel = async (refresh: boolean) => {
    if (refresh) {
      await getChannels();
    }
    setShowModalChannel({ show: false, data: null });
  };

  const handleTabActive = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getagentsCatalogue();
        await getProviders();
        await getChannels();
        await getAgents();
      } catch (error: any) {
        AlertService.toastError(t('general.atention'), error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-4 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">
                      {t('asaid.link-title-operations')}
                    </li>
                    <li className="breadcrumb-item text-muted"> {t('asaid.link-menu-channels')}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-message-notif fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                      </i>
                      <span className="ms-2">SMS</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-4 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div>
                  <div className="card mb-5 mb-xl-10">
                    <div className="card-body pt-9 pb-0">
                      <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                        <div className="me-7 mb-4">
                          {activeTab === 'tabOne' && (
                            <>
                              <i className="ki-duotone ki-technology-4 fs-4x hover-scale me-2 mt-10 text-primary">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                                <span className="path6"></span>
                                <span className="path7"></span>
                              </i>
                            </>
                          )}
                          {activeTab === 'tabTwo' && (
                            <>
                              <i className="ki-duotone ki-message-notif fs-4x hover-scale me-2 mt-10 text-primary">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                              </i>
                            </>
                          )}
                        </div>
                        <div className="flex-grow-1">
                          <div className="d-flex justify-content-between align-items-start flex-wrap mb-4">
                            <div className="d-flex flex-column">
                              <div className="d-flex align-items-center mb-2">
                                <a
                                  className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                                  href="#"
                                >
                                  {t('general.estatistics')}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap flex-stack">
                            <div className="d-flex flex-column flex-grow-1 pe-8">
                              <div className="d-flex flex-wrap">
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                  <div className="d-flex align-items-center">
                                    <i className="ki-duotone ki-book-open fs-2x hover-scale me-2 text-info">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                      <span className="path3"></span>
                                      <span className="path4"></span>
                                    </i>
                                    <div className="fs-2 fw-bold">
                                      {activeTab === 'tabOne' && <>{totalRegistersChannels}</>}
                                      {activeTab === 'tabTwo' && <>{totalRegistersAgents}</>}
                                    </div>
                                  </div>
                                  <div className="fw-semibold fs-4 text-gray-500">
                                    {t('channels.sms.registers')}
                                  </div>
                                </div>
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                  <div className="d-flex align-items-center">
                                    <i className="ki-duotone ki-check-circle fs-2x hover-scale me-2 text-info">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                    </i>
                                    <div className="fs-2 fw-bold">
                                      {activeTab === 'tabOne' && <>{totalChannels}</>}
                                      {activeTab === 'tabTwo' && <>{totalAgents}</>}
                                    </div>
                                  </div>
                                  <div className="fw-semibold fs-4 text-gray-500">
                                    {t('channels.sms.actives')}
                                  </div>
                                </div>
                                {activeTab === 'tabOne' && (
                                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                    <div className="d-flex align-items-center">
                                      <i className="bi bi-globe-americas fs-1 hover-scale me-2 text-info"></i>
                                      <div className="fs-2 fw-bold">{totalInternational}</div>
                                    </div>
                                    <div className="fw-semibold fs-4 text-gray-500">
                                      {t('channels.sms.international')}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                              <div className="d-flex justify-content-end w-100 mt-auto mb-2">
                                {activeTab === 'tabOne' && (
                                  <>
                                    <a
                                      href="#"
                                      className="btn btn-primary hover-scale fw-bold"
                                      onClick={() =>
                                        setShowModalChannel({ show: true, data: null })
                                      }
                                    >
                                      <i className="ki-duotone ki-abstract-10 fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                      </i>
                                      {t('channels.sms.btn.addChannel')}
                                    </a>
                                  </>
                                )}
                                {activeTab === 'tabTwo' && (
                                  <>
                                    <a
                                      href="#"
                                      className="btn btn-primary hover-scale fw-bold"
                                      onClick={() => setShowModalAgent({ show: true, data: null })}
                                    >
                                      <i className="ki-duotone ki-abstract-10 fs-2">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                      </i>
                                      {t('channels.sms.btn.addAgent')}
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bold">
                        <li className="nav-item">
                          <a
                            className={`nav-link text-active-primary pb-4 cursor-pointer ${
                              activeTab === 'tabOne' ? 'active' : ''
                            }`}
                            onClick={() => handleTabActive('tabOne')}
                          >
                            <span className="d-flex align-items-center ms-2 fs-3 fw-bold">
                              {t('channels.sms.channels.tab')}
                            </span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link text-active-primary pb-4 cursor-pointer ${
                              activeTab === 'tabTwo' ? 'active' : ''
                            }`}
                            onClick={() => handleTabActive('tabTwo')}
                          >
                            <span className="d-flex align-items-center ms-2 fs-3 fw-bold">
                              {t('channels.sms.agents.tab')}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card mb-5 mb-xl-10">
                    <div className="card-header align-items-center border-0 mt-5">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="fw-bolder fw-bold fs-2"> {t('channels.sms.list')}</span>
                        <span className="text-gray-400 mt-2 fw-semibold fs-4">
                          {activeTab === 'tabOne' && <>{t('channels.sms.channels.tab')}</>}
                          {activeTab === 'tabTwo' && <>{t('channels.sms.agents.tab')}</>}
                        </span>
                      </h3>
                      <div className="card-toolbar"></div>
                    </div>
                    <div className="card-body pt-1">
                      {activeTab === 'tabOne' && (
                        <>
                          <Datatable
                            columns={columnsChannels}
                            data={channels}
                            isLoading={isRequesting}
                          />
                        </>
                      )}
                      {activeTab === 'tabTwo' && (
                        <>
                          <Datatable
                            columns={columnsAgents}
                            data={agents}
                            isLoading={isRequesting}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <AgentModal
        providers={providers}
        show={showModalAgent.show}
        payload={showModalAgent.data}
        onRequestClose={(refresh: any) => handleShowModalAgent(refresh)}
      />
      <ChannelModal
        show={showModalChannel.show}
        payload={showModalChannel.data}
        agents={agentsChannel}
        onRequestClose={(refresh: any) => handleShowModalChannel(refresh)}
      />
    </>
  );
}

export default ChannelSMSPage;
