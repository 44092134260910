import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ChannelsService } from '../../../services/channels.service';
import { ProvidersService } from '../../../services/providers.service';
import { AlertService } from '../../../services/alert.service';
import Header from '../../../components/layout/header.component';
import Asaid from '../../../components/layout/asaid.component';
import Footer from '../../../components/layout/footer.component';
import useBgPages from '../../../hooks/bg-pages.hook';
import AddAccount from '../wa/add-account.modal';
import Datatable from '../../../components/datatable/datatable.component';
import useRequest from '../../../hooks/use-request.hook';
import { useNavigate } from 'react-router-dom';

interface Data {
  _id: string;
  created_at: string;
  status: boolean;
  provider: string;
  name: string;
}

function ChannelWaPage() {
  useBgPages();
  const { t } = useTranslation();
  const { call, isRequesting } = useRequest();
  const navigate = useNavigate();
  const [totalAccounts, settotalAccounts] = useState<number>(0);
  const [totalActives, settotalActives] = useState<number>(0);
  const [providers, setProviders] = useState<any[]>([]);
  const [accounts, setAccounts] = useState<Data[]>([]);

  const columns = [
    {
      name: t('channels.wa.data-account'),
      sortable: true,
      cell: (account: any) => {
        const provider = providers.find((p) => p.value === account.provider);
        return (
          <li className="list-group-item">
            <div className="d-flex flex-column">
              <div className="w-100 mb-1">
                <strong className="fs-6">{t('channels.wa.name')}:</strong>
                <span className="ms-1">{account.name || t('general.no-info')}</span>
              </div>
              <div className="w-100 mb-1 d-flex align-items-center">
                <strong className="fs-6">{t('channels.wa.provider')}:</strong>
                {provider && provider.label === 'Meta' && (
                  <>
                    <span className="d-none">meta</span>
                    <span className="m-n2 theme-light-show">
                      <img
                        src="/assets/media/logos/meta-large.svg"
                        alt="Proveedor"
                        width={'80em'}
                      />
                    </span>
                    <span className="m-n2 theme-dark-show">
                      <img
                        src="/assets/media/logos/meta-large-dark.svg"
                        alt="Proveedor"
                        width={'80em'}
                      />
                    </span>
                  </>
                )}
              </div>
              <div className="w-100">
                <strong className="fs-6">{t('channels.wa.status')}:</strong>
                {account.status ? (
                  <span className="badge badge-light-primary fs-6 ms-2">
                    <i className="ki-duotone ki-check-circle text-primary fs-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    {t('general.active')}
                  </span>
                ) : (
                  <span className="badge badge-light-danger fs-9">
                    <i className="ki-duotone ki-minus-circle text-danger fs-6">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    {t('general.inactive')}
                  </span>
                )}
              </div>
            </div>
          </li>
        );
      }
    },
    {
      name: t('channels.wa.data-config'),
      sortable: true,
      cell: (account: any) => {
        const phone_number = account.settings.phone_number || t('general.no-info');
        const countryCode = phone_number !== t('general.no-info') ? phone_number.slice(0, 2) : '';
        const restOfNumber = phone_number !== t('general.no-info') ? phone_number.slice(2) : '';

        const phone_number_id = account.settings.phone_number_id || t('general.no-info');
        const waba_id = account.settings.waba_id || t('general.no-info');
        const app_id = account.settings.app_id || t('general.no-info');
        const token = account.settings.token || t('general.no-info');

        return (
          <li className="list-group-item">
            <div className="d-flex flex-column">
              <div className="w-100 mb-1">
                <strong className="fs-6">{t('channels.wa.number')}:</strong>
                <span className="ms-1 fs-6">
                  {countryCode === '52' && phone_number !== t('general.no-info') && (
                    <img
                      className="rounded-circle me-2"
                      src="/assets/media/flags/mexico.svg"
                      alt="México"
                      width={'14px'}
                    />
                  )}
                  {phone_number !== t('general.no-info')
                    ? `+${countryCode} ${restOfNumber}`
                    : t('general.no-info')}
                </span>
              </div>
              <div className="w-100 mb-1">
                <strong className="fs-6">{t('channels.wa.id-number')}:</strong>
                <span className="ms-1 fs-6">{phone_number_id}</span>
              </div>
              <div className="w-100 mb-1">
                <strong className="fs-6">{t('channels.wa.id-waba')}:</strong>
                <span className="ms-1 fs-6">{waba_id}</span>
              </div>
              <div className="w-100">
                <strong className="fs-6">{t('channels.wa.id-app')}:</strong>
                <span className="ms-1 fs-6">{app_id}</span>
              </div>
              <div className="w-100">
                <strong className="fs-6">{t('channels.wa.token')}:</strong>
                <span className="ms-1 fs-6 d-inline-block text-break">{token}</span>
              </div>
            </div>
          </li>
        );
      }
    },
    {
      name: t('channels.wa.data-date'),
      sortable: true,
      width: 20,
      cell: (account: any) => {
        const fechaHora = new Date(account.created_at);
        const fecha = fechaHora.toISOString().split('T')[0];
        const hora = fechaHora.toISOString().split('T')[1].split('.')[0];

        return (
          <li className="list-group-item">
            <div className="d-flex flex-column">
              <div className="w-100 mb-1">
                <strong className="fs-6">{t('general.day')}:</strong>
                <span className="ms-1">{fecha || t('general.no-info')}</span>
              </div>
              <div className="w-100">
                <strong className="fs-6">{t('general.hour')}:</strong>
                <span className="ms-1">{hora || t('general.no-info')}</span>
              </div>
            </div>
          </li>
        );
      }
    },
    {
      name: t('general.acctions'),
      sortable: false,
      center: true,
      exportable: false,
      cell: (account: any) => {
        return (
          <>
            <div>
              <button
                className="btn btn-light-primary btn-sm btn-flex btn-center"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                type="button"
              >
                {t('general.acctions')}
                <i className="ki-duotone ki-down fs-5 ms-1" />
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    onClick={() => setShowModal({ show: true, data: account })}
                  >
                    <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">{t('channels.wa.btn-update')}</span>
                  </a>
                </div>
                <div className="menu-item px-3">
                  <a className="menu-link px-3" onClick={() => navigate('/templates/wa')}>
                    <span className="menu-icon">
                      <i className="ki-duotone ki-picture fs-2x text-primary">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </span>
                    <span className="ms-2">{t('Templates')}</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  ];

  const getAccounts = async () => {
    try {
      const response = await call(() => ChannelsService.getAccountsWhatsApp());
      const accountsData: Data[] = response;
      settotalActives(accountsData.filter((channel) => channel.status === true).length);
      settotalAccounts(accountsData.length);
      setAccounts(accountsData);
    } catch (error) {
      console.error(t('general.error'), error);
    }
  };

  const getProviders = async () => {
    try {
      const data = await ProvidersService.getProviders();
      setProviders(
        data.map((item: any) => ({
          value: item._id,
          label: item.name,
          type: item.service_type
        }))
      );
    } catch (error: any) {
      AlertService.toastError(t('general.atention'), error);
    }
  };

  const [showModal, setShowModal] = useState({
    show: false,
    data: null
  });

  const handleShowModal = async (refresh: boolean) => {
    if (refresh) {
      await getAccounts();
    }
    setShowModal({ show: false, data: null });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getProviders();
        await getAccounts();
      } catch (error: any) {
        AlertService.toastError(t('general.atention'), error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">
                      {t('asaid.link-title-operations')}
                    </li>
                    <li className="breadcrumb-item text-muted"> {t('asaid.link-menu-channels')}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-whatsapp fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <span className="ms-2">WhatsApp</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-4">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">{t('general.estatistics')}</span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('channels.wa.accounts')}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-category fs-2 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>
                      </div>

                      <div className="card-body pt-1">
                        <div className="d-flex flex-stack mb-2 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-book-open fs-3x text-info w-35px me-6 hover-scale">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-info fw-bolder"
                                href="#"
                              >
                                {t('channels.wa.our-account')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('channels.wa.our-account-total')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder ">
                            {totalAccounts}
                          </span>
                        </div>
                        <div className="d-flex flex-stack mb-10 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-check-circle fs-3x text-primary w-35px me-6 hover-scale">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-primary fw-bolder"
                                href="#"
                              >
                                {t('channels.wa.actives')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('channels.wa.total-actives')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder">
                            {totalActives}
                          </span>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                          <a
                            href="#"
                            className="btn btn-primary hover-scale"
                            onClick={() => setShowModal({ show: true, data: null })}
                            // data-bs-toggle="modal"
                            // data-bs-target="#add_new_account"
                          >
                            <i className="ki-duotone ki-abstract-10 fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            {t('channels.wa.btn-add')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            {t('channels.wa.accounts')}
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('channels.wa.registred')}
                          </span>
                        </h3>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body pt-1">
                        <Datatable columns={columns} data={accounts} isLoading={isRequesting} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <AddAccount
            show={showModal.show}
            onRequestClose={(refresh: any) => handleShowModal(refresh)}
            payload={showModal.data}
          />
        </div>
      </div>
    </>
  );
}

export default ChannelWaPage;
