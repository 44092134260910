import React, { useState } from 'react';
import { AlertService } from '../../services/alert.service';
import Logs from '../../pages/campaings/show-logs.modal';

interface NodeData {
  id: string;
  data: {
    enqueuedMessages: number;
    label: string;
    description?: string;
    externalId?: string;
    config?: {
      key1?: string;
      key2?: string;
    };
    user?: {
      id: string;
      name: string;
    };
    createdAt?: string;
    actions?: string[];
    icon?: string;
    totalMessage: number;
    status: string;
  };
  position: {
    x: number;
    y: number;
  };
  sourcePosition?: string;
  targetPosition?: string;
  type?: string;
  style?: React.CSSProperties;
  labelChannel?: string;
  idChannel?: string;
}

interface ShowModalState {
  show: boolean;
  data: any;
}

interface CustomNodeActionsProps {
  node: NodeData;
  updateNodeStatus: (nodeId: string, status: string) => void;
  deleteNode: (nodeId: string) => void;
  addNode: (nodeId: string, enqueuedMessages: number, labelChannel: string) => void;
  getCustomNodeStatus: (nodeId: string) => string | null;
}

const CustomNodeConsumerChannel: React.FC<CustomNodeActionsProps> = ({
  node,
  updateNodeStatus,
  deleteNode,
  addNode,
  getCustomNodeStatus
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState<ShowModalState>({
    show: false,
    data: null
  });

  const handleShowModal = async (refresh: boolean) => {
    if (refresh) {
      //  await function();
    }
    setShowModal({ show: false, data: null });
  };

  const handleRestart = async () => {
    const customNodeStatus = getCustomNodeStatus(node.id);

    if (customNodeStatus === 'paused') {
      AlertService.toastError(
        `La campaña en general está pausada, no se puede reanudar el envío del canal ${node.data.label}.`
      );
      return;
    }

    const confirmed = await AlertService.question(
      `¿Deseas reiniciar el consumidor ${node.labelChannel} - ${node.data.label}?`,
      'Volveremos a calcular lo datos necesarios.',
      () => Promise.resolve(),
      'Reiniciar'
    );

    if (!confirmed) return;
    try {
      updateNodeStatus(node.id, 'restart');
      setTimeout(() => {
        AlertService.toastSuccess(
          `¡ Éxito !, Reiniciamos el consumidor ${node.labelChannel} - ${node.data.label} correctamente.`
        );
        updateNodeStatus(node.id, 'process');
      }, 4000);
    } catch (error) {
      console.error(
        `Error al reiniciar el consumidor ${node.labelChannel} - ${node.data.label} : ${error}`
      );
      AlertService.toastError(
        `¡ Atención !, No se pudo reiniciar el consumidor ${node.labelChannel} - ${node.data.label}, Contacta a tu administrador.`
      );
    }
  };

  const handleAdd = async () => {
    if (node.data.status === 'process') {
      setShowAlert(true);
    } else {
      await addConsumer();
    }
  };

  const addConsumer = async () => {
    const confirmed = await AlertService.question(
      `¿Deseas agregar nuevo consumidor al canal ${node.labelChannel}?`,
      'Esta acción no se podrá revertir.',
      () => Promise.resolve(),
      'Agregar'
    );

    if (!confirmed) return;

    try {
      addNode(node.idChannel!, node.data.enqueuedMessages!, node.labelChannel!);
      await AlertService.toastSuccess(
        `¡ Éxito !, Agregamos un nuevo consumidor al canal ${node.labelChannel}.`
      );
    } catch (error) {
      console.error(`Error al agregar un nuevo consumidor al canal ${node.labelChannel}: ${error}`);
      AlertService.toastError(
        `¡ Atención !, No se pudo agregar el nuevo consumidor al canal ${node.labelChannel}, Contacta a tu administrador.`
      );
    }
  };

  return (
    <>
      <>
        <h4 className="mb-5 text-primary">
          {node.labelChannel} - {node.data.label}
        </h4>
        <div className="d-flex flex-stack">
          <div className="d-flex align-items-center flex-row-fluid flex-wrap">
            <div className="flex-grow-1">
              <a className="text-gray-800 text-hover-primary fs-6 fw-bold">Reinicar consumidor</a>
              <span className="text-muted fw-semibold d-block fs-7">Actualizaremos datos.</span>
            </div>
            <a className="btn btn-icon btn-light-info fs-8 fw-bold" onClick={handleRestart}>
              <i className="ki-duotone ki-abstract-20 fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </a>
          </div>
        </div>
        <div className="separator separator-dashed my-4" />
      </>
      <div className="d-flex flex-stack mb-5">
        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
          <div className="flex-grow-1">
            <a className="text-gray-800 text-hover-primary fs-6 fw-bold">Agregar consumidor</a>
            <span className="text-muted fw-semibold d-block fs-7">Tomar pecaución.</span>
          </div>
          <a className="btn btn-icon btn-light-primary fs-8 fw-bold" onClick={handleAdd}>
            <i className="ki-duotone ki-abstract-10 fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </a>
        </div>
      </div>
      {showAlert && (
        <div className="card alert-warning-fow border-warning mb-3">
          <div className="card-body pb-0">
            <div className="d-flex flex-column justify-content-between">
              <div className="pt-12">
                <h4 className="text-warning text-center fs-1 fw-bolder">¡ Atención !</h4>
                <div className="text-center fs-7 fw-semibold pt-4 pb-1">
                  El consumidor actual{' '}
                  <span className="text-primary fw-bolder">
                    {node.data.label} del canal {node.labelChannel}
                  </span>{' '}
                  está funcionando con normalidad. Sin embargo, agregar otro consumidor podría
                  afectar el envío de mensajes en el{' '}
                  <span className="text-primary fw-bolder">canal {node.labelChannel}.</span>
                </div>
                <div className="text-center py-7">
                  <a
                    className="btn btn-light-primary btn-sm fs-6 px-6"
                    onClick={async () => {
                      setShowAlert(false);
                      await addConsumer();
                    }}
                  >
                    Continuar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="separator separator-dashed my-4" />
      <div className="d-flex flex-stack mb-5">
        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
          <div className="flex-grow-1">
            <a className="text-gray-800 text-hover-primary fs-6 fw-bold">Ver Logs</a>
            <span className="text-muted fw-semibold d-block fs-7">Funcionamiento consumidor.</span>
          </div>
          <a
            className="btn btn-icon btn-light-danger fs-8 fw-bold"
            onClick={() => setShowModal({ show: true, data: node })}
          >
            <i className="ki-duotone ki-eye fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </i>
          </a>
        </div>
      </div>
      <Logs
        show={showModal.show}
        onRequestClose={(refresh: any) => handleShowModal(refresh)}
        payload={showModal.data}
      />
    </>
  );
};

export default CustomNodeConsumerChannel;
