import { API_ENDPOINT } from '../constants';
import { HTTP } from './http.service';

export const ChannelsService = {
  getAccountsWhatsApp: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}channels/whatsapp`);
  },
  saveAccountWhatsApp: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}channels/whatsapp`, data);
  },
  updateAccountWhatsApp: (data: any) => {
    return HTTP.put<any, any>(`${API_ENDPOINT.CORE}channels/whatsapp`, data);
  },
  getAgentsSMS: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}channels/sms-agents`);
  },
  getChannelsSMS: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}channels/sms`);
  },
  getAgentsCataogue: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}channels/sms-agents/catalogue`);
  },
  addAgentsSMS: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}channels/sms-agents`, data);
  },
  updateAgentsSMS: (data: any) => {
    return HTTP.put<any, any>(`${API_ENDPOINT.CORE}channels/sms-agents`, data);
  },
  addChannelsMS: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}channels/sms`, data);
  },
  updateChannelsMS: (data: any) => {
    return HTTP.put<any, any>(`${API_ENDPOINT.CORE}channels/sms`, data);
  }
};
