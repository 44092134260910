import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../components/layout/header.component';
import Asaid from '../../../components/layout/asaid.component';
import Footer from '../../../components/layout/footer.component';
import useBgPages from '../../../hooks/bg-pages.hook';
import Select from 'react-select';
import Datatable from '../../../components/datatable/datatable.component';

interface Agent {
  sms_agent: string;
  priority: number;
}

interface Data {
  type: string;
  _id: string;
  name: string;
  status: boolean;
  created_at: string;
  updated_at: string;
}

function TemplatesRcsPage() {
  useBgPages();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [templates, setTemplates] = useState<Data[]>([]);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [typeTemplate, setTypeTemplate] = useState('');

  const channel = useMemo(() => location?.state?.channel || 'basic', [location]);

  const filtersTemplates = [
    { value: 'rcs_basic', label: t('templates.rcs.rcs-title') + ' ' + t('templates.rcs.basic') },
    { value: 'rcs_single', label: t('templates.rcs.rcs-title') + ' ' + t('templates.rcs.single') },
    {
      value: 'rcs_conversacional',
      label: t('templates.rcs.rcs-title') + ' ' + t('templates.rcs.conversational')
    }
  ];

  const columns = [
    {
      name: t('templates.rcs.name'),
      sortable: true,
      width: 15,
      cell: (row: any) => {
        return (
          <div className="d-flex flex-column w-175px">
            <div className="mb-1">
              <span className="d-flex align-items-center ms-1 fs-7">
                <span className="fs-4 text-break">{row.name || t('general.no-info')}</span>
              </span>
            </div>
          </div>
        );
      }
    },
    {
      name: t('templates.rcs.rcs-type'),
      sortable: true,
      center: true,
      width: 30,
      cell: (row: any) => {
        // Determina la clase basada en el valor de row.type
        const className =
          row.type === t('templates.rcs.single')
            ? 'text-info fw-bolder'
            : row.type === t('templates.rcs.conversational')
              ? 'text-primary fw-bolder'
              : 'fw-bolder';

        return (
          <div className="d-flex justify-content-center align-items-center">
            <span className={className}>{row.type}</span>
          </div>
        );
      }
    },
    {
      name: t('templates.rcs.preview'),
      sortable: true,
      center: true,
      width: 30,
      cell: (row: any) => (
        <a
          className="btn btn-light btn-sm hover-scale fw-bold"
          onClick={() => {
            if (row.type === 'Basic') {
              setTypeTemplate('basic');
            } else if (row.type === 'Single') {
              setTypeTemplate('single');
            } else if (row.type === 'Conversational') {
              setTypeTemplate('conversational');
            }
          }}
        >
          <i className="ki-duotone ki-eye fs-2">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          {t('templates.rcs.preview')}
        </a>
      )
    },
    {
      name: t('general.acctions'),
      sortable: false,
      center: true,
      exportable: false,
      width: 30,
      cell: (row: any) => (
        <div>
          <button
            title="img"
            className="btn btn-light-primary btn-flex btn-center btn-sm fw-bold"
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            type="button"
          >
            {t('general.acctions')}
            <i className="ki-duotone ki-down fs-4 ms-1" />
          </button>
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold fs-7 w-175px py-3"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#"
                // onClick={() => setShowModalChannel({ show: true, data: channels })}
              >
                <i className="ki-duotone ki-notepad-edit fs-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <span className="ms-2">{t('channels.wa.btn-update')}</span>
              </a>
              <a
                className="menu-link px-3"
                href="#"
                // onClick={() => setShowModalChannel({ show: true, data: channels })}
              >
                <i className="ki-duotone ki-copy fs-2"></i>
                <span className="ms-2">{t('templates.rcs.duplicate')}</span>
              </a>
              <a
                className="menu-link px-3"
                href="#"
                // onClick={() => setShowModalChannel({ show: true, data: channels })}
              >
                <i className="ki-duotone ki-text fs-2"></i>
                <span className="ms-2">{t('templates.rcs.rename')}</span>
              </a>
              <div className="separator my-2"></div>
              <a
                className="menu-link px-3"
                href="#"
                // onClick={() => setShowModalChannel({ show: true, data: channels })}
              >
                <i className="ki-duotone ki-trash fs-2 text-danger">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                </i>
                <span className="ms-2">{t('general.delete')}</span>
              </a>
            </div>
          </div>
        </div>
      )
    }
  ];

  useEffect(() => {
    const data = [
      {
        type: 'Basic',
        _id: '6691c6b84cb2941fb297ad0a',
        name: 'Encuesta Baz 23-07-2024	',
        status: true,
        created_at: '2024-07-11T23:31:28.430Z',
        updated_at: '2024-07-14T23:30:36.138Z',
        html: []
      },
      {
        type: 'Single',
        _id: '6691c6e84cb2941fb297ad11',
        name: 'Promo_verano_2024',
        status: true,
        created_at: '2024-07-11T23:31:28.430Z',
        updated_at: '2024-07-11T23:31:28.430Z',
        html: []
      },
      {
        type: 'Conversacional',
        _id: '6691c7284cb2941fb297ad18',
        name: '3269-RegresoAclases-190724',
        status: true,
        created_at: '2024-07-11T23:31:28.430Z',
        updated_at: '2024-07-14T23:34:48.585Z',
        html: []
      }
    ];

    // setTemplates(data);
    setTemplates(data.filter((template) => template.type.toUpperCase() === channel.toUpperCase()));
  }, []);

  useEffect(() => {
    const checkTheme = () => {
      const isDark = document.documentElement.getAttribute('data-bs-theme') === 'dark';
      setIsDarkTheme(isDark);
    };
    checkTheme();
    const observer = new MutationObserver(() => checkTheme());
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-bs-theme']
    });
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">Operaciones</li>
                    <li className="breadcrumb-item text-muted">Canales</li>
                    <li className="breadcrumb-item text-muted">RCS {channel}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      {/* <i className="ki-duotone ki-picture fs-3x text-primary">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i> */}
                      <a
                        className="symbol symbol-circle symbol-50px hover-scale cursor-pointer"
                        onClick={() => navigate(-1)}
                      >
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-double-left  fs-2x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </a>
                      <span className="ms-2">{t('templates.rcs.rcs-title')}</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="card mb-5 mb-xl-10">
                  <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <div className="me-7 mb-4">
                        <i className="ki-duotone ki-chart-pie-3 fs-4x hover-scale me-2 mt-10">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                        </i>
                      </div>
                      <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-4">
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                              <a
                                className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                                href="#"
                              >
                                {t('general.estatistics')}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap flex-stack">
                          <div className="d-flex flex-column flex-grow-1 pe-8">
                            <div className="d-flex flex-wrap">
                              <div className="alert alert-dismissible bg-gray-200 border-dashed border-gray-500 rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <i className="ki-duotone ki-messages fs-2x hover-scale me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                  <div className="fs-2 fw-bold">1</div>
                                </div>
                                <div className="fw-semibold fs-4 text-gray-700">
                                  {t('templates.rcs.basic')}
                                </div>
                              </div>
                              <div className="alert alert-dismissible bg-light-info border-dashed border-info rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <i className="ki-duotone ki-messages fs-2x hover-scale me-2 text-info">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                  <div className="fs-2 fw-bold">1</div>
                                </div>
                                <div className="fw-semibold fs-4 text-info">
                                  {t('templates.rcs.single')}
                                </div>
                              </div>
                              <div className="alert alert-dismissible bg-light-primary border-dashed border-primary rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <i className="ki-duotone ki-messages fs-2x hover-scale me-2 text-primary">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                  <div className="fs-2 fw-bold">1</div>
                                </div>
                                <div className="fw-semibold fs-4 text-primary">
                                  {t('templates.rcs.conversational')}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                            <div className="d-flex justify-content-end w-100 mt-auto mb-2">
                              <a
                                className="btn btn-primary hover-scale fw-bold"
                                onClick={() =>
                                  navigate('/templates/create-template-RCS', { state: { channel } })
                                }
                              >
                                <i className="ki-duotone ki-abstract-10 fs-2">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                                {t('templates.rcs.add-btn')}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bold">
                      <li className="nav-item"></li>
                      <li className="nav-item"></li>
                    </ul>
                  </div>
                </div>
                <div className="row g-xl-10">
                  <div className="col-xl-8 mb-5">
                    <div className="card">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            {t('templates.rcs.templates-title')}
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('templates.rcs.registered')}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <Select
                            options={filtersTemplates}
                            noOptionsMessage={() => (
                              <div className="empty-select">{t('general.no-options')}</div>
                            )}
                            className="form-select-custom"
                            classNamePrefix="form-select-custom"
                            placeholder={t('templates.rcs.filter-placeholder')}
                            onChange={(selectedOption: { value: string; label: string } | null) => {
                              if (selectedOption) {
                                // setSelectedCompanyId(selectedOption.value);
                                // fetchData(selectedOption.value);
                              }
                            }}
                            value={filtersTemplates.find((company) => company.value === '')}
                            isClearable
                          />
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <Datatable
                          columns={columns}
                          data={templates}
                          // data={areas} isLoading={isRequesting}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div
                      data-kt-sticky="true"
                      data-kt-sticky-name="docs-sticky-summary"
                      data-kt-sticky-offset="{default: true, xl: '330px'}"
                      data-kt-sticky-top="100px"
                      data-kt-sticky-animation="false"
                      data-kt-sticky-zindex="95"
                    >
                      <div className={isDarkTheme ? 'wrap-android-dark' : 'wrap-android'}>
                        {typeTemplate === 'conversational' && (
                          <div className="preview-container-RCS">
                            <div
                              className={
                                isDarkTheme ? 'preview-template-RCS-dark' : 'preview-template-RCS'
                              }
                            >
                              <div>
                                <div>
                                  <div className="preview-template-img-container-url mb-5">
                                    <img
                                      alt="Uploaded"
                                      className="preview-image mw-100 mb-3"
                                      src="https://images-na.ssl-images-amazon.com/images/G/33/img23/Furniture_hard_launch_store_revamp/Comodas_Furniture_440x440.jpg"
                                    />
                                  </div>
                                </div>
                                <div className="preview-template-header fw-bolder">
                                  Aprovecha esta oferta
                                </div>
                                <p className="mb-10">
                                  Todo tenemos descuentos hasta del 60%, no te quedes sin tus
                                  muebles.
                                </p>
                                <div className="text-center">
                                  <p className="text-info">
                                    <i className="bi bi-globe-americas text-info fw-bolder" />
                                    <span className="ms-2 fw-bolder">Comprar ya</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="carrousel-container mt-5">
                              <div className="preview-cards-carrousel">
                                <div
                                  className={
                                    isDarkTheme
                                      ? 'preview-template-RCS-dark me-5'
                                      : 'preview-template-RCS me-5'
                                  }
                                >
                                  <div>
                                    <div className="preview-template-img-container-url mb-5">
                                      <img
                                        alt="Uploaded"
                                        className="preview-image mw-100 mb-3"
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuoJUQSjEimA12KZvWDcv-hksZRqexjdPmag&s"
                                      />
                                    </div>
                                  </div>
                                  <div className="preview-template-header fw-bold">
                                    Los descuentos llegaron ya
                                  </div>
                                  <p className="mb-10">
                                    No te pierdas todo la moda para este regreso a clases.
                                  </p>
                                  <div className="text-center">
                                    <p className="text-info">
                                      <i className="bi bi-globe-americas text-info fw-bolder" />
                                      <span className="ms-2 fw-bolder">comprar</span>
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={
                                    isDarkTheme
                                      ? 'preview-template-RCS-dark me-5'
                                      : 'preview-template-RCS me-5'
                                  }
                                >
                                  <div>
                                    <div className="preview-template-img-container-url mb-5">
                                      <img
                                        alt="Uploaded"
                                        className="preview-image mw-100 mb-3"
                                        src="https://media.bcompras.com.mx/xPynq88JXmdZ9MgE6gD50hso.webp"
                                      />
                                    </div>
                                  </div>
                                  <div className="preview-template-header fw-bold">Tennis</div>
                                  <p className="mb-10">Todo para este regreso a clases.</p>
                                  <div className="text-center">
                                    <p className="text-info">
                                      <i className="bi bi-globe-americas text-info fw-bolder" />
                                      <span className="ms-2 fw-bolder">Tienda online</span>
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={
                                    isDarkTheme
                                      ? 'preview-template-RCS-dark me-5'
                                      : 'preview-template-RCS me-5'
                                  }
                                >
                                  <div>
                                    <div className="preview-template-img-container-url mb-5">
                                      <img
                                        alt="Uploaded"
                                        className="preview-image mw-100 mb-3"
                                        src="https://cdn1.coppel.com/images/catalog/pm/2230353-1.jpg"
                                      />
                                    </div>
                                  </div>
                                  <div className="preview-template-header fw-bold">Electrónica</div>
                                  <p className="mb-10">
                                    Todo lo que quieras de electrónica para que siempre andes
                                    conectado.
                                  </p>
                                  <div className="text-center">
                                    <p className="text-info">
                                      <i className="bi bi-globe-americas text-info fw-bolder" />
                                      <span className="ms-2 fw-bolder">Click aquí</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {typeTemplate === 'single' && (
                          <div className="preview-container-RCS">
                            <div
                              className={
                                isDarkTheme ? 'preview-template-RCS-dark' : 'preview-template-RCS'
                              }
                            >
                              <div>
                                <div>
                                  <div className="preview-template-img-container-url mb-5">
                                    <img
                                      src="https://mueblesavanti.com.mx/cdn/shop/files/1..jpg?v=1713223477&width=1500"
                                      alt="Uploaded"
                                      className="preview-image mw-100 mb-3"
                                    />
                                  </div>
                                </div>
                                <div className="preview-template-header fw-bolder">
                                  Aprovecha esta oferta
                                </div>
                                <p className="mb-10">
                                  Todo tenemos descuentos hasta del 60%, no te quedes sin tus
                                  muebles.
                                </p>
                                <div className="text-center">
                                  <p className="text-info">
                                    <i className="bi bi-globe-americas text-info fw-bolder"></i>
                                    <span className="ms-2 fw-bolder">Comprar ya</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {typeTemplate === 'basic' && (
                          <div>
                            <div
                              className={
                                isDarkTheme
                                  ? 'preview-template-RCS-dark ms-4 mb-3'
                                  : 'preview-template-RCS-Basic ms-4 mb-3'
                              }
                            >
                              <div className="preview-template-header fw-bold">
                                Hola te recordamos que tienes un pago pendiente
                              </div>
                            </div>
                            <div className="row">
                              <div className="text-right btn-container-Basic">
                                <div
                                  className={
                                    isDarkTheme ? 'btn-answer-basic-dark' : 'btn-answer-basic'
                                  }
                                >
                                  Pagar
                                </div>

                                <div
                                  className={
                                    isDarkTheme
                                      ? 'btn-answer-basic-dark ms-2'
                                      : 'btn-answer-basic ms-2'
                                  }
                                >
                                  Ya pagué
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default TemplatesRcsPage;
function useNavigationState(arg0: (state: any) => any) {
  throw new Error('Function not implemented.');
}
