import React from 'react';
import { AlertService } from '../../services/alert.service';

interface NodeData {
  id: string;
  data: {
    label: string;
    description?: string;
    externalId?: string;
    config?: {
      key1?: string;
      key2?: string;
    };
    user?: {
      id: string;
      name: string;
    };
    createdAt?: string;
    actions?: string[];
    icon?: string;
    totalMessage: number;
    status: string;
    labelChannel?: string;
    labelConsumer?: string;
  };
  position: {
    x: number;
    y: number;
  };
  sourcePosition?: string;
  targetPosition?: string;
  type?: string;
  style?: React.CSSProperties;
}

interface CustomNodeActionsProps {
  node: NodeData;
  updateNodeStatus: (nodeId: string, status: string) => void;
  deleteNode: (nodeId: string) => void;
  getCustomNodeStatus: (nodeId: string) => string | null;
}

const CustomNodeActionsProvider: React.FC<CustomNodeActionsProps> = ({ node }) => {
  return (
    <>
      <h4 className="mb-8 text-primary">
        {node.data.labelChannel} - {node.data.labelConsumer} - {node.data.label}
      </h4>
      {node.data.status === 'process' ? (
        <>
          <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-12 p-6">
            <i className="ki-duotone ki-check-circle fs-2tx text-primary me-4">
              <span className="path1" />
              <span className="path2" />
            </i>
            <div className="d-flex flex-stack flex-grow-1">
              <div className="fw-semibold">
                <h4 className="text-gray-900 fw-bold mb-5">Procesando mensajes.</h4>
                <div className="fs-6 text-gray-700">
                  El proveedor esta operando con{' '}
                  <span className="fw-bold text-primary">normalidad</span>.
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed mb-12 p-6">
            <i className="ki-duotone ki-cross-circle fs-2tx text-danger me-4">
              <span className="path1" />
              <span className="path2" />
            </i>
            <div className="d-flex flex-stack flex-grow-1">
              <div className="fw-semibold">
                <h4 className="text-gray-900 fw-bold mb-5">Encontramos un Error.</h4>
                <div className="fs-6 text-gray-700">
                  El proveedor esta teniendo difultados con el{' '}
                  <span className="fw-bold text-danger">procesamiento de mensajes.</span>.
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomNodeActionsProvider;
