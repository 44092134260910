import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Aside from '../../../components/layout/asaid.component';
import Header from '../../../components/layout/header.component';
import style from './templates-wa.module.scss';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Input from '../../../components/forms/input.component';
import Select from 'react-select';
import TextArea from '../../../components/forms/text-area.component';
import InvalidFeedback from '../../../components/forms/invalid-feedback.component';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/forms/button.component';
import { AlertService } from '../../../services/alert.service';
import QuickReplyBtn from './quick-reply-btn.component';
import UrlBtn from './url-btn.component';
import useBgPages from '../../../hooks/bg-pages.hook';

function CreateTemplatesWa() {
  useBgPages();
  const { t } = useTranslation();
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const languageOptions = [
    { value: 'es_MX', label: t('templates.wa.create.es') },
    { value: 'en', label: t('templates.wa.create.en') }
  ];
  const headerOptions = [
    { value: 'none', label: t('templates.wa.create.header-option-1') },
    { value: 'TEXT', label: t('templates.wa.create.header-option-2') },
    { value: 'IMAGE', label: t('templates.wa.create.header-option-3') },
    { value: 'VIDEO', label: t('templates.wa.create.header-option-4') },
    { value: 'DOCUMENT', label: t('templates.wa.create.header-option-5') },
    { value: 'LOCATIONS', label: t('templates.wa.create.header-option-6') }
  ];

  const defaultHeaderValues = {
    type: 'HEADER',
    format: {
      value: 'none',
      label: t('templates.wa.create.header-option-1')
    },
    text: '',
    content: {
      value: '',
      label: ''
    },
    file: '',
    example: {
      header_handle: ''
    },
    url: ''
  };
  const defaultBodyValues = {
    type: 'BODY',
    text: '',
    example: {
      body_text: []
    }
  };
  const defaultFooterValues = {
    type: 'FOOTER',
    text: ''
  };

  const defaultButtonValues = {
    type: '',
    text: '',
    url: '',
    style: '', // dynamic o static
    example: ''
  };
  const defaultButtonsValues = {
    type: 'BUTTONS',
    buttons: []
  };
  const defaultValues = {
    name: '',
    category: '',
    language: '',
    header: defaultHeaderValues,
    body: defaultBodyValues,
    footer: defaultFooterValues,
    buttons: defaultButtonsValues
  };

  const {
    register,
    watch,
    reset,
    control,
    resetField,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const buttonActionsArray: any = useFieldArray({
    control,
    name: 'buttons.buttons' as never
  });

  const navigate = useNavigate();
  const header = watch('header');
  const body = watch('body.text');
  const footer = watch('footer.text');
  const buttons = watch('buttons');

  const submit = async (data: any) => {
    console.log(data);
    AlertService.toastSuccess(t('templates.wa.success'));
    navigate('/templates/wa');
  };

  const bodyVars = useFieldArray({
    control,
    name: 'body.example.body_text' as never
  });

  const handleName = ({ target: { value } }: any) => {
    const newValue = value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '_')
      .toLowerCase();
    setValue('name', newValue);
  };

  const addBtn = (type: string) => {
    const urlButtonsCount = buttonActionsArray.fields.filter(
      (button: any) => button.type === 'URL'
    ).length;

    if (type === 'URL' && urlButtonsCount >= 2) {
      AlertService.toastWarning(t('templates.wa.warning-url'));
      return;
    }

    if (buttonActionsArray.fields.length < 10) {
      buttonActionsArray.append({
        ...defaultButtonValues,
        type,
        is_btn_blacklist: false
      });
    } else {
      AlertService.toastWarning(t('templates.wa.warning-buttons'));
    }
  };

  const goBack = () => {
    reset(defaultValues as any);
    navigate('/templates/wa');
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('category', event.target.value);
  };

  const handleKeyDown = (e: any, maxLength: number) => {
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  };

  const uploadFile = async ({ target: { files } }: any) => {
    const bytesToMegabytes = (bytes: any) => {
      const megabytes = bytes / (1024 * 1024);
      return megabytes.toFixed(2);
    };

    const file = files[0];
    const sizeMB = +bytesToMegabytes(file.size);
    const type = header.format.value;
    if (sizeMB > 5) {
      AlertService.toastError(t('templates.wa.error-size'));
      setValue('header.file', '');
      return;
    }
    if (
      type === 'IMAGE' &&
      file.type !== 'image/jpg' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png'
    ) {
      AlertService.toastError('Sólo se permiten imagenes en formato png o jpg');
      setValue('header.file', '');
      return;
    }
    if (type === 'VIDEO' && file.type !== 'video/mp4') {
      AlertService.toastError('Sólo se permiten videos en formato mp4');
      setValue('header.file', '');
      return;
    }
    if (type === 'DOCUMENT' && file.type !== 'application/pdf') {
      AlertService.toastError('Sólo se permiten archivos en formato PDF');
      setValue('header.file', '');
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result as any);
    };
    reader.readAsDataURL(file);

    const promise: any = async () => {
      return true;
    };

    AlertService.toastLoading(
      promise(),
      t('templates.wa.create.uploading-file-1'),
      t('templates.wa.create.uploading-file-2'),
      t('templates.wa.create.uploading-file-3')
    );
  };

  useEffect(() => {
    const match = body.match(/{{\d+}}/g);
    if (!match) return bodyVars.replace([]);

    let tokens = match.map((t) => t.replace(/{{|}}/gi, ''));
    tokens = tokens.filter((x, i, a) => a.indexOf(x) == i);

    bodyVars.replace(tokens.map((t) => ({ key: t, value: '' })));
  }, [body]);

  useEffect(() => {
    const checkTheme = () => {
      const isDark = document.documentElement.getAttribute('data-bs-theme') === 'dark';
      setIsDarkTheme(isDark);
    };
    checkTheme();
    const observer = new MutationObserver(() => checkTheme());
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-bs-theme']
    });
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Aside />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">{t('templates.wa.tools')}</li>
                    <li className="breadcrumb-item text-muted">
                      {t('templates.wa.templates-title')}
                    </li>
                    <li className="breadcrumb-item text-muted"> {t('templates.wa.wa-title')}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <a
                        className="symbol symbol-circle symbol-50px hover-scale cursor-pointer"
                        onClick={() => goBack()}
                      >
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-double-left fs-2x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </a>
                      <span className="ms-3"> {t('templates.wa.add-btn')}</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid mt-5" id="kt_post">
              <div className="container-xxl">
                <form className="form" onSubmit={handleSubmit(submit)}>
                  <div className="row g-xl-10">
                    <div className="col-xl-8 mb-5">
                      <div className="card  mb-xxl-8">
                        <div className="card-header align-items-center border-0 mt-10">
                          <label className="d-flex flex-stack mb-10">
                            <span className="d-flex align-items-center me-2">
                              <span className="symbol symbol-circle symbol-50px me-6">
                                <span className="symbol-label bg-light-primary">
                                  <i className="ki-duotone ki-messages fs-3x text-primary">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                    <span className="path5"></span>
                                  </i>
                                </span>
                              </span>
                              <span className="d-flex flex-column">
                                <h2 className="fw-bold">
                                  {t('templates.wa.create.type-title')}
                                  <span className="required"></span>
                                </h2>
                                <div className="text-muted fw-semibold fs-6">
                                  {t('templates.wa.create.type-description')}
                                </div>
                              </span>
                            </span>
                          </label>
                          <div className="card-toolbar"></div>
                        </div>
                        <div className="card-body pt-1">
                          <div className="row mb-7">
                            <div className="col-md-12 mb-5"></div>
                            <div className="fv-row">
                              <label
                                className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 mb-6 ${
                                  watch('category') === 'MARKETING' ? 'active' : ''
                                }`}
                              >
                                <input
                                  className="btn-check"
                                  type="radio"
                                  name="project_type"
                                  checked={watch('category') === 'MARKETING'}
                                  value="MARKETING"
                                  onChange={handleRadioChange}
                                />
                                <span className="d-flex">
                                  <i className="ki-duotone ki-price-tag fs-3x">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                    <span className="path5"></span>
                                    <span className="path6"></span>
                                  </i>
                                  <span className="ms-4">
                                    <span className="fs-3 fw-bold text-gray-900 mb-2 d-block">
                                      {t('templates.wa.marketing-name')}
                                    </span>
                                    <span className="fw-semibold fs-4 text-muted">
                                      {t('templates.wa.create.marketing-description')}
                                    </span>
                                  </span>
                                </span>
                              </label>
                              <label
                                className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 mb-6 ${
                                  watch('category') === 'UTILITY' ? 'active' : ''
                                }`}
                              >
                                <input
                                  className="btn-check"
                                  type="radio"
                                  checked={watch('category') === 'UTILITY'}
                                  name="project_type"
                                  value="UTILITY"
                                  onChange={handleRadioChange}
                                />
                                <span className="d-flex">
                                  <i className="ki-duotone ki-notification fs-3x">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                  </i>
                                  <span className="ms-4">
                                    <span className="fs-3 fw-bold text-gray-900 mb-2 d-block">
                                      {t('templates.wa.utility-name')}
                                    </span>

                                    <span className="fw-semibold fs-4 text-muted">
                                      {t('templates.wa.create.utility-description')}
                                    </span>
                                  </span>
                                </span>
                              </label>
                              <label
                                className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 mb-6 ${
                                  watch('category') === 'AUTHENTICATION' ? 'active' : ''
                                }`}
                              >
                                <input
                                  className="btn-check"
                                  type="radio"
                                  name="project_type"
                                  checked={watch('category') === 'AUTHENTICATION'}
                                  value="AUTHENTICATION"
                                  onChange={handleRadioChange}
                                />
                                <span className="d-flex">
                                  <i className="ki-duotone ki-key fs-3x">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                    <span className="path5"></span>
                                    <span className="path6"></span>
                                  </i>
                                  <span className="ms-4">
                                    <span className="fs-3 fw-bold text-gray-900 mb-2 d-block">
                                      {t('templates.wa.authentication-name')}
                                    </span>

                                    <span className="fw-semibold fs-4 text-muted">
                                      {t('templates.wa.create.authentication-description')}
                                    </span>
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {watch('category') !== '' && (
                        <>
                          <div className="card mb-5 mb-xxl-8">
                            <div className="card-header align-items-center border-0 mt-10">
                              <label className="d-flex flex-stack mb-10">
                                <span className="d-flex align-items-center me-2">
                                  <span className="symbol symbol-circle symbol-50px me-6">
                                    <span className="symbol-label bg-light-primary">
                                      <i className="ki-duotone ki-note-2 fs-3x text-primary">
                                        <span className="path1" />
                                        <span className="path2" />
                                        <span className="path3" />
                                        <span className="path4" />
                                      </i>
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <h2 className="fw-bold">
                                      {t('templates.wa.create.data-title')}
                                      <span className="required"></span>
                                    </h2>
                                    <div className="text-muted fw-semibold fs-6">
                                      {t('templates.wa.create.data-description')}
                                    </div>
                                  </span>
                                </span>
                              </label>
                              <div className="card-toolbar"></div>
                            </div>
                            <div className="card-body pt-1">
                              <div className="row">
                                <div className="col-8">
                                  <Input
                                    label={t('templates.wa.create.name-label')}
                                    currentLength={watch('name')?.length}
                                    maxLength={512}
                                    requiredIndicator="required"
                                    placeholder={t('templates.wa.create.name-placeholder')}
                                    errors={errors.name}
                                    onKeyDown={(e: any) => handleKeyDown(e, 512)}
                                    {...register('name', {
                                      required: true,
                                      onChange: handleName,
                                      maxLength: { value: 512, message: '512' }
                                    })}
                                  />
                                </div>
                                <div className="col-4">
                                  <label className="form-label required">
                                    {t('templates.wa.create.language-label')}
                                  </label>
                                  <Controller
                                    control={control}
                                    name="language"
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error }
                                    }) => (
                                      <>
                                        <Select
                                          options={languageOptions}
                                          noOptionsMessage={() => (
                                            <div className="empty-select">
                                              {t('general.no-options')}
                                            </div>
                                          )}
                                          className={
                                            error
                                              ? 'form-select-custom is-invalid'
                                              : 'form-select-custom'
                                          }
                                          classNamePrefix="form-select-custom"
                                          placeholder={t(
                                            'templates.wa.create.language-placeholder'
                                          )}
                                          onChange={(
                                            selectedOption: { value: string; label: string } | null
                                          ) => {
                                            onChange(selectedOption ? selectedOption.value : null);
                                            setValue(
                                              'language',
                                              selectedOption ? selectedOption.value : ''
                                            );
                                          }}
                                          value={
                                            languageOptions.find(
                                              (language: any) => language.value === value
                                            ) || null
                                          }
                                        />
                                        <InvalidFeedback error={error} />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card mb-5 mb-xxl-8">
                            <div className="card-header align-items-center border-0 mt-10">
                              <label className="d-flex flex-stack mb-10">
                                <span className="d-flex align-items-center me-2">
                                  <span className="symbol symbol-circle symbol-50px me-6">
                                    <span className="symbol-label bg-light-primary">
                                      <i className="ki-duotone ki-burger-menu-6 text-primary fs-2x"></i>
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <h2 className="fw-bold">
                                      {t('templates.wa.create.content-title')}
                                      <span className="required"></span>
                                    </h2>
                                    <div className="text-muted fw-semibold fs-6">
                                      {t('templates.wa.create.content-description')}
                                    </div>
                                  </span>
                                </span>
                              </label>
                              <div className="card-toolbar"></div>
                            </div>
                            <div className="card-body pt-1">
                              <div className="col">
                                <label className="card-title h4 mb-3">
                                  {t('templates.wa.create.header-label')}{' '}
                                  <span className="fs-8 text-muted ms-1">
                                    {t('templates.wa.create.optional')}
                                  </span>
                                </label>
                                <Controller
                                  control={control}
                                  name="header.format.value"
                                  rules={{ required: true }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error }
                                  }) => (
                                    <>
                                      <Select
                                        options={headerOptions}
                                        noOptionsMessage={() => (
                                          <div className="empty-select">
                                            {t('general.no-options')}
                                          </div>
                                        )}
                                        className={
                                          error
                                            ? 'form-select-custom is-invalid'
                                            : 'form-select-custom'
                                        }
                                        classNamePrefix="form-select-custom"
                                        placeholder={t('templates.wa.create.header-placeholder')}
                                        onChange={(
                                          selectedOption: { value: string; label: string } | null
                                        ) => {
                                          onChange(selectedOption ? selectedOption.value : null);
                                          {
                                            header && resetField('header.text'),
                                              resetField('header.file'),
                                              setImageSrc(null);
                                          }
                                          setValue(
                                            'header.format.value',
                                            selectedOption ? selectedOption.value : ''
                                          );
                                        }}
                                        value={
                                          headerOptions.find(
                                            (header: any) => header.value === value
                                          ) || null
                                        }
                                      />
                                      <InvalidFeedback error={error} />
                                    </>
                                  )}
                                />
                              </div>
                              {header.format.value === 'TEXT' && (
                                <>
                                  <div className="col mt-5">
                                    <div className="fv-row">
                                      <label className="card-title h4 required">
                                        {t('templates.wa.create.header-option-2')}
                                      </label>
                                      <Input
                                        currentLength={watch(`header.text`).length}
                                        maxLength={60}
                                        placeholder={t('templates.wa.create.text-placeholder')}
                                        errors={errors.header?.text}
                                        onKeyDown={(e: any) => handleKeyDown(e, 60)}
                                        {...register(`header.text`, {
                                          required: true,
                                          maxLength: { value: 60, message: '60' }
                                        })}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                              {(header.format.value === 'IMAGE' ||
                                header.format.value === 'VIDEO' ||
                                header.format.value === 'DOCUMENT') && (
                                <div className="col-12 mt-3">
                                  <div>
                                    <div className="alert alert-soft-secondary" role="alert">
                                      <h5>{t('templates.wa.create.alert-media-title')}</h5>
                                      <p>{t('templates.wa.create.alert-media-text')}</p>
                                      <div>
                                        <Input
                                          label={
                                            header.format.value === 'IMAGE'
                                              ? t('templates.wa.create.header-option-3')
                                              : header.format.value === 'VIDEO'
                                                ? t('templates.wa.create.header-option-4')
                                                : t('templates.wa.create.header-option-5')
                                          }
                                          type="file"
                                          accept={
                                            header.format.value === 'IMAGE'
                                              ? 'image/jpeg,image/png'
                                              : header.format.value === 'VIDEO'
                                                ? 'video/mp4'
                                                : header.format.value === 'DOCUMENT'
                                                  ? 'application/pdf'
                                                  : ''
                                          }
                                          errors={errors.header?.file}
                                          {...register('header.file', {
                                            required: true,
                                            onChange: uploadFile
                                          })}
                                          sizeClass="form-control-sm"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="fv-row mt-5">
                                <label className="card-title h4 required">
                                  {t('templates.wa.create.body-label')}
                                </label>
                                <TextArea
                                  placeholder={t('templates.wa.create.body-placeholder')}
                                  errors={errors.body?.text}
                                  value={watch('body.text')?.length}
                                  counter={1024}
                                  onKeyDown={(e: any) => handleKeyDown(e, 1024)}
                                  {...register('body.text', {
                                    required: true,
                                    maxLength: { value: 1024, message: '1024' }
                                  })}
                                />
                                {bodyVars.fields.length > 0 && (
                                  <div className="mb-3">
                                    <div
                                      className={`${isDarkTheme ? style.bg_alert_dark : style.bg_alert}`}
                                      role="alert"
                                    >
                                      <h5>{t('templates.wa.create.alert-body-variables-title')}</h5>
                                      <p>{t('templates.wa.create.alert-body-variables-text')}</p>

                                      <h6>{t('templates.wa.create.body-label-1')}</h6>
                                      {bodyVars.fields.map((row, index) => (
                                        <div
                                          className="d-flex align-items-center mb-2"
                                          key={row.id}
                                        >
                                          <label className="form-label mx-3">{`{{${index + 1}}}`}</label>
                                          <div className="flex-grow-1">
                                            <Input
                                              errors={errors.body?.example?.body_text?.[index]}
                                              {...register(
                                                `body.example.body_text.${index}.value` as never,
                                                { required: true }
                                              )}
                                              sizeClass="form-control-sm"
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div>
                                <label className="card-title h4">
                                  {t('templates.wa.create.footer-label')}{' '}
                                  <span className="fs-8 text-muted ms-1">
                                    {t('templates.wa.create.optional')}
                                  </span>
                                </label>
                                <Input
                                  currentLength={watch(`footer.text`).length}
                                  maxLength={60}
                                  placeholder={t('templates.wa.create.footer-placeholder')}
                                  errors={errors.footer?.text}
                                  onKeyDown={(e: any) => handleKeyDown(e, 60)}
                                  {...register(`footer.text`, {
                                    required: false,
                                    maxLength: { value: 60, message: '60' }
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="card mb-5 mb-xxl-8">
                            <div className="card-header align-items-center border-0 mt-10">
                              <label className="d-flex flex-stack mb-10">
                                <span className="d-flex align-items-center me-2">
                                  <span className="symbol symbol-circle symbol-50px me-6">
                                    <span className="symbol-label bg-light-primary">
                                      <i className="ki-duotone ki-exit-right fs-3x text-primary">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                      </i>
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <h2 className="fw-bold">
                                      {t('templates.wa.create.buttons-title')}
                                      <span className="fs-8 text-muted ms-1">
                                        {t('templates.wa.create.optional')}
                                      </span>
                                    </h2>
                                    <div className="text-muted fw-semibold fs-6">
                                      {t('templates.wa.create.buttons-description')}
                                    </div>
                                  </span>
                                </span>
                              </label>
                              <div className="card-toolbar"></div>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className=" col-12">
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-secondary btn-sm"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="bi-plus-lg"></i>
                                      {t('templates.wa.create.add-buttons')}
                                    </button>
                                    <div
                                      className="dropdown-menu p-2"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        className="dropdown-item"
                                        onClick={() => addBtn('QUICK_REPLY')}
                                      >
                                        {t('templates.wa.create.quick-reply')}
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => addBtn('QUICK_REPLY_DISABLE_MKT')}
                                      >
                                        {t('templates.wa.create.quick-reply-disable')}
                                      </a>
                                      <a className="dropdown-item" onClick={() => addBtn('URL')}>
                                        {t('templates.wa.create.go-url')}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {buttonActionsArray.fields.length > 0 && (
                                <div className="row mb-3">
                                  <div className="col">
                                    {buttonActionsArray.fields.map((row: any, index: any) => (
                                      <div key={row.id}>
                                        {row.type === 'QUICK_REPLY' && (
                                          <QuickReplyBtn
                                            errors={errors.buttons?.[index as never]}
                                            register={register}
                                            index={index}
                                            watch={watch}
                                            onDelete={() => buttonActionsArray.remove(index)}
                                            type={row.type}
                                          />
                                        )}
                                        {row.type === 'QUICK_REPLY_DISABLE_MKT' && (
                                          <QuickReplyBtn
                                            errors={errors.buttons?.[index as never]}
                                            register={register}
                                            index={index}
                                            watch={watch}
                                            onDelete={() => buttonActionsArray.remove(index)}
                                            type={row.type}
                                          />
                                        )}
                                        {row.type === 'URL' && (
                                          <UrlBtn
                                            errors={errors.buttons?.[index as never]}
                                            register={register}
                                            index={index}
                                            watch={watch}
                                            onDelete={() => buttonActionsArray.remove(index)}
                                          />
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-xl-4">
                      <div
                        className="mb-5"
                        data-kt-sticky="true"
                        data-kt-sticky-name="docs-sticky-summary"
                        data-kt-sticky-top="220px"
                        data-kt-sticky-animation="true"
                        data-kt-sticky-zindex="95"
                      >
                        <div className={isDarkTheme ? 'wrap-iphone-wa-dark' : 'wrap-iphone-wa'}>
                          <div className={`${style.bg_whatsApp}`}>
                            <div className={`${style.container_message}`}>
                              <div className={`${style.sticky}`}>
                                <div className="mt-3 me-2">
                                  <div
                                    className={`${isDarkTheme ? style.preview_template_dark : style.preview_template}`}
                                  >
                                    {header.format.value === 'TEXT' && (
                                      <div
                                        className={`${isDarkTheme ? style.preview_template_dark_header : style.preview_template_header}`}
                                      >
                                        {watch('header.text')}
                                      </div>
                                    )}
                                    {header.format.value === 'IMAGE' && (
                                      <div
                                        className={`${isDarkTheme ? style.preview_template_dark_header : style.preview_template_header}`}
                                      >
                                        <div
                                          className={`${isDarkTheme ? style.preview_template_dark_image : style.preview_template_image}`}
                                          style={{ backgroundImage: `url(${imageSrc})` }}
                                        >
                                          {!imageSrc && <i className="bi-card-image"></i>}
                                        </div>
                                      </div>
                                    )}
                                    {header.format.value === 'VIDEO' && (
                                      <div
                                        className={`${isDarkTheme ? style.preview_template_dark_header : style.preview_template_header}`}
                                      >
                                        {' '}
                                        <div
                                          className={`${isDarkTheme ? style.preview_template_dark_video : style.preview_template_video}`}
                                        >
                                          {' '}
                                          <i className="bi-play-circle-fill"></i>
                                        </div>{' '}
                                      </div>
                                    )}
                                    {header.format.value === 'DOCUMENT' && (
                                      <div
                                        className={`${isDarkTheme ? style.preview_template_dark_header : style.preview_template_header}`}
                                      >
                                        {' '}
                                        <div
                                          className={`${isDarkTheme ? style.preview_template_dark_video : style.preview_template_video}`}
                                        >
                                          {' '}
                                          <i className="bi-filetype-pdf"></i>
                                        </div>{' '}
                                      </div>
                                    )}
                                    {header.format.value === 'LOCATIONS' && (
                                      <div
                                        className={`${isDarkTheme ? style.preview_template_dark_header : style.preview_template_header}`}
                                      >
                                        {' '}
                                        <div
                                          className={`${isDarkTheme ? style.preview_template_dark_video : style.preview_template_video}`}
                                        >
                                          {' '}
                                          <i className="bi-geo-alt"></i>
                                        </div>{' '}
                                      </div>
                                    )}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: body.replace(/\n/g, '<br/>')
                                      }}
                                    ></div>
                                    <div
                                      className={`${isDarkTheme ? style.preview_template_dark_footer : style.preview_template_footer}`}
                                    >
                                      {footer}
                                    </div>
                                  </div>
                                </div>
                                {buttons.buttons.map((btn: any, index) => (
                                  <div className="me-2">
                                    <div
                                      key={index}
                                      className={`${isDarkTheme ? style.preview_template_dark_btn : style.preview_template_btn} ${isDarkTheme ? style.preview_template_dark : style.preview_template}`}
                                    >
                                      {btn.type === 'URL' && (
                                        <>
                                          <i className="bi-box-arrow-up-right"></i> {btn.text}
                                        </>
                                      )}
                                      {btn.type === 'QUICK_REPLY' && (
                                        <>
                                          <i className="bi-reply"></i> {btn.text}
                                        </>
                                      )}
                                      {btn.type === 'QUICK_REPLY_DISABLE_MKT' && (
                                        <>
                                          <i className="bi-reply"></i> {btn.text}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-left pt-2">
                    <Button
                      type="submit"
                      typeStepperBtn="submit"
                      id="sendData"
                      className="btn btn-lg btn-primary w-325px mb-5 hover-scale"
                      disabled={!isValid || !watch('category')}
                      isLoading={isSubmitting}
                    >
                      {t('templates.wa.create.save-btn')}
                      <i className="ki-duotone ki-send ms-2 fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateTemplatesWa;
