import React from 'react';
import style from './loader-table.module.scss';
import { useTranslation } from 'react-i18next';
function LoaderModalTable() {
  const { t } = useTranslation();
  return (
    <div style={{ height: '400px' }}>
      <div className={`${style.loader}`}>
        <h3 className={style.textloader}>{t('general.loader')}</h3>
      </div>
    </div>
  );
}

export default LoaderModalTable;
