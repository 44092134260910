import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

declare const $: any;

interface DateHourProps {
  onDatesChange?: (date: moment.Moment) => void;
  optional?: boolean;
  label?: string;
  requiredIndicator?: string;
  errors?: any;
  sizeClass?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}

const DateHour = React.forwardRef<HTMLDivElement, DateHourProps>(
  (
    {
      onDatesChange,
      optional = false,
      label,
      requiredIndicator = '',
      errors,
      sizeClass = 'form-control-lg',
      value,
      placeholder,
      onChange,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const rangeElement = useRef<HTMLDivElement>(null);
    const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);

    useEffect(() => {
      if (!rangeElement.current) return;

      $(rangeElement.current).daterangepicker(
        {
          singleDatePicker: true,
          timePicker: true,
          timePicker24Hour: true,
          timePickerIncrement: 1,
          startDate: selectedDate ? selectedDate : moment(),
          locale: {
            format: 'YYYY-MM-DD H:mm',
            applyLabel: 'Aplicar',
            cancelLabel: 'Cancelar',
            customRangeLabel: 'Personalizar',
            daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            monthNames: [
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          },
          isInvalidDate: (date: moment.Moment) => date.isBefore(moment().startOf('day'))
        },
        (start: moment.Moment) => {
          setSelectedDate(start);
          if (onDatesChange) onDatesChange(start);
          if (onChange) onChange(start.format('YYYY-MM-DD H:mm'));
        }
      );

      return () => {
        if (rangeElement.current) {
          $(rangeElement.current).daterangepicker('destroy');
        }
      };
    }, [rangeElement, selectedDate, onDatesChange, onChange]);

    useEffect(() => {
      if (value && moment(value).isValid()) {
        setSelectedDate(moment(value));
      } else {
        setSelectedDate(null);
      }
    }, [value]);

    return (
      <>
        {label && (
          <label className={'form-label ' + requiredIndicator}>
            {label}{' '}
            {optional && <span className="fs-8 text-muted ms-1">({t('general.optional')})</span>}
          </label>
        )}
        <div className={`d-flex ${errors ? 'is-invalid' : ''}`}>
          <div
            title="date"
            className={`form-control form-control-solid ${sizeClass}`}
            ref={rangeElement}
            {...rest}
          >
            <span className="text-gray-600 fw-bold text-date">
              {selectedDate ? selectedDate.format('YYYY-MM-DD H:mm') : placeholder}
            </span>
          </div>
          {errors && <div className="invalid-feedback">{errors.message}</div>}
        </div>
      </>
    );
  }
);

export default DateHour;
