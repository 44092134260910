import { useEffect, useState } from 'react';
import { Areas } from '../../services/area.service';
import { useTranslation } from 'react-i18next';
import Header from '../../components/layout/header.component';
import Select from 'react-select';
import Asaid from '../../components/layout/asaid.component';
import Footer from '../../components/layout/footer.component';
import AddArea from './add-area.modal';
import useBgPages from '../../hooks/bg-pages.hook';
import Datatable from '../../components/datatable/datatable.component';
import { CompaniesService } from '../../services/companies.service';
import useRequest from '../../hooks/use-request.hook';

interface Data {
  _id: string;
  created_at: string;
  status: boolean;
}

function AreasPage() {
  useBgPages();
  const { t } = useTranslation();
  const { call, isRequesting } = useRequest();
  const [totalAreas, settotalAreas] = useState<number>(0);
  const [totalActivos, settotalActivos] = useState<number>(0);
  const [companies, setCompanies] = useState<{ value: string; label: string }[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [nameCompany, setNameCompany] = useState<string>('');
  const [areas, setAreas] = useState<Data[]>([]);

  const columns = [
    {
      name: t('areas-page.title-colum-1'),
      cell: (areas: any) => {
        return <h5>{areas.name}</h5>;
      }
    },
    {
      name: t('areas-page.title-colum-2'),
      cell: (areas: any) => {
        const fechaHora = new Date(areas.created_at);
        const fecha = fechaHora.toISOString().split('T')[0];
        const hora = fechaHora.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
        return (
          <div>
            <h5>
              {t('general.date')}: <span className="text-muted">{fecha}</span>
            </h5>
            <h5>
              {t('general.hour')}: <span className="text-muted">{hora}</span>
            </h5>
          </div>
        );
      }
    },
    {
      name: t('areas-page.title-colum-3'),
      cell: (areas: any) =>
        areas.status ? (
          <span className="badge badge-light-primary fs-6">
            <i className="ki-duotone ki-check-circle text-primary fs-3">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {t('general.active')}
          </span>
        ) : (
          <span className="badge badge-light-danger fs-6">
            <i className="ki-duotone ki-minus-circle text-danger fs-3">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {t('general.inactive')}
          </span>
        )
    }
  ];

  const fetchData = async (companyId?: string) => {
    try {
      const response = await call(() =>
        Areas.getAreas({ company: companyId || selectedCompanyId })
      );
      const areasData: Data[] = response;
      const activeAreas = areasData.filter((area) => area.status === true);

      settotalAreas(areasData.length);
      settotalActivos(activeAreas.length);
      setAreas(areasData);

      setSelectedCompanyId(companyId || selectedCompanyId);

      const selectedCompany = companies.find(
        (company) => company.value === (companyId || selectedCompanyId)
      );
      if (selectedCompany) {
        setNameCompany(selectedCompany.label);
      }
    } catch (error) {
      console.error(t('general.error'), error);
    }
  };

  const getCompanies = async () => {
    try {
      const data = await CompaniesService.getCompanies();
      const mappedCompanies = data.map((item: any) => ({
        value: item._id,
        label: item.name
      }));
      setCompanies(mappedCompanies);

      if (mappedCompanies.length > 0) {
        setSelectedCompanyId(mappedCompanies[0].value);
        setNameCompany(mappedCompanies[0].label);
        fetchData(mappedCompanies[0].value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [showModal, setShowModal] = useState({
    show: false
  });

  const handleShowModal = async (refresh: boolean) => {
    if (refresh) {
      await fetchData();
    }
    setShowModal({ show: false });
  };

  useEffect(() => {
    getCompanies();
  }, []);
  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">
                      {t('asaid.link-title-operations')}
                    </li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-data fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                      </i>
                      <span className="ms-3">{t('asaid.link-areas')}</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-4 mb-xl-10">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            {t('areas-page.title-estatistics')}
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('asaid.link-areas')} {nameCompany}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-category fs-1 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <div className="d-flex flex-stack mb-2 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-book-open fs-3x text-info w-35px me-6 hover-scale">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-info fw-bolder"
                                href="#"
                              >
                                {t('areas-page.item-our-areas')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('areas-page.item-our-subtitle-areas')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder ">
                            {totalAreas}
                          </span>
                        </div>
                        <div className="d-flex flex-stack mb-10 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-check-circle fs-3x text-primary w-35px me-6 hover-scale">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-primary fw-bolder"
                                href="#"
                              >
                                {t('areas-page.item-active')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('areas-page.item-our-subtitle-active')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder">
                            {totalActivos}
                          </span>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                          <a
                            href="#"
                            className="btn btn-primary hover-scale"
                            onClick={() => setShowModal({ show: true })}
                          >
                            <i className="ki-duotone ki-abstract-10 fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            {t('areas-page.btn-add-area')}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">{t('general.filters')}</span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('general.filters-company')}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-setting-4 fs-1 text-primary"></i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <label className="form-label">
                          {t('areas-page.modal-add-area.stepper-1-input2')}
                        </label>
                        <Select
                          options={companies}
                          noOptionsMessage={() => (
                            <div className="empty-select">{t('general.no-options')}</div>
                          )}
                          className="form-select-custom"
                          classNamePrefix="form-select-custom"
                          placeholder="Selecciona una empresa"
                          onChange={(selectedOption: { value: string; label: string } | null) => {
                            if (selectedOption) {
                              setSelectedCompanyId(selectedOption.value);
                              setNameCompany(selectedOption.label);
                              fetchData(selectedOption.value);
                            }
                          }}
                          value={companies.find((company) => company.value === selectedCompanyId)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 mb-5 mb-xl-10">
                    <div className="row g-5 g-xl-10 h-xxl-50 mb-0 mb-xl-10">
                      <div className="card mb-5 mb-xxl-8">
                        <div className="card-header align-items-center border-0 mt-5">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="fw-bolder fw-bold fs-2">{t('asaid.link-areas')}</span>
                            <span className="text-gray-400 mt-2 fw-semibold fs-6">
                              {t('areas-page.subtitle-table')}
                            </span>
                          </h3>
                          <div className="card-toolbar">
                            {' '}
                            <span className="fw-bolder fw-bold fs-2">{nameCompany}</span>
                          </div>
                        </div>
                        <div className="card-body pt-1">
                          <Datatable columns={columns} data={areas} isLoading={isRequesting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8"></div>
              </div>
            </div>
          </div>
          <Footer />
          <AddArea
            companies={companies}
            updateArea={(companyId: string) => fetchData(companyId)}
            show={showModal.show}
            onRequestClose={(refresh: any) => handleShowModal(refresh)}
          />
        </div>
      </div>
    </>
  );
}

export default AreasPage;
