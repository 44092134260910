import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ES from './languages/es.json';
import EN from './languages/en.json';
import ZH from './languages/zh.json';

const resources = {
  es: {
    translation: ES
  },
  en: {
    translation: EN
  },
  zh: {
    translation: ZH
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
