import React from 'react';
import { Handle, Position } from 'reactflow';

const CustomNodeProvider = ({ data }: any) => {
  const providerImages: any = {
    cm: '/assets/media/logos/cm.png',
    zorya: '/assets/media/logos/zorya.png',
    meta: '/assets/media/logos/meta.png',
    spark: '/assets/media/logos/spark.png',
    voices: '/assets/media/logos/voices.png'
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'process':
        return 'bg-light-primary border-primary';
      case 'restart':
        return 'alert-info-fow border-info';
      case 'paused':
        return 'bg-light-warning border-warning';
      case 'error':
        return 'alert-danger-fow border-danger';
      case 'pending':
        return 'bg-light';
      default:
        return 'bg-light';
    }
  };

  const getProviderImage = (label: string) => {
    return Object.keys(providerImages)
      .map((key) => ({ key, url: providerImages[key] }))
      .find(({ key }) => label.toLowerCase().includes(key))?.url;
  };

  const imageUrl = getProviderImage(data.label);

  return (
    <div
      className={`card p-0 h-md-100 mb-6 mb-md-0 border border-2 ${getStatusClass(data.status)}`}
    >
      <div className="card-header border-0">
        <div className="card-title">
          {imageUrl && <img src={imageUrl} alt={data.label} width="35" className="" />}
          <h1 className="fw-bold ms-1">{data.label}</h1>
        </div>
      </div>
      <Handle type="target" position={Position.Left} />
      <div className="card-body pt-0">
        <div className="fw-bold fs-2">
          <div className="fs-3 fw-bolder">
            {data.status === 'error'
              ? 'Verifica el problema.'
              : data.status === 'paused'
                ? 'Se ha pausado el envío.'
                : 'Proveedor operando correctamente.'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomNodeProvider;
