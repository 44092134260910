import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function DropLanguage() {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  const languages = [
    {
      value: 'es',
      label: t('languages.es'),
      flag: '/assets/media/flags/mexico.svg'
    },
    {
      value: 'en',
      label: t('languages.en'),
      flag: '/assets/media/flags/united-states.svg'
    },
    {
      value: 'zh',
      label: t('languages.zh'),
      flag: '/assets/media/flags/china.svg'
    }
  ];

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setCurrentLang(language);
    const event = new Event('languageChanged');
    window.dispatchEvent(event);
  };

  useEffect(() => {
    setCurrentLang(i18n.resolvedLanguage || 'es');
  }, [i18n.language]);

  const currentLanguage = languages.find((lang) => lang.value === currentLang) || languages[0];

  return (
    <div
      className="menu-item px-5"
      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
      data-kt-menu-placement="left-start"
      data-kt-menu-offset="-15px, 0"
    >
      <a className="menu-link px-5">
        <span className="menu-title position-relative">
          {t('header.title-2')}
          <span className="fs-8 rounded badge badge-light-primary px-3 py-2 position-absolute translate-middle-y top-50 end-0">
            {currentLanguage.label}
            <img
              className="w-15px h-15px bg-primary rounded-1 ms-2"
              src={currentLanguage.flag}
              alt=""
            />
          </span>
        </span>
      </a>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
        data-kt-menu="true"
      >
        {languages.map((language) => (
          <div key={language.value} className="menu-item px-3 my-0">
            <a
              className={`menu-link px-3 py-2 ${currentLang === language.value ? 'active' : ''}`}
              onClick={() => changeLanguage(language.value)}
            >
              <span className="menu-icon">
                <img
                  src={language.flag}
                  className="rounded-circle me-2"
                  height={'16px'}
                  title={`languages-${language.value}`}
                />
              </span>
              <span className="menu-title">{language.label}</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DropLanguage;
