import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Asaid from '../../components/layout/asaid.component';
import Header from '../../components/layout/header.component';
import Footer from '../../components/layout/footer.component';
import useBgPages from '../../hooks/bg-pages.hook';
import Datatable from '../../components/datatable/datatable.component';
import SemiDonutChart from '../../components/charts/semi-donut-chart.component';

interface Data {
  send_type: string[];
  _id: string;
  user: string;
  name: string;
  init_Date: string;
  finish_Date: string;
  creation_Date: string;
  status: string;
  created_at: string;
  updated_at: string;
}

type Metric = {
  label: string;
  total: number;
  color: string;
};

type Resume = {
  total_delivered: Metric;
  total_send: Metric;
  total_errors: Metric;
  total_invalid: Metric;
  total_process: Metric;
};

function LiveMonitoringPage() {
  useBgPages();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<Data[]>([]);

  const columns = [
    {
      name: 'Datos de campaña',
      sortable: true,
      width: 15,
      cell: (row: any) => {
        return (
          <div className="d-flex flex-column">
            <div className="mb-1">
              <div className="d-flex flex-column">
                <div className="mb-1">
                  <span className="d-flex align-items-center ms-1 fs-7">
                    <strong className="fs-7">{t('channels.sms.tc-name')}:</strong>
                    <br />
                    <span className="ms-1 fs-7">{row.name || t('general.no-info')}</span>
                  </span>
                </div>
                <div className="mb-1">
                  <span className="d-flex align-items-center ms-1 fs-7">
                    <strong className="fs-7">Usuario:</strong>
                    <span className="fs-7 ms-1">{row.user}</span>
                  </span>
                </div>
                <div className="mb-1">
                  <span className="d-flex align-items-center ms-1 fs-7">
                    <strong className="fs-7">Registro:</strong>
                    <span className="fs-7 ms-1"> {row.creation_Date || t('general.no-info')}</span>
                  </span>
                </div>
                <div className="mb-1">
                  <span className="d-flex align-items-center ms-1 fs-7">
                    <strong className="fs-7">Inicio:</strong>
                    <span className="fs-7 ms-1">{row.init_Date || t('general.no-info')}</span>
                  </span>
                </div>
                <div className="mb-1">
                  <span className="d-flex align-items-center ms-1 fs-7">
                    <strong className="fs-7">Fin:</strong>
                    <span className="fs-7 ms-1"> {row.finish_Date || t('general.no-info')}</span>
                  </span>
                </div>
                <div className="mb-1">
                  <span className="d-flex align-items-center ms-1 fs-7">
                    <strong className="fs-7">ID:</strong>
                    <span className="text-primary fw-bold  fs-7 ms-1">{row._id}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }
    },
    {
      name: 'Status',
      sortable: true,
      center: true,
      width: 25,
      cell: (row: any) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <span
              className={`badge badge-light-${row.status === 'error' ? 'danger' : 'primary'} fs-8`}
            >
              <i
                className={`ki-duotone ki-${row.status === 'error' ? 'cross-circle' : 'abstract-20 refresh-animate'} text-${row.status === 'error' ? 'danger' : 'primary'} fs-1`}
              >
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <span className="ms-2">{row.status === 'error' ? 'Error' : 'Procesando'}</span>
            </span>
          </div>
        );
      }
    },
    {
      name: 'Tipo de Envío',
      sortable: true,
      center: true,
      width: 20,
      cell: (row: any) => {
        const operators = [
          {
            name: 'wa',
            types: ['wa'],
            icon: (
              <i className="ki-duotone ki-whatsapp fs-3x mb-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            ),
            label: 'WhatsApp'
          },
          {
            name: 'sms',
            types: ['sms'],
            icon: (
              <i className="ki-duotone ki-message-notif fs-3x mb-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
              </i>
            ),
            label: 'SMS'
          },
          {
            name: 'rcs',
            types: ['rcs_basic', 'rcs_single', 'rcs_conversational'],
            icon: (
              <i className="ki-duotone ki-messages fs-3x mb-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            ),
            label: 'RCS'
          }
        ];

        const filteredOperators = operators.filter((operator) =>
          operator.types.some((type) => row.send_type.includes(type))
        );

        if (filteredOperators.length === 1) {
          const operator = filteredOperators[0];
          return (
            <div className="text-center">
              {operator.icon}
              <div>
                <a className="text-gray-800 fw-bold text-hover-primary fs-7">{operator.label}</a>
              </div>
            </div>
          );
        }

        if (filteredOperators.length > 1) {
          return (
            <div className="text-center p-3">
              <i className="ki-duotone ki-technology-4 fs-3x mb-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
                <span className="path6"></span>
                <span className="path7"></span>
              </i>
              <div>
                <a className="text-gray-800 fw-bold text-hover-primary fs-7">Multicanal</a>
              </div>
            </div>
          );
        }

        return null;
      }
    },
    {
      name: 'Metricas',
      sortable: false,
      center: true,
      cell: (row: any) => {
        const resume: Resume = {
          total_delivered: {
            label: 'Entregados',
            total: 0,
            color: '#579743'
          },
          total_send: {
            label: 'Enviados',
            total: 0,
            color: '#0070BC'
          },
          total_errors: {
            label: 'Errores',
            total: 0,
            color: '#F1416C'
          },
          total_invalid: {
            label: 'Invalidos',
            total: 0,
            color: '#575A5C'
          },
          total_process: {
            label: 'Procesando',
            total: 0,
            color: '#FAA916'
          }
        };

        Object.keys(row.metricas).forEach((key) => {
          switch (key) {
            case 'Entregados':
              resume.total_delivered.total = row.metricas[key];
              break;
            case 'Enviados':
              resume.total_send.total = row.metricas[key];
              break;
            case 'Errores':
              resume.total_errors.total = row.metricas[key];
              break;
            case 'Invalidos':
              resume.total_invalid.total = row.metricas[key];
              break;
            case 'Procesando':
              resume.total_process.total = row.metricas[key];
              break;
            default:
              break;
          }
        });

        return (
          <div>
            <SemiDonutChart
              datasetName="Resumen"
              dataset={{
                labels: [
                  resume.total_delivered.label,
                  resume.total_send.label,
                  resume.total_errors.label,
                  resume.total_invalid.label,
                  resume.total_process.label
                ],
                data: [
                  resume.total_delivered.total,
                  resume.total_send.total,
                  resume.total_errors.total,
                  resume.total_invalid.total,
                  resume.total_process.total
                ],
                backgroundColor: [
                  resume.total_delivered.color,
                  resume.total_send.color,
                  resume.total_errors.color,
                  resume.total_invalid.color,
                  resume.total_process.color
                ]
              }}
            />
          </div>
        );
      }
    },
    {
      name: t('general.acctions'),
      sortable: false,
      center: true,
      exportable: false,
      width: 30,
      cell: (row: any) => (
        <div>
          <button
            className="btn btn-sm btn-light-primary"
            onClick={() => {
              navigate('/campaigns/live-monitoring', {
                state: { data: row }
              });
            }}
          >
            <i className="bi-eye-fill fs-4 fw-bolder">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </i>
            Ver
          </button>
        </div>
      )
    }
  ];

  useEffect(() => {
    const data = [
      {
        send_type: ['wa', 'sms', 'rcs_basic', 'rcs_single', 'rcs_conversational'],
        channels: [
          {
            wa: {
              status: 'process',
              totalmsg: 145000,
              consumers: {
                0: {
                  status: 'process',
                  tasa: 6000
                }
              }
            },
            sms: {
              status: 'process',
              totalmsg: 121212,
              consumers: {
                0: {
                  status: 'process',
                  tasa: 6000
                }
              },
              providers: {
                0: {
                  _id: '98242166412424',
                  status: 'process',
                  name: 'Voices'
                }
              }
            },
            rcs_basic: {
              status: 'process',
              totalmsg: 34223,
              consumers: {
                0: {
                  status: 'process',
                  tasa: 2800
                }
              },
              providers: {
                0: {
                  _id: '98242166412424',
                  status: 'process',
                  name: 'Voices'
                }
              }
            },
            rcs_single: {
              status: 'process',
              totalmsg: 33333,
              consumers: {
                0: {
                  status: 'process',
                  tasa: 2800
                }
              },
              providers: {
                0: {
                  _id: '98242166412424',
                  status: 'process',
                  name: 'Voices'
                }
              }
            },
            rcs_conversational: {
              status: 'process',
              totalmsg: 4567,
              consumers: {
                0: {
                  status: 'process',
                  tasa: 2800
                }
              },
              providers: {
                0: {
                  _id: '98242166412424',
                  status: 'process',
                  name: 'Voices'
                }
              }
            }
          }
        ],
        metricas: {
          Entregados: 47680,
          Enviados: 89150,
          Errores: 54125,
          Invalidos: 88530,
          Procesando: 58850
        },
        totalmsg: 338335,
        _id: 'CH-828363',
        name: 'Cobranza_extrajudicial',
        user: 'superRoot',
        status: 'process',
        init_Date: '2024-08-07 02:35:00',
        finish_Date: '2024-08-07 03:35:45',
        creation_Date: '2024-08-07 09:20:04',
        created_at: '2024-07-11T23:31:28.430Z',
        updated_at: '2024-07-14T23:30:36.138Z'
      },
      {
        send_type: ['wa', 'sms'],
        channels: [
          {
            wa: {
              status: 'process',
              totalmsg: 234324,
              consumers: {
                0: {
                  status: 'process',
                  tasa: 2800
                }
              }
            },
            sms: {
              status: 'process',
              totalmsg: 12424,
              consumers: {
                0: {
                  status: 'process',
                  tasa: 2350
                }
              },
              providers: {
                0: {
                  _id: '98242166412424',
                  status: 'process',
                  name: 'Voices'
                }
              }
            }
          }
        ],
        metricas: {
          Entregados: 45320,
          Enviados: 61240,
          Errores: 0,
          Invalidos: 72150,
          Procesando: 29900
        },
        _id: 'CH-828233',
        name: 'tarjeta_crédito',
        user: 'superRoot',
        status: 'process',
        totalmsg: 208610,
        init_Date: '2024-08-07 08:30:00',
        finish_Date: '2024-08-07 09:30:00',
        creation_Date: '2024-08-05 12:00:00',
        created_at: '2024-07-11T23:31:28.430Z',
        updated_at: '2024-07-11T23:31:28.430Z'
      },
      {
        send_type: ['sms'],
        channels: [
          {
            sms: {
              status: 'process',
              totalmsg: 246748,
              consumers: {
                0: {
                  status: 'error',
                  tasa: 5300
                }
              },
              providers: {
                0: {
                  _id: '2832535235235',
                  status: 'process',
                  name: 'Voices'
                }
              }
            }
          }
        ],
        metricas: {
          Entregados: 37220,
          Enviados: 52135,
          Errores: 7600,
          Invalidos: 45600,
          Procesando: 63958
        },
        _id: 'CH-43543',
        name: 'NU_campaña',
        user: 'superRoot',
        status: 'process',
        totalmsg: 206513,
        init_Date: '2024-08-07 04:30:00',
        finish_Date: '2024-08-07 09:30:00',
        creation_Date: '2024-08-05 07:00:00',
        created_at: '2024-07-11T23:31:28.430Z',
        updated_at: '2024-07-11T23:31:28.430Z'
      }
    ];

    setCampaigns(data);
  }, []);

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-7 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">{t('campaigns.campaigns-title')}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-abstract-27 fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <span className="pulse-ring"></span>
                      <span className="ms-2">Monitoreo</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-7 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="card mb-5 mb-xl-10">
                  <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <div className="me-7 mb-4">
                        <i className="ki-duotone ki-chart-pie-3 fs-4x hover-scale me-2 mt-10">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                        </i>
                      </div>
                      <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-4">
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                              <a
                                className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                                href="#"
                              >
                                {t('general.estatistics')}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap flex-stack">
                          <div className="d-flex flex-column flex-grow-1 pe-8">
                            <div className="d-flex flex-wrap">
                              <div className="alert alert-dismissible bg-gray-200 border-dashed border-gray-500 rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <i className="ki-duotone ki-book-open fs-2x hover-scale me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                  </i>
                                  <div className="fs-2 fw-bold">3</div>
                                </div>
                                <div className="fw-bolder fs-4 text-gray-700">Total</div>
                              </div>
                              <div className="alert alert-dismissible bg-light-primary border-dashed border-primary rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <i className="ki-duotone ki-abstract-20 fs-2x hover-scale me-2 text-primary refresh-animate">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                  <div className="fs-2 fw-bold">3</div>
                                </div>
                                <div className="fw-bolder fs-4 text-primary">Procesando</div>
                              </div>
                              <div className="alert alert-dismissible bg-light-danger border-dashed border-danger rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <i className="ki-duotone ki-cross-circle fs-2x hover-scale me-2 text-danger">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                  <div className="fs-2 fw-bold">0</div>
                                </div>
                                <div className="fw-bolder fs-4 text-danger">Canceladas</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bold">
                      <li className="nav-item"></li>
                      <li className="nav-item"></li>
                    </ul>
                  </div>
                </div>
                <div className="row g-xl-12">
                  <div className="col-xl-12 mb-5">
                    <div className="card">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">Campañas</span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-7">Listado</span>
                        </h3>
                      </div>
                      <div className="card-body pt-1">
                        <Datatable
                          columns={columns}
                          data={campaigns}
                          // data={areas} isLoading={isRequesting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default LiveMonitoringPage;
