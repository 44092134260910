import { useEffect, useState } from 'react';
import { useForm, Controller, useWatch, set } from 'react-hook-form';
import { Dropzone } from '@dropzone-ui/react';
import { useTranslation } from 'react-i18next';
import { CampaignsService } from '../../services/campaings.service';
import { AlertService } from '../../services/alert.service';
import Header from '../../components/layout/header.component';
import Asaid from '../../components/layout/asaid.component';
import Footer from '../../components/layout/footer.component';
import useBgPages from '../../hooks/bg-pages.hook';
import Input from '../../components/forms/input.component';
import Button from '../../components/forms/button.component';
import Switch from '../../components/forms/switch.component';
import DateHour from '../../components/forms/date-hour.component';
import TextArea from '../../components/forms/text-area.component';
import TimePicker from '../../components/forms/time-picker.component';
import Select from 'react-select';
import InvalidFeedback from '../../components/forms/invalid-feedback.component';

declare const KTComponents: any;

function CampaignsPage() {
  useBgPages();
  const { t } = useTranslation();
  const [blacklistList, setBlacklistList] = useState<{ value: string; label: string }[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [typeMessage, setTypeMessage] = useState<any>('');
  const [typeMessageFirst, setTypeMessageFirst] = useState<any>('');
  const [typeMessageSecond, setTypeMessageSecond] = useState<any>('');
  const [typeRCS, setTypeRCS] = useState('');
  const [typeRCSFirst, setTypeRCSFirst] = useState('');
  const [typeRCSSecond, setTypeRCSSecond] = useState('');
  const [totalCredits, setTotalCredits] = useState(0);
  const [numberPreview, setNumberPreview] = useState('');
  const [nameChannel, setNameChannel] = useState('');
  const [nameChannelFirst, setNameChannelFisrt] = useState('');
  const [nameChannelSecond, setNameChannelSecond] = useState('');
  const [timeOptions, setTimeOptions] = useState<any>([]);
  const [promgramConfig, setPromgramConfig] = useState({
    programing: false,
    dosing: false
  });
  const [alternativeConfig, setAlternativeConfig] = useState({
    alternativeChannel: false,
    timeWait: false,
    timeWaitSecond: false,
    secondAlternative: false
  });

  const defaultValues = {
    name: '',
    headers: '',
    dateHour: '',
    timeZone: '',
    initHour: '',
    finishHour: '',
    programing: false,
    dosing: false,
    uniqueSend: false,
    blacklist: '',
    columnChannel: '',
    typeSend: '',
    // config sms
    msgSMS: '',
    acentos: false,
    // config wa
    templatesWA: '',
    // config rcs_basic
    templatesBasic: '',
    // config rcs_single
    templatesSingle: '',
    // config rcs_conversational
    templatesConversational: '',
    //Telegram
    msgTelegram: '',
    //Voz
    audioVoz: '',
    //Alternative channel
    timeOption: '',
    alternativeFirst: '',
    alternativeSecond: '',
    timeOptionSecond: '',
    alternativeThird: '',
    alternativeFourth: ''
  };

  const optionsHeaders = [
    { value: 'numero', label: 'Número' },
    { value: 'nombre', label: 'Nombre' },
    { value: 'precio', label: 'Precio' },
    { value: 'canal', label: 'Canal' }
  ];

  const typesSend = [
    { value: 'wa', label: 'WhatsApp' },
    { value: 'rcs_basic', label: 'RCS Basic' },
    { value: 'rcs_single', label: 'RCS Single' },
    { value: 'rcs_conversational', label: 'RCS Conversacional' },
    { value: 'sms', label: 'SMS' }
  ];

  const [filteredTypesSend, setFilteredTypesSend] = useState(typesSend);
  const [filteredTypesSendSecond, setFilteredTypesSendSecond] = useState(typesSend);
  const [filteredTypesSendThird, setFilteredTypesSendThird] = useState(typesSend);

  const templatesWA = [
    { value: '1', label: 'plantilla_header_video' },
    { value: '2', label: 'plantilla_header_location' },
    { value: '3', label: 'plantilla_header_documento' },
    { value: '4', label: 'plantilla_header_texto' }
  ];

  const templatesBasic = [{ value: '1', label: 'Encuesta Baz 23-07-2024' }];
  const templatesSingle = [{ value: '1', label: 'Promo_verano_2024' }];
  const templatesConversational = [{ value: '1', label: '3269-RegresoAclases-190724' }];

  const zone = [
    { value: 'America/Tijuana', label: '(UTC-8:00) Tijuana, Baja California' },
    {
      value: 'America/Sinaloa',
      label: '(UTC-7:00) Hermosillo, Sonora, Mazatlán, Sinaloa, Tepic, Nayarit'
    },
    { value: 'America/Mexico_City', label: '(UTC-6:00) Ciudad de México, Guadalajara, Monterrey' },
    { value: 'America/Cancun', label: '(UTC-5:00) Cancún, Quintana Roo' }
  ];

  const {
    register,
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const selectedTypes = useWatch({ control, name: 'typeSend' });
  const showWhatsAppConfig = selectedTypes.includes('wa');
  const showSMSConfig = selectedTypes.includes('sms');
  const showBasicConfig = selectedTypes.includes('basic');
  const showSingleConfig = selectedTypes.includes('single');
  const showConversationalConfig = selectedTypes.includes('conversational');
  const showVozConfig = selectedTypes.includes('voz');

  const RalternativeFirst = useWatch({ control, name: 'alternativeFirst' });
  const RshowWhatsAppConfig = RalternativeFirst.includes('wa');
  const RshowSMSConfig = RalternativeFirst.includes('sms');
  const RshowBasicConfig = RalternativeFirst.includes('basic');
  const RshowSingleConfig = RalternativeFirst.includes('single');
  const RshowConversationalConfig = RalternativeFirst.includes('conversational');
  const RshowVozConfig = RalternativeFirst.includes('voz');

  const RalternativeSecond = useWatch({ control, name: 'alternativeSecond' });
  const RSecondshowWhatsAppConfig = RalternativeSecond.includes('wa');
  const RSecondshowSMSConfig = RalternativeSecond.includes('sms');
  const RSecondshowBasicConfig = RalternativeSecond.includes('basic');
  const RSecondshowSingleConfig = RalternativeSecond.includes('single');
  const RSecondshowConversationalConfig = RalternativeSecond.includes('conversational');
  const RSecondshowVozConfig = RalternativeSecond.includes('voz');

  const msgSMS = watch('msgSMS');
  const acentosCheck = watch('acentos');
  const acentosPattern = acentosCheck ? /^(.*)$/ : /^[^\u00C0-\u017F]*$/;

  const updateFiles = (incomingFiles: any[]) => {
    const allowedExtensions = ['.zip', '.txt', '.csv'];

    if (incomingFiles.length > 0) {
      const incomingFileExtension = incomingFiles[0].name.split('.').pop();

      if (!allowedExtensions.includes(`.${incomingFileExtension}`)) {
        AlertService.toastError('Solo se permiten archivos con extensión .zip, .txt o .csv');
      } else {
        setFiles([incomingFiles[0]]);
      }
    } else {
      setFiles([]);
    }
  };

  const removeFile = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
    setFiles([]);
    setValue('headers', '');
  };

  const blacklist = async () => {
    try {
      const data = await CampaignsService.getBlacklist();
      const mappedBlacklist = data.map((item: any) => ({
        value: item._id,
        label: item.name
      }));
      setBlacklistList(mappedBlacklist);
    } catch (error: any) {
      console.log(error);
      AlertService.toastError(t('general.atention'), error);
    }
  };

  const sendCampaign = async (data: any) => {
    const confirmed = await AlertService.question(
      '¿Deseas enviar esta campaña?',
      'Te recomendamos revisar nuevamente tu información.',
      () => Promise.resolve(),
      'Enviar'
    );

    if (!confirmed) return;

    try {
      setFiles([]);
      reset(defaultValues);
      setTypeRCS('');
      setTotalCredits(0);
      setNumberPreview('');
      setPromgramConfig({
        programing: false,
        dosing: false
      });
      setAlternativeConfig({
        alternativeChannel: false,
        secondAlternative: false,
        timeWait: false,
        timeWaitSecond: false
      });
      AlertService.toastSuccess('¡ Éxito !, Enviando campaña.');
      console.log(data);
    } catch (error) {
      console.error(error);
      AlertService.toastError('¡ Atención !, hubo un problema al enviar tu campaña: ' + error);
    }
  };

  useEffect(() => {
    const options = [];
    for (let i = 5; i <= 60; i += 5) {
      options.push({ value: i, label: ` ${i} ${t('minutos')}` });
    }
    setTimeOptions(options);
  }, [alternativeConfig.timeWait]);

  useEffect(() => {
    const checkTheme = () => {
      const isDark = document.documentElement.getAttribute('data-bs-theme') === 'dark';
      setIsDarkTheme(isDark);
    };
    checkTheme();
    const observer = new MutationObserver(() => checkTheme());
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-bs-theme']
    });
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const calculateCredits = () => {
      const msgLength = msgSMS ? msgSMS.length : 0;
      const credits = acentosCheck ? Math.ceil(msgLength / 65) : Math.ceil(msgLength / 160);
      setTotalCredits(credits);
    };

    calculateCredits();
  }, [msgSMS, acentosCheck]);

  const selectedTypeSend = watch('typeSend');
  const selectedAlternativeFirst = watch('alternativeFirst');
  const selectedAlternativeSecond = watch('alternativeSecond');

  useEffect(() => {
    const rcsTypes = ['rcs_basic', 'rcs_single', 'rcs_conversational'];

    const isRcsSelected = () => {
      return (
        rcsTypes.includes(selectedTypeSend) ||
        rcsTypes.includes(selectedAlternativeFirst) ||
        rcsTypes.includes(selectedAlternativeSecond)
      );
    };

    const filterTypesForFirstSelect = () => {
      return typesSend.filter((type) => {
        if (type.value === selectedTypeSend) return false;
        if (isRcsSelected() && rcsTypes.includes(type.value)) return false;
        return true;
      });
    };

    const filterTypesForSecondSelect = (remainingOptions: any) => {
      return remainingOptions.filter((type: any) => {
        if (type.value === selectedAlternativeFirst) return false;
        if (isRcsSelected() && rcsTypes.includes(type.value)) return false;
        return true;
      });
    };

    const filterTypesForThirdSelect = (remainingOptions: any) => {
      return remainingOptions.filter((type: any) => {
        if ([selectedTypeSend, selectedAlternativeFirst].includes(type.value)) return false;
        if (isRcsSelected() && rcsTypes.includes(type.value)) return false;
        return true;
      });
    };
    const remainingForSecondSelect = filterTypesForSecondSelect(filterTypesForFirstSelect());
    const remainingForThirdSelect = filterTypesForThirdSelect(remainingForSecondSelect);

    setFilteredTypesSend(filterTypesForFirstSelect());
    setFilteredTypesSendSecond(remainingForSecondSelect);
    setFilteredTypesSendThird(remainingForThirdSelect);
  }, [selectedTypeSend, selectedAlternativeFirst, selectedAlternativeSecond]);

  const useHandleTypeMessageChange = (type: string, setTypeRCS: (type: string) => void) => {
    useEffect(() => {
      const handleTypeMessageChange = () => {
        if (type !== 'wa') {
          setValue('templatesWA', '');
          setNumberPreview('');
        }

        switch (type) {
          case 'rcs_basic':
            setTypeRCS('basic');
            break;
          case 'rcs_single':
            setTypeRCS('single');
            break;
          case 'rcs_conversational':
            setTypeRCS('conversational');
            break;
          default:
            setTypeRCS('');
        }

        if (type !== 'sms') {
          setValue('msgSMS', '');
        }
        if (type !== 'telegram') {
          setValue('msgTelegram', '');
        }
      };

      handleTypeMessageChange();
    }, [type, setTypeRCS]);
  };

  useHandleTypeMessageChange(typeMessage, setTypeRCS);
  useHandleTypeMessageChange(typeMessageFirst, setTypeRCSFirst);
  useHandleTypeMessageChange(typeMessageSecond, setTypeRCSSecond);

  useEffect(() => {
    blacklist();
  }, []);

  const getChannelName = (types: any) => {
    if (types.includes('wa')) {
      return 'WhatsApp';
    } else if (types.includes('sms')) {
      return 'SMS';
    } else if (types.includes('basic')) {
      return 'RCS Basic';
    } else if (types.includes('single')) {
      return 'RCS Single';
    } else if (types.includes('conversational')) {
      return 'RCS Conversational';
    } else if (types.includes('voz')) {
      return 'Voz';
    } else {
      return 'el canal que selecciones';
    }
  };

  useEffect(() => {
    setNameChannel(getChannelName(selectedTypes));
  }, [selectedTypes]);

  useEffect(() => {
    setNameChannelFisrt(getChannelName(RalternativeFirst));
  }, [RalternativeFirst]);

  useEffect(() => {
    setNameChannelSecond(getChannelName(RalternativeSecond));
  }, [RalternativeSecond]);

  KTComponents.init();

  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Asaid />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">{t('campaigns.campaigns-title')}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-send fs-2x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <span className="ms-2">{t('campaigns.send-campaigns')}</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row gy-5 g-xl-10">
                  <form className="form" onSubmit={handleSubmit(sendCampaign)}>
                    <div className="row mb-7">
                      <div className="col-md-12">
                        <div className="card mb-5">
                          <div className="card-header align-items-center border-0 mt-5">
                            <label className="d-flex flex-stack mb-10">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-circle symbol-50px me-6">
                                  <span className="symbol-label bg-light-primary">
                                    <i className="ki-duotone ki-abstract-26 fs-3x text-primary">
                                      <span className="path1" />
                                      <span className="path2" />
                                    </i>
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <h2 className="fw-bold">
                                    {t('campaigns.general-data')}
                                    <span className="required"></span>
                                  </h2>
                                  <div className="text-muted fw-semibold fs-6">
                                    {t('campaigns.general-data-description')}
                                  </div>
                                </span>
                              </span>
                            </label>
                            <div className="card-toolbar"></div>
                          </div>
                          <div className="card-body pt-1">
                            <div className="row">
                              <div className="col-md-6 mt-4">
                                {files.length === 0 ? (
                                  <Dropzone
                                    onChange={updateFiles}
                                    value={files}
                                    className="dropzone cursor-default"
                                    accept=".zip,.txt,.csv"
                                    maxFiles={1}
                                  >
                                    <div className="dz-mkssage">
                                      <>
                                        <i className="ki-duotone ki-file-up fs-5x text-primary me-4">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                        <div className="d-flex flex-center flex-grow-1 flex-wrap flex-md-nowrap">
                                          <div className="mb-3 mb-md-0 fw-semibold">
                                            <h5 className="text-gray-900 fw-bold">
                                              {t('campaigns.upload-file')}
                                            </h5>
                                            <div className="fs-7 pe-7 text-info">
                                              {t('campaigns.upload-file-description')}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </Dropzone>
                                ) : (
                                  <div className="dropzone cursor-default">
                                    <div className="dz-g">
                                      <i className="ki-duotone ki-file-added text-primary fs-5x text-primary me-4">
                                        <span className="path1" />
                                        <span className="path2" />
                                      </i>
                                      <div className="d-flex flex-column align-items-center flex-grow-1">
                                        <div className="mb-3 mb-md-0 fw-semibold text-center">
                                          <h5 className="text-gray-900 fw-bold">
                                            {t('campaigns.uploaded-file-success')}
                                          </h5>
                                          <div className="fs-7 text-info">{files[0]?.name}</div>
                                        </div>
                                        <a
                                          className="btn btn-danger px-6 text-nowrap btn-sm mt-3"
                                          href="#"
                                          onClick={removeFile}
                                        >
                                          {t('general.delete')}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="fv-row mb-7">
                                  <Input
                                    label={t('campaigns.name-label')}
                                    requiredIndicator="required"
                                    placeholder={t('campaigns.name-placeholder')}
                                    errors={errors.name}
                                    {...register('name', { required: true })}
                                  />
                                </div>
                                <div className="fv-row">
                                  <label className="form-label required">
                                    {t('campaigns.column-number')}
                                  </label>
                                  <Controller
                                    control={control}
                                    name="headers"
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error }
                                    }) => (
                                      <>
                                        <Select
                                          options={optionsHeaders}
                                          noOptionsMessage={() => (
                                            <div className="empty-select">
                                              {t('general.no-options')}
                                            </div>
                                          )}
                                          className={
                                            error
                                              ? 'form-select-custom is-invalid'
                                              : 'form-select-custom'
                                          }
                                          classNamePrefix="form-select-custom"
                                          placeholder={t('campaigns.column-number-placeholder')}
                                          onChange={(
                                            selectedOption: { value: string; label: string } | null
                                          ) => {
                                            onChange(selectedOption ? selectedOption.value : null);
                                          }}
                                          value={
                                            optionsHeaders.find(
                                              (option: { value: string; label: string }) =>
                                                option.value === value
                                            ) || null
                                          }
                                        />
                                        <InvalidFeedback error={error} />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-8">
                      <div className="col-md-12">
                        <div className="card mb-5">
                          <div className="card-header align-items-center border-0 mt-5">
                            <label className="d-flex flex-stack mb-10">
                              <span className="d-flex align-items-center me-2">
                                <span className="symbol symbol-circle symbol-50px me-6">
                                  <span className="symbol-label bg-light-info">
                                    <i className="ki-duotone ki-gear fs-3x text-info">
                                      <span className="path1" />
                                      <span className="path2" />
                                    </i>
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <h2 className="fw-bold">
                                    {t('campaigns.scheduling-title')}
                                    <span className="required"></span>
                                  </h2>
                                  <div className="text-muted fw-semibold fs-6">
                                    {t('campaigns.scheduling-description')}
                                  </div>
                                </span>
                              </span>
                            </label>
                            <div className="card-toolbar"></div>
                          </div>
                          <div className="card-body pt-1">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="fv-row mb-10">
                                  <label className="d-flex flex-stack cursor-pointer mb-5">
                                    <span className="d-flex align-items-center me-2">
                                      <span className="symbol me-6 symbol-label">
                                        <i className="ki-duotone ki-calendar fs-2x">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fw-bold fs-6">
                                          {t('campaigns.schedule-switch-title')}
                                        </span>
                                        <span className="fs-7 text-muted">
                                          {t('campaigns.schedule-switch-description')}
                                        </span>
                                      </span>
                                    </span>
                                    <span className="form-check form-check-custom form-check-solid">
                                      <Switch
                                        name="programing"
                                        checked={promgramConfig.programing}
                                        value="1"
                                        onChange={() =>
                                          setPromgramConfig((prevConfig) => ({
                                            ...prevConfig,
                                            programing: !prevConfig.programing
                                          }))
                                        }
                                      />
                                    </span>
                                  </label>
                                  {promgramConfig.programing && (
                                    <>
                                      <div className="row mb-1">
                                        <div className="col-6 fv-row">
                                          <Controller
                                            control={control}
                                            name="dateHour"
                                            rules={{ required: t('campaigns.required') }}
                                            render={({ field }) => (
                                              <DateHour
                                                label={t('campaigns.date-time')}
                                                requiredIndicator="required"
                                                errors={errors.dateHour}
                                                placeholder={t('campaigns.date-time-placeholder')}
                                                value={field.value}
                                                onChange={field.onChange}
                                              />
                                            )}
                                          />
                                        </div>
                                        <div className="col-6 fv-row">
                                          <label className="form-label required">
                                            {t('campaigns.time-zone')}
                                          </label>
                                          <Controller
                                            control={control}
                                            name="timeZone"
                                            rules={{ required: true }}
                                            render={({
                                              field: { onChange, value },
                                              fieldState: { error }
                                            }) => (
                                              <>
                                                <Select
                                                  options={zone}
                                                  noOptionsMessage={() => (
                                                    <div className="empty-select">
                                                      {t('general.no-options')}
                                                    </div>
                                                  )}
                                                  className={
                                                    error
                                                      ? 'form-select-custom is-invalid'
                                                      : 'form-select-custom'
                                                  }
                                                  classNamePrefix="form-select-custom"
                                                  placeholder={t('campaigns.time-zone-placeholder')}
                                                  onChange={(
                                                    selectedOption: {
                                                      value: string;
                                                      label: string;
                                                    } | null
                                                  ) => {
                                                    onChange(
                                                      selectedOption ? selectedOption.value : null
                                                    );
                                                  }}
                                                  value={
                                                    zone.find(
                                                      (option: { value: string; label: string }) =>
                                                        option.value === value
                                                    ) || null
                                                  }
                                                />
                                                <InvalidFeedback error={error} />
                                              </>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="fv-row mb-10">
                                  <label className="d-flex flex-stack cursor-pointer mb-5">
                                    <span className="d-flex align-items-center me-2">
                                      <span className="symbol me-6 symbol-label">
                                        <i className="ki-duotone ki-time fs-2x">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fw-bold fs-6">
                                          {t('campaigns.dosage-switch-title')}
                                        </span>
                                        <span className="fs-7 text-muted">
                                          {t('campaigns.dosage-switch-description')}
                                        </span>
                                      </span>
                                    </span>
                                    <span className="form-check form-check-custom form-check-solid">
                                      <Switch
                                        name="dosing"
                                        checked={promgramConfig.dosing}
                                        value="1"
                                        onChange={() =>
                                          setPromgramConfig((prevConfig) => ({
                                            ...prevConfig,
                                            dosing: !prevConfig.dosing
                                          }))
                                        }
                                      />
                                    </span>
                                  </label>
                                  {promgramConfig.dosing && (
                                    <>
                                      <div className="row">
                                        <div className="col-md-6 mb-7">
                                          <Controller
                                            control={control}
                                            name="initHour"
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                              <TimePicker
                                                requiredIndicator="required"
                                                {...field}
                                                label="Hora inicio"
                                                placeholder="Selecciona una hora inicio"
                                                time={field.value}
                                                onChange={field.onChange}
                                                disable={false}
                                                errors={errors.initHour}
                                              />
                                            )}
                                          />
                                        </div>
                                        <div className="col-md-6 mb-7">
                                          <Controller
                                            control={control}
                                            name="finishHour"
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                              <TimePicker
                                                requiredIndicator="required"
                                                {...field}
                                                label="Hora Final"
                                                placeholder="Selecciona una hora Final"
                                                time={field.value}
                                                onChange={field.onChange}
                                                disable={false}
                                                errors={errors.initHour}
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="row mb-10">
                                  <label className="d-flex flex-stack cursor-pointer mb-0">
                                    <span className="d-flex align-items-center me-2">
                                      <span className="symbol me-6 symbol-label">
                                        <i className="ki-duotone ki-send fs-2x">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fw-bold fs-6">Envíos únicos</span>
                                        <span className="fs-7 text-muted">
                                          Activalo si desea que sean envíos únicos
                                        </span>
                                      </span>
                                    </span>
                                    <span className="form-check form-check-custom form-check-solid">
                                      <Switch {...register('uniqueSend')} />
                                    </span>
                                  </label>
                                </div>
                                <div className="row mb-10">
                                  <label className="form-label">
                                    Blacklist
                                    <span className="fs-8 text-muted ms-1">
                                      ({t('general.optional')})
                                    </span>
                                  </label>
                                  <Controller
                                    control={control}
                                    name="blacklist"
                                    rules={{ required: false }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error }
                                    }) => (
                                      <>
                                        <Select
                                          options={blacklistList}
                                          noOptionsMessage={() => (
                                            <div className="empty-select">
                                              {t('general.no-options')}
                                            </div>
                                          )}
                                          className="form-select-custom"
                                          isClearable
                                          classNamePrefix="form-select-custom"
                                          placeholder="Elige una blacklist"
                                          onChange={(
                                            selectedOption: { value: string; label: string } | null
                                          ) => {
                                            onChange(selectedOption ? selectedOption.value : null);
                                          }}
                                          value={
                                            blacklistList.find(
                                              (option: { value: string; label: string }) =>
                                                option.value === value
                                            ) || null
                                          }
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {/* Canal Principal */}
                        <div className="col-md-12 card mb-15">
                          <div className="row mb-5 mb-xxl-8">
                            <div className="col-md-8">
                              <div className="card-header align-items-center border-0 mt-5">
                                <label className="d-flex flex-stack mb-10">
                                  <span className="d-flex align-items-center me-2">
                                    <span className="symbol symbol-circle symbol-50px me-6">
                                      <span className="symbol-label bg-light-primary">
                                        <i className="ki-duotone ki-send fs-3x text-primary">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                        </i>
                                      </span>
                                    </span>
                                    <span className="d-flex flex-column">
                                      <h2 className="fw-bold">
                                        Canal principal
                                        <span className="required"></span>
                                      </h2>
                                      <div className="text-muted fw-semibold fs-6">
                                        Selecciona el canal principal para el envío de tu campaña.
                                      </div>
                                    </span>
                                  </span>
                                </label>
                                <div className="card-toolbar"></div>
                              </div>
                              <div className="card-body pt-1">
                                <div className="fv-row mb-15">
                                  <label className="form-label required">Canal de envío</label>
                                  <Controller
                                    control={control}
                                    name="typeSend"
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error }
                                    }) => (
                                      <>
                                        <Select
                                          options={typesSend}
                                          className="form-select-custom"
                                          classNamePrefix="form-select-custom"
                                          placeholder="Selecciona el tipo de envío"
                                          noOptionsMessage={() => (
                                            <div className="empty-select">
                                              {t('general.no-options')}
                                            </div>
                                          )}
                                          onChange={(selectedOption) => {
                                            onChange(selectedOption ? selectedOption.value : null);
                                            setTypeMessage(
                                              selectedOption ? selectedOption.value : null
                                            );
                                          }}
                                          value={
                                            typesSend.find((option) => option.value === value) ||
                                            null
                                          }
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                                {showWhatsAppConfig && (
                                  <>
                                    <div className="separator separator-content my-15 text-muted">
                                      Configuración
                                    </div>
                                    <div className="align-items-center border-0 mt-10">
                                      <div className="d-flex">
                                        <label className="d-flex flex-stack mb-3">
                                          <span className="d-flex align-items-center me-2">
                                            <span className="symbol symbol-circle symbol-50px me-6">
                                              <span className="symbol-label">
                                                <i className="ki-duotone ki-whatsapp fs-3x">
                                                  <span className="path1" />
                                                  <span className="path2" />
                                                </i>
                                              </span>
                                            </span>
                                            <span className="d-flex flex-column">
                                              <h2 className="fw-bold">
                                                WhatsApp
                                                <span className="required"></span>
                                              </h2>
                                              <div className="text-muted fw-semibold fs-6">
                                                Configura tu canal
                                              </div>
                                            </span>
                                          </span>
                                        </label>
                                      </div>
                                      <div className="col pt-1">
                                        <div className="fv-row mb-7">
                                          <label className="form-label required">Templates</label>
                                          <Controller
                                            control={control}
                                            name="templatesWA"
                                            rules={{ required: true }}
                                            render={({
                                              field: { onChange, value },
                                              fieldState: { error }
                                            }) => (
                                              <>
                                                <Select
                                                  options={templatesWA}
                                                  noOptionsMessage={() => (
                                                    <div className="empty-select">
                                                      {t('general.no-options')}
                                                    </div>
                                                  )}
                                                  className="form-select-custom"
                                                  classNamePrefix="form-select-custom"
                                                  placeholder="Selecciona una template"
                                                  onChange={(selectedOption) => {
                                                    const selectedValue = selectedOption
                                                      ? selectedOption.value
                                                      : '';
                                                    onChange(selectedValue);
                                                    setNumberPreview(selectedValue);
                                                  }}
                                                  value={
                                                    templatesWA.filter((option) =>
                                                      value.includes(option.value)
                                                    ) || []
                                                  }
                                                />
                                                <InvalidFeedback error={error} />
                                              </>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {showBasicConfig && (
                                  <>
                                    <div className="separator separator-content my-15 text-muted">
                                      Configuración
                                    </div>
                                    <div className="align-items-center border-0 mt-10">
                                      <div className="d-flex">
                                        <label className="d-flex flex-stack mb-3">
                                          <span className="d-flex align-items-center me-2">
                                            <span className="symbol symbol-circle symbol-50px me-6">
                                              <span className="symbol-label">
                                                <i className="ki-duotone ki-messages fs-3x">
                                                  <span className="path1" />
                                                  <span className="path2" />
                                                </i>
                                              </span>
                                            </span>
                                            <span className="d-flex flex-column">
                                              <h2 className="fw-bold">
                                                RCS Basic
                                                <span className="required"></span>
                                              </h2>
                                              <div className="text-muted fw-semibold fs-6">
                                                Configura tu canal
                                              </div>
                                            </span>
                                          </span>
                                        </label>
                                      </div>
                                      <div className="col pt-1">
                                        <div className="fv-row mb-7">
                                          <label className="form-label required">Templates</label>
                                          <Controller
                                            control={control}
                                            name="templatesBasic"
                                            rules={{ required: true }}
                                            render={({
                                              field: { onChange, value },
                                              fieldState: { error }
                                            }) => (
                                              <>
                                                <Select
                                                  options={templatesBasic}
                                                  noOptionsMessage={() => (
                                                    <div className="empty-select">
                                                      {t('general.no-options')}
                                                    </div>
                                                  )}
                                                  className="form-select-custom"
                                                  classNamePrefix="form-select-custom"
                                                  placeholder="Selecciona una template"
                                                  onChange={(selectedOption) => {
                                                    const selectedValue = selectedOption
                                                      ? selectedOption.value
                                                      : '';
                                                    onChange(selectedValue);
                                                  }}
                                                  value={
                                                    templatesBasic.filter((option) =>
                                                      value.includes(option.value)
                                                    ) || []
                                                  }
                                                />
                                                <InvalidFeedback error={error} />
                                              </>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {showSingleConfig && (
                                  <>
                                    <div className="separator separator-content my-15 text-muted">
                                      Configuración
                                    </div>
                                    <div className="mb-5 mb-xxl-8">
                                      <div className="align-items-center border-0 mt-5">
                                        <label className="d-flex flex-stack mb-3">
                                          <span className="d-flex align-items-center me-2">
                                            <span className="symbol symbol-circle symbol-50px me-6">
                                              <span className="symbol-label">
                                                <i className="ki-duotone ki-messages fs-3x">
                                                  <span className="path1" />
                                                  <span className="path2" />
                                                </i>
                                              </span>
                                            </span>
                                            <span className="d-flex flex-column">
                                              <h2 className="fw-bold">
                                                RCS Single
                                                <span className="required"></span>
                                              </h2>
                                              <div className="text-muted fw-semibold fs-6">
                                                Configura tu canal
                                              </div>
                                            </span>
                                          </span>
                                        </label>
                                      </div>
                                      <div className="pt-1">
                                        <div className="fv-row mb-7">
                                          <label className="form-label required">Templates</label>
                                          <Controller
                                            control={control}
                                            name="templatesSingle"
                                            rules={{ required: true }}
                                            render={({
                                              field: { onChange, value },
                                              fieldState: { error }
                                            }) => (
                                              <>
                                                <Select
                                                  options={templatesSingle}
                                                  noOptionsMessage={() => (
                                                    <div className="empty-select">
                                                      {t('general.no-options')}
                                                    </div>
                                                  )}
                                                  className="form-select-custom"
                                                  classNamePrefix="form-select-custom"
                                                  placeholder="Selecciona una template"
                                                  onChange={(selectedOption) => {
                                                    const selectedValue = selectedOption
                                                      ? selectedOption.value
                                                      : '';
                                                    onChange(selectedValue);
                                                  }}
                                                  value={
                                                    templatesSingle.filter((option) =>
                                                      value.includes(option.value)
                                                    ) || []
                                                  }
                                                />
                                                <InvalidFeedback error={error} />
                                              </>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {showConversationalConfig && (
                                  <>
                                    <div className="separator separator-content my-15 text-muted">
                                      Configuración
                                    </div>
                                    <div className="mb-5 mb-xxl-8">
                                      <div className="align-items-center border-0 mt-5">
                                        <label className="d-flex flex-stack mb-3">
                                          <span className="d-flex align-items-center me-2">
                                            <span className="symbol symbol-circle symbol-50px me-6">
                                              <span className="symbol-label">
                                                <i className="ki-duotone ki-messages fs-3x">
                                                  <span className="path1" />
                                                  <span className="path2" />
                                                </i>
                                              </span>
                                            </span>
                                            <span className="d-flex flex-column">
                                              <h2 className="fw-bold">
                                                RCS Conversational
                                                <span className="required"></span>
                                              </h2>
                                              <div className="text-muted fw-semibold fs-6">
                                                Configura tu canal
                                              </div>
                                            </span>
                                          </span>
                                        </label>
                                      </div>
                                      <div className="pt-1">
                                        <div className="fv-row mb-7">
                                          <label className="form-label required">Templates</label>
                                          <Controller
                                            control={control}
                                            name="templatesConversational"
                                            rules={{ required: true }}
                                            render={({
                                              field: { onChange, value },
                                              fieldState: { error }
                                            }) => (
                                              <>
                                                <Select
                                                  options={templatesConversational}
                                                  noOptionsMessage={() => (
                                                    <div className="empty-select">
                                                      {t('general.no-options')}
                                                    </div>
                                                  )}
                                                  className="form-select-custom"
                                                  classNamePrefix="form-select-custom"
                                                  placeholder="Selecciona una template"
                                                  onChange={(selectedOption) => {
                                                    const selectedValue = selectedOption
                                                      ? selectedOption.value
                                                      : '';
                                                    onChange(selectedValue);
                                                  }}
                                                  value={
                                                    templatesConversational.filter((option) =>
                                                      value.includes(option.value)
                                                    ) || []
                                                  }
                                                />
                                                <InvalidFeedback error={error} />
                                              </>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {showSMSConfig && (
                                  <>
                                    <div className="separator separator-content my-15 text-muted">
                                      Configuración
                                    </div>
                                    <div className="mb-5 mb-xxl-8">
                                      <div className="align-items-center border-0 mt-5">
                                        <label className="d-flex flex-stack mb-10">
                                          <span className="d-flex align-items-center me-2">
                                            <span className="symbol symbol-circle symbol-50px me-6">
                                              <span className="symbol-label">
                                                <i className="ki-duotone ki-message-notif fs-3x">
                                                  <span className="path1" />
                                                  <span className="path2" />
                                                  <span className="path3" />
                                                  <span className="path4" />
                                                  <span className="path5" />
                                                </i>
                                              </span>
                                            </span>
                                            <span className="d-flex flex-column">
                                              <h2 className="fw-bold">
                                                SMS
                                                <span className="required"></span>
                                              </h2>
                                              <div className="text-muted fw-semibold fs-6">
                                                Configura tu canal
                                              </div>
                                            </span>
                                          </span>
                                        </label>
                                      </div>
                                      <div className="pt-1">
                                        <label className="d-flex flex-stack cursor-pointer mb-5">
                                          <span className="d-flex align-items-center me-2">
                                            <span className="d-flex flex-column">
                                              <span className="fw-bold fs-6">Acentos</span>
                                              <span className="fs-7 text-muted">
                                                Activa si tu mensaje llevará acentos.
                                              </span>
                                            </span>
                                          </span>
                                          <span className="form-check form-check-custom form-check-solid">
                                            <Switch {...register('acentos')} />
                                          </span>
                                        </label>
                                        <div className="fv-row mb-7">
                                          <TextArea
                                            label="Mensaje SMS"
                                            requiredIndicator="required"
                                            placeholder="Ingresa mensaje"
                                            errors={errors.msgSMS}
                                            {...register('msgSMS', {
                                              required: true,
                                              setValueAs: (value) => value.trim(),
                                              pattern: {
                                                value: acentosPattern,
                                                message:
                                                  'No tienes activado que este mensaje acepte acentos'
                                              }
                                            })}
                                          />
                                        </div>
                                        <div
                                          key="1"
                                          className="alert alert-dismissible bg-light-info border border-info border-dashed d-flex flex-column flex-sm-row p-5 mb-10"
                                        >
                                          <i className="ki-duotone ki-dollar fs-2hx text-info me-4 mb-5 mb-sm-0">
                                            <span className="path1" />
                                            <span className="path2" />
                                            <span className="path3" />
                                          </i>
                                          <div className="d-flex flex-column pe-0 pe-sm-10">
                                            <h5 className="mb-1">
                                              Caracteres utilizados:{' '}
                                              <span className="text-info fw-bolder">
                                                {watch('msgSMS').length}
                                              </span>
                                            </h5>
                                            <h5 className="mb-3">
                                              Créditos consumidos:{' '}
                                              <span className="text-danger fw-bolder">
                                                {totalCredits}
                                              </span>
                                            </h5>
                                            {acentosCheck ? (
                                              <span>
                                                Este mensaje costará 1 crédito por cada 65
                                                caracteres.
                                              </span>
                                            ) : (
                                              <span>
                                                Este mensaje costará 1 crédito por cada 160
                                                caracteres.
                                              </span>
                                            )}
                                          </div>
                                          <span className="text-danger fw-bolder">
                                            Atención: Al enviar tu campaña por SMS, no podemos
                                            ofrecerte reprocesar números con error, ya que no
                                            existen o están disponibles. Esto afectará también a
                                            otros canales como WhatsApp, RCS, y Voz, que generarán
                                            error.
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {/* Preview Canal Principal mockups*/}
                            <div className="col-md-4">
                              <div className="my-8 mb-xxl-8 ms-6">
                                <div className="pt-1">
                                  <div className="col-4">
                                    {(typeMessage === '' || typeMessage == 'voz') && (
                                      <>
                                        <div
                                          className={
                                            isDarkTheme ? 'wrap-iphone-sms-dark' : 'wrap-iphone-sms'
                                          }
                                        >
                                          <div className="preview-container-RCS"></div>
                                        </div>
                                      </>
                                    )}
                                    {typeMessage === 'wa' && (
                                      <>
                                        <div
                                          className={
                                            isDarkTheme ? 'wrap-iphone-wa-dark' : 'wrap-iphone-wa'
                                          }
                                        >
                                          {numberPreview === '1' && (
                                            <div className="templates-wa_container_message__PCLjO">
                                              <div className="templates-wa_sticky__rNkEe">
                                                <div className="mt-3 me-2">
                                                  <div className="templates-wa_preview_template__0tuw3">
                                                    <div className="templates-wa_preview_template_header__eE2Y0">
                                                      <div className="templates-wa_preview_template_video__AXOqv">
                                                        <i className="bi-play-circle-fill" />
                                                      </div>
                                                    </div>
                                                    <div>Mira el video de este meteorito 😍</div>
                                                  </div>
                                                  <div className="undefined" />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {numberPreview === '2' && (
                                            <div className="templates-wa_container_message__PCLjO">
                                              <div className="templates-wa_sticky__rNkEe">
                                                <div className="mt-3 me-2">
                                                  <div className="templates-wa_preview_template__0tuw3">
                                                    <div className="templates-wa_preview_template_header__eE2Y0">
                                                      <div className="templates-wa_preview_template_video__AXOqv">
                                                        <i className="bi-geo-alt" />
                                                      </div>
                                                    </div>
                                                    <div>
                                                      Hola, te compartimos la ubicación de tu cita
                                                    </div>
                                                  </div>
                                                  <div className="undefined" />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {numberPreview === '3' && (
                                            <div className="templates-wa_container_message__PCLjO">
                                              <div className="templates-wa_sticky__rNkEe">
                                                <div className="mt-3 me-2">
                                                  <div className="templates-wa_preview_template__0tuw3">
                                                    <div className="templates-wa_preview_template_header__eE2Y0">
                                                      <div className="templates-wa_preview_template_video__AXOqv">
                                                        <i className="bi-filetype-pdf" />
                                                      </div>
                                                    </div>
                                                    <div>
                                                      {`Hola {{1}}`}
                                                      <br />
                                                      <br />
                                                      Te enviamos tu volante de adminisión
                                                    </div>
                                                  </div>
                                                  <div className="undefined" />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {numberPreview === '4' && (
                                            <div className="templates-wa_container_message__PCLjO">
                                              <div className="templates-wa_sticky__rNkEe">
                                                <div className="mt-3 me-2">
                                                  <div className="templates-wa_preview_template__0tuw3">
                                                    <div className="templates-wa_preview_template_header__eE2Y0">
                                                      {`Bienvenido {{1}}`}
                                                    </div>
                                                    <div>
                                                      {`Hola {{1}}, esta es una prueba de template.`}
                                                    </div>
                                                    <div className="templates-wa_preview_template_footer__CAEVR">
                                                      Pie de mensaje
                                                    </div>
                                                  </div>
                                                  <div className="undefined">
                                                    <div className="me-0">
                                                      <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                        <i className="bi-reply" /> Quick Reply
                                                      </div>
                                                    </div>
                                                    <div className="me-0">
                                                      <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                        <i className="bi-box-arrow-up-right" />{' '}
                                                        Visit website
                                                      </div>
                                                    </div>
                                                    <div className="me-0">
                                                      <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                        <i className="bi-box-arrow-up-right" />{' '}
                                                        Visit website dinamico
                                                      </div>
                                                    </div>
                                                    <div className="me-0">
                                                      <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                        <i className="bi-telephone" /> Call phone
                                                        number
                                                      </div>
                                                    </div>
                                                    <div className="me-0">
                                                      <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                        <i className="bi-clipboard" /> Copy offer
                                                        code
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    )}
                                    {typeMessage === 'sms' && (
                                      <>
                                        <div
                                          className={
                                            isDarkTheme ? 'wrap-iphone-sms-dark' : 'wrap-iphone-sms'
                                          }
                                        >
                                          {msgSMS && (
                                            <div className="preview-container-SMS">
                                              <div
                                                className={
                                                  isDarkTheme ? 'preview-sms-dark' : 'preview-sms'
                                                }
                                              >
                                                {msgSMS}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    )}
                                    {typeMessage.includes('rcs') && (
                                      <>
                                        {typeRCS === 'basic' && (
                                          <>
                                            <div
                                              className={
                                                isDarkTheme
                                                  ? 'wrap-android-dark ms-8'
                                                  : 'wrap-android ms-8'
                                              }
                                            >
                                              {watch('templatesBasic') && (
                                                <div>
                                                  <div
                                                    className={
                                                      isDarkTheme
                                                        ? 'preview-template-RCS-dark ms-4 mb-3'
                                                        : 'preview-template-RCS-Basic ms-4 mb-3'
                                                    }
                                                  >
                                                    <div className="preview-template-header fw-bold">
                                                      Hola te recordamos que tienes un pago
                                                      pendiente
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div className="text-right btn-container-Basic">
                                                      <div
                                                        className={
                                                          isDarkTheme
                                                            ? 'btn-answer-basic-dark'
                                                            : 'btn-answer-basic'
                                                        }
                                                      >
                                                        Pagar
                                                      </div>

                                                      <div
                                                        className={
                                                          isDarkTheme
                                                            ? 'btn-answer-basic-dark ms-2'
                                                            : 'btn-answer-basic ms-2'
                                                        }
                                                      >
                                                        Ya pagué
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
                                        {typeRCS === 'single' && (
                                          <>
                                            <div
                                              className={
                                                isDarkTheme
                                                  ? 'wrap-android-dark ms-6'
                                                  : 'wrap-android ms-6'
                                              }
                                            >
                                              {watch('templatesSingle') && (
                                                <div className="preview-container-RCS">
                                                  <div
                                                    className={
                                                      isDarkTheme
                                                        ? 'preview-template-RCS-dark'
                                                        : 'preview-template-RCS'
                                                    }
                                                  >
                                                    <div>
                                                      <div>
                                                        <div className="preview-template-img-container-url mb-5">
                                                          <img
                                                            src="https://mueblesavanti.com.mx/cdn/shop/files/1..jpg?v=1713223477&width=1500"
                                                            alt="Uploaded"
                                                            className="preview-image mw-100 mb-3"
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="preview-template-header fw-bolder">
                                                        Aprovecha esta oferta
                                                      </div>
                                                      <p className="mb-10">
                                                        Todo tenemos descuentos hasta del 60%, no te
                                                        quedes sin tus muebles.
                                                      </p>
                                                      <div className="text-center">
                                                        <p className="text-info">
                                                          <i className="bi bi-globe-americas text-info fw-bolder"></i>
                                                          <span className="ms-2 fw-bolder">
                                                            Comprar ya
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
                                        {typeRCS === 'conversational' && (
                                          <>
                                            <div
                                              className={
                                                isDarkTheme
                                                  ? 'wrap-android-dark ms-6'
                                                  : 'wrap-android ms-6'
                                              }
                                            >
                                              {watch('templatesConversational') && (
                                                <div className="preview-container-RCS">
                                                  <div
                                                    className={
                                                      isDarkTheme
                                                        ? 'preview-template-RCS-dark'
                                                        : 'preview-template-RCS'
                                                    }
                                                  >
                                                    <div>
                                                      <div>
                                                        <div className="preview-template-img-container-url mb-5">
                                                          <img
                                                            alt="Uploaded"
                                                            className="preview-image mw-100 mb-3"
                                                            src="https://images-na.ssl-images-amazon.com/images/G/33/img23/Furniture_hard_launch_store_revamp/Comodas_Furniture_440x440.jpg"
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="preview-template-header fw-bolder">
                                                        Aprovecha esta oferta
                                                      </div>
                                                      <p className="mb-10">
                                                        Todo tenemos descuentos hasta del 60%, no te
                                                        quedes sin tus muebles.
                                                      </p>
                                                      <div className="text-center">
                                                        <p className="text-info">
                                                          <i className="bi bi-globe-americas text-info fw-bolder" />
                                                          <span className="ms-2 fw-bolder">
                                                            Comprar ya
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="carrousel-container mt-5">
                                                    <div className="preview-cards-carrousel">
                                                      <div
                                                        className={
                                                          isDarkTheme
                                                            ? 'preview-template-RCS-dark me-5'
                                                            : 'preview-template-RCS me-5'
                                                        }
                                                      >
                                                        <div>
                                                          <div className="preview-template-img-container-url mb-5">
                                                            <img
                                                              alt="Uploaded"
                                                              className="preview-image mw-100 mb-3"
                                                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuoJUQSjEimA12KZvWDcv-hksZRqexjdPmag&s"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="preview-template-header fw-bold">
                                                          Los descuentos llegaron ya
                                                        </div>
                                                        <p className="mb-10">
                                                          No te pierdas todo la moda para este
                                                          regreso a clases.
                                                        </p>
                                                        <div className="text-center">
                                                          <p className="text-info">
                                                            <i className="bi bi-globe-americas text-info fw-bolder" />
                                                            <span className="ms-2 fw-bolder">
                                                              comprar
                                                            </span>
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          isDarkTheme
                                                            ? 'preview-template-RCS-dark me-5'
                                                            : 'preview-template-RCS me-5'
                                                        }
                                                      >
                                                        <div>
                                                          <div className="preview-template-img-container-url mb-5">
                                                            <img
                                                              alt="Uploaded"
                                                              className="preview-image mw-100 mb-3"
                                                              src="https://http2.mlstatic.com/D_NQ_NP_992215-MLM69053085398_042023-O.webp"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="preview-template-header fw-bold">
                                                          Tennis
                                                        </div>
                                                        <p className="mb-10">
                                                          Todo para este regreso a clases.
                                                        </p>
                                                        <div className="text-center">
                                                          <p className="text-info">
                                                            <i className="bi bi-globe-americas text-info fw-bolder" />
                                                            <span className="ms-2 fw-bolder">
                                                              Tienda online
                                                            </span>
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          isDarkTheme
                                                            ? 'preview-template-RCS-dark me-5'
                                                            : 'preview-template-RCS me-5'
                                                        }
                                                      >
                                                        <div>
                                                          <div className="preview-template-img-container-url mb-5">
                                                            <img
                                                              alt="Uploaded"
                                                              className="preview-image mw-100 mb-3"
                                                              src="https://cdn1.coppel.com/images/catalog/pm/2230353-1.jpg"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="preview-template-header fw-bold">
                                                          Electrónica
                                                        </div>
                                                        <p className="mb-10">
                                                          Todo lo que quieras de electrónica para
                                                          que siempre andes conectado.
                                                        </p>
                                                        <div className="text-center">
                                                          <p className="text-info">
                                                            <i className="bi bi-globe-americas text-info fw-bolder" />
                                                            <span className="ms-2 fw-bolder">
                                                              Click aquí
                                                            </span>
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                    {typeMessage == 'telegram' && (
                                      <>
                                        <div
                                          className={
                                            isDarkTheme
                                              ? 'wrap-iphone-telegram-dark'
                                              : 'wrap-iphone-telegram'
                                          }
                                        >
                                          {watch('msgTelegram') !== '' && (
                                            <div className="preview-container-telegram">
                                              <div
                                                className={
                                                  isDarkTheme
                                                    ? 'preview-telegram-dark'
                                                    : 'preview-telegram'
                                                }
                                              >
                                                {watch('msgTelegram')}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    )}
                                    <div>
                                      <div className="ms-14">
                                        <Button
                                          type="button"
                                          id="sendMessage"
                                          className="btn btn-lg btn-light-info my-5 hover-scale text-nowrap"
                                          onClick={() =>
                                            AlertService.toastSuccess('Mensaje enviado con éxito')
                                          }
                                        >
                                          Enviar Mensaje de Prueba
                                          <i className="ki-duotone ki-send ms-2 fs-1">
                                            <span className="path1"></span>
                                            <span className="path2"></span>
                                          </i>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Reproceso errores canal principal */}
                        <div className="col-md-12 mt-5 mb-15">
                          {typeMessage !== 'sms' ? (
                            <>
                              <div className="col-md-12 card ribbon ribbon-top">
                                <div className="ribbon-label bg-info fw-bolder">1er reproceso</div>
                                <div className="row mb-5 mb-xxl-8">
                                  <div className="col-md-12">
                                    <div className="card-header align-items-center border-0 mt-5">
                                      <label className="d-flex flex-stack mb-10">
                                        <span className="d-flex align-items-center me-2">
                                          <span className="symbol symbol-circle symbol-50px me-6">
                                            <span className="symbol-label bg-light-info">
                                              <i className="ki-duotone ki-abstract-20 fs-3x text-info">
                                                <span className="path1" />
                                                <span className="path2" />
                                              </i>
                                            </span>
                                          </span>
                                          <span className="d-flex flex-column">
                                            <h2 className="fw-bold">
                                              Canal de reproceso{' '}
                                              <span className="fs-8 text-muted ms-1">
                                                ({t('general.optional')})
                                              </span>
                                            </h2>
                                            <div className="text-muted fw-semibold fs-6">
                                              Reprocesa los registros que se puedan ir a{' '}
                                              <span className="text-danger fw-bolder fs-5">
                                                error
                                              </span>{' '}
                                              al enviarlos por tu canal principal, que es{' '}
                                              <span className="text-primary fw-bolder fs-5">
                                                {nameChannel}.
                                              </span>
                                            </div>
                                          </span>
                                        </span>
                                      </label>
                                      <div className="card-toolbar"></div>
                                    </div>
                                    {typeMessage !== 'sms' ? (
                                      <div className="card-body pt-1">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <div className="fv-row mb-5">
                                              <label className="d-flex flex-stack cursor-pointer mb-5">
                                                <span className="d-flex align-items-center me-2">
                                                  <span className="symbol me-6 symbol-label">
                                                    <i className="ki-duotone ki-send fs-2x">
                                                      <span className="path1" />
                                                      <span className="path2" />
                                                    </i>
                                                  </span>
                                                  <span className="d-flex flex-column">
                                                    <span className="fw-bold fs-6">
                                                      Reprocesar errores
                                                    </span>
                                                    <span className="fs-7 text-muted">
                                                      Activa si deseas reprocesar
                                                    </span>
                                                  </span>
                                                </span>
                                                <span className="form-check form-check-custom form-check-solid">
                                                  <Switch
                                                    name="principalReprocess"
                                                    checked={alternativeConfig.alternativeChannel}
                                                    value="1"
                                                    disabled={typeMessage ? false : true}
                                                    onChange={() =>
                                                      setAlternativeConfig((prevConfig) => ({
                                                        ...prevConfig,
                                                        alternativeChannel:
                                                          !prevConfig.alternativeChannel
                                                      }))
                                                    }
                                                  />
                                                </span>
                                              </label>
                                            </div>
                                          </div>

                                          <div className="row my-5">
                                            {alternativeConfig.alternativeChannel && (
                                              <>
                                                <div className="fv-row mb-7 col-md-8">
                                                  <div className="col mb-5">
                                                    <label className="form-label mb-4">
                                                      Intervalo de tiempo
                                                      <span className="fs-8 text-muted ms-1">
                                                        (Opcional)
                                                      </span>
                                                      <br />
                                                      <span className="fs-7 text-primary fw-bolder">
                                                        Es el tiempo que se espera antes de volver a
                                                        intentar reprocesar el error.
                                                      </span>
                                                    </label>
                                                    <Controller
                                                      control={control}
                                                      name="timeOption"
                                                      rules={{ required: false }}
                                                      render={({
                                                        field: { onChange, value },
                                                        fieldState: { error }
                                                      }) => (
                                                        <>
                                                          <Select
                                                            options={timeOptions}
                                                            noOptionsMessage={() => (
                                                              <div className="empty-select">
                                                                {t('general.no-options')}
                                                              </div>
                                                            )}
                                                            className={
                                                              error
                                                                ? 'form-select-custom is-invalid'
                                                                : 'form-select-custom'
                                                            }
                                                            classNamePrefix={'form-select-custom'}
                                                            isClearable
                                                            placeholder={t(
                                                              'Selecciona un periodo de tiempo'
                                                            )}
                                                            onChange={(
                                                              selectedOption: {
                                                                value: string;
                                                                label: string;
                                                              } | null
                                                            ) => {
                                                              onChange(
                                                                selectedOption
                                                                  ? selectedOption.value
                                                                  : null
                                                              );
                                                              setValue(
                                                                'timeOption',
                                                                selectedOption
                                                                  ? selectedOption.value
                                                                  : ''
                                                              );
                                                            }}
                                                            value={
                                                              timeOptions.find(
                                                                (option: {
                                                                  value: string;
                                                                  label: string;
                                                                }) => option.value === value
                                                              ) || null
                                                            }
                                                          />
                                                          <InvalidFeedback error={error} />
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                  <div className="col">
                                                    <label className="form-label required">
                                                      Canal de reproceso
                                                    </label>
                                                    <Controller
                                                      control={control}
                                                      name="alternativeFirst"
                                                      rules={{ required: true }}
                                                      render={({
                                                        field: { onChange, value },
                                                        fieldState: { error }
                                                      }) => (
                                                        <>
                                                          <Select
                                                            options={filteredTypesSend}
                                                            noOptionsMessage={() => (
                                                              <div className="empty-select">
                                                                {t('general.no-options')}
                                                              </div>
                                                            )}
                                                            className="form-select-custom"
                                                            classNamePrefix="form-select-custom"
                                                            placeholder="Selecciona un canal"
                                                            onChange={(selectedOption) => {
                                                              onChange(
                                                                selectedOption
                                                                  ? selectedOption.value
                                                                  : null
                                                              );
                                                              setTypeMessageFirst(
                                                                selectedOption
                                                                  ? selectedOption.value
                                                                  : null
                                                              );
                                                            }}
                                                            value={
                                                              typesSend.find(
                                                                (option) => option.value === value
                                                              ) || null
                                                            }
                                                          />
                                                          <InvalidFeedback error={error} />
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                  <div className="col">
                                                    {RshowWhatsAppConfig && (
                                                      <>
                                                        <div className="separator separator-content my-15 text-muted">
                                                          Configuración
                                                        </div>
                                                        <div className="align-items-center border-0 mt-15">
                                                          <div className="d-flex">
                                                            <label className="d-flex flex-stack mb-5">
                                                              <span className="d-flex align-items-center me-2">
                                                                <span className="symbol symbol-circle symbol-50px me-6">
                                                                  <span className="symbol-label">
                                                                    <i className="ki-duotone ki-whatsapp fs-3x">
                                                                      <span className="path1" />
                                                                      <span className="path2" />
                                                                    </i>
                                                                  </span>
                                                                </span>
                                                                <span className="d-flex flex-column">
                                                                  <h2 className="fw-bold">
                                                                    WhatsApp
                                                                    <span className="required"></span>
                                                                  </h2>
                                                                  <div className="text-muted fw-semibold fs-6">
                                                                    Configura tu canal
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="col pt-1">
                                                            <div className="fv-row mb-7">
                                                              <label className="form-label required">
                                                                Templates
                                                              </label>
                                                              <Controller
                                                                control={control}
                                                                name="templatesWA"
                                                                rules={{ required: true }}
                                                                render={({
                                                                  field: { onChange, value },
                                                                  fieldState: { error }
                                                                }) => (
                                                                  <>
                                                                    <Select
                                                                      options={templatesWA}
                                                                      noOptionsMessage={() => (
                                                                        <div className="empty-select">
                                                                          {t('general.no-options')}
                                                                        </div>
                                                                      )}
                                                                      className="form-select-custom"
                                                                      classNamePrefix="form-select-custom"
                                                                      placeholder="Selecciona una template"
                                                                      onChange={(
                                                                        selectedOption
                                                                      ) => {
                                                                        const selectedValue =
                                                                          selectedOption
                                                                            ? selectedOption.value
                                                                            : '';
                                                                        onChange(selectedValue);
                                                                        setNumberPreview(
                                                                          selectedValue
                                                                        );
                                                                      }}
                                                                      value={
                                                                        templatesWA.filter(
                                                                          (option) =>
                                                                            value.includes(
                                                                              option.value
                                                                            )
                                                                        ) || []
                                                                      }
                                                                    />
                                                                    <InvalidFeedback
                                                                      error={error}
                                                                    />
                                                                  </>
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                    {RshowBasicConfig && (
                                                      <>
                                                        <div className="separator separator-content my-15 text-muted">
                                                          Configuración
                                                        </div>
                                                        <div className="align-items-center border-0 mt-10">
                                                          <div className="d-flex">
                                                            <label className="d-flex flex-stack mb-10">
                                                              <span className="d-flex align-items-center me-2">
                                                                <span className="symbol symbol-circle symbol-50px me-6">
                                                                  <span className="symbol-label">
                                                                    <i className="ki-duotone ki-messages fs-3x">
                                                                      <span className="path1" />
                                                                      <span className="path2" />
                                                                    </i>
                                                                  </span>
                                                                </span>
                                                                <span className="d-flex flex-column">
                                                                  <h2 className="fw-bold">
                                                                    RCS Basic
                                                                    <span className="required"></span>
                                                                  </h2>
                                                                  <div className="text-muted fw-semibold fs-6">
                                                                    Configura tu canal
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="col pt-1">
                                                            <div className="fv-row mb-7">
                                                              <label className="form-label required">
                                                                Templates
                                                              </label>
                                                              <Controller
                                                                control={control}
                                                                name="templatesBasic"
                                                                rules={{ required: true }}
                                                                render={({
                                                                  field: { onChange, value },
                                                                  fieldState: { error }
                                                                }) => (
                                                                  <>
                                                                    <Select
                                                                      options={templatesBasic}
                                                                      noOptionsMessage={() => (
                                                                        <div className="empty-select">
                                                                          {t('general.no-options')}
                                                                        </div>
                                                                      )}
                                                                      className="form-select-custom"
                                                                      classNamePrefix="form-select-custom"
                                                                      placeholder="Selecciona una template"
                                                                      onChange={(
                                                                        selectedOption
                                                                      ) => {
                                                                        const selectedValue =
                                                                          selectedOption
                                                                            ? selectedOption.value
                                                                            : '';
                                                                        onChange(selectedValue);
                                                                      }}
                                                                      value={
                                                                        templatesBasic.filter(
                                                                          (option) =>
                                                                            value.includes(
                                                                              option.value
                                                                            )
                                                                        ) || []
                                                                      }
                                                                    />
                                                                    <InvalidFeedback
                                                                      error={error}
                                                                    />
                                                                  </>
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                    {RshowSingleConfig && (
                                                      <>
                                                        <div className="separator separator-content my-15 text-muted">
                                                          Configuración
                                                        </div>
                                                        <div className="mb-5 mb-xxl-8">
                                                          <div className="align-items-center border-0 mt-5">
                                                            <label className="d-flex flex-stack mb-10">
                                                              <span className="d-flex align-items-center me-2">
                                                                <span className="symbol symbol-circle symbol-50px me-6">
                                                                  <span className="symbol-label">
                                                                    <i className="ki-duotone ki-messages fs-3x">
                                                                      <span className="path1" />
                                                                      <span className="path2" />
                                                                    </i>
                                                                  </span>
                                                                </span>
                                                                <span className="d-flex flex-column">
                                                                  <h2 className="fw-bold">
                                                                    RCS Single
                                                                    <span className="required"></span>
                                                                  </h2>
                                                                  <div className="text-muted fw-semibold fs-6">
                                                                    Configura tu canal
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="pt-1">
                                                            <div className="fv-row mb-7">
                                                              <label className="form-label required">
                                                                Templates
                                                              </label>
                                                              <Controller
                                                                control={control}
                                                                name="templatesSingle"
                                                                rules={{ required: true }}
                                                                render={({
                                                                  field: { onChange, value },
                                                                  fieldState: { error }
                                                                }) => (
                                                                  <>
                                                                    <Select
                                                                      options={templatesSingle}
                                                                      noOptionsMessage={() => (
                                                                        <div className="empty-select">
                                                                          {t('general.no-options')}
                                                                        </div>
                                                                      )}
                                                                      className="form-select-custom"
                                                                      classNamePrefix="form-select-custom"
                                                                      placeholder="Selecciona una template"
                                                                      onChange={(
                                                                        selectedOption
                                                                      ) => {
                                                                        const selectedValue =
                                                                          selectedOption
                                                                            ? selectedOption.value
                                                                            : '';
                                                                        onChange(selectedValue);
                                                                      }}
                                                                      value={
                                                                        templatesSingle.filter(
                                                                          (option) =>
                                                                            value.includes(
                                                                              option.value
                                                                            )
                                                                        ) || []
                                                                      }
                                                                    />
                                                                    <InvalidFeedback
                                                                      error={error}
                                                                    />
                                                                  </>
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                    {RshowConversationalConfig && (
                                                      <>
                                                        <div className="separator separator-content my-15 text-muted">
                                                          Configuración
                                                        </div>
                                                        <div className="mb-5 mb-xxl-8">
                                                          <div className="align-items-center border-0 mt-5">
                                                            <label className="d-flex flex-stack mb-10">
                                                              <span className="d-flex align-items-center me-2">
                                                                <span className="symbol symbol-circle symbol-50px me-6">
                                                                  <span className="symbol-label">
                                                                    <i className="ki-duotone ki-messages fs-3x">
                                                                      <span className="path1" />
                                                                      <span className="path2" />
                                                                    </i>
                                                                  </span>
                                                                </span>
                                                                <span className="d-flex flex-column">
                                                                  <h2 className="fw-bold">
                                                                    RCS Conversational
                                                                    <span className="required"></span>
                                                                  </h2>
                                                                  <div className="text-muted fw-semibold fs-6">
                                                                    Configura tu canal
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="pt-1">
                                                            <div className="fv-row mb-7">
                                                              <label className="form-label required">
                                                                Templates
                                                              </label>
                                                              <Controller
                                                                control={control}
                                                                name="templatesConversational"
                                                                rules={{ required: true }}
                                                                render={({
                                                                  field: { onChange, value },
                                                                  fieldState: { error }
                                                                }) => (
                                                                  <>
                                                                    <Select
                                                                      options={
                                                                        templatesConversational
                                                                      }
                                                                      noOptionsMessage={() => (
                                                                        <div className="empty-select">
                                                                          {t('general.no-options')}
                                                                        </div>
                                                                      )}
                                                                      className="form-select-custom"
                                                                      classNamePrefix="form-select-custom"
                                                                      placeholder="Selecciona una template"
                                                                      onChange={(
                                                                        selectedOption
                                                                      ) => {
                                                                        const selectedValue =
                                                                          selectedOption
                                                                            ? selectedOption.value
                                                                            : '';
                                                                        onChange(selectedValue);
                                                                      }}
                                                                      value={
                                                                        templatesConversational.filter(
                                                                          (option) =>
                                                                            value.includes(
                                                                              option.value
                                                                            )
                                                                        ) || []
                                                                      }
                                                                    />
                                                                    <InvalidFeedback
                                                                      error={error}
                                                                    />
                                                                  </>
                                                                )}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                    {RshowSMSConfig && (
                                                      <>
                                                        <div className="separator separator-content my-15 text-muted">
                                                          Configuración
                                                        </div>
                                                        <div className="mb-5 mb-xxl-8">
                                                          <div className="align-items-center border-0 mt-5">
                                                            <label className="d-flex flex-stack mb-10">
                                                              <span className="d-flex align-items-center me-2">
                                                                <span className="symbol symbol-circle symbol-50px me-6">
                                                                  <span className="symbol-label">
                                                                    <i className="ki-duotone ki-message-notif fs-3x">
                                                                      <span className="path1" />
                                                                      <span className="path2" />
                                                                      <span className="path3" />
                                                                      <span className="path4" />
                                                                      <span className="path5" />
                                                                    </i>
                                                                  </span>
                                                                </span>
                                                                <span className="d-flex flex-column">
                                                                  <h2 className="fw-bold">
                                                                    SMS
                                                                    <span className="required"></span>
                                                                  </h2>
                                                                  <div className="text-muted fw-semibold fs-6">
                                                                    Configura tu canal
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="pt-1">
                                                            <label className="d-flex flex-stack cursor-pointer mb-5">
                                                              <span className="d-flex align-items-center me-2">
                                                                <span className="d-flex flex-column">
                                                                  <span className="fw-bold fs-6">
                                                                    Acentos
                                                                  </span>
                                                                  <span className="fs-7 text-muted">
                                                                    Activa si tu mensaje llevará
                                                                    acentos.
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span className="form-check form-check-custom form-check-solid">
                                                                <Switch {...register('acentos')} />
                                                              </span>
                                                            </label>
                                                            <div className="fv-row mb-7">
                                                              <TextArea
                                                                label="Mensaje SMS"
                                                                requiredIndicator="required"
                                                                placeholder="Ingresa mensaje"
                                                                errors={errors.msgSMS}
                                                                {...register('msgSMS', {
                                                                  required: true,
                                                                  setValueAs: (value) =>
                                                                    value.trim(),
                                                                  pattern: {
                                                                    value: acentosPattern,
                                                                    message:
                                                                      'No tienes activado que este mensaje acepte acentos'
                                                                  }
                                                                })}
                                                              />
                                                            </div>
                                                            <div
                                                              key="1"
                                                              className="alert alert-dismissible bg-light-info border border-info border-dashed d-flex flex-column flex-sm-row p-5 mb-10"
                                                            >
                                                              <i className="ki-duotone ki-dollar fs-2hx text-info me-4 mb-5 mb-sm-0">
                                                                <span className="path1" />
                                                                <span className="path2" />
                                                                <span className="path3" />
                                                              </i>
                                                              <div className="d-flex flex-column pe-0 pe-sm-10">
                                                                <h5 className="mb-1">
                                                                  Caracteres utilizados:{' '}
                                                                  <span className="text-info fw-bolder">
                                                                    {watch('msgSMS').length}
                                                                  </span>
                                                                </h5>
                                                                <h5 className="mb-3">
                                                                  Créditos consumidos:{' '}
                                                                  <span className="text-danger fw-bolder">
                                                                    {totalCredits}
                                                                  </span>
                                                                </h5>
                                                                {acentosCheck ? (
                                                                  <span>
                                                                    Este mensaje costará 1 crédito
                                                                    por cada 65 caracteres.
                                                                  </span>
                                                                ) : (
                                                                  <span>
                                                                    Este mensaje costará 1 crédito
                                                                    por cada 160 caracteres.
                                                                  </span>
                                                                )}
                                                              </div>
                                                            </div>
                                                            <span className="text-danger fw-bolder">
                                                              Atención: Al enviar tu campaña por
                                                              SMS, no podemos ofrecerte reprocesar
                                                              números con error, ya que no existen o
                                                              están disponibles. Esto afectará
                                                              también a otros canales como WhatsApp,
                                                              RCS, y Voz, que generarán error.
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="fv-row mb-7 col-md-4">
                                                  <div className="col-md-4">
                                                    {alternativeConfig.alternativeChannel && (
                                                      <>
                                                        {(typeMessageFirst === '' ||
                                                          typeMessageFirst === 'voz') && (
                                                          <div
                                                            className={`wrap-iphone-sms ms-8 ${isDarkTheme ? 'wrap-iphone-sms-dark' : ''}`}
                                                          >
                                                            <div className="preview-container-RCS"></div>
                                                          </div>
                                                        )}
                                                        {typeMessageFirst === 'wa' && (
                                                          <>
                                                            <div
                                                              className={
                                                                isDarkTheme
                                                                  ? 'wrap-iphone-wa-dark ms-8'
                                                                  : 'wrap-iphone-wa ms-8'
                                                              }
                                                            >
                                                              {numberPreview === '1' && (
                                                                <div className="templates-wa_container_message__PCLjO">
                                                                  <div className="templates-wa_sticky__rNkEe">
                                                                    <div className="mt-3 me-2">
                                                                      <div className="templates-wa_preview_template__0tuw3">
                                                                        <div className="templates-wa_preview_template_header__eE2Y0">
                                                                          <div className="templates-wa_preview_template_video__AXOqv">
                                                                            <i className="bi-play-circle-fill" />
                                                                          </div>
                                                                        </div>
                                                                        <div>
                                                                          Mira el video de este
                                                                          meteorito 😍
                                                                        </div>
                                                                      </div>
                                                                      <div className="undefined" />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                              {numberPreview === '2' && (
                                                                <div className="templates-wa_container_message__PCLjO">
                                                                  <div className="templates-wa_sticky__rNkEe">
                                                                    <div className="mt-3 me-2">
                                                                      <div className="templates-wa_preview_template__0tuw3">
                                                                        <div className="templates-wa_preview_template_header__eE2Y0">
                                                                          <div className="templates-wa_preview_template_video__AXOqv">
                                                                            <i className="bi-geo-alt" />
                                                                          </div>
                                                                        </div>
                                                                        <div>
                                                                          Hola, te compartimos la
                                                                          ubicación de tu cita
                                                                        </div>
                                                                      </div>
                                                                      <div className="undefined" />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                              {numberPreview === '3' && (
                                                                <div className="templates-wa_container_message__PCLjO">
                                                                  <div className="templates-wa_sticky__rNkEe">
                                                                    <div className="mt-3 me-2">
                                                                      <div className="templates-wa_preview_template__0tuw3">
                                                                        <div className="templates-wa_preview_template_header__eE2Y0">
                                                                          <div className="templates-wa_preview_template_video__AXOqv">
                                                                            <i className="bi-filetype-pdf" />
                                                                          </div>
                                                                        </div>
                                                                        <div>
                                                                          {`Hola {{1}}`}
                                                                          <br />
                                                                          <br />
                                                                          Te enviamos tu volante de
                                                                          adminisión
                                                                        </div>
                                                                      </div>
                                                                      <div className="undefined" />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                              {numberPreview === '4' && (
                                                                <div className="templates-wa_container_message__PCLjO">
                                                                  <div className="templates-wa_sticky__rNkEe">
                                                                    <div className="mt-3 me-2">
                                                                      <div className="templates-wa_preview_template__0tuw3">
                                                                        <div className="templates-wa_preview_template_header__eE2Y0">
                                                                          {`Bienvenido {{1}}`}
                                                                        </div>
                                                                        <div>
                                                                          {`Hola {{1}}, esta es una prueba de template.`}
                                                                        </div>
                                                                        <div className="templates-wa_preview_template_footer__CAEVR">
                                                                          Pie de mensaje
                                                                        </div>
                                                                      </div>
                                                                      <div className="undefined">
                                                                        <div className="me-0">
                                                                          <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                                            <i className="bi-reply" />{' '}
                                                                            Quick Reply
                                                                          </div>
                                                                        </div>
                                                                        <div className="me-0">
                                                                          <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                                            <i className="bi-box-arrow-up-right" />{' '}
                                                                            Visit website
                                                                          </div>
                                                                        </div>
                                                                        <div className="me-0">
                                                                          <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                                            <i className="bi-box-arrow-up-right" />{' '}
                                                                            Visit website dinamico
                                                                          </div>
                                                                        </div>
                                                                        <div className="me-0">
                                                                          <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                                            <i className="bi-telephone" />{' '}
                                                                            Call phone number
                                                                          </div>
                                                                        </div>
                                                                        <div className="me-0">
                                                                          <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                                            <i className="bi-clipboard" />{' '}
                                                                            Copy offer code
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </>
                                                        )}
                                                        {typeMessageFirst === 'sms' && (
                                                          <>
                                                            <div
                                                              className={
                                                                isDarkTheme
                                                                  ? 'wrap-iphone-sms-dark ms-8'
                                                                  : 'wrap-iphone-sms ms-8'
                                                              }
                                                            >
                                                              {msgSMS && (
                                                                <div className="preview-container-SMS">
                                                                  <div
                                                                    className={
                                                                      isDarkTheme
                                                                        ? 'preview-sms-dark'
                                                                        : 'preview-sms'
                                                                    }
                                                                  >
                                                                    {msgSMS}
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </>
                                                        )}
                                                        {typeMessageFirst.includes('rcs') && (
                                                          <>
                                                            {typeRCSFirst === 'basic' && (
                                                              <>
                                                                <div
                                                                  className={
                                                                    isDarkTheme
                                                                      ? 'wrap-android-dark ms-8'
                                                                      : 'wrap-android ms-8'
                                                                  }
                                                                >
                                                                  {watch('templatesBasic') && (
                                                                    <div>
                                                                      <div
                                                                        className={
                                                                          isDarkTheme
                                                                            ? 'preview-template-RCS-dark ms-4 mb-3'
                                                                            : 'preview-template-RCS-Basic ms-4 mb-3'
                                                                        }
                                                                      >
                                                                        <div className="preview-template-header fw-bold">
                                                                          Hola te recordamos que
                                                                          tienes un pago pendiente
                                                                        </div>
                                                                      </div>
                                                                      <div className="row">
                                                                        <div className="text-right btn-container-Basic">
                                                                          <div
                                                                            className={
                                                                              isDarkTheme
                                                                                ? 'btn-answer-basic-dark'
                                                                                : 'btn-answer-basic'
                                                                            }
                                                                          >
                                                                            Pagar
                                                                          </div>

                                                                          <div
                                                                            className={
                                                                              isDarkTheme
                                                                                ? 'btn-answer-basic-dark ms-2'
                                                                                : 'btn-answer-basic ms-2'
                                                                            }
                                                                          >
                                                                            Ya pagué
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </>
                                                            )}
                                                            {typeRCSFirst === 'single' && (
                                                              <>
                                                                <div
                                                                  className={
                                                                    isDarkTheme
                                                                      ? 'wrap-android-dark ms-8'
                                                                      : 'wrap-android ms-8'
                                                                  }
                                                                >
                                                                  {watch('templatesSingle') && (
                                                                    <div className="preview-container-RCS">
                                                                      <div
                                                                        className={
                                                                          isDarkTheme
                                                                            ? 'preview-template-RCS-dark'
                                                                            : 'preview-template-RCS'
                                                                        }
                                                                      >
                                                                        <div>
                                                                          <div>
                                                                            <div className="preview-template-img-container-url mb-5">
                                                                              <img
                                                                                src="https://mueblesavanti.com.mx/cdn/shop/files/1..jpg?v=1713223477&width=1500"
                                                                                alt="Uploaded"
                                                                                className="preview-image mw-100 mb-3"
                                                                              />
                                                                            </div>
                                                                          </div>
                                                                          <div className="preview-template-header fw-bolder">
                                                                            Aprovecha esta oferta
                                                                          </div>
                                                                          <p className="mb-10">
                                                                            Todo tenemos descuentos
                                                                            hasta del 60%, no te
                                                                            quedes sin tus muebles.
                                                                          </p>
                                                                          <div className="text-center">
                                                                            <p className="text-info">
                                                                              <i className="bi bi-globe-americas text-info fw-bolder"></i>
                                                                              <span className="ms-2 fw-bolder">
                                                                                Comprar ya
                                                                              </span>
                                                                            </p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </>
                                                            )}
                                                            {typeRCSFirst === 'conversational' && (
                                                              <>
                                                                <div
                                                                  className={
                                                                    isDarkTheme
                                                                      ? 'wrap-android-dark ms-8'
                                                                      : 'wrap-android ms-8'
                                                                  }
                                                                >
                                                                  {watch(
                                                                    'templatesConversational'
                                                                  ) && (
                                                                    <div className="preview-container-RCS">
                                                                      <div
                                                                        className={
                                                                          isDarkTheme
                                                                            ? 'preview-template-RCS-dark'
                                                                            : 'preview-template-RCS'
                                                                        }
                                                                      >
                                                                        <div>
                                                                          <div>
                                                                            <div className="preview-template-img-container-url mb-5">
                                                                              <img
                                                                                alt="Uploaded"
                                                                                className="preview-image mw-100 mb-3"
                                                                                src="https://images-na.ssl-images-amazon.com/images/G/33/img23/Furniture_hard_launch_store_revamp/Comodas_Furniture_440x440.jpg"
                                                                              />
                                                                            </div>
                                                                          </div>
                                                                          <div className="preview-template-header fw-bolder">
                                                                            Aprovecha esta oferta
                                                                          </div>
                                                                          <p className="mb-10">
                                                                            Todo tenemos descuentos
                                                                            hasta del 60%, no te
                                                                            quedes sin tus muebles.
                                                                          </p>
                                                                          <div className="text-center">
                                                                            <p className="text-info">
                                                                              <i className="bi bi-globe-americas text-info fw-bolder" />
                                                                              <span className="ms-2 fw-bolder">
                                                                                Comprar ya
                                                                              </span>
                                                                            </p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div className="carrousel-container mt-5">
                                                                        <div className="preview-cards-carrousel">
                                                                          <div
                                                                            className={
                                                                              isDarkTheme
                                                                                ? 'preview-template-RCS-dark me-5'
                                                                                : 'preview-template-RCS me-5'
                                                                            }
                                                                          >
                                                                            <div>
                                                                              <div className="preview-template-img-container-url mb-5">
                                                                                <img
                                                                                  alt="Uploaded"
                                                                                  className="preview-image mw-100 mb-3"
                                                                                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuoJUQSjEimA12KZvWDcv-hksZRqexjdPmag&s"
                                                                                />
                                                                              </div>
                                                                            </div>
                                                                            <div className="preview-template-header fw-bold">
                                                                              Los descuentos
                                                                              llegaron ya
                                                                            </div>
                                                                            <p className="mb-10">
                                                                              No te pierdas todo la
                                                                              moda para este regreso
                                                                              a clases.
                                                                            </p>
                                                                            <div className="text-center">
                                                                              <p className="text-info">
                                                                                <i className="bi bi-globe-americas text-info fw-bolder" />
                                                                                <span className="ms-2 fw-bolder">
                                                                                  comprar
                                                                                </span>
                                                                              </p>
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              isDarkTheme
                                                                                ? 'preview-template-RCS-dark me-5'
                                                                                : 'preview-template-RCS me-5'
                                                                            }
                                                                          >
                                                                            <div>
                                                                              <div className="preview-template-img-container-url mb-5">
                                                                                <img
                                                                                  alt="Uploaded"
                                                                                  className="preview-image mw-100 mb-3"
                                                                                  src="https://http2.mlstatic.com/D_NQ_NP_992215-MLM69053085398_042023-O.webp"
                                                                                />
                                                                              </div>
                                                                            </div>
                                                                            <div className="preview-template-header fw-bold">
                                                                              Tennis
                                                                            </div>
                                                                            <p className="mb-10">
                                                                              Todo para este regreso
                                                                              a clases.
                                                                            </p>
                                                                            <div className="text-center">
                                                                              <p className="text-info">
                                                                                <i className="bi bi-globe-americas text-info fw-bolder" />
                                                                                <span className="ms-2 fw-bolder">
                                                                                  Tienda online
                                                                                </span>
                                                                              </p>
                                                                            </div>
                                                                          </div>
                                                                          <div
                                                                            className={
                                                                              isDarkTheme
                                                                                ? 'preview-template-RCS-dark me-5'
                                                                                : 'preview-template-RCS me-5'
                                                                            }
                                                                          >
                                                                            <div>
                                                                              <div className="preview-template-img-container-url mb-5">
                                                                                <img
                                                                                  alt="Uploaded"
                                                                                  className="preview-image mw-100 mb-3"
                                                                                  src="https://cdn1.coppel.com/images/catalog/pm/2230353-1.jpg"
                                                                                />
                                                                              </div>
                                                                            </div>
                                                                            <div className="preview-template-header fw-bold">
                                                                              Electrónica
                                                                            </div>
                                                                            <p className="mb-10">
                                                                              Todo lo que quieras de
                                                                              electrónica para que
                                                                              siempre andes
                                                                              conectado.
                                                                            </p>
                                                                            <div className="text-center">
                                                                              <p className="text-info">
                                                                                <i className="bi bi-globe-americas text-info fw-bolder" />
                                                                                <span className="ms-2 fw-bolder">
                                                                                  Click aquí
                                                                                </span>
                                                                              </p>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </div>
                                                  <div>
                                                    <div className="ms-20">
                                                      <Button
                                                        type="button"
                                                        id="sendMessage"
                                                        className="btn btn-lg btn-light-info my-5 hover-scale text-nowrap"
                                                        onClick={() =>
                                                          AlertService.toastSuccess(
                                                            'Mensaje enviado con éxito'
                                                          )
                                                        }
                                                      >
                                                        Enviar Mensaje de Prueba
                                                        <i className="ki-duotone ki-send ms-2 fs-1">
                                                          <span className="path1"></span>
                                                          <span className="path2"></span>
                                                        </i>
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div className="card-body pt-1 pb-0">
                                          <div className="bg-light-danger text-danger text-center p-10">
                                            <p className="m-0">
                                              No es posible seleccionar un canal alterno porque se
                                              ha elegido SMS como el canal principal.
                                            </p>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* Reproceso del primer reproceso */}
                        {RalternativeFirst !== '' &&
                          RalternativeFirst !== 'sms' &&
                          selectedTypes !== 'sms' && (
                            <div className="col-md-12 mt-5">
                              {typeMessageFirst !== 'sms' ? (
                                <>
                                  <div className="col-md-12 card ribbon ribbon-top">
                                    <div className="ribbon-label bg-info fw-bolder">
                                      2do reproceso
                                    </div>
                                    <div className="row mb-5 mb-xxl-8">
                                      <div className="col-md-12">
                                        <div className="card-header align-items-center border-0 mt-5">
                                          <label className="d-flex flex-stack mb-10">
                                            <span className="d-flex align-items-center me-2">
                                              <span className="symbol symbol-circle symbol-50px me-6">
                                                <span className="symbol-label bg-light-info">
                                                  <i className="ki-duotone ki-abstract-20 fs-3x text-info">
                                                    <span className="path1" />
                                                    <span className="path2" />
                                                  </i>
                                                </span>
                                              </span>
                                              <span className="d-flex flex-column">
                                                <h2 className="fw-bold">
                                                  Canal de reproceso{' '}
                                                  <span className="fs-8 text-muted ms-1">
                                                    ({t('general.optional')})
                                                  </span>
                                                </h2>
                                                <div className="text-muted fw-semibold fs-6">
                                                  Reprocesa nuevamente los registros que se puedan
                                                  ir a{' '}
                                                  <span className="text-danger fw-bolder fs-5">
                                                    error
                                                  </span>{' '}
                                                  al haberlos reprocesado por{' '}
                                                  <span className="text-primary fw-bolder fs-5">
                                                    {nameChannelFirst}.
                                                  </span>
                                                </div>
                                              </span>
                                            </span>
                                          </label>
                                          <div className="card-toolbar"></div>
                                        </div>
                                        {typeMessageFirst !== 'sms' ? (
                                          <div className="card-body pt-1">
                                            <div className="row">
                                              <div className="col-md-6">
                                                <div className="fv-row mb-5">
                                                  <label className="d-flex flex-stack cursor-pointer mb-5">
                                                    <span className="d-flex align-items-center me-2">
                                                      <span className="symbol me-6 symbol-label">
                                                        <i className="ki-duotone ki-send fs-2x">
                                                          <span className="path1" />
                                                          <span className="path2" />
                                                        </i>
                                                      </span>
                                                      <span className="d-flex flex-column">
                                                        <span className="fw-bold fs-6">
                                                          Reprocesar errores nuevamente
                                                        </span>
                                                        <span className="fs-7 text-muted">
                                                          Activa si deseas reprocesar
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span className="form-check form-check-custom form-check-solid">
                                                      <Switch
                                                        name="secondReprocess"
                                                        checked={
                                                          alternativeConfig.secondAlternative
                                                        }
                                                        value="1"
                                                        disabled={typeMessageFirst ? false : true}
                                                        onChange={() =>
                                                          setAlternativeConfig((prevConfig) => ({
                                                            ...prevConfig,
                                                            secondAlternative:
                                                              !prevConfig.secondAlternative
                                                          }))
                                                        }
                                                      />
                                                    </span>
                                                  </label>
                                                </div>
                                              </div>

                                              <div className="row my-5">
                                                {alternativeConfig.secondAlternative && (
                                                  <>
                                                    <div className="fv-row mb-7 col-md-8">
                                                      <div className="col mb-5">
                                                        <label className="form-label mb-4">
                                                          Intervalo de tiempo
                                                          <span className="fs-8 text-muted ms-1">
                                                            (Opcional)
                                                          </span>
                                                          <br />
                                                          <span className="fs-7 text-primary fw-bolder">
                                                            Es el tiempo que se espera antes de
                                                            volver a intentar reprocesar el error.
                                                          </span>
                                                        </label>
                                                        <Controller
                                                          control={control}
                                                          name="timeOptionSecond"
                                                          rules={{ required: false }}
                                                          render={({
                                                            field: { onChange, value },
                                                            fieldState: { error }
                                                          }) => (
                                                            <>
                                                              <Select
                                                                options={timeOptions}
                                                                noOptionsMessage={() => (
                                                                  <div className="empty-select">
                                                                    {t('general.no-options')}
                                                                  </div>
                                                                )}
                                                                className={
                                                                  error
                                                                    ? 'form-select-custom is-invalid'
                                                                    : 'form-select-custom'
                                                                }
                                                                classNamePrefix={
                                                                  'form-select-custom'
                                                                }
                                                                isClearable
                                                                placeholder={t(
                                                                  'Selecciona un periodo de tiempo'
                                                                )}
                                                                onChange={(
                                                                  selectedOption: {
                                                                    value: string;
                                                                    label: string;
                                                                  } | null
                                                                ) => {
                                                                  onChange(
                                                                    selectedOption
                                                                      ? selectedOption.value
                                                                      : null
                                                                  );
                                                                  setValue(
                                                                    'timeOptionSecond',
                                                                    selectedOption
                                                                      ? selectedOption.value
                                                                      : ''
                                                                  );
                                                                }}
                                                                value={
                                                                  timeOptions.find(
                                                                    (option: {
                                                                      value: string;
                                                                      label: string;
                                                                    }) => option.value === value
                                                                  ) || null
                                                                }
                                                              />
                                                              <InvalidFeedback error={error} />
                                                            </>
                                                          )}
                                                        />
                                                      </div>
                                                      <div className="col">
                                                        <label className="form-label required">
                                                          Canal de reproceso
                                                        </label>
                                                        <Controller
                                                          control={control}
                                                          name="alternativeSecond"
                                                          rules={{ required: true }}
                                                          render={({
                                                            field: { onChange, value },
                                                            fieldState: { error }
                                                          }) => (
                                                            <>
                                                              <Select
                                                                options={filteredTypesSendSecond}
                                                                noOptionsMessage={() => (
                                                                  <div className="empty-select">
                                                                    {t('general.no-options')}
                                                                  </div>
                                                                )}
                                                                className="form-select-custom"
                                                                classNamePrefix="form-select-custom"
                                                                placeholder="Selecciona un canal"
                                                                onChange={(selectedOption) => {
                                                                  onChange(
                                                                    selectedOption
                                                                      ? selectedOption.value
                                                                      : null
                                                                  );
                                                                  setTypeMessageSecond(
                                                                    selectedOption
                                                                      ? selectedOption.value
                                                                      : null
                                                                  );
                                                                }}
                                                                value={
                                                                  typesSend.find(
                                                                    (option) =>
                                                                      option.value === value
                                                                  ) || null
                                                                }
                                                              />
                                                              <InvalidFeedback error={error} />
                                                            </>
                                                          )}
                                                        />
                                                      </div>
                                                      <div className="col">
                                                        {RSecondshowWhatsAppConfig && (
                                                          <>
                                                            <div className="separator separator-content my-15 text-muted">
                                                              Configuración
                                                            </div>
                                                            <div className="align-items-center border-0 mt-15">
                                                              <div className="d-flex">
                                                                <label className="d-flex flex-stack mb-5">
                                                                  <span className="d-flex align-items-center me-2">
                                                                    <span className="symbol symbol-circle symbol-50px me-6">
                                                                      <span className="symbol-label">
                                                                        <i className="ki-duotone ki-whatsapp fs-3x">
                                                                          <span className="path1" />
                                                                          <span className="path2" />
                                                                        </i>
                                                                      </span>
                                                                    </span>
                                                                    <span className="d-flex flex-column">
                                                                      <h2 className="fw-bold">
                                                                        WhatsApp
                                                                        <span className="required"></span>
                                                                      </h2>
                                                                      <div className="text-muted fw-semibold fs-6">
                                                                        Configura tu canal
                                                                      </div>
                                                                    </span>
                                                                  </span>
                                                                </label>
                                                              </div>
                                                              <div className="col pt-1">
                                                                <div className="fv-row mb-7">
                                                                  <label className="form-label required">
                                                                    Templates
                                                                  </label>
                                                                  <Controller
                                                                    control={control}
                                                                    name="templatesWA"
                                                                    rules={{ required: true }}
                                                                    render={({
                                                                      field: { onChange, value },
                                                                      fieldState: { error }
                                                                    }) => (
                                                                      <>
                                                                        <Select
                                                                          options={templatesWA}
                                                                          noOptionsMessage={() => (
                                                                            <div className="empty-select">
                                                                              {t(
                                                                                'general.no-options'
                                                                              )}
                                                                            </div>
                                                                          )}
                                                                          className="form-select-custom"
                                                                          classNamePrefix="form-select-custom"
                                                                          placeholder="Selecciona una template"
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValue =
                                                                              selectedOption
                                                                                ? selectedOption.value
                                                                                : '';
                                                                            onChange(selectedValue);
                                                                            setNumberPreview(
                                                                              selectedValue
                                                                            );
                                                                          }}
                                                                          value={
                                                                            templatesWA.filter(
                                                                              (option) =>
                                                                                value.includes(
                                                                                  option.value
                                                                                )
                                                                            ) || []
                                                                          }
                                                                        />
                                                                        <InvalidFeedback
                                                                          error={error}
                                                                        />
                                                                      </>
                                                                    )}
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                        {RSecondshowBasicConfig && (
                                                          <>
                                                            <div className="separator separator-content my-15 text-muted">
                                                              Configuración
                                                            </div>
                                                            <div className="align-items-center border-0 mt-10">
                                                              <div className="d-flex">
                                                                <label className="d-flex flex-stack mb-10">
                                                                  <span className="d-flex align-items-center me-2">
                                                                    <span className="symbol symbol-circle symbol-50px me-6">
                                                                      <span className="symbol-label">
                                                                        <i className="ki-duotone ki-messages fs-3x">
                                                                          <span className="path1" />
                                                                          <span className="path2" />
                                                                        </i>
                                                                      </span>
                                                                    </span>
                                                                    <span className="d-flex flex-column">
                                                                      <h2 className="fw-bold">
                                                                        RCS Basic
                                                                        <span className="required"></span>
                                                                      </h2>
                                                                      <div className="text-muted fw-semibold fs-6">
                                                                        Configura tu canal
                                                                      </div>
                                                                    </span>
                                                                  </span>
                                                                </label>
                                                              </div>
                                                              <div className="col pt-1">
                                                                <div className="fv-row mb-7">
                                                                  <label className="form-label required">
                                                                    Templates
                                                                  </label>
                                                                  <Controller
                                                                    control={control}
                                                                    name="templatesBasic"
                                                                    rules={{ required: true }}
                                                                    render={({
                                                                      field: { onChange, value },
                                                                      fieldState: { error }
                                                                    }) => (
                                                                      <>
                                                                        <Select
                                                                          options={templatesBasic}
                                                                          noOptionsMessage={() => (
                                                                            <div className="empty-select">
                                                                              {t(
                                                                                'general.no-options'
                                                                              )}
                                                                            </div>
                                                                          )}
                                                                          className="form-select-custom"
                                                                          classNamePrefix="form-select-custom"
                                                                          placeholder="Selecciona una template"
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValue =
                                                                              selectedOption
                                                                                ? selectedOption.value
                                                                                : '';
                                                                            onChange(selectedValue);
                                                                          }}
                                                                          value={
                                                                            templatesBasic.filter(
                                                                              (option) =>
                                                                                value.includes(
                                                                                  option.value
                                                                                )
                                                                            ) || []
                                                                          }
                                                                        />
                                                                        <InvalidFeedback
                                                                          error={error}
                                                                        />
                                                                      </>
                                                                    )}
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                        {RSecondshowSingleConfig && (
                                                          <>
                                                            <div className="separator separator-content my-15 text-muted">
                                                              Configuración
                                                            </div>
                                                            <div className="mb-5 mb-xxl-8">
                                                              <div className="align-items-center border-0 mt-5">
                                                                <label className="d-flex flex-stack mb-10">
                                                                  <span className="d-flex align-items-center me-2">
                                                                    <span className="symbol symbol-circle symbol-50px me-6">
                                                                      <span className="symbol-label">
                                                                        <i className="ki-duotone ki-messages fs-3x">
                                                                          <span className="path1" />
                                                                          <span className="path2" />
                                                                        </i>
                                                                      </span>
                                                                    </span>
                                                                    <span className="d-flex flex-column">
                                                                      <h2 className="fw-bold">
                                                                        RCS Single
                                                                        <span className="required"></span>
                                                                      </h2>
                                                                      <div className="text-muted fw-semibold fs-6">
                                                                        Configura tu canal
                                                                      </div>
                                                                    </span>
                                                                  </span>
                                                                </label>
                                                              </div>
                                                              <div className="pt-1">
                                                                <div className="fv-row mb-7">
                                                                  <label className="form-label required">
                                                                    Templates
                                                                  </label>
                                                                  <Controller
                                                                    control={control}
                                                                    name="templatesSingle"
                                                                    rules={{ required: true }}
                                                                    render={({
                                                                      field: { onChange, value },
                                                                      fieldState: { error }
                                                                    }) => (
                                                                      <>
                                                                        <Select
                                                                          options={templatesSingle}
                                                                          noOptionsMessage={() => (
                                                                            <div className="empty-select">
                                                                              {t(
                                                                                'general.no-options'
                                                                              )}
                                                                            </div>
                                                                          )}
                                                                          className="form-select-custom"
                                                                          classNamePrefix="form-select-custom"
                                                                          placeholder="Selecciona una template"
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValue =
                                                                              selectedOption
                                                                                ? selectedOption.value
                                                                                : '';
                                                                            onChange(selectedValue);
                                                                          }}
                                                                          value={
                                                                            templatesSingle.filter(
                                                                              (option) =>
                                                                                value.includes(
                                                                                  option.value
                                                                                )
                                                                            ) || []
                                                                          }
                                                                        />
                                                                        <InvalidFeedback
                                                                          error={error}
                                                                        />
                                                                      </>
                                                                    )}
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                        {RSecondshowConversationalConfig && (
                                                          <>
                                                            <div className="separator separator-content my-15 text-muted">
                                                              Configuración
                                                            </div>
                                                            <div className="mb-5 mb-xxl-8">
                                                              <div className="align-items-center border-0 mt-5">
                                                                <label className="d-flex flex-stack mb-10">
                                                                  <span className="d-flex align-items-center me-2">
                                                                    <span className="symbol symbol-circle symbol-50px me-6">
                                                                      <span className="symbol-label">
                                                                        <i className="ki-duotone ki-messages fs-3x">
                                                                          <span className="path1" />
                                                                          <span className="path2" />
                                                                        </i>
                                                                      </span>
                                                                    </span>
                                                                    <span className="d-flex flex-column">
                                                                      <h2 className="fw-bold">
                                                                        RCS Conversational
                                                                        <span className="required"></span>
                                                                      </h2>
                                                                      <div className="text-muted fw-semibold fs-6">
                                                                        Configura tu canal
                                                                      </div>
                                                                    </span>
                                                                  </span>
                                                                </label>
                                                              </div>
                                                              <div className="pt-1">
                                                                <div className="fv-row mb-7">
                                                                  <label className="form-label required">
                                                                    Templates
                                                                  </label>
                                                                  <Controller
                                                                    control={control}
                                                                    name="templatesConversational"
                                                                    rules={{ required: true }}
                                                                    render={({
                                                                      field: { onChange, value },
                                                                      fieldState: { error }
                                                                    }) => (
                                                                      <>
                                                                        <Select
                                                                          options={
                                                                            templatesConversational
                                                                          }
                                                                          noOptionsMessage={() => (
                                                                            <div className="empty-select">
                                                                              {t(
                                                                                'general.no-options'
                                                                              )}
                                                                            </div>
                                                                          )}
                                                                          className="form-select-custom"
                                                                          classNamePrefix="form-select-custom"
                                                                          placeholder="Selecciona una template"
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValue =
                                                                              selectedOption
                                                                                ? selectedOption.value
                                                                                : '';
                                                                            onChange(selectedValue);
                                                                          }}
                                                                          value={
                                                                            templatesConversational.filter(
                                                                              (option) =>
                                                                                value.includes(
                                                                                  option.value
                                                                                )
                                                                            ) || []
                                                                          }
                                                                        />
                                                                        <InvalidFeedback
                                                                          error={error}
                                                                        />
                                                                      </>
                                                                    )}
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                        {RSecondshowSMSConfig && (
                                                          <>
                                                            <div className="separator separator-content my-15 text-muted">
                                                              Configuración
                                                            </div>
                                                            <div className="mb-5 mb-xxl-8">
                                                              <div className="align-items-center border-0 mt-5">
                                                                <label className="d-flex flex-stack mb-10">
                                                                  <span className="d-flex align-items-center me-2">
                                                                    <span className="symbol symbol-circle symbol-50px me-6">
                                                                      <span className="symbol-label">
                                                                        <i className="ki-duotone ki-message-notif fs-3x">
                                                                          <span className="path1" />
                                                                          <span className="path2" />
                                                                          <span className="path3" />
                                                                          <span className="path4" />
                                                                          <span className="path5" />
                                                                        </i>
                                                                      </span>
                                                                    </span>
                                                                    <span className="d-flex flex-column">
                                                                      <h2 className="fw-bold">
                                                                        SMS
                                                                        <span className="required"></span>
                                                                      </h2>
                                                                      <div className="text-muted fw-semibold fs-6">
                                                                        Configura tu canal
                                                                      </div>
                                                                    </span>
                                                                  </span>
                                                                </label>
                                                              </div>
                                                              <div className="pt-1">
                                                                <label className="d-flex flex-stack cursor-pointer mb-5">
                                                                  <span className="d-flex align-items-center me-2">
                                                                    <span className="d-flex flex-column">
                                                                      <span className="fw-bold fs-6">
                                                                        Acentos
                                                                      </span>
                                                                      <span className="fs-7 text-muted">
                                                                        Activa si tu mensaje llevará
                                                                        acentos.
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span className="form-check form-check-custom form-check-solid">
                                                                    <Switch
                                                                      {...register('acentos')}
                                                                    />
                                                                  </span>
                                                                </label>
                                                                <div className="fv-row mb-7">
                                                                  <TextArea
                                                                    label="Mensaje SMS"
                                                                    requiredIndicator="required"
                                                                    placeholder="Ingresa mensaje"
                                                                    errors={errors.msgSMS}
                                                                    {...register('msgSMS', {
                                                                      required: true,
                                                                      setValueAs: (value) =>
                                                                        value.trim(),
                                                                      pattern: {
                                                                        value: acentosPattern,
                                                                        message:
                                                                          'No tienes activado que este mensaje acepte acentos'
                                                                      }
                                                                    })}
                                                                  />
                                                                </div>
                                                                <div
                                                                  key="1"
                                                                  className="alert alert-dismissible bg-light-info border border-info border-dashed d-flex flex-column flex-sm-row p-5 mb-10"
                                                                >
                                                                  <i className="ki-duotone ki-dollar fs-2hx text-info me-4 mb-5 mb-sm-0">
                                                                    <span className="path1" />
                                                                    <span className="path2" />
                                                                    <span className="path3" />
                                                                  </i>
                                                                  <div className="d-flex flex-column pe-0 pe-sm-10">
                                                                    <h5 className="mb-1">
                                                                      Caracteres utilizados:{' '}
                                                                      <span className="text-info fw-bolder">
                                                                        {watch('msgSMS').length}
                                                                      </span>
                                                                    </h5>
                                                                    <h5 className="mb-3">
                                                                      Créditos consumidos:{' '}
                                                                      <span className="text-danger fw-bolder">
                                                                        {totalCredits}
                                                                      </span>
                                                                    </h5>
                                                                    {acentosCheck ? (
                                                                      <span>
                                                                        Este mensaje costará 1
                                                                        crédito por cada 65
                                                                        caracteres.
                                                                      </span>
                                                                    ) : (
                                                                      <span>
                                                                        Este mensaje costará 1
                                                                        crédito por cada 160
                                                                        caracteres.
                                                                      </span>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                                <span className="text-danger fw-bolder">
                                                                  Atención: Al enviar tu campaña por
                                                                  SMS, no podemos ofrecerte
                                                                  reprocesar números con error, ya
                                                                  que no existen o están
                                                                  disponibles. Esto afectará también
                                                                  a otros canales como WhatsApp,
                                                                  RCS, y Voz, que generarán error.
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="fv-row mb-7 col-md-4">
                                                      <div className="col-md-4">
                                                        {alternativeConfig.secondAlternative && (
                                                          <>
                                                            {(typeMessageSecond === '' ||
                                                              typeMessageSecond === 'voz') && (
                                                              <div
                                                                className={`wrap-iphone-sms ms-8 ${isDarkTheme ? 'wrap-iphone-sms-dark' : ''}`}
                                                              >
                                                                <div className="preview-container-RCS"></div>
                                                              </div>
                                                            )}
                                                            {typeMessageSecond === 'wa' && (
                                                              <>
                                                                <div
                                                                  className={
                                                                    isDarkTheme
                                                                      ? 'wrap-iphone-wa-dark ms-8'
                                                                      : 'wrap-iphone-wa ms-8'
                                                                  }
                                                                >
                                                                  {numberPreview === '1' && (
                                                                    <div className="templates-wa_container_message__PCLjO">
                                                                      <div className="templates-wa_sticky__rNkEe">
                                                                        <div className="mt-3 me-2">
                                                                          <div className="templates-wa_preview_template__0tuw3">
                                                                            <div className="templates-wa_preview_template_header__eE2Y0">
                                                                              <div className="templates-wa_preview_template_video__AXOqv">
                                                                                <i className="bi-play-circle-fill" />
                                                                              </div>
                                                                            </div>
                                                                            <div>
                                                                              Mira el video de este
                                                                              meteorito 😍
                                                                            </div>
                                                                          </div>
                                                                          <div className="undefined" />
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                  {numberPreview === '2' && (
                                                                    <div className="templates-wa_container_message__PCLjO">
                                                                      <div className="templates-wa_sticky__rNkEe">
                                                                        <div className="mt-3 me-2">
                                                                          <div className="templates-wa_preview_template__0tuw3">
                                                                            <div className="templates-wa_preview_template_header__eE2Y0">
                                                                              <div className="templates-wa_preview_template_video__AXOqv">
                                                                                <i className="bi-geo-alt" />
                                                                              </div>
                                                                            </div>
                                                                            <div>
                                                                              Hola, te compartimos
                                                                              la ubicación de tu
                                                                              cita
                                                                            </div>
                                                                          </div>
                                                                          <div className="undefined" />
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                  {numberPreview === '3' && (
                                                                    <div className="templates-wa_container_message__PCLjO">
                                                                      <div className="templates-wa_sticky__rNkEe">
                                                                        <div className="mt-3 me-2">
                                                                          <div className="templates-wa_preview_template__0tuw3">
                                                                            <div className="templates-wa_preview_template_header__eE2Y0">
                                                                              <div className="templates-wa_preview_template_video__AXOqv">
                                                                                <i className="bi-filetype-pdf" />
                                                                              </div>
                                                                            </div>
                                                                            <div>
                                                                              {`Hola {{1}}`}
                                                                              <br />
                                                                              <br />
                                                                              Te enviamos tu volante
                                                                              de adminisión
                                                                            </div>
                                                                          </div>
                                                                          <div className="undefined" />
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                  {numberPreview === '4' && (
                                                                    <div className="templates-wa_container_message__PCLjO">
                                                                      <div className="templates-wa_sticky__rNkEe">
                                                                        <div className="mt-3 me-2">
                                                                          <div className="templates-wa_preview_template__0tuw3">
                                                                            <div className="templates-wa_preview_template_header__eE2Y0">
                                                                              {`Bienvenido {{1}}`}
                                                                            </div>
                                                                            <div>
                                                                              {`Hola {{1}}, esta es una prueba de template.`}
                                                                            </div>
                                                                            <div className="templates-wa_preview_template_footer__CAEVR">
                                                                              Pie de mensaje
                                                                            </div>
                                                                          </div>
                                                                          <div className="undefined">
                                                                            <div className="me-0">
                                                                              <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                                                <i className="bi-reply" />{' '}
                                                                                Quick Reply
                                                                              </div>
                                                                            </div>
                                                                            <div className="me-0">
                                                                              <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                                                <i className="bi-box-arrow-up-right" />{' '}
                                                                                Visit website
                                                                              </div>
                                                                            </div>
                                                                            <div className="me-0">
                                                                              <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                                                <i className="bi-box-arrow-up-right" />{' '}
                                                                                Visit website
                                                                                dinamico
                                                                              </div>
                                                                            </div>
                                                                            <div className="me-0">
                                                                              <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                                                <i className="bi-telephone" />{' '}
                                                                                Call phone number
                                                                              </div>
                                                                            </div>
                                                                            <div className="me-0">
                                                                              <div className="templates-wa_preview_template_btn__L-yV8 templates-wa_preview_template__0tuw3">
                                                                                <i className="bi-clipboard" />{' '}
                                                                                Copy offer code
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </>
                                                            )}
                                                            {typeMessageSecond === 'sms' && (
                                                              <>
                                                                <div
                                                                  className={
                                                                    isDarkTheme
                                                                      ? 'wrap-iphone-sms-dark ms-8'
                                                                      : 'wrap-iphone-sms ms-8'
                                                                  }
                                                                >
                                                                  {msgSMS && (
                                                                    <div className="preview-container-SMS">
                                                                      <div
                                                                        className={
                                                                          isDarkTheme
                                                                            ? 'preview-sms-dark'
                                                                            : 'preview-sms'
                                                                        }
                                                                      >
                                                                        {msgSMS}
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </>
                                                            )}
                                                            {typeMessageSecond.includes('rcs') && (
                                                              <>
                                                                {typeRCSSecond === 'basic' && (
                                                                  <>
                                                                    <div
                                                                      className={
                                                                        isDarkTheme
                                                                          ? 'wrap-android-dark ms-8'
                                                                          : 'wrap-android ms-8'
                                                                      }
                                                                    >
                                                                      {watch('templatesBasic') && (
                                                                        <div>
                                                                          <div
                                                                            className={
                                                                              isDarkTheme
                                                                                ? 'preview-template-RCS-dark ms-4 mb-3'
                                                                                : 'preview-template-RCS-Basic ms-4 mb-3'
                                                                            }
                                                                          >
                                                                            <div className="preview-template-header fw-bold">
                                                                              Hola te recordamos que
                                                                              tienes un pago
                                                                              pendiente
                                                                            </div>
                                                                          </div>
                                                                          <div className="row">
                                                                            <div className="text-right btn-container-Basic">
                                                                              <div
                                                                                className={
                                                                                  isDarkTheme
                                                                                    ? 'btn-answer-basic-dark'
                                                                                    : 'btn-answer-basic'
                                                                                }
                                                                              >
                                                                                Pagar
                                                                              </div>

                                                                              <div
                                                                                className={
                                                                                  isDarkTheme
                                                                                    ? 'btn-answer-basic-dark ms-2'
                                                                                    : 'btn-answer-basic ms-2'
                                                                                }
                                                                              >
                                                                                Ya pagué
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  </>
                                                                )}
                                                                {typeRCSSecond === 'single' && (
                                                                  <>
                                                                    <div
                                                                      className={
                                                                        isDarkTheme
                                                                          ? 'wrap-android-dark ms-8'
                                                                          : 'wrap-android ms-8'
                                                                      }
                                                                    >
                                                                      {watch('templatesSingle') && (
                                                                        <div className="preview-container-RCS">
                                                                          <div
                                                                            className={
                                                                              isDarkTheme
                                                                                ? 'preview-template-RCS-dark'
                                                                                : 'preview-template-RCS'
                                                                            }
                                                                          >
                                                                            <div>
                                                                              <div>
                                                                                <div className="preview-template-img-container-url mb-5">
                                                                                  <img
                                                                                    src="https://mueblesavanti.com.mx/cdn/shop/files/1..jpg?v=1713223477&width=1500"
                                                                                    alt="Uploaded"
                                                                                    className="preview-image mw-100 mb-3"
                                                                                  />
                                                                                </div>
                                                                              </div>
                                                                              <div className="preview-template-header fw-bolder">
                                                                                Aprovecha esta
                                                                                oferta
                                                                              </div>
                                                                              <p className="mb-10">
                                                                                Todo tenemos
                                                                                descuentos hasta del
                                                                                60%, no te quedes
                                                                                sin tus muebles.
                                                                              </p>
                                                                              <div className="text-center">
                                                                                <p className="text-info">
                                                                                  <i className="bi bi-globe-americas text-info fw-bolder"></i>
                                                                                  <span className="ms-2 fw-bolder">
                                                                                    Comprar ya
                                                                                  </span>
                                                                                </p>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  </>
                                                                )}
                                                                {typeRCSSecond ===
                                                                  'conversational' && (
                                                                  <>
                                                                    <div
                                                                      className={
                                                                        isDarkTheme
                                                                          ? 'wrap-android-dark ms-8'
                                                                          : 'wrap-android ms-8'
                                                                      }
                                                                    >
                                                                      {watch(
                                                                        'templatesConversational'
                                                                      ) && (
                                                                        <div className="preview-container-RCS">
                                                                          <div
                                                                            className={
                                                                              isDarkTheme
                                                                                ? 'preview-template-RCS-dark'
                                                                                : 'preview-template-RCS'
                                                                            }
                                                                          >
                                                                            <div>
                                                                              <div>
                                                                                <div className="preview-template-img-container-url mb-5">
                                                                                  <img
                                                                                    alt="Uploaded"
                                                                                    className="preview-image mw-100 mb-3"
                                                                                    src="https://images-na.ssl-images-amazon.com/images/G/33/img23/Furniture_hard_launch_store_revamp/Comodas_Furniture_440x440.jpg"
                                                                                  />
                                                                                </div>
                                                                              </div>
                                                                              <div className="preview-template-header fw-bolder">
                                                                                Aprovecha esta
                                                                                oferta
                                                                              </div>
                                                                              <p className="mb-10">
                                                                                Todo tenemos
                                                                                descuentos hasta del
                                                                                60%, no te quedes
                                                                                sin tus muebles.
                                                                              </p>
                                                                              <div className="text-center">
                                                                                <p className="text-info">
                                                                                  <i className="bi bi-globe-americas text-info fw-bolder" />
                                                                                  <span className="ms-2 fw-bolder">
                                                                                    Comprar ya
                                                                                  </span>
                                                                                </p>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          <div className="carrousel-container mt-5">
                                                                            <div className="preview-cards-carrousel">
                                                                              <div
                                                                                className={
                                                                                  isDarkTheme
                                                                                    ? 'preview-template-RCS-dark me-5'
                                                                                    : 'preview-template-RCS me-5'
                                                                                }
                                                                              >
                                                                                <div>
                                                                                  <div className="preview-template-img-container-url mb-5">
                                                                                    <img
                                                                                      alt="Uploaded"
                                                                                      className="preview-image mw-100 mb-3"
                                                                                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuoJUQSjEimA12KZvWDcv-hksZRqexjdPmag&s"
                                                                                    />
                                                                                  </div>
                                                                                </div>
                                                                                <div className="preview-template-header fw-bold">
                                                                                  Los descuentos
                                                                                  llegaron ya
                                                                                </div>
                                                                                <p className="mb-10">
                                                                                  No te pierdas todo
                                                                                  la moda para este
                                                                                  regreso a clases.
                                                                                </p>
                                                                                <div className="text-center">
                                                                                  <p className="text-info">
                                                                                    <i className="bi bi-globe-americas text-info fw-bolder" />
                                                                                    <span className="ms-2 fw-bolder">
                                                                                      comprar
                                                                                    </span>
                                                                                  </p>
                                                                                </div>
                                                                              </div>
                                                                              <div
                                                                                className={
                                                                                  isDarkTheme
                                                                                    ? 'preview-template-RCS-dark me-5'
                                                                                    : 'preview-template-RCS me-5'
                                                                                }
                                                                              >
                                                                                <div>
                                                                                  <div className="preview-template-img-container-url mb-5">
                                                                                    <img
                                                                                      alt="Uploaded"
                                                                                      className="preview-image mw-100 mb-3"
                                                                                      src="https://http2.mlstatic.com/D_NQ_NP_992215-MLM69053085398_042023-O.webp"
                                                                                    />
                                                                                  </div>
                                                                                </div>
                                                                                <div className="preview-template-header fw-bold">
                                                                                  Tennis
                                                                                </div>
                                                                                <p className="mb-10">
                                                                                  Todo para este
                                                                                  regreso a clases.
                                                                                </p>
                                                                                <div className="text-center">
                                                                                  <p className="text-info">
                                                                                    <i className="bi bi-globe-americas text-info fw-bolder" />
                                                                                    <span className="ms-2 fw-bolder">
                                                                                      Tienda online
                                                                                    </span>
                                                                                  </p>
                                                                                </div>
                                                                              </div>
                                                                              <div
                                                                                className={
                                                                                  isDarkTheme
                                                                                    ? 'preview-template-RCS-dark me-5'
                                                                                    : 'preview-template-RCS me-5'
                                                                                }
                                                                              >
                                                                                <div>
                                                                                  <div className="preview-template-img-container-url mb-5">
                                                                                    <img
                                                                                      alt="Uploaded"
                                                                                      className="preview-image mw-100 mb-3"
                                                                                      src="https://cdn1.coppel.com/images/catalog/pm/2230353-1.jpg"
                                                                                    />
                                                                                  </div>
                                                                                </div>
                                                                                <div className="preview-template-header fw-bold">
                                                                                  Electrónica
                                                                                </div>
                                                                                <p className="mb-10">
                                                                                  Todo lo que
                                                                                  quieras de
                                                                                  electrónica para
                                                                                  que siempre andes
                                                                                  conectado.
                                                                                </p>
                                                                                <div className="text-center">
                                                                                  <p className="text-info">
                                                                                    <i className="bi bi-globe-americas text-info fw-bolder" />
                                                                                    <span className="ms-2 fw-bolder">
                                                                                      Click aquí
                                                                                    </span>
                                                                                  </p>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </div>
                                                      <div>
                                                        <div className="ms-20">
                                                          <Button
                                                            type="button"
                                                            id="sendMessage"
                                                            className="btn btn-lg btn-light-info my-5 hover-scale text-nowrap"
                                                            onClick={() =>
                                                              AlertService.toastSuccess(
                                                                'Mensaje enviado con éxito'
                                                              )
                                                            }
                                                          >
                                                            Enviar Mensaje de Prueba
                                                            <i className="ki-duotone ki-send ms-2 fs-1">
                                                              <span className="path1"></span>
                                                              <span className="path2"></span>
                                                            </i>
                                                          </Button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="card-body pt-1 pb-0">
                                              <div className="bg-light-danger text-danger text-center p-10">
                                                <p className="m-0">
                                                  No es posible seleccionar un canal alterno porque
                                                  se ha elegido SMS como el canal principal.
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        {/* Enviar */}
                        <div className="d-flex flex-stack my-10">
                          <div>
                            <div className="me-2">
                              <Button
                                type="submit"
                                typeStepperBtn="submit"
                                id="sendData"
                                className="btn btn-lg btn-primary w-100 mb-5 hover-scale"
                                disabled={
                                  !isValid ||
                                  (alternativeConfig.timeWait && !watch('timeOption')) ||
                                  (alternativeConfig.timeWaitSecond &&
                                    !watch('timeOptionSecond')) ||
                                  (alternativeConfig.alternativeChannel &&
                                    !watch('alternativeFirst')) ||
                                  (alternativeConfig.secondAlternative &&
                                    !watch('alternativeSecond'))
                                }
                                isLoading={isSubmitting}
                              >
                                Enviar campaña
                                <i className="ki-duotone ki-send ms-2 fs-1">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default CampaignsPage;
