import { useEffect, useState } from 'react';
import useBgPages from '../../hooks/bg-pages.hook';
import { useTranslation } from 'react-i18next';
import useRequest from '../../hooks/use-request.hook';
import Aside from '../../components/layout/asaid.component';
import Header from '../../components/layout/header.component';
import Footer from '../../components/layout/footer.component';
import DataTable from '../../components/datatable/datatable.component';
import { RolesService } from '../../services/roles.service';
import AddRoles from './add-roles.modal';
function RolesPage() {
  useBgPages();
  const { t } = useTranslation();
  const { call, isRequesting } = useRequest();
  const [rows, setRows] = useState<any>([]);
  const [total, setTotal] = useState<any>();
  const [actives, setActives] = useState<number>();
  const columns = [
    {
      name: t('roles.title-column-1'),
      sortable: true,
      cell: (rows: any) => {
        return <h5 className="mw-150px">{rows.name}</h5>;
      }
    },
    {
      name: t('roles.title-column-2'),
      cell: (rows: any) => {
        const fechaHora = new Date(rows.created_at);
        const fecha = fechaHora.toISOString().split('T')[0];
        const hora = fechaHora.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
        return (
          <div>
            <h5>
              {t('general.date')}: <span className="text-muted">{fecha}</span>
            </h5>
            <h5>
              {t('general.hour')}: <span className="text-muted">{hora}</span>
            </h5>
          </div>
        );
      }
    },
    {
      name: t('roles.title-column-3'),
      cell: (row: any) =>
        row.status ? (
          <span className="badge badge-light-primary fs-6">
            <i className="ki-duotone ki-check-circle text-primary fs-3">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {t('general.active')}
          </span>
        ) : (
          <span className="badge badge-light-danger fs-6">
            <i className="ki-duotone ki-minus-circle text-danger fs-3">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            {t('general.inactive')}
          </span>
        )
    }
  ];
  const getRoles = async () => {
    const data = await call(RolesService.getRoles);
    if (data) {
      setRows(data);
      setTotal(data?.length);
      const activeRoles = data?.filter((roles: any) => roles.status === true);
      setActives(activeRoles.length);
    } else {
      setRows([]);
      setTotal(0);
      setActives(0);
    }
  };
  const [showModal, setShowModal] = useState({
    show: false
  });

  const handleShowModal = async (refresh: boolean) => {
    if (refresh) {
      getRoles();
    }
    setShowModal({ show: false });
  };
  useEffect(() => {
    getRoles();
  }, []);
  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Aside />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">{t('asaid.link-users')}</li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <i className="ki-duotone ki-colors-square fs-3x">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                      </i>
                      <span className="ms-3">{t('asaid.link-roles')}</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="row gy-5 g-xl-10">
                  <div className="col-xl-4 mb-xl-10">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            {t('roles.title-statistics')}
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('asaid.link-roles')}
                          </span>
                        </h3>
                        <div className="card-toolbar">
                          <span className="btn btn-clean btn-sm btn-icon btn-icon-primary me-n3">
                            <i className="ki-duotone ki-category fs-1 text-primary">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                          </span>
                        </div>
                      </div>
                      <div className="card-body pt-1">
                        <div className="d-flex flex-stack mb-2 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-profile-user fs-3x text-info w-35px me-6 hover-scale">
                              <span className="path1"></span>
                              <span className="path2"></span>
                              <span className="path3"></span>
                              <span className="path4"></span>
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-info fw-bolder"
                                href="#"
                              >
                                {t('roles.my-roles')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('roles.total-roles')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder ">
                            {total}
                          </span>
                        </div>
                        <div className="d-flex flex-stack mb-10 item-border-hover px-3 py-2 ms-n3">
                          <div className="d-flex align-items-center">
                            <i className="ki-duotone ki-check-circle fs-3x text-primary w-35px me-6 hover-scale">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                              <span className="path4" />
                            </i>
                            <div className="pe-2">
                              <a
                                title="s"
                                className="fs-5 text-gray-800 text-hover-primary fw-bolder"
                                href="#"
                              >
                                {t('roles.title-active')}
                              </a>
                              <div className="text-gray-400 fw-semibold mt-1">
                                {t('roles.item-active')}
                              </div>
                            </div>
                          </div>
                          <span className="label rounded bg-light w-25px h-25px fs-5 fw-bolder">
                            {actives}
                          </span>
                        </div>
                        <div className="d-flex align-items-center flex-nowrap text-nowrap py-1">
                          <a
                            className="btn btn-primary hover-scale"
                            onClick={() => setShowModal({ show: true })}
                          >
                            <i className="ki-duotone ki-abstract-10 fs-2">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                            {t('roles.add-roles')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 mb-5 mb-xl-10">
                    <div className="row g-5 g-xl-10 h-xxl-50 mb-0 mb-xl-10">
                      <div className="card mb-5 mb-xxl-8">
                        <div className="card-header align-items-center border-0 mt-5">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="fw-bolder fw-bold fs-2">{t('asaid.link-roles')}</span>
                            <span className="text-gray-400 mt-2 fw-semibold fs-6">
                              {t('roles.registered')}
                            </span>
                          </h3>
                        </div>
                        <div className="card-body pt-1">
                          <DataTable columns={columns} data={rows} isLoading={isRequesting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <AddRoles show={showModal.show} onRequestClose={(refresh: any) => handleShowModal(refresh)} />
    </>
  );
}

export default RolesPage;
