import { useTranslation } from 'react-i18next';
import Input from '../../../components/forms/input.component';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import InvalidFeedback from '../../../components/forms/invalid-feedback.component';
import { useState } from 'react';

function UrlBtn({ errors, register, watch, index, onDelete }: any) {
  const { t } = useTranslation();
  const { control } = useForm({
    mode: 'onChange'
  });
  const [style, setStyle] = useState<any>('');

  const options = [
    {
      value: 'static',
      label: t('templates.wa.create.static'),
      disabled: false
    },
    {
      value: 'dynamic',
      label: t('templates.wa.create.dynamic'),
      disabled: false
    }
  ];
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleKeyDown = (e: any, maxLength: number) => {
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  };
  return (
    <div className="border p-7 mt-4">
      <div className="mb-2">
        <i className="ki-duotone ki-fasten fs-1">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
        {t('templates.wa.create.go-website')}
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <Input
                  label={t('templates.wa.create.text-btn-label')}
                  errors={errors?.text}
                  requiredIndicator={'required'}
                  currentLength={watch(`buttons.buttons.${index}.text`)?.length}
                  maxLength={25}
                  placeholder={t('templates.wa.create.text-btn-label')}
                  onKeyDown={(e: any) => handleKeyDown(e, 25)}
                  {...register(`buttons.buttons.${index}.text`, {
                    required: true,
                    maxLength: { value: 25, message: '25' },
                    setValueAs: (value: any) => value.trim()
                  })}
                />
              </div>
              <div className="flex-grow-1 mx-2">
                <label className="form-label required">{t('templates.wa.create.url-type')} </label>
                <Controller
                  control={control}
                  name="language"
                  rules={{ required: true }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <Select
                        options={options}
                        required={true}
                        noOptionsMessage={() => (
                          <div className="empty-select">{t('general.no-options')}</div>
                        )}
                        className={error ? 'form-select-custom is-invalid' : 'form-select-custom'}
                        classNamePrefix="form-select-custom"
                        placeholder={t('templates.wa.create.url-type-placeholder')}
                        onChange={(selectedOption: { value: string; label: string } | null) => {
                          onChange(selectedOption ? selectedOption.value : null);
                          setStyle(selectedOption ? selectedOption.value : null);
                        }}
                        value={options.find((language: any) => language.value === value) || null}
                      />
                      <InvalidFeedback error={error} />
                    </>
                  )}
                />
              </div>
              <div className="flex-grow-1">
                <Input
                  label={t('templates.wa.create.url-site')}
                  requiredIndicator={'required'}
                  errors={errors?.url}
                  currentLength={watch(`buttons.buttons.${index}.url`)?.length}
                  maxLength={2000}
                  placeholder={t('templates.wa.create.url-site-placeholder')}
                  onKeyDown={(e: any) => handleKeyDown(e, 2000)}
                  {...register(`buttons.buttons.${index}.url`, {
                    required: true,
                    maxLength: { value: 2000, message: '2000' },
                    setValueAs: (value: any) => value.trim()
                  })}
                />
              </div>
            </div>

            {style === 'dynamic' && (
              <div className="mt-2 alert alert-warning">
                {t('templates.wa.create.dynamic-warning')}
              </div>
            )}
            {style === 'dynamic' && (
              <div className="mt-3">
                <h5>{t('templates.wa.create.dynamic-example')}</h5>
                <p>{t('templates.wa.create.dynamic-example-description')}</p>

                <p className="font-black">
                  {watch(`buttons.buttons.${index}.url`)}
                  {`{{1}}`}.
                </p>

                <div className="d-flex align-items-center mb-2">
                  <label className="form-label mx-3">{`{{1}}`}</label>
                  <div className="flex-grow-1">
                    <Input
                      errors={errors?.example}
                      {...register(`buttons.buttons.${index}.example`, { required: true })}
                      sizeClass="form-control-sm"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <a
            className="btn btn-light-danger btn-icon ms-3 mt-4"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onDelete}
          >
            <i className="ki-duotone ki-trash fs-2x">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
            </i>
          </a>
        </div>
      </div>
    </div>
  );
}
export default UrlBtn;
