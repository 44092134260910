import { useCallback } from 'react';

const useRCSStructures = () => {
  const createSuggestion = (text: string, url: string) => ({
    action: {
      text,
      postbackData: '{{postback}}',
      openUrlAction: {
        url
      }
    }
  });

  const createCMStructure = useCallback((data: any) => {
    // RCS BASIC
    if (data.typeRCS === 'rcs_basic') {
      const createSuggestion = (text: string) => ({
        reply: {
          text,
          postbackData: '{{postback}}'
        }
      });

      const message = { text: data.msgBasic };
      const suggestions = [data.btnBasic1, data.btnBasic2].filter(Boolean).map(createSuggestion);

      return [{ message: suggestions.length ? { ...message, suggestions } : message }];
    }
    // Estructura para rcs_single o rcs_conversational
    if (data.typeRCS === 'rcs_single' || data.typeRCS === 'rcs_conversational') {
      const richCard = {
        standaloneCard: {
          thumbnailImageAlignment: 'RIGHT',
          cardOrientation: 'VERTICAL',
          cardContent: {
            title: data.title,
            description: data.msg,
            media: {
              height: 'MEDIUM',
              contentInfo: {
                fileUrl: data.URLImage,
                thumbnailUrl: data.URLImagePoster
              }
            },
            suggestions: [createSuggestion(data.labelURL, data.linkURL)]
          }
        }
      };

      return [{ message: { richCard } }];
    }
    return null;
  }, []);

  const createGoogleStructure = useCallback((data: any) => {
    // RCS BASIC
    if (data.typeRCS === 'rcs_basic') {
      const createSuggestion = (text: string) => ({
        reply: {
          text,
          postbackData: '{{postback}}'
        }
      });

      const contentMessage = { text: data.msgBasic };
      const suggestions = [data.btnBasic1, data.btnBasic2].filter(Boolean).map(createSuggestion);

      return [
        { contentMessage: suggestions.length ? { ...contentMessage, suggestions } : contentMessage }
      ];
    }
    return null;
  }, []);

  const generateStructures = useCallback(
    (data: any) => {
      const cmData = createCMStructure(data);
      const googleData = createGoogleStructure(data);
      return { cmData, googleData };
    },
    [createCMStructure, createGoogleStructure]
  );

  return { generateStructures };
};

export default useRCSStructures;
