import { useForm, Controller } from 'react-hook-form';
import Input from '../../components/forms/input.component';
import Select from 'react-select';
import Button from '../../components/forms/button.component';
import useStepper from '../../hooks/stepper.hook';
import InvalidFeedback from '../../components/forms/invalid-feedback.component';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInputValidation from '../../hooks/input-validation.hook';
import { ChannelsService } from '../../services/channels.service';
import { AlertService } from '../../services/alert.service';
import { CompaniesService } from '../../services/companies.service';
import { APPS } from '../../constants';
import useModal from '../../hooks/close-modal.hook';
interface AddCompanyProps {
  show: boolean;
  onRequestClose: (refresh: boolean) => void;
}
interface Channel {
  _id: string;
  price: string;
}
interface Channels {
  [key: string]: Channel;
}
function AddCompany({ show, onRequestClose }: AddCompanyProps) {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  useModal(modalRef, show);

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const { currentStep, setCurrentStep, resteStepper } = useStepper('add_new_area_stepper');
  const { priceRegex, handlePrice, validatePrice } = useInputValidation();
  const defaultValues = {
    company: {
      name: ''
    },
    company_user: {
      username: '',
      full_name: '',
      email: ''
    },
    area: {
      name: ''
    },
    area_user: {
      username: '',
      full_name: '',
      email: ''
    },
    channels: {
      whatsapp: {
        _id: '',
        price: ''
      },
      rcsConversational: {
        _id: '',
        price: ''
      },
      rcsSingle: {
        _id: '',
        price: ''
      },
      rcsBasic: {
        _id: '',
        price: ''
      },
      sms: {
        _id: '',
        price: ''
      }
    }
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const [channelConfig, setchannelConfig] = useState({
    whatsapp: false,
    rcsConversational: false,
    rcsSingle: false,
    rcsBasic: false,
    sms: false
  });

  const [listData, setListaData] = useState({
    channelWhatsApp: [],
    channelRcsConversacional: [],
    channelRcsSingle: [],
    channelRcsBasic: [],
    channelSMS: []
  });
  const isStepValid = (step: number, watch: any) => {
    const values = watch();
    const isEmailValid = (email: any) => !email || emailRegex.test(email);
    switch (step) {
      case 1:
        return (
          !!watch('company.name') &&
          !!watch('company_user.username') &&
          !!watch('company_user.full_name') &&
          isEmailValid(values?.company_user?.email)
        );
      case 2:
        return (
          !!watch('area.name') &&
          !!watch('area_user.username') &&
          !!watch('area_user.full_name') &&
          isEmailValid(values?.area_user?.email)
        );
      case 3:
        return (
          (channelConfig.whatsapp
            ? !!values.channels.whatsapp._id &&
              !!values.channels.whatsapp.price &&
              validatePrice(values.channels.whatsapp.price)
            : true) &&
          (channelConfig.rcsConversational
            ? !!values.channels.rcsConversational._id &&
              !!values.channels.rcsConversational.price &&
              validatePrice(values.channels.rcsConversational.price)
            : true) &&
          (channelConfig.rcsSingle
            ? !!values.channels.rcsSingle._id &&
              !!values.channels.rcsSingle.price &&
              validatePrice(values.channels.rcsSingle.price)
            : true) &&
          (channelConfig.rcsBasic
            ? !!values.channels.rcsBasic._id &&
              !!values.channels.rcsBasic.price &&
              validatePrice(values.channels.rcsBasic.price)
            : true) &&
          (channelConfig.sms
            ? !!values.channels.sms._id &&
              !!values.channels.sms.price &&
              validatePrice(values.channels.sms.price)
            : true)
        );
      default:
        return false;
    }
  };

  const isCurrentStepValid = isStepValid(currentStep, watch);

  const getData = async (type?: string) => {
    let data: any;
    const types = type ? [type] : [APPS.CHANNEL_WHATSAPP];
    try {
      for (const currentType of types) {
        if (currentType === APPS.CHANNEL_WHATSAPP) {
          data = await ChannelsService.getAccountsWhatsApp();
          setListaData((prevData) => ({
            ...prevData,
            channelWhatsApp: data.map((item: any) => ({
              value: item._id,
              label: item.name
            }))
          }));
        }
      }
    } catch (error: any) {
      AlertService.toastError(t('general.atention'), error);
    }
  };

  const AddCompany = async (data: any) => {
    try {
      data.channels = cleanChannels(data.channels);
      await CompaniesService.saveCompany(data);
      resetForm();
      await onRequestClose(true);
      AlertService.toastSuccess(t('companies-page.alert-success-add'));
    } catch (error: any) {
      console.log('LoginPage.login.error:', error);
      AlertService.toastError(t('companies-page.alert-error-add'));
    }
  };

  const cleanChannels = (channels: Channels): Channels => {
    const defaultChannelId = '';
    const cleanedChannels: Channels = {};
    for (const key in channels) {
      if (channels[key]._id && channels[key]._id !== defaultChannelId) {
        cleanedChannels[key] = {
          _id: channels[key]._id,
          price: parseFloat(channels[key].price || '0.0').toString()
        };
      }
    }
    return cleanedChannels;
  };

  const resetForm = () => {
    reset();
    resteStepper.goFirst();
    setCurrentStep(1);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div aria-hidden="true" className="modal fade" ref={modalRef} data-bs-backdrop="static">
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header justify-content-end border-0 pb-0">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => (onRequestClose(false), resetForm())}
            >
              <i className="ki-duotone ki-cross fs-2x">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
          </div>
          <div className="mt-1 text-center">
            <h2>{t('companies-page.modal-add-company.title-add-company')}</h2>
            <div className="text-muted fw-semibold fs-5">
              <span dangerouslySetInnerHTML={{ __html: t('channels.sms.modal-C-Subtitle') }}></span>
            </div>
            <div className="separator border-2 my-5"></div>
          </div>
          <div className="modal-body mt-10 px-5 px-xl-20">
            <div
              className="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row"
              id="add_new_area_stepper"
            >
              <div className="d-flex flex-row-auto w-100 w-lg-300px">
                <div className="stepper-nav">
                  <div className="stepper-item me-5 current" data-kt-stepper-element="nav">
                    <div className="stepper-wrapper d-flex align-items-center">
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check" />
                        <span className="stepper-number">1</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">
                          {t(
                            'companies-page.modal-add-company.modal-steps.step-data-company.title'
                          )}
                        </h3>
                        <div className="stepper-desc">
                          {t(
                            'companies-page.modal-add-company.modal-steps.step-data-company.description'
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="stepper-line h-40px" />
                  </div>
                  <div className="stepper-item me-5" data-kt-stepper-element="nav">
                    <div className="stepper-wrapper d-flex align-items-center">
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check" />
                        <span className="stepper-number">2</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">
                          {t('companies-page.modal-add-company.modal-steps.step-data-area.title')}
                        </h3>
                        <div className="stepper-desc">
                          {t(
                            'companies-page.modal-add-company.modal-steps.step-data-area.description'
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="stepper-line h-40px" />
                  </div>
                  <div className="stepper-item me-5" data-kt-stepper-element="nav">
                    <div className="stepper-wrapper d-flex align-items-center">
                      <div className="stepper-icon w-40px h-40px">
                        <i className="stepper-check fas fa-check" />
                        <span className="stepper-number">3</span>
                      </div>
                      <div className="stepper-label">
                        <h3 className="stepper-title">
                          {t('companies-page.modal-add-company.modal-steps.step-channels.title')}
                        </h3>
                        <div className="stepper-desc">
                          {t(
                            'companies-page.modal-add-company.modal-steps.step-channels.description'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-row-fluid">
                <form className="form" onSubmit={handleSubmit(AddCompany)}>
                  <div className="mb-5">
                    {/*Primer Wizard*/}
                    <div className="flex-column current" data-kt-stepper-element="content">
                      <div className="pb-10 pb-lg-12">
                        <label className="d-flex flex-stack mb-7">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-briefcase fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <h2 className="fw-bold">
                                {t('companies-page.modal-add-company.title-data-company')}
                              </h2>
                              <div className="text-muted fw-semibold fs-6">
                                {t('companies-page.modal-add-company.item-data-company')}
                              </div>
                            </span>
                          </span>
                        </label>
                        <div className="fv-row mb-5">
                          <Input
                            label={t('companies-page.modal-add-company.label-name-company')}
                            requiredIndicator="required"
                            placeholder={t(
                              'companies-page.modal-add-company.subtitle-name-company'
                            )}
                            errors={errors?.company?.name}
                            {...register('company.name', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="pb-10 pb-lg-12">
                        <label className="d-flex flex-stack mb-7">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-info">
                                <i className="ki-duotone ki-faceid fs-2x text-info">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <h2 className="fw-bold">
                                {t('companies-page.modal-add-company.title-register-user')}
                              </h2>
                              <div className="text-muted fw-semibold fs-6">
                                {t('companies-page.modal-add-company.subtitle-register-user')}
                              </div>
                            </span>
                          </span>
                        </label>
                        <div className="fv-row mb-5">
                          <Input
                            label={t('companies-page.modal-add-company.label-user')}
                            requiredIndicator="required"
                            placeholder={t('companies-page.modal-add-company.user-placeholder')}
                            errors={errors?.company_user?.username}
                            {...register('company_user.username', { required: true })}
                          />
                        </div>
                        <div className="fv-row mb-5">
                          <Input
                            label={t('companies-page.modal-add-company.label-full-name')}
                            requiredIndicator="required"
                            placeholder={t(
                              'companies-page.modal-add-company.full-name-placeholder'
                            )}
                            errors={errors?.company_user?.full_name}
                            {...register('company_user.full_name', { required: true })}
                          />
                        </div>
                        <div className="fv-row">
                          <Input
                            label={t('companies-page.modal-add-company.label-email')}
                            optional={true}
                            placeholder={t('companies-page.modal-add-company.email-placeholder')}
                            errors={errors?.company_user?.email}
                            {...register('company_user.email', {
                              required: false,
                              pattern: {
                                value: emailRegex,
                                message: t('companies-page.modal-add-company.error-email-message')
                              }
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    {/*Segundo Wizard*/}
                    <div className="flex-column" data-kt-stepper-element="content">
                      <div className="pb-10 pb-lg-12">
                        <label className="d-flex flex-stack mb-7">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-data fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <h2 className="fw-bold">
                                {t('companies-page.modal-add-company.title-data-area')}
                              </h2>
                              <div className="text-muted fw-semibold fs-6">
                                {t('companies-page.modal-add-company.item-data-area')}
                              </div>
                            </span>
                          </span>
                        </label>
                        <div className="fv-row mb-5">
                          <Input
                            label={t('companies-page.modal-add-company.label-name-area')}
                            requiredIndicator="required"
                            placeholder={t(
                              'companies-page.modal-add-company.name-area-placeholder'
                            )}
                            errors={errors?.area?.name}
                            {...register('area.name', { required: true })}
                          />
                        </div>
                      </div>
                      <div className="pb-10 pb-lg-12">
                        <label className="d-flex flex-stack mb-7">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-info">
                                <i className="ki-duotone ki-faceid fs-2x text-info">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <h2 className="fw-bold">
                                {t('companies-page.modal-add-company.title-register-user')}
                              </h2>
                              <div className="text-muted fw-semibold fs-6">
                                {t('companies-page.modal-add-company.subtitle-register-area')}
                              </div>
                            </span>
                          </span>
                        </label>
                        <div className="fv-row mb-5">
                          <Input
                            label={t('companies-page.modal-add-company.label-user')}
                            requiredIndicator="required"
                            placeholder={t('companies-page.modal-add-company.user-placeholder')}
                            errors={errors?.area_user?.username}
                            {...register('area_user.username', { required: true })}
                          />
                        </div>
                        <div className="fv-row mb-5">
                          <Input
                            label={t('companies-page.modal-add-company.label-full-name')}
                            requiredIndicator="required"
                            placeholder={t(
                              'companies-page.modal-add-company.full-name-placeholder'
                            )}
                            errors={errors?.area_user?.full_name}
                            {...register('area_user.full_name', { required: true })}
                          />
                        </div>
                        <div className="fv-row">
                          <Input
                            label={t('companies-page.modal-add-company.label-email')}
                            optional={true}
                            placeholder={t('companies-page.modal-add-company.email-placeholder')}
                            errors={errors.area_user?.email}
                            {...register('area_user.email', {
                              required: false,
                              pattern: {
                                value: emailRegex,
                                message: t('companies-page.modal-add-company.error-email-message')
                              }
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    {/*Tercer Wizard*/}
                    <div className="flex-column" data-kt-stepper-element="content">
                      <span className="d-flex flex-column mb-10">
                        <span className="fw-bold fs-2">
                          {t('areas-page.modal-add-area.stepper-3-title1')}
                        </span>
                        <span className="fs-7 text-muted">
                          {t('areas-page.modal-add-area.stepper-3-subtitle1')}
                        </span>
                      </span>
                      <div className="scroll mh-500px">
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px  me-6">
                              <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-whatsapp fs-2x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                {t('areas-page.modal-add-area.stepper-3-ch1')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-3-ch1-subtitle')}
                              </span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              value="1"
                              name="framework"
                              checked={channelConfig.whatsapp}
                              type="checkbox"
                              onChange={() =>
                                setchannelConfig((prevConfig) => ({
                                  ...prevConfig,
                                  whatsapp: !prevConfig.whatsapp
                                }))
                              }
                            />
                          </span>
                        </label>
                        {channelConfig.whatsapp && (
                          <>
                            <div className="fv-row mb-5">
                              <label className="form-label required">
                                {' '}
                                {t('areas-page.modal-add-area.stepper-3-input-accounts')}
                              </label>
                              <Controller
                                control={control}
                                name="channels.whatsapp._id"
                                rules={{ required: !channelConfig.whatsapp }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      options={listData.channelWhatsApp}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {t('general.no-options')}
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? 'form-select-custom is-invalid'
                                          : 'form-select-custom'
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={t(
                                        'areas-page.modal-add-area.stepper-3-input-accounts-placeholder'
                                      )}
                                      onChange={(
                                        selectedOption: { value: string; label: string } | null
                                      ) => {
                                        onChange(selectedOption ? selectedOption.value : null);
                                      }}
                                      value={
                                        listData.channelWhatsApp.find(
                                          (option: { value: string; label: string }) =>
                                            option.value === value
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row mb-10">
                              <Input
                                label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                requiredIndicator="required"
                                placeholder={t(
                                  'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                )}
                                onKeyDown={handlePrice}
                                errors={errors?.channels?.whatsapp?.price}
                                {...register('channels.whatsapp.price', {
                                  required: !channelConfig.whatsapp,
                                  pattern: {
                                    value: priceRegex,
                                    message: t('forms.invalid-FeedBack.valid-price')
                                  }
                                })}
                              />
                            </div>
                          </>
                        )}
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px  me-6">
                              <span className="symbol-label bg-light-info">
                                <i className="ki-duotone ki-messages fs-2x text-info">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                {t('areas-page.modal-add-area.stepper-3-ch2')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-3-rcs-subtitle')}
                              </span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              value="1"
                              name="framework"
                              checked={channelConfig.rcsConversational}
                              type="checkbox"
                              onChange={() =>
                                setchannelConfig((prevConfig) => ({
                                  ...prevConfig,
                                  rcsConversational: !prevConfig.rcsConversational
                                }))
                              }
                            />
                          </span>
                        </label>
                        {channelConfig.rcsConversational && (
                          <>
                            <div className="fv-row mb-5">
                              <label className="form-label required">
                                {t('areas-page.modal-add-area.stepper-3-input-agents')}
                              </label>
                              <Controller
                                control={control}
                                name="channels.rcsConversational._id"
                                rules={{ required: !channelConfig.rcsConversational }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      options={listData.channelRcsConversacional}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {t('general.no-options')}
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? 'form-select-custom is-invalid'
                                          : 'form-select-custom'
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={t(
                                        'areas-page.modal-add-area.stepper-3-input-agents-placeholder'
                                      )}
                                      onChange={(
                                        selectedOption: { value: string; label: string } | null
                                      ) => {
                                        onChange(selectedOption ? selectedOption.value : null);
                                      }}
                                      value={
                                        listData.channelRcsConversacional.find(
                                          (option: { value: string; label: string }) =>
                                            option.value === value
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row mb-10">
                              <Input
                                label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                requiredIndicator="required"
                                placeholder={t(
                                  'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                )}
                                onKeyDown={handlePrice}
                                errors={errors?.channels?.rcsConversational?.price}
                                {...register('channels.rcsConversational.price', {
                                  required: !channelConfig.rcsConversational,
                                  pattern: {
                                    value: priceRegex,
                                    message: t('forms.invalid-FeedBack.valid-price')
                                  }
                                })}
                              />
                            </div>
                          </>
                        )}
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-info">
                                <i className="ki-duotone ki-messages fs-2x text-info">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                {t('areas-page.modal-add-area.stepper-3-ch3')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-3-rcs-subtitle')}
                              </span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              value="1"
                              name="framework"
                              checked={channelConfig.rcsSingle}
                              type="checkbox"
                              onChange={() =>
                                setchannelConfig((prevConfig) => ({
                                  ...prevConfig,
                                  rcsSingle: !prevConfig.rcsSingle
                                }))
                              }
                            />
                          </span>
                        </label>
                        {channelConfig.rcsSingle && (
                          <>
                            <div className="fv-row mb-5">
                              <label className="form-label required">
                                {t('areas-page.modal-add-area.stepper-3-input-agents')}
                              </label>
                              <Controller
                                control={control}
                                name="channels.rcsSingle._id"
                                rules={{ required: !channelConfig.rcsSingle }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      options={listData.channelRcsSingle}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          No hay opciones disponibles.
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? 'form-select-custom is-invalid'
                                          : 'form-select-custom'
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={t(
                                        'areas-page.modal-add-area.stepper-3-input-agents-placeholder'
                                      )}
                                      onChange={(
                                        selectedOption: { value: string; label: string } | null
                                      ) => {
                                        onChange(selectedOption ? selectedOption.value : null);
                                      }}
                                      value={
                                        listData.channelRcsSingle.find(
                                          (option: { value: string; label: string }) =>
                                            option.value === value
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row mb-10">
                              <Input
                                label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                requiredIndicator="required"
                                placeholder={t(
                                  'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                )}
                                onKeyDown={handlePrice}
                                errors={errors.channels?.rcsSingle?.price}
                                {...register('channels.rcsSingle.price', {
                                  required: !channelConfig.rcsSingle,
                                  pattern: {
                                    value: priceRegex,
                                    message: t('forms.invalid-FeedBack.valid-price')
                                  }
                                })}
                              />
                            </div>
                          </>
                        )}
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-info">
                                <i className="ki-duotone ki-messages fs-2x text-info">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                {t('areas-page.modal-add-area.stepper-3-ch4')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-3-rcs-subtitle')}
                              </span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              value="1"
                              name="framework"
                              checked={channelConfig.rcsBasic}
                              type="checkbox"
                              onChange={() =>
                                setchannelConfig((prevConfig) => ({
                                  ...prevConfig,
                                  rcsBasic: !prevConfig.rcsBasic
                                }))
                              }
                            />
                          </span>
                        </label>
                        {channelConfig.rcsBasic && (
                          <>
                            <div className="fv-row mb-5">
                              <label className="form-label required">
                                {t('areas-page.modal-add-area.stepper-3-input-agents')}
                              </label>
                              <Controller
                                control={control}
                                name="channels.rcsBasic._id"
                                rules={{ required: !channelConfig.rcsBasic }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      options={listData.channelRcsBasic}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {t('general.no-options')}
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? 'form-select-custom is-invalid'
                                          : 'form-select-custom'
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={t(
                                        'areas-page.modal-add-area.stepper-3-input-agents-placeholder'
                                      )}
                                      onChange={(
                                        selectedOption: { value: string; label: string } | null
                                      ) => {
                                        onChange(selectedOption ? selectedOption.value : null);
                                      }}
                                      value={
                                        listData.channelRcsBasic.find(
                                          (option: { value: string; label: string }) =>
                                            option.value === value
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row mb-10">
                              <Input
                                label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                requiredIndicator="required"
                                placeholder={t(
                                  'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                )}
                                onKeyDown={handlePrice}
                                errors={errors.channels?.rcsBasic?.price}
                                {...register('channels.rcsBasic.price', {
                                  required: !channelConfig.rcsBasic,
                                  pattern: {
                                    value: priceRegex,
                                    message: t('forms.invalid-FeedBack.valid-price')
                                  }
                                })}
                              />
                            </div>
                          </>
                        )}
                        <label className="d-flex flex-stack cursor-pointer mb-5">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-circle symbol-50px me-6">
                              <span className="symbol-label bg-light-warning">
                                <i className="ki-duotone ki-message-notif fs-2x text-warning">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                </i>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                {t('areas-page.modal-add-area.stepper-3-ch5')}
                                <span className="fs-8 text-muted ms-1">
                                  ({t('general.optional')})
                                </span>
                              </span>
                              <span className="fs-7 text-muted">
                                {t('areas-page.modal-add-area.stepper-3-ch5-subtitle')}
                              </span>
                            </span>
                          </span>
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              value="1"
                              name="framework"
                              checked={channelConfig.sms}
                              type="checkbox"
                              onChange={() =>
                                setchannelConfig((prevConfig) => ({
                                  ...prevConfig,
                                  sms: !prevConfig.sms
                                }))
                              }
                            />
                          </span>
                        </label>
                        {channelConfig.sms && (
                          <>
                            <div className="fv-row mb-5">
                              <label className="form-label required">
                                {t('areas-page.modal-add-area.stepper-3-input-mask')}
                              </label>
                              <Controller
                                control={control}
                                name="channels.sms._id"
                                rules={{ required: !channelConfig.sms }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      options={listData.channelSMS}
                                      noOptionsMessage={() => (
                                        <div className="empty-select">
                                          {t('general.no-options')}
                                        </div>
                                      )}
                                      className={
                                        error
                                          ? 'form-select-custom is-invalid'
                                          : 'form-select-custom'
                                      }
                                      classNamePrefix="form-select-custom"
                                      placeholder={t(
                                        'areas-page.modal-add-area.stepper-3-input-mask-placeholder'
                                      )}
                                      onChange={(
                                        selectedOption: { value: string; label: string } | null
                                      ) => {
                                        onChange(selectedOption ? selectedOption.value : null);
                                      }}
                                      value={
                                        listData.channelSMS.find(
                                          (option: { value: string; label: string }) =>
                                            option.value === value
                                        ) || null
                                      }
                                    />
                                    <InvalidFeedback error={error} />
                                  </>
                                )}
                              />
                            </div>
                            <div className="fv-row mb-10">
                              <Input
                                label={t('areas-page.modal-add-area.stepper-3-input-price')}
                                requiredIndicator="required"
                                placeholder={t(
                                  'areas-page.modal-add-area.stepper-3-input-price-placeholder'
                                )}
                                onKeyDown={handlePrice}
                                errors={errors.channels?.sms?.price}
                                {...register('channels.sms.price', {
                                  required: !channelConfig.sms,
                                  pattern: {
                                    value: priceRegex,
                                    message: t('forms.invalid-FeedBack.valid-price')
                                  }
                                })}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*Botones*/}
                  <div className="d-flex flex-stack mb-10">
                    <div className="me-2">
                      <Button
                        type="button"
                        typeStepperBtn="previous"
                        id="previous"
                        className="btn btn-lg btn-secondary w-100 mb-5 hover-scale"
                      >
                        <i className="ki-duotone ki-double-left ms-2 fs-1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        {t('general.back')}
                      </Button>
                    </div>
                    <div>
                      <div className="me-2">
                        <Button
                          type="submit"
                          typeStepperBtn="submit"
                          id="sendData"
                          className="btn btn-lg btn-primary w-100 mb-5 hover-scale"
                          disabled={!isValid || !isCurrentStepValid}
                          isLoading={isSubmitting}
                        >
                          {t('general.send')}
                          <i className="ki-duotone ki-send ms-2 fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-lg btn-primary w-100 mb-5 hover-scale"
                          id="next"
                          typeStepperBtn="next"
                          disabled={!isCurrentStepValid}
                        >
                          {t('general.continue')}
                          <i className="ki-duotone ki-double-right ms-2 fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCompany;
