import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Aside from '../../../components/layout/asaid.component';
import Header from '../../../components/layout/header.component';
import Footer from '../../../components/layout/footer.component';
import CustomDataTable from '../../../components/datatable/datatable.component';
import useBgPages from '../../../hooks/bg-pages.hook';
import useRequest from '../../../hooks/use-request.hook';
import style from './templates-wa.module.scss';

function TemplatesWaPage() {
  useBgPages();
  const { t } = useTranslation();
  const { call, isRequesting } = useRequest();
  const [rows, setRows] = useState<any>([]);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [totalTemplates, seTotalTemplates] = useState(0);
  const [marketing, setMarketing] = useState(0);
  const [utility, setUtility] = useState(0);
  const [authentication, setAuthentication] = useState(0);
  const navigate = useNavigate();
  const columns = [
    {
      name: t('templates.wa.name'),
      cell: (row: any) => (
        <div>
          <h5>{row.name}</h5>
        </div>
      )
    },
    {
      name: t('templates.wa.language'),
      center: true,
      cell: (row: any) => {
        switch (row.language) {
          case 'es_MX':
            return (
              <img
                src={'/assets/media/flags/mexico.svg'}
                className="rounded-circle me-2"
                height={'16px'}
                title={`language-Spanish`}
              />
            );
          case 'en':
            return (
              <img
                src={'/assets/media/flags/united-states.svg'}
                className="rounded-circle me-2"
                height={'16px'}
                title={`language-English`}
              />
            );
        }
      }
    },
    {
      name: t('templates.wa.status'),
      cell: (row: any) => {
        switch (row.status) {
          case 'APPROVED':
            return (
              <span className="badge badge-light-primary fs-6 btn-sm">
                <i className="ki-duotone ki-check-circle text-primary fs-3">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                {t('templates.wa.approved')}
              </span>
            );
          case 'REJECTED':
            return (
              <span className="badge badge-light-danger fs-6">
                <i className="ki-duotone ki-minus-circle text-danger fs-3">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                {t('templates.wa.rejected')}
              </span>
            );
          default:
            return (
              <span className="badge badge-light-secondary fs-6">
                <i className="ki-duotone ki-question-circle text-secondary fs-3">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                {t('templates.wa.unknown')}
              </span>
            );
        }
      }
    },
    {
      name: t('templates.wa.category'),
      cell: (row: any) => (
        <div>
          <h5>
            {row.category.toLowerCase().charAt(0).toUpperCase() +
              row.category.slice(1).toLowerCase()}
          </h5>
        </div>
      )
    },
    {
      name: t('templates.wa.preview'),
      sortable: true,
      center: true,
      cell: (row: any) => (
        <button
          className="btn btn-light btn-sm hover-scale fw-bold text-nowrap"
          onClick={() => setSelectedRow(row)}
        >
          <i className="ki-duotone ki-eye fs-2">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          {t('templates.wa.preview')}
        </button>
      )
    },
    {
      name: t('templates.wa.actions'),
      sortable: false,
      center: true,
      exportable: false,
      cell: (row: any) => {
        return (
          <>
            <div>
              <button
                className="btn btn-light-primary btn-flex btn-center btn-sm"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                type="button"
              >
                {t('templates.wa.actions')}
                <i className="ki-duotone ki-down fs-5 ms-1" />
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <a
                    className="menu-link px-3"
                    // onClick={() => setShowModal1({ show: true, data: row })}
                  >
                    <i className="ki-duotone ki-notepad-edit fs-2x text-primary">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span className="ms-2">{t('templates.wa.edit')}</span>
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  ];

  const getTemplates = async () => {
    const data = await call(() => [
      {
        name: 'plantilla_header_video',
        components: [
          {
            type: 'HEADER',
            format: 'VIDEO',
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/427878400_850465669862642_5631878804366147916_n.mp4?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=7KKN8c3Ioy4Q7kNvgEkENHi&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&oh=01_Q5AaIM-CugLB2iXkeIxb6IL7-EbaRoM5o2kJkCu_GLzPpOve&oe=66D1DCD9'
              ]
            }
          },
          {
            type: 'BODY',
            text: 'Mira el video de este meteorito 😍'
          }
        ],
        language: 'es_MX',
        status: 'APPROVED',
        category: 'MARKETING',
        sub_category: 'CUSTOM',
        id: '850465666529309'
      },
      {
        name: 'plantilla_header_location',
        components: [
          {
            type: 'HEADER',
            format: 'LOCATION'
          },
          {
            type: 'BODY',
            text: 'Hola, te compartimos la ubicación de tu cita'
          }
        ],
        language: 'es_MX',
        status: 'APPROVED',
        category: 'MARKETING',
        sub_category: 'CUSTOM',
        id: '822967479978949'
      },
      {
        name: 'plantilla_header_documento',
        components: [
          {
            type: 'HEADER',
            format: 'DOCUMENT',
            example: {
              header_handle: [
                'https://scontent.whatsapp.net/v/t61.29466-34/437128306_8415723355105513_730425529134924363_n.pdf?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=z-Jl3ij2TcYQ7kNvgFmA_2g&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&oh=01_Q5AaIJkcpVRuZh7DzM_6yACSZFlBds9nzNu6dBHRfCl0Y-sg&oe=66D1C475'
              ]
            }
          },
          {
            type: 'BODY',
            text: 'Hola {{1}}\n\nTe enviamos tu volante de adminisión',
            example: {
              body_text: [['Luis']]
            }
          }
        ],
        language: 'es_MX',
        status: 'APPROVED',
        category: 'MARKETING',
        sub_category: 'CUSTOM',
        id: '8415723348438847'
      },
      {
        name: 'plantilla_header_texto',
        components: [
          {
            type: 'HEADER',
            format: 'TEXT',
            text: 'Bienvenido {{1}}',
            example: {
              header_text: ['Luis']
            }
          },
          {
            type: 'BODY',
            text: 'Hola {{1}}, esta es una prueba de template.',
            example: {
              body_text: [['Luis']]
            }
          },
          {
            type: 'FOOTER',
            text: 'Pie de mensaje'
          },
          {
            type: 'BUTTONS',
            buttons: [
              {
                type: 'QUICK_REPLY',
                text: 'Quick Reply'
              },
              {
                type: 'URL',
                text: 'Visit website',
                url: 'https://voices.com.mx/'
              },
              {
                type: 'URL',
                text: 'Visit website dinamico',
                url: 'https://voices.com.mx/{{1}}',
                example: ['https://voices.com.mx/qwerty']
              },
              {
                type: 'PHONE_NUMBER',
                text: 'Call phone number',
                phone_number: '+525544332211'
              },
              {
                type: 'COPY_CODE',
                text: 'Copy offer code',
                example: ['1234567']
              }
            ]
          }
        ],
        language: 'en',
        status: 'APPROVED',
        category: 'MARKETING',
        sub_category: 'CUSTOM',
        id: '991917352478767'
      }
    ]);

    setRows(data);
    seTotalTemplates(data.length);
    setMarketing(data.filter((x: any) => x.category === 'MARKETING').length);
    setUtility(data.filter((x: any) => x.category === 'UTILITY').length);
    setAuthentication(data.filter((x: any) => x.category === 'AUTHENTICATION').length);
  };

  useEffect(() => {
    const checkTheme = () => {
      const isDark = document.documentElement.getAttribute('data-bs-theme') === 'dark';
      setIsDarkTheme(isDark);
    };
    checkTheme();
    const observer = new MutationObserver(() => checkTheme());
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-bs-theme']
    });
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    getTemplates();
  }, []);
  return (
    <>
      <div className="page d-flex flex-row flex-column-fluid">
        <Aside />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="toolbar" id="kt_toolbar">
              <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                <div className="d-flex flex-column align-items-start justify-content-center flex-wrap me-2">
                  <ul className="breadcrumb fw-semibold fs-base my-1">
                    <li className="breadcrumb-item text-muted">{t('templates.wa.tools')}</li>
                    <li className="breadcrumb-item text-muted">
                      {t('templates.wa.templates-title')}
                    </li>
                  </ul>
                  <h1 className="fw-bold fw-bold my-1 fs-2">
                    <span className="d-flex align-items-center">
                      <a
                        className="symbol symbol-circle symbol-50px hover-scale cursor-pointer"
                        onClick={() => navigate('/channels/wa')}
                      >
                        <span className="symbol-label bg-light-primary">
                          <i className="ki-duotone ki-double-left fs-2x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                      </a>
                      <i className="ki-duotone ki-whatsapp fs-3x ms-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      <span className="ms-2"> {t('templates.wa.wa-title')}</span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <div className="post fs-6 d-flex flex-column-fluid" id="kt_post">
              <div className="container-xxl">
                <div className="card mb-5 mb-xl-10">
                  <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                      <div className="me-7 mb-4">
                        <i className="ki-duotone ki-chart-pie-3 fs-4x hover-scale me-2 mt-10">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                        </i>
                      </div>
                      <div className="flex-grow-1">
                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-4">
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                              <a
                                className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                                href="#"
                              >
                                {t('general.estatistics')}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap flex-stack">
                          <div className="d-flex flex-column flex-grow-1 pe-8">
                            <div className="d-flex flex-wrap">
                              <div className="alert alert-dismissible bg-light-primary border-dashed border-primary rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <i className="ki-duotone ki-whatsapp fs-2x hover-scale me-2 text-primary">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </i>
                                  <div className="fs-2 fw-bold">{totalTemplates}</div>
                                </div>
                                <div className="fw-semibold fs-4 text-primary">
                                  {t('templates.wa.templates-title')}
                                </div>
                              </div>
                              <div className="alert alert-dismissible bg-gray-200 border-dashed border-gray-500 rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <i className="ki-duotone ki-price-tag fs-2x hover-scale me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                    <span className="path5"></span>
                                    <span className="path6"></span>
                                  </i>
                                  <div className="fs-2 fw-bold">{marketing}</div>
                                </div>
                                <div className="fw-semibold fs-4 text-gray-700">
                                  {t('templates.wa.marketing-name')}
                                </div>
                              </div>
                              <div className="alert alert-dismissible bg-gray-200 border-dashed border-gray-500 rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <i className="ki-duotone ki-notification fs-2x hover-scale me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                  </i>
                                  <div className="fs-2 fw-bold">{utility}</div>
                                </div>
                                <div className="fw-semibold fs-4 text-gray-700">
                                  {t('templates.wa.utility-name')}
                                </div>
                              </div>
                              <div className="alert alert-dismissible bg-gray-200 border-dashed border-gray-500 rounded min-w-125px py-3 px-4 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <i className="ki-duotone ki-key fs-2x hover-scale me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                    <span className="path5"></span>
                                    <span className="path6"></span>
                                  </i>
                                  <div className="fs-2 fw-bold">{authentication}</div>
                                </div>
                                <div className="fw-semibold fs-4 text-gray-700">
                                  {t('templates.wa.authentication-name')}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                            <div className="d-flex justify-content-end w-100 mt-auto mb-2">
                              <a
                                className="btn btn-primary hover-scale fw-bold"
                                onClick={() => navigate('/templates/create-template-WA')}
                              >
                                <i className="ki-duotone ki-abstract-10 fs-2">
                                  <span className="path1"></span>
                                  <span className="path2"></span>
                                </i>
                                {t('templates.wa.add-btn')}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-4 fw-bold">
                      <li className="nav-item"></li>
                      <li className="nav-item"></li>
                    </ul>
                  </div>
                </div>
                <div className="row g-xl-10">
                  <div className="col-xl-8 mb-5">
                    <div className="card">
                      <div className="card-header align-items-center border-0 mt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="fw-bolder fw-bold fs-2">
                            {t('templates.wa.templates-title')}
                          </span>
                          <span className="text-gray-400 mt-2 fw-semibold fs-6">
                            {t('templates.wa.registered')}
                          </span>
                        </h3>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body pt-1">
                        <CustomDataTable columns={columns} data={rows} isLoading={isRequesting} />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div
                      className="mb-5"
                      data-kt-sticky="true"
                      data-kt-sticky-name="docs-sticky-summary"
                      data-kt-sticky-offset="{default: true, xl: '330px'}"
                      data-kt-sticky-top="100px"
                      data-kt-sticky-animation="true"
                      data-kt-sticky-zindex="95"
                    >
                      {!selectedRow ? (
                        <div className={isDarkTheme ? 'wrap-iphone-wa-dark' : 'wrap-iphone-wa'}>
                          <div className={`${style.bg_whatsApp}`}></div>
                        </div>
                      ) : (
                        <div className={isDarkTheme ? 'wrap-iphone-wa-dark' : 'wrap-iphone-wa'}>
                          <div className={`${style.bg_whatsApp}`}>
                            <div className={`${style.container_message}`}>
                              <div className={`${style.sticky}`}>
                                <div className="mt-3 me-2">
                                  <div
                                    className={`${isDarkTheme ? style.preview_template_dark : style.preview_template}`}
                                  >
                                    {selectedRow.components.map((component: any, index: number) => {
                                      if (component.type === 'HEADER') {
                                        if (component.format === 'TEXT') {
                                          return (
                                            <div
                                              key={index}
                                              className={`${isDarkTheme ? style.preview_template_dark_header : style.preview_template_header}`}
                                            >
                                              {component.text}
                                            </div>
                                          );
                                        }
                                        if (component.format === 'IMAGE') {
                                          return (
                                            <div
                                              key={index}
                                              className={`${isDarkTheme ? style.preview_template_dark_header : style.preview_template_header}`}
                                            >
                                              <div
                                                className={`${isDarkTheme ? style.preview_template_dark_image : style.preview_template_image}`}
                                                style={{
                                                  backgroundImage: `url(${component.example.header_handle[0]})`
                                                }}
                                              >
                                                {!component.example.header_handle[0] && (
                                                  <i className="bi-card-image"></i>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (component.format === 'VIDEO') {
                                          return (
                                            <div
                                              key={index}
                                              className={`${isDarkTheme ? style.preview_template_dark_header : style.preview_template_header}`}
                                            >
                                              <div
                                                className={`${isDarkTheme ? style.preview_template_dark_video : style.preview_template_video}`}
                                              >
                                                <i className="bi-play-circle-fill"></i>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (component.format === 'DOCUMENT') {
                                          return (
                                            <div
                                              key={index}
                                              className={`${isDarkTheme ? style.preview_template_dark_header : style.preview_template_header}`}
                                            >
                                              <div
                                                className={`${isDarkTheme ? style.preview_template_dark_video : style.preview_template_video}`}
                                              >
                                                <i className="bi-filetype-pdf"></i>
                                              </div>
                                            </div>
                                          );
                                        }
                                        if (component.format === 'LOCATION') {
                                          return (
                                            <div
                                              key={index}
                                              className={`${isDarkTheme ? style.preview_template_dark_header : style.preview_template_header}`}
                                            >
                                              <div
                                                className={`${isDarkTheme ? style.preview_template_dark_video : style.preview_template_video}`}
                                              >
                                                <i className="bi-geo-alt"></i>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                      if (component.type === 'BODY') {
                                        return (
                                          <div
                                            key={index}
                                            dangerouslySetInnerHTML={{
                                              __html: component.text.replace(/\n/g, '<br/>')
                                            }}
                                          ></div>
                                        );
                                      }
                                      if (component.type === 'FOOTER') {
                                        return (
                                          <div
                                            key={index}
                                            className={`${isDarkTheme ? style.preview_template_dark_footer : style.preview_template_footer}`}
                                          >
                                            {component.text}
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </div>
                                  <div
                                    className={`${isDarkTheme ? style.preview_template_dark_btn_container : style.preview_template_btn_container}`}
                                  >
                                    {selectedRow.components
                                      .filter((component: any) => component.type === 'BUTTONS')
                                      .flatMap((component: any) => component.buttons)
                                      .map((btn: any, btnIndex: number) => (
                                        <div className="me-1">
                                          <div
                                            key={btnIndex}
                                            className={`${isDarkTheme ? style.preview_template_dark_btn : style.preview_template_btn} ${isDarkTheme ? style.preview_template_dark : style.preview_template}`}
                                          >
                                            {btn.type === 'URL' && (
                                              <>
                                                <i className="bi-box-arrow-up-right"></i> {btn.text}
                                              </>
                                            )}
                                            {btn.type === 'QUICK_REPLY' && (
                                              <>
                                                <i className="bi-reply"></i> {btn.text}
                                              </>
                                            )}
                                            {btn.type === 'QUICK_REPLY_DISABLE_MKT' && (
                                              <>
                                                <i className="bi-reply"></i> {btn.text}
                                              </>
                                            )}
                                            {btn.type === 'PHONE_NUMBER' && (
                                              <>
                                                <i className="bi-telephone"></i> {btn.text}
                                              </>
                                            )}
                                            {btn.type === 'COPY_CODE' && (
                                              <>
                                                <i className="bi-clipboard"></i> {btn.text}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default TemplatesWaPage;
