import React from 'react';
import { AlertService } from '../../services/alert.service';

interface NodeData {
  id: string;
  data: {
    label: string;
    description?: string;
    externalId?: string;
    config?: {
      key1?: string;
      key2?: string;
    };
    user?: {
      id: string;
      name: string;
    };
    createdAt?: string;
    actions?: string[];
    icon?: string;
    totalMessage: number;
    status: string;
  };
  position: {
    x: number;
    y: number;
  };
  sourcePosition?: string;
  targetPosition?: string;
  type?: string;
  style?: React.CSSProperties;
}

interface CustomNodeActionsProps {
  node: NodeData;
  updateNodeStatus: (nodeId: string, status: string) => void;
  deleteNode: (nodeId: string) => void;
  getCustomNodeStatus: (nodeId: string) => string | null;
}

const CustomNodeActionsChannel: React.FC<CustomNodeActionsProps> = ({
  node,
  updateNodeStatus,
  deleteNode,
  getCustomNodeStatus
}) => {
  const handleResume = async () => {
    const customNodeStatus = getCustomNodeStatus(node.id);

    if (customNodeStatus === 'paused') {
      AlertService.toastError(
        `La campaña en general está pausada, no se puede reanudar el envío del canal ${node.data.label}.`
      );
      return;
    }

    const confirmed = await AlertService.question(
      `¿Quieres reanudar el envío del canal ${node.data.label}?`,
      '',
      () => Promise.resolve(),
      'Reanudar envío'
    );

    if (!confirmed) return;

    try {
      AlertService.toastSuccess(`¡ Éxito !, Seguiremos con el envío de ${node.data.label}.`);
      updateNodeStatus(node.id, 'process');
    } catch (error) {
      console.error('Error al reanudar el envío:', error);
      AlertService.toastError(
        '¡ Atención !, No se pudo reanudar el envío, Contacta a tu administrador.'
      );
    }
  };

  const handlePause = async () => {
    const customNodeStatus = getCustomNodeStatus(node.id);

    if (customNodeStatus === 'paused') {
      AlertService.toastError(
        `La campaña en general está pausada, no se puede reanudar el envío del canal ${node.data.label}.`
      );
      return;
    }

    const confirmed = await AlertService.question(
      `¿Quieres pausar el envío del canal ${node.data.label}?`,
      'Podrás reanudar el envío en cualquier momento.',
      () => Promise.resolve(),
      'Pausar envío'
    );

    if (!confirmed) return;
    try {
      AlertService.toastSuccess(`¡ Éxito !, Pausaremos el envío de ${node.data.label}.`);
      updateNodeStatus(node.id, 'paused');
    } catch (error) {
      console.error('Error al pausar tu campaña:', error);
      AlertService.toastError(
        '¡ Atención !, No se pudo pausar tu campaña, Contacta a tu administrador.'
      );
    }
  };

  const handleCancel = async () => {
    const confirmed = await AlertService.question(
      `¿Quieres cancelar el envío del canal ${node.data.label}?`,
      'Esta acción no se podrá revertir.',
      () => Promise.resolve(),
      'Cancelar envío'
    );

    if (!confirmed) return;
    try {
      await AlertService.toastSuccess(`¡ Éxito !, Cancelamos el envío de ${node.data.label}.`);
      deleteNode(node.id);
    } catch (error) {
      console.error('Error al cancelar tu envío:', error);
      AlertService.toastError(
        '¡ Atención !, No se pudo cancelar tu envío, Contacta a tu administrador.'
      );
    }
  };

  return (
    <>
      {node.data.status === 'process' ? (
        <>
          <h4 className="mb-5 text-primary">{node.data.label}</h4>
          <div className="d-flex flex-stack">
            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
              <div className="flex-grow-1">
                <a className="text-gray-800 text-hover-primary fs-6 fw-bold">Pausar canal</a>
                <span className="text-muted fw-semibold d-block fs-7">Podrás reanudar después</span>
              </div>
              <a className="btn btn-icon btn-light-warning fs-8 fw-bold" onClick={handlePause}>
                <i className="bi bi-pause-circle fs-2"></i>
              </a>
            </div>
          </div>
          <div className="separator separator-dashed my-4" />
        </>
      ) : (
        <>
          <div className="d-flex flex-stack">
            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
              <div className="flex-grow-1">
                <a className="text-gray-800 text-hover-primary fs-6 fw-bold">Reanudar canal</a>
                <span className="text-muted fw-semibold d-block fs-7">Reanudaremos el envío.</span>
              </div>
              <a className="btn btn-icon btn-light-primary fs-8 fw-bold" onClick={handleResume}>
                <i className="bi bi-play-circle-fill fs-2"></i>
              </a>
            </div>
          </div>
          <div className="separator separator-dashed my-4" />
        </>
      )}
      <div className="d-flex flex-stack">
        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
          <div className="flex-grow-1">
            <a className="text-gray-800 text-hover-primary fs-6 fw-bold">Cancelar canal</a>
            <span className="text-muted fw-semibold d-block fs-7">No se puede revertir.</span>
          </div>
          <a className="btn btn-icon btn-light-danger fs-8 fw-bold" onClick={handleCancel}>
            <i className="bi bi-stop-circle fs-2"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default CustomNodeActionsChannel;
