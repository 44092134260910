import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
interface InvalidFeedbackProps {
  error: any;
  className?: any;
}

function InvalidFeedback({ error, className = '' }: InvalidFeedbackProps) {
  const { t } = useTranslation();

  const getType = useCallback(() => {
    switch (error?.type) {
      case 'required':
        return `${t('forms.invalid-FeedBack.required-file-error')}`;
      case 'maxLength':
        return t('forms.invalid-FeedBack.maxlength', { message: error.message });
      case 'minLength':
        return t('forms.invalid-FeedBack.minlength', { message: error.message });
      case 'min':
        return `${error.message}`;
      case 'max':
        return `${error.message}`;
      case 'custom':
        return `${error.message}`;
      case 'pattern':
        return `${error.message}`;
      default:
        return `${t('forms.invalid-FeedBack.required-file-error')}`;
    }
  }, [error?.type]);

  return error ? <span className={`invalid-feedback ${className}`}>{getType()}</span> : <></>;
}

export default InvalidFeedback;
