import React from 'react';
import InvalidFeedback from './invalid-feedback.component';

export default React.forwardRef(({ label, errors, ...register }: any, ref) => {
  return (
    <>
      <div className="form-check form-switch form-check-custom form-check-solid">
        <input
          type="checkbox"
          className="form-check-input h-30px w-50px cursor-pointer"
          ref={ref}
          {...register}
        />
        <label className="form-check-label">{label}</label>
      </div>
      <InvalidFeedback error={errors} />
    </>
  );
});
