import { useTranslation } from 'react-i18next';
import style from './loader.module.scss';

function Loader() {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ height: '500px' }}>
        <div className={`${style.pulse}`}></div>
        <h3 className={style.textloader}>{t('general.loader')}</h3>
      </div>
    </>
  );
}

export default Loader;
