import { API_ENDPOINT } from '../constants';
import { HTTP } from './http.service';

export const CompaniesService = {
  getCompanies: () => {
    return HTTP.get<any, any>(`${API_ENDPOINT.CORE}companies`);
  },
  saveCompany: (data: any) => {
    return HTTP.post<any, any>(`${API_ENDPOINT.CORE}wizard/company`, data);
  },
  editCompany: (data: any) => {
    return HTTP.put<any, any>(`${API_ENDPOINT.CORE}companies/`, data);
  }
};
